import { Autocomplete, TextField, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useContext, useEffect, useState } from 'react'
import { ZeroApiContext } from '../app/Organisation'
import { UserContext } from '../app/UserApp'
import { FormField } from './useFormQuery'
import { callOrGet, ifNull, preventPropagation } from './utils'
import { GqlTabletField } from "../table/GqlTable";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-grid',
    margin: theme.spacing(1),
    minWidth: '18ch',
    flexGrow: 1,
    '& > .MuiTextField-root': {
      display: 'grid',
      width: '-webkit-fill-available',
    },
  },
}))

interface Option {
  id: string
  name: string
}

const filterOptions = (options: Option[], params: { inputValue: string }) => {
  return options.filter((option) => option.name && option.name.cyrillicToLatin().indexOf(params.inputValue.cyrillicToLatin()) > -1)
}

interface FormFieldProps extends GqlTabletField<string> {
  options: Option[]
  onClear?: () => void
}

export default function OptionsAutoComplete(props: FormFieldProps) {
  const classes = useStyles()

  const user = useContext(UserContext)

  const [isOpen, setIsOpen] = useState(false)

  const item = props.item || props.rowData
  const propsValue = props.name && props.value && typeof props.value == 'object' ? props.value[props.name] : props.value
  const value = ifNull(propsValue, callOrGet(props.default, item))

  const onChange = props.onChange !== undefined ? props.onChange : () => {}

  let selected: Option | null = null

  const options = props.options
  options?.forEach((item: any) => {
    if (item.id === value) {
      selected = item
    }
  })

  useEffect(() => {
    if (props.name && selected && item && typeof props.value == 'object') {
      console.log('useEffect')
      item[props.name + 'Val'] = props.value
      onChange(selected.id)
    }
  }, [typeof props.value == 'object'])

  const fieldSize = props.size === 'small' ? 'small' : 'medium'

  return (
    <Autocomplete
      className={classes.root}
      onOpen={() => {
        setIsOpen(true)
      }}
      onClose={() => {
        setIsOpen(false)
      }}
      autoHighlight
      selectOnFocus
      fullWidth={props.fullWidth}
      handleHomeEndKeys
      options={options}
      filterOptions={filterOptions}
      value={selected}
      onChange={(event, value) => (props.nullable || (value && value.id) ? onChange(value ? value.id : null) : undefined)}
      onInputChange={(e, value) => value.length == 0 && props.onClear && props.onClear()}
      onSubmit={props.onSubmit}
      disabled={callOrGet(props.disabled, value, item)}
      size={fieldSize}
      onKeyDown={(event) => preventPropagation(event, isOpen)}
      isOptionEqualToValue={(option, value) => value && option.id == value.id}
      getOptionLabel={(option) => option?.name || ''}
      renderInput={(params) => {
        params.inputProps.autoCorrect = 'off'
        params.inputProps.autoCapitalize = 'off'
        params.inputProps.style = { fontSize: props.size == 'small' ? 12 : 14 }
        return (
          <TextField
            {...params}
            label={
              props.isForm
                ? React.isValidElement(props.label)
                  ? props.label
                  : user.translate(props.label?.toString() || props.name.snakeCase())
                : undefined
            }
            error={callOrGet(props.error, value)}
            variant={props.isForm ? 'outlined' : 'standard'}
            size={fieldSize}
            fullWidth={props.fullWidth}
            InputProps={{
              ...params.InputProps,
              startAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
            }}
          />
        )
      }}
    />
  )
}
OptionsAutoComplete.gql = 'String'

const Render = (props: FormFieldProps) => {
  const propsValue = props.name && props.value && typeof props.value == 'object' ? props.value[props.name] : props.value
  const value = ifNull(propsValue, callOrGet(props.default, props.item))
  let selected = props.options.find((item: any) => item.id === value)?.name || {}
  if (selected) {
    return <div style={{ display: 'inline-block' }}>{selected}</div>
  }
  return <div style={{ display: 'inline-block' }}/>
}

OptionsAutoComplete.render = (props: FormFieldProps, rowValue: string, row: any) => {
  return <Render {...props} value={rowValue} rowData={row} />
}
