import { gql, useMutation } from "@apollo/client"
import * as React from "react"
import { useNavigate } from "react-router"
import { UserContext } from "../app/UserApp"
import AuthScreen from "./AuthScreen"

const GQL_LOGIN = gql`
  mutation ($email: String!, $password: String!, $phone: String) {
    auth_login(email: $email, password: $password, phone: $phone) {
      ok
      message
      profile {
        id
        name
      }
    }
  }
`

interface Props {
  onLogin: () => Promise<any>
}

export default function SignIn(props: Props) {
  const user = React.useContext(UserContext)

  const navigate = useNavigate()

  const [login, { loading, data }] = useMutation(GQL_LOGIN, {
    errorPolicy: "ignore",
  })
  const response = data?.auth_login

  const onSubmit = (data: FormData) => {
    login({
      fetchPolicy: "network-only",
      variables: {
        email: data.get("email"),
        password: data.get("password"),
      },
    })
      .then((e) => {
        if (e.data?.auth_login?.ok === true) {
          props.onLogin().then(() => {
            navigate("/")
          })
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }

  return (
    <AuthScreen
      title={user.translate("title_sign_in")}
      actionTitle={user.translate("button_sign_in")}
      onSubmit={onSubmit}
      error={response?.ok === false && response?.message}
      info={response?.ok === true && response?.message}
      isLoading={loading || response?.ok === true}
      fields={[
        { name: "email", label: user.translate("label_email_address") },
        { name: "password", label: user.translate("label_password"), type: "password" },
      ]}
      actions={[
        { name: "forgot", label: user.translate("button_forgot_password") },
        { name: "register", label: user.translate("button_sign_up_back") },
      ]}
      rememberMe
    />
  )
}
