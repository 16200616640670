import { gql, useMutation, useQuery } from '@apollo/client'
import { useEffect, useMemo, useRef, useState } from 'react'
import { uid } from '../common/useUID'
import { callOrGet } from '../form/utils'
import useLazyEffect from '../hooks/useLazyEffect'
import generateGql from './generateGql'
import { Field, GqlFilter, GqlQuery, GqlRWQuery, GqlSelection, QueryOptions } from './interface'

interface GqlAction {
  type: string
  params: any
  autoRefetch?: boolean
}

export function useEntityRelationQuery(
  entity: string,
  fields: Field<any>[],
  relField = 'organisationId',
  relValue: any,
  options: QueryOptions = {},
) {
  const isPageVisible = true // usePageVisibility()

  const promiseRef = useRef<{ resolve: (value: any) => void; reject: (value: any) => void }>()
  const [action, setAction] = useState<GqlAction>()

  const allFields = fields || []
  const gqlFields = allFields.filter((field) => field.gql !== undefined)

  const fieldsKey = fields.map((field) => field.name + field.subSelection)
  const entityName = options.single ? entity : `${entity}s`
  const getItemsMethodName = options.all
    ? `getAll${entity}s`
    : relField === 'organisationId'
    ? `get${entity}sByContextOrganisationId`
    : `get${entity}By${relField.capitalize()}`

  const gqlGetList = useMemo(() => {
    if (options.mode === 'editOnly' || gqlFields.length == 0) return gql``

    var filter: GqlFilter | undefined = undefined
    const addFilter = (filterOptions: GqlFilter) => {
      if (filter) {
        filterOptions.and = filter
      }
      filter = filterOptions
    }
    if (options.dateRangeFilter)
      addFilter({
        by: options.dateRangeField || 'created',
        gte: options.dateRangeFilter.startDate.getTime() || 0,
        lte: options.dateRangeFilter.endDate.getTime() || new Date().getTime(),
      })
    if (options.filter) addFilter(options.filter)

    const args = { ...options.args }
    if (!options.all) {
      args['$' + relField] = 'String!'
    }

    if (filter) {
      args.filter = filter
    }
    const unwrapArgs: (container: Field<any>[]) => GqlSelection = (container: Field<any>[]) =>
      container.toMapBy(
        (field) => field.name,
        (field) => {
          if (field.nested) {
            if (!field.subSelection) throw Error('subSelection must be set with nested = true')
            return unwrapArgs(Object.values(field.subSelection))
          }
          return field.subSelection || true
        },
      )

    const gqlContent = generateGql({
      [getItemsMethodName]: {
        args,
        select: unwrapArgs(gqlFields),
      },
    })

    return gql(gqlContent)
  }, [entityName, fieldsKey, relField, relValue, JSON.stringify(options.filter)])

  const saveGqlFields = gqlFields.filter((field) => field.readOnly !== true && field.virtual !== true)

  const gqlCreateItem = useMemo(() => {
    if (options.mode === 'readOnly' || saveGqlFields.length === 0) return gql``

    const dependentQueries: { [key: string]: GqlQuery } = {}

    const unwrap: (container: Field<any>[], prefix: string) => GqlSelection = (container: Field<any>[], prefix: string) => {
      const items = container.groupBy((it) => (it.dependent ? 'dependent' : 'independent'))
      items.dependent?.forEach((field) => {
        if (!field.subSelection) throw Error('field.dependent = true, requires subSelection')
        dependentQueries[`create${field.name.capitalize()}`] = {
          args: {
            input: unwrap(
              Object.values(field.subSelection).filter((field) => field.readOnly !== true && field.virtual !== true),
              `${prefix}${field.name}_`,
            ),
          },
          select: (field.keys || ['id', '_id']).toMapBy(
            (key) => key,
            (key) => true,
          ),
        }
      })
      return items.independent?.toNotNullMapBy(
        (field) => (field.dependent ? null : field.nested ? field.gqlCreate || '' : `$${field.name}`),
        (field) => {
          if (field.nested) {
            if (!field.subSelection) throw Error('field.nested = true, requires subSelection')
            let nestedItems = Object.values(field.subSelection).filter((field) => field.readOnly !== true && field.virtual !== true)
            const nestedFields = unwrap(nestedItems, `${prefix}${field.name}_`)
            if (!nestedFields || Object.keys(nestedFields).length === 0) return null
            return nestedFields
          }
          if (prefix) return { alias: '$' + prefix + field.name, type: field.gql }

          return field.gql
        },
      )
    }

    const input = unwrap(saveGqlFields, '')

    const gqlContent = generateGql(
      {
        [`create${entity}`]: {
          args: { input },
          select: (options.keys || ['id', '_id']).toMapBy(
            (key) => key,
            (key) => true,
          ),
        },
        ...dependentQueries,
      },
      'mutation',
    )

    return gql(gqlContent)
  }, [entityName, fields])

  const gqlDeleteItem = useMemo(() => {
    if (options.mode === 'readOnly' || gqlFields.length == 0) return gql``
    if (options.keys) {
      const gqlDelete = generateGql(
        {
          [`delete${entity}`]: {
            args: options.keys.toMapBy(
              (k) => `$${k}`,
              (k) => 'String!',
            ),
          },
        },
        'mutation',
      )
      return gql(gqlDelete)
    }
    return gql`mutation ($id: String!) { delete${entity}(id: $id)}`
  }, [entity, options.keys, gqlFields.joinOf(',', (field) => field.name)])

  const gqlHardDeleteItem = useMemo(() => {
    if (options.mode === 'readOnly' || gqlFields.length == 0) return gql``

    const queryContent = generateGql(
      {
        [`hardDelete${entity}`]: {
          args: (options.keys || ['id']).toMapBy(
            (key) => '$' + key,
            () => 'String!',
          ),
        },
      },
      'mutation',
    )
    return gql(queryContent)
  }, [entity, options.keys])

  const gqlUnDeleteItem = useMemo(() => {
    if (options.mode == 'readOnly' || gqlFields.length == 0) return gql``

    const queryContent = generateGql(
      {
        [`undelete${entity}`]: {
          args: (options.keys || ['id']).toMapBy(
            (key) => '$' + key,
            () => 'String!',
          ),
        },
      },
      'mutation',
    )
    return gql(queryContent)
  }, [entity, options.keys])

  const variables = options.all ? {} : { [relField]: relValue }

  const runEffect = useLazyEffect()

  const onLoad = options.onLoad

  const {
    data: dataItems,
    loading: loadingItems,
    refetch: refetchItems,
  } = useQuery(gqlGetList, {
    pollInterval: isPageVisible ? (options.pollInterval !== undefined ? options.pollInterval : 8000) : 0,
    skip: options.skip || relValue === undefined || relValue === null,
    onCompleted:
      onLoad &&
      ((data) => {
        runEffect(() => onLoad(data[getItemsMethodName]))
      }),
    variables: variables,
  })

  const [saveItem, { loading: loadingSave }] = useMutation(gqlCreateItem)
  const [deleteItem, { loading: loadingDelete }] = useMutation(gqlDeleteItem)
  const [hardDeleteItem, { loading: loadingHardDelete }] = useMutation(gqlHardDeleteItem)
  const [unDeleteItem, { loading: loadingUnDelete }] = useMutation(gqlUnDeleteItem)

  useEffect(() => {
    if (action?.type == undefined) return
    if (promiseRef.current == undefined) return

    const promise = promiseRef.current
    promiseRef.current = undefined

    const params = action.params || {}
    const autoRefetch = action.autoRefetch != undefined ? action.autoRefetch : true

    if (action.type === 'save') {
      const variables: { [key: string]: any } = {}
      const unwrapVars =
        (prefix = '') =>
        (field: Field<any>) => {
          if (field.virtual) return
          if (field.nested) {
            if (!field.subSelection) throw Error('subSelection is requred when using field.nested = true')
            Object.values(field.subSelection).forEach(unwrapVars(prefix + field.name + '_'))
            return
          }

          const fieldName = prefix + field.name

          if (field.forceValue !== undefined) return (variables[fieldName] = field.forceValue)

          const data = params[fieldName]

          const conv = field.convert || ((a) => a)

          if (data === null && field.gql && field.gql.indexOf('String') > -1) return (variables[fieldName] = 'null')
          if (data !== undefined) return (variables[fieldName] = conv(data))

          if (field.id) return (variables[fieldName] = uid())

          const fieldValue = callOrGet(field.value, params)

          if (fieldValue !== undefined) return (variables[fieldName] = conv(fieldValue))
          if (field.valueFromParent) {
            if (!options.getParentValue) throw Error('valueFromParent is used but options.getParentValue is not provided')
            return (variables[fieldName] = conv(options.getParentValue(field.valueFromParent)))
          }
          if (field.default) {
            if (field.default instanceof Function) {
              return (variables[fieldName] = conv(field.default(params)))
            } else {
              return (variables[fieldName] = conv(field.default))
            }
          }
        }

      gqlFields.forEach(unwrapVars())

      saveItem({ variables })
        .then((e) => {
          if (autoRefetch) return refetchItems()
          return e.data[`create${entity}`]
        })
        .then(promise.resolve)
        .catch(promise.reject)
    } else if (action.type == 'delete') {
      deleteItem({
        variables: params,
      })
        .then((e) => {
          if (autoRefetch) return refetchItems()
          return e.data
        })
        .then(promise.resolve)
        .catch(promise.reject)
    } else if (action.type == 'hardDelete') {
      hardDeleteItem({
        variables: params,
      })
        .then((e) => {
          if (autoRefetch) return refetchItems()
          return e.data
        })
        .then(promise.resolve)
        .catch(promise.reject)
    } else if (action.type == 'unDelete') {
      unDeleteItem({
        variables: params,
      })
        .then((e) => {
          if (autoRefetch) return refetchItems()
          return e.data
        })
        .then(promise.resolve)
        .catch(promise.reject)
    } else {
      console.error('Unknown action', action)
    }

    setAction(undefined)
  }, [action])

  const doAction = (action: string, params: any, autoRefetch: boolean = true) => {
    const promise = new Promise<any>((resolve, reject) => {
      promiseRef.current = { resolve, reject }
    })
    setAction({ type: action, params: params, autoRefetch: autoRefetch })
    return promise
  }

  const dataField = options.single ? 'item' : 'items'

  return {
    isLoading: loadingItems,
    isLoadingAction: loadingSave || loadingDelete || loadingHardDelete || loadingUnDelete,
    [dataField]: dataItems && dataItems[getItemsMethodName],
    refresh: refetchItems,
    saveItem: (itemData: any, autoRefetch: boolean) => doAction('save', itemData, autoRefetch),
    deleteItem: (itemData: any) => doAction('delete', itemData),
    hardDeleteItem: (itemData: any) => doAction('hardDelete', itemData),
    unDeleteItem: (itemData: any) => doAction('unDelete', itemData),
  } as GqlRWQuery<any>
}
