import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { createStyles, makeStyles } from '@mui/styles';
// import { Theme } from '@mui/system';
import * as React from 'react';
import FadeIn from 'react-fade-in';
import Show from '../common/Show';
import useUrlQuery from '../common/useUrlQuery';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://wtouristic.com">
        WTouristic
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: "100vw",
      minHeight: "100vh",
    },
    container: {},
    form: {
      width: "100vw",
      maxWidth: 320,
    },
  })
);

interface FieldParams {
  name: string;
  label: string;
  type?: string;
}

export default function AuthScreen(props: {
  title: string;
  actionTitle: string;
  isLoading: boolean;
  onSubmit: (FormData: FormData) => void;
  fields: FieldParams[];
  actions: FieldParams[];
  error?: string;
  info?: string;
  rememberMe?: boolean;
}) {
  const classes = useStyles();

  const query = useUrlQuery();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    props.onSubmit(data);
  };

  const expandAction = Array.from(props.actions).maxBy((item) => item.label.length);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" className={classes.root}>
        <CssBaseline />
        <FadeIn>
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {props.title}
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} className={classes.form}>
              {props.fields.map((field: FieldParams) => (
                <TextField
                  margin="normal"
                  key={field.name}
                  required
                  fullWidth
                  id={field.name}
                  label={field.label}
                  name={field.name}
                  autoComplete={field.name}
                  type={field.type}
                  autoFocus
                  disabled={props.isLoading}
                  defaultValue={query.get(field.name)}
                />
              ))}
              <Show if={props.rememberMe}>
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                  disabled={props.isLoading}
                />
              </Show>
              <Show if={props.error}>
                <FadeIn transitionDuration={200}>
                  <div style={{ color: "red", fontSize: 14, fontWeight: 100 }}>{props.error}</div>
                </FadeIn>
              </Show>
              <Show if={!props.error && props.info}>
                <FadeIn transitionDuration={200}>
                  <div style={{ color: "gray", fontSize: 14, fontWeight: 100 }}>{props.info}</div>
                </FadeIn>
              </Show>
              <Show if={!props.info && !props.error}>
                <div style={{ fontSize: 14 }}>&nbsp;</div>
              </Show>
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={props.isLoading}>
                {props.actionTitle}
              </Button>
              <Grid container>
                {props.actions.map((field, index) => (
                  <Grid item key={field.name} xs={field === expandAction}>
                    <Link href={`/${field.name}`} variant="body2">
                      {field.label}
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </FadeIn>
      </Container>
    </ThemeProvider>
  );
}
