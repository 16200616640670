import { LinearProgress } from "@mui/material"
import { Theme } from "@mui/material/styles"
import { createStyles, makeStyles } from "@mui/styles"
import * as React from "react"
import FadeIn from "react-fade-in"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      background: "#FFFFFFAA",
      zIndex: 99,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    loading: {
      width: 220,
    },
  })
)

interface Props {
  show?: boolean
}

export default ({ show }: Props) => {
  const classes = useStyles()

  return (
    <FadeIn key="loading" visible={show} transitionDuration={200} delay={150}>
      <div key="loading" className={classes.root}>
        <LinearProgress key="loading" variant="indeterminate" className={classes.loading} />
      </div>
    </FadeIn>
  )
}
