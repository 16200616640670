import * as React from 'react'
import EditText from '../../../../../form/EditText'
import EditTextDateEpoch from '../../../../../form/EditTextDateEpoch'
import { HiddenField } from '../../../../../form/HiddenField'
import FilterField from '../../../../../form/FilterField'
import FormCreators from '../../../../../form/FormCreators'
import NestedItemForm from '../../../../../form/NestedItemForm'
import SelectOption from '../../../../../form/SelectOption'
import { GqlFetchQuery, GqlRWQuery } from '../../../../../gql/interface'
import GqlTable from '../../../../../table/GqlTable'
import useCustomerTitles from '../../../../hooks/useCustomerTitles'
import { ZeroApiContext } from '../../../../Organisation'
import { UserContext } from '../../../../UserApp'
import { SaleCustomer } from '../../Models'
import SaleCustomersToolbarTitle from './SaleCustomersToolbarTitle'

export interface Props {
  saleId?: string
  customers?: SaleCustomer[]
  setCustomers: (data: SaleCustomer[]) => void
  onSelect?: (data: SaleCustomer[], rowData?: SaleCustomer) => void
}

export default function SaleCustomersTable({ saleId, customers, setCustomers, onSelect }: Props) {
  const user = React.useContext(UserContext)
  const organisation = React.useContext(ZeroApiContext)

  const queryRef = React.useRef<GqlFetchQuery<any> | GqlRWQuery<any>>()
  const CustomerTitles = useCustomerTitles()

  const onCustomerItems = (items: any[]) => {
    if (JSON.stringify(items) !== JSON.stringify(customers)) {
      setCustomers(items)
    }
  }

  const short = Boolean(onSelect)

  return (
    <GqlTable
      entity="SaleCustomer"
      onItems={onCustomerItems}
      onSelectionChange={onSelect}
      dense
      noPaging
      noSearch
      readOnly={short}
      noExport={short}
      noTitle={short}
      queryRef={queryRef}
      title={<SaleCustomersToolbarTitle saleId={saleId} onUpdate={queryRef.current?.refresh} />}
      // treeLookup={(row, other) => row.groupItem && !other.groupItem && row.group === other.group}
      options={{
        showSelectGroupCheckbox: true,
      }}
    >
      <HiddenField name="id" gql="String" id />
      <FilterField name="saleId" value={saleId} />
      <EditText name="group" default="" grouping />
      <NestedItemForm name="customer" gql="Customer">
        <HiddenField name="id" gql="String" id />
        <HiddenField name="organisationId" value={organisation.id} />
        <EditText name="intName" default="" width={24} />
        <SelectOption name="title" options={CustomerTitles} width={1} />
        <EditText name="firstName" default="" />
        <EditText name="lastName" default="" />
        <EditText name="passport" default="" />
        <EditText name="email" default="" nullable hidden={short} />
        <EditText name="phone" default="" nullable hidden={short} />
        <EditTextDateEpoch name="birthday" gql="String" date nullable hidden={short} />
        <FormCreators name="id" param={user} hidden={short} />
      </NestedItemForm>
    </GqlTable>
  )
}
