import { DatePicker, DateRangePicker } from '@mui/lab'
import { DateRange } from '@mui/lab/DateRangePicker'
import { TextField, TextFieldProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import { Box } from '@mui/system'
import React, { useContext, useEffect, useState } from 'react'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import Moment from 'react-moment'
import { UserContext, UserContextParams } from '../app/UserApp'
import { HiddenField } from './HiddenField'
import { FormField } from './useFormQuery'
import { callOrGet } from './utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-grid',
      minWidth: '14ch',
      flexGrow: 1,
    },
    field: {
      minWidth: '14ch',
      paddingBottom: 1,
    },
  }),
)

interface FormFieldProps extends FormField<string> {
  nameTo?: string
  defaultTo?: string | number | Date
  nameToNullable?: boolean
  date?: boolean | ((row: any) => boolean)
  dateInt?: boolean | ((row: any) => boolean)
  range?: boolean | ((row: any) => boolean)
  toMinDate?: Date | ((row: any, parentRow: any) => Date | undefined)
  minDate?: Date | ((row: any, parentRow: any) => Date | undefined)
  maxDate?: Date | ((row: any, parentRow: any) => Date | undefined)
}

const EditDate = (props: FormFieldProps) => {
  const classes = useStyles()

  const user = useContext(UserContext)

  const [minDate, setMinDate] = useState<Date>()

  const item = props.item || props.rowData || {}

  const isDate = callOrGet(props.date, item)
  const isDateInt = callOrGet(props.dateInt, item)
  const isRange = callOrGet(props.range, item)

  const parseDate = (value: any) => {
    if (!isDate) return new Date(parseInt(value))
    if (!isDateInt) return new Date(value)

    const valueInt = value instanceof Date ? value.getTime() : typeof value === 'number' ? value : new Date(parseInt(value)).getTime()
    const date = new Date()
    date.setFullYear(valueInt / 10000)
    date.setMonth((valueInt / 100) % 12)
    date.setDate(valueInt % 31)
    return date
  }
  const formatDate = (value: Date) => {
    if (!isDate) return value.getTime()
    if (!isDateInt) return value.toISODate()
    return value.getFullYear() * 10000 + value.getMonth() * 100 + value.getDate()
  }

  let dateFrom = props.value ? parseDate(props.value) : ('Invalid Date' as any as Date)
  if (dateFrom.toString() === 'Invalid Date') dateFrom = parseDate(callOrGet(props.default, item) || '')
  if (dateFrom.toString() === 'Invalid Date' && props.valueFromParent && props.parentRowData) {
    console.log('parent', callOrGet(props.valueFromParent, props.parentRowData))
    dateFrom = new Date(props.parentRowData[callOrGet(props.valueFromParent, props.parentRowData)])
  }
  if (dateFrom.toString() === 'Invalid Date') dateFrom = new Date()

  const onChange = props.onChange ? props.onChange : (date: string) => {}

  const fontSize = props.size === 'small' ? 12 : 16

  useEffect(() => {
    if (props.value !== formatDate(dateFrom)) {
      item[props.name] = formatDate(dateFrom)
    }
    const dateTo = props.nameTo || 'dateTo'
    if (!isRange && props.defaultTo !== undefined && item[dateTo] !== props.defaultTo) {
      item[dateTo] = formatDate(parseDate(props.defaultTo))
    }
  }, [isRange, isDate])

  if (isRange) {
    let dateTo = props.value ? parseDate(item[props.nameTo || 'dateTo']) : 'Invalid Date'
    if (dateTo === 'Invalid Date') dateTo = parseDate(props.defaultTo || '')
    if (dateTo === 'Invalid Date') dateTo = new Date()

    return (
      <DateRangePicker
        label={props.isForm && (props.label || user.translate(props.name))}
        value={[dateFrom, dateTo]}
        disabled={callOrGet(props.disabled, dateFrom, item)}
        className={classes.root}
        onOpen={() => props.minDate && setMinDate(callOrGet(props.minDate, item, props.parentRowData))}
        showDaysOutsideCurrentMonth={true}
        minDate={minDate ?? callOrGet(props.minDate, item, props.parentRowData)}
        maxDate={props.maxDate && callOrGet(props.maxDate, item, props.parentRowData)}
        startText={null}
        endText={null}
        disableCloseOnSelect={false}
        onChange={(date: DateRange<Date | null>, keyboardInputValue?: string) => {
          if (!date) return
          const from = formatDate(date[0] || new Date())
          const to = date[1] ? formatDate(date[1]) : null
          item[props.name] = from
          item[props.nameTo || 'dateTo'] = to
          props.toMinDate && setMinDate(callOrGet(props.toMinDate, item, props.parentRowData))
          return onChange(from.toString())
        }}
        autoFocus
        inputFormat={'dd-MM-yyyy'}
        renderInput={(startProps: JSX.IntrinsicAttributes & TextFieldProps, endProps: JSX.IntrinsicAttributes & TextFieldProps) => (
          <>
            <TextField
              {...startProps}
              sx={{ m: props.isForm ? 1 : 0, mb: 0.5, ml: 1, fontSize }}
              className={classes.field}
              style={{ fontSize: props.size == 'small' ? 12 : 16 }}
              onSubmit={props.onSubmit}
              variant={props.isForm ? 'outlined' : 'standard'}
              fullWidth={props.fullWidth}
              error={callOrGet(props.error, [dateFrom, dateTo])}
            />
            <Box sx={{ mx: 1 }}> to </Box>
            <TextField
              {...endProps}
              sx={{ m: props.isForm ? 1 : 0, mb: 0.5, fontSize }}
              className={classes.field}
              onSubmit={props.onSubmit}
              variant={props.isForm ? 'outlined' : 'standard'}
              fullWidth={props.fullWidth}
              error={callOrGet(props.error, [dateFrom, dateTo])}
            />
          </>
        )}
      />
    )
  } else {
    return (
      <DatePicker
        label={
          props.isForm &&
          (React.isValidElement(props.label) ? props.label : user.translate(props.label?.toString() || props.name.snakeCase()))
        }
        minDate={props.minDate && callOrGet(props.minDate, item, props.parentRowData)}
        maxDate={props.maxDate && callOrGet(props.maxDate, item, props.parentRowData)}
        value={dateFrom}
        disabled={callOrGet(props.disabled, dateFrom, item)}
        onChange={(date: Date | null) => {
          if (!date) return
          const formattedDate = formatDate(date)
          if (props.nameTo) {
            item[props.nameTo || 'dateTo'] = formattedDate.toString()
          }
          return onChange(formattedDate.toString())
        }}
        inputFormat={'dd-MM-yyyy'}
        renderInput={(inputProps: JSX.IntrinsicAttributes & TextFieldProps) => (
          <TextField
            {...inputProps}
            sx={{ m: props.isForm ? 1 : 0 }}
            className={classes.root}
            style={{ fontSize: props.size !== 'small' ? 16 : 14 }}
            onSubmit={props.onSubmit}
            variant={props.isForm ? 'outlined' : 'standard'}
            fullWidth={props.fullWidth}
            inputProps={{
              ...inputProps.inputProps,
              style: {
                fontSize: props.size !== 'small' ? 16 : 14,
              },
            }}
            error={callOrGet(props.error, dateFrom)}
            size={props.size !== 'small' ? 'medium' : 'small'}
          />
        )}
      />
    )
  }
}

EditDate.gql = 'Long'
EditDate.fields = (props: FormFieldProps, user: UserContextParams) => {
  const isRange = callOrGet(props.range, undefined)
  if (!isRange) return undefined
  return [
    <HiddenField name={props.name || 'dateFrom'} nullable={props.nullable} gql={props.gql || 'Long'} />,
    <HiddenField name={props.nameTo || 'dateTo'} nullable={props.nameToNullable || props.nullable} gql={props.gql || 'Long'} />,
  ]
}

EditDate.render = (props: FormFieldProps, value: string, rowData: any) => {
  const item = rowData || props.item || props.rowData || {}

  const isDate = callOrGet(props.date, item)
  const isRange = callOrGet(props.range, item)

  const parseDate = isDate ? (value: any) => new Date(value) : (value: any) => new Date(parseInt(value))

  let dateFrom = value ? parseDate(value) : null
  if (!dateFrom) dateFrom = parseDate(callOrGet(props.default, item) || '')
  if (!dateFrom) dateFrom = new Date()

  if (isRange) {
    let dateTo = props.nameTo ? parseDate(rowData[props.nameTo]) : null
    if (!dateTo) dateTo = parseDate(props.defaultTo || '')
    if (!dateTo) dateTo = new Date()

    // const from = dateFrom instanceof Date ? dateFrom.toLocaleDateString() : dateFrom
    // const to = dateTo instanceof Date ? dateTo.toLocaleDateString() : dateTo

    const fromFormat = dateFrom?.getFullYear() === dateTo?.getFullYear() ? 'D MMM' : 'D MMM YYYY'

    return (
      <div style={{ whiteSpace: 'nowrap' }}>
        <Moment format={fromFormat}>{dateFrom}</Moment> - <Moment format="D MMM YYYY">{dateTo}</Moment>
      </div>
    )
  } else {
    return <Moment format="D MMM YYYY">{dateFrom}</Moment>
  }
}

export default EditDate
