import * as React from 'react'
import EditText from '../../form/EditText'
import EntityAutoComplete from '../../form/EntityAutoComplete'
import { HiddenField } from '../../form/HiddenField'
import FormDialog from '../../form/FormDialog'
import NestedItemForm from '../../form/NestedItemForm'
import { ZeroApiContext } from '../Organisation'
import { UserContext } from '../UserApp'

type Props = {
  value: string
  code?: string
  onClose: (t: any) => void
}
export default function CreateSubject({ value, code, onClose }: Props) {
  const user = React.useContext(UserContext)
  const organisation = React.useContext(ZeroApiContext)

  return (
    <FormDialog
      entity="KomitentCompany"
      keys={['companyId', 'organisationId']}
      item={true}
      onClose={(item) => onClose({ ...item, id: item.companyId })}
      autoClose
    >
      <HiddenField name="organisationId" gql="String" value={organisation.id} />
      {/* <Switch name="active" gql="Long" label=" A" default={true} /> */}
      <NestedItemForm name="company" gql="Subject">
        <HiddenField name="id" gql="String" id />
        <EntityAutoComplete name="typeId" label="type" gql="String" entity="SubjectType" />
        <NestedItemForm name="name" gql="Translation">
          <EditText name={user.lang} label="name" gql="String" value={value} width={24} />
          <HiddenField name={user.secLang} value={(row: any) => (row ? row[user.lang] ?? row[`name_${user.lang}`] : '')} />
        </NestedItemForm>
        <EditText name="codeName" gql="String" value={code} />
        <NestedItemForm name="city" gql="City" transitient>
          <EntityAutoComplete name="countryId" entity="Country" label="country" all />
        </NestedItemForm>
        <EntityAutoComplete
          name="cityId"
          label="city"
          entity="City"
          byField="countryId"
          byFieldValue={(row) => row.city && row.city.countryId}
        />
        {/* <EditText name="vatNum" label="vat_num" gql="String" value="" hidden={!subjectType.hasRegInfo} />
        <EditText name="regNumber" label="reg_num" gql="String" value="" hidden={!subjectType.hasRegInfo} /> */}
        <EditText name="address" label="address" gql="String" default="" />
        <EditText name="phone" label="contact_phone" gql="String" default="" />
        <EditText name="email" label="contact_email" gql="String" default="" />
        <EntityAutoComplete
          name="parentSubjectId"
          label="parent"
          entity="Subject"
          treeLookup="company.id"
          // hidden={!subjectType.hasParent}
          nullable
        />
      </NestedItemForm>
    </FormDialog>
  )
}
