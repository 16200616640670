interface Item {
  total?: number
  amount?: number
  priceExchRate?: number
  invoiced?: number
}

export const getTotalOrAmount = (item?: Item): number | undefined => {
  if (!item) return undefined

  const { total, amount, priceExchRate } = item
  if (total) return total
  if (amount) return amount * (priceExchRate || 1)
  return amount
}

export const getProfitLoss = (item?: Item) => {
  if (!item) return 0

  const amount = getTotalOrAmount(item)
  if (!amount) return 0

  const { invoiced } = item
  if (!invoiced) return 0

  return invoiced - amount
}

export const SIGNIFICANT_PROFIT_LOSS_THRESHOLD = 0.05

export const getProfitLossPercentage = (item?: Item) => {
  if (!item) return 0

  const amount = getTotalOrAmount(item)
  if (!amount) return 0

  const { invoiced } = item
  if (!invoiced) return 0

  return invoiced / amount - 1
}

export const isProfitLossSignificant = (item?: Item) => {
  return Math.abs(getProfitLossPercentage(item)) > SIGNIFICANT_PROFIT_LOSS_THRESHOLD
}
