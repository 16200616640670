import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import React, { useContext } from 'react'
import { UserContext } from '../app/UserApp'
import { FormField } from './useFormQuery'
import { callOrGet, ifNull } from './utils'

interface FormFieldProps extends FormField<boolean> {
  textual?: boolean
}

const FormSwitch = (props: FormFieldProps) => {
  const user = useContext(UserContext)

  const item = props.item ?? props.rowData
  const value = ifNull(props.value, callOrGet(props.default, item))

  const control = (
    <Switch
      id={`form_${props.name}`}
      name={props.name}
      disabled={callOrGet(props.disabled, value, item)}
      style={{ fontSize: props.size === 'small' ? 12 : 14 }}
      checked={value}
      required
      onChange={(e) => {
        if (!props.onChange) return
        return props.onChange(Boolean(e.target.checked))
      }}
      color="primary"
      inputProps={{ 'aria-label': 'primary checkbox' }}
      autoFocus
    />
  )
  if (!props.isForm) return control

  return (
    <FormGroup aria-label="position" row>
      <FormControlLabel control={control} label={props.label ?? user.translate(props.name.snakeCase())} labelPlacement={'start'} />
    </FormGroup>
  )
}

const Render = ({ value }: { value: boolean }) => {
  const user = useContext(UserContext)
  return <div>{user.translate(value ? 'yes' : 'no')}</div>
}

FormSwitch.render = (props: FormFieldProps, value: boolean) => {
  if (props.textual) return <Render value={value} />
  return value ? <Check fontSize="small" color="success" sx={{ p: 0 }} /> : <Close color="action" fontSize="small" sx={{ p: 0 }} />
}

FormSwitch.convert = (value: any) => {
  console.log('convert', value)
  if (value === true) return 1
  if (value === false || value === undefined || value === null) return 0
  if (value === 'true') return 1
  if (value === 'false') return 0
  return parseInt(value)
}

FormSwitch.cellStyle = {
  padding: 0,
  textAlign: 'center',
}

FormSwitch.width = 1
FormSwitch.align = 'center'
FormSwitch.gql = 'Long'

export default FormSwitch
