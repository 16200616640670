import { CurrencyAmountField, EditText, EditTextInt, GqlReport, HiddenField } from '@zeroapi-io/zeroapireact'
import * as React from 'react'
import { useParams } from 'react-router'
import useConfirmDialog from '../../../common/ConfirmDialog'
import PageContainer from '../../../common/PageContainer'

export const TotalReport: React.FC = () => {
  const { itemId } = useParams()

  const confirmDialog = useConfirmDialog()

  return (
    <PageContainer visible={!Boolean(itemId)} animate="fade">
      {confirmDialog.render()}
      <GqlReport entity="report_total" dense="dense" fontSize="small" pageSize={50}>
        <HiddenField name="saleId" />
        <EditText name="employee" />
        <EditText name="subjectName" />
        <EditText name="groupCode" />
        <CurrencyAmountField name="amount" />
        <EditTextInt name="customersCount" />
        <CurrencyAmountField name="amountBank" />
        <CurrencyAmountField name="amountCash" />
        <CurrencyAmountField name="amount" />
        <CurrencyAmountField name="invoiced" />
        <CurrencyAmountField name="profit" />
      </GqlReport>
    </PageContainer>
  )
}
