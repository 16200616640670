import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import * as React from 'react'
import { useParams } from 'react-router'
import PageContainer from '../../../common/PageContainer'
import EditText from '../../../form/EditText'
import EditTextInt from '../../../form/EditTextInt'
import EntityAutoComplete from '../../../form/EntityAutoComplete'
import FAIconField from '../../../form/FAIconField'
import { HiddenField } from '../../../form/HiddenField'
import NestedItemForm from '../../../form/NestedItemForm'
import GqlTable from '../../../table/GqlTable'
import useSimpleEntityIconItem from '../../hooks/useSimpleEntityIconItem'
import { UserContext } from '../../UserApp'

export default function ServiceClassItem() {
  const user = React.useContext(UserContext)

  const { itemId } = useParams()

  const { loading, name: className, icon: classIcon } = useSimpleEntityIconItem('ServiceClass', 'id', itemId)

  const filterByClass = itemId ? { by: 'classId', eqStr: itemId } : undefined

  const title = loading ? (
    <>...</>
  ) : (
    <>
      {className}&nbsp; <FontAwesomeIcon icon={(classIcon ?? '') as IconName} size="lg" color="#666" />
    </>
  )

  return (
    <PageContainer visible={Boolean(itemId)} animate="slide">
      <Box sx={{ p: 1 }}>
        <GqlTable entity="ServiceType" title={title} filter={filterByClass} hasBack>
          <HiddenField name="id" gql="String" id />
          <HiddenField name="classId" gql="String" value={itemId} />
          <NestedItemForm name="name" gql="Translation">
            <EditText name={user.lang} label="name" gql="String" default="" />
            <HiddenField name={user.secLang} value={(row: any) => (row ? row[user.lang] ?? row[`name_${user.lang}`] : '')} />
          </NestedItemForm>
          <EditTextInt name="capacity" gql="Long" label="capacity" default={1} />
          <EditText name="codeName" gql="String" label="code_name" default="" width={1} />
          <FAIconField name="icon" label="icon" gql="String" default={'circle'} width={1} />
          <EntityAutoComplete
            name="parentTypeId"
            label="parent_service_type"
            filter={filterByClass}
            entity="ServiceType"
            width={1}
            exclude={(value, row) => (row ? [row.id] : [])}
            treeLookup="id"
            nullable
          />
        </GqlTable>
      </Box>
    </PageContainer>
  )
}
