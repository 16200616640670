import { callOrGet } from './utils'
import { GqlTabletField } from '../table/GqlTable'

interface FormFieldProps extends GqlTabletField<any> {}

export default function FilterField(props: FormFieldProps, value: any, row: any) {
  const item = props.item || props.rowData || {}

  console.log(props.name, value, item)
  if (props.valueFromParent && props.parentRowData) {
    value = props.parentRowData[callOrGet(props.valueFromParent, props.parentRowData)]
  }

  if (item[props.name] !== value) {
    console.log(props.name, value, item)
    item[props.name] = value
  }

  return null
}

FilterField.gql = 'String'
