import GroupAddIcon from '@mui/icons-material/GroupAdd'
import { IconButton, Typography } from '@mui/material'
import * as React from 'react'
import AppDialog from '../../../../../common/AppDialog'
import BulkForm from '../../../../../form/BulkForm'
import EditText from '../../../../../form/EditText'
import EditTextInt from '../../../../../form/EditTextInt'
import { ZeroApiContext } from '../../../../Organisation'
import { UserContext } from '../../../../UserApp'

interface Props {
  saleId?: string
  onUpdate?: () => void
}

export default ({ saleId, onUpdate }: Props) => {
  const user = React.useContext(UserContext)
  const organisation = React.useContext(ZeroApiContext)
  const [showAddGroup, setShowAddGroup] = React.useState(false)

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
      <Typography variant="h6">{user.translate('customers')}</Typography>
      &nbsp;
      <IconButton onClick={() => setShowAddGroup(true)}>
        <GroupAddIcon />
      </IconButton>
      <AppDialog title="add_group" open={showAddGroup} onClose={setShowAddGroup}>
        <BulkForm
          entity="SaleCustomer"
          onSave={onUpdate ? () => onUpdate() : undefined}
          onSubmit={(item: any) => {
            console.log(item)
            const items: any[] = []
            for (let index = 0; index < item.count; index++) {
              items.push({
                saleId,
                group: item.groupName,
                createCustomer: {
                  organisationId: organisation.id,
                  intName: `Tourist ${index + 1}`,
                  firstName: `Tourist ${index + 1}`,
                  lastName: ' ',
                  title: 'Other',
                },
              })
            }
            console.log('items', items)
            return items
          }}
        >
          <EditTextInt name="count" minValue={1} />
          <EditText name="groupName" />
        </BulkForm>
      </AppDialog>
    </div>
  )
}
