import { useContext } from 'react'
import { useEntityContextReadOnlyQuery } from '../../gql/useEntityQuery'
import { UserContext } from '../UserApp'

export interface SubjectType {
  id: string
  codeName: string
  name: string
  icon: string
  hasParent: boolean
  hasRegInfo: boolean
}

export default function useSubjectTypes(): { loading: boolean; items: SubjectType[] } {
  const user = useContext(UserContext)
  const subjectTypeFields = [
    { name: 'id', gql: 'String!' },
    { name: 'codeName', gql: 'String!' },
    {
      name: 'icon',
      gql: 'String!',
    },
    { name: 'hasParent', gql: 'Long!' },
    { name: 'hasRegInfo', gql: 'Long!' },
    {
      name: 'name',
      gql: 'Translation!',
      subSelection: { [user.lang.toLocaleLowerCase()]: true },
    },
  ]
  const query = useEntityContextReadOnlyQuery('SubjectType', subjectTypeFields)
  if (query.isLoading) return { loading: true, items: [] }
  if (query.items === undefined) throw Error('Can not load SubjectTypes')
  return { loading: false, items: query.items.map((it: any) => ({ ...it, name: it.name[user.lang] })) }
}
