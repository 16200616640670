import { Box } from '@mui/material'
import * as React from 'react'
import { Route, Routes, useNavigate, useParams } from 'react-router'
import PageContainer from '../../../common/PageContainer'
import { UserContext } from '../../UserApp'
import CitiesList from './CitiesList'
import LocationsList from './LocationsList'

import { GqlTable, EditText, HiddenField, NestedItemForm, SelectCurrency } from '@zeroapi-io/zeroapireact'

export default function CountriesList() {
  const navigate = useNavigate()
  const { itemId } = useParams()

  const user = React.useContext(UserContext)

  const onItemClick = (e: any, row: any) => {
    navigate(`./${row.id}`)
  }

  return (
    <>
      <PageContainer visible={!Boolean(itemId)} animate="fade">
        <Box sx={{ p: 1 }}>
          <GqlTable entity="Country" onItemClick={onItemClick} dense="dense" all>
            <HiddenField name="id" id />
            <EditText name="iso" label="iso2" gql="String" default="" width={1} />
            <EditText name="iso3" label="iso3" gql="String" default="" width={1} />
            <NestedItemForm name="name" gql="Translation">
              <EditText
                name={user.lang}
                label={`${user.translate('name')} (${user.lang.toUpperCase()})`}
                gql="String"
                default=""
                width={48}
              />
              <EditText
                name={user.secLang}
                label={`${user.translate('name')} (${user.secLang.toUpperCase()})`}
                gql="String"
                default=""
                width={48}
              />
            </NestedItemForm>
            <SelectCurrency name="currency" label="mark" group="currency" default={'EUR'} />
            <EditText name="currencyName" label="name" group="currency" default="" width={1} />
            <HiddenField name="latitude" label="latitude" group="geo" gql="Float" value={0.0} />
            <HiddenField name="longitude" label="latitude" group="geo" gql="Float" value={0.0} />
          </GqlTable>
        </Box>
      </PageContainer>
      <CitiesLocationsRoute />
    </>
  )
}

const CitiesLocationsRoute = () => (
  <Routes>
    <Route path="/" element={<CitiesList />} />
    <Route path="/cities/:cityId" element={<LocationsList />} />
  </Routes>
)
