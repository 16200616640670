import { useContext } from "react"
import { ZeroApiContext } from "../app/Organisation"
import { Field, QueryOptions } from "./interface"
import { useEntityRelationQuery } from "./useEntityRelationQuery"
import { useEntityRelationReadOnlyQuery } from "./useEntityRelationReadOnlyQuery"

export function useEntityQuery(
  entity: string,
  fields: Field<any>[],
  relField?: string,
  relValue?: string,
  options?: QueryOptions
) {
  const company = useContext(ZeroApiContext)
  let field = relField !== undefined ? relField : "organisationId"
  let value = relField !== undefined ? relValue : company.id
  return useEntityRelationQuery(entity, fields, field, value, options)
}

export function useEntityContextQuery(entity: string, fields: Field<any>[], options?: QueryOptions) {
  const company = useContext(ZeroApiContext)
  return useEntityRelationQuery(entity, fields, "organisationId", company.id, options)
}

export function useEntityContextReadOnlyQuery<T>(entity: string, fields: Field<any>[], options?: QueryOptions) {
  const company = useContext(ZeroApiContext)
  return useEntityRelationReadOnlyQuery<T>(entity, fields, "organisationId", company.id, options)
}
