export const preventPropagation = (event: any, isOpen?: boolean) => {
  if (isOpen === false) return
  if (event.key === 'Enter') {
    event.stopPropagation()
  }
}

export const ifNull = (...params: (any | null | undefined)[]) => {
  // noinspection PointlessBooleanExpressionJS
  return params.find((param) => param !== undefined && param !== null)
}

export const callOrGet: <T>(value: T | ((...args: any[]) => T), ...args: any[]) => any = (value, ...args) => {
  if (value instanceof Function) return value(...args)
  return value
}

export const parseInteger = (value: any) => {
  const result = parseInt(value)
  if (isNaN(result)) return ''
  return result
}

export const parseDecimal = (value: any) => {
  const result = parseFloat(value)
  if (isNaN(result)) return ''
  return result
}

export const switchCase = (...cases: (any | boolean)[]) => {
  const count = cases.length
  for (let i = 0; i < count; i++) {
    const caseStatement = cases[i]
    if (i === count - 1) return caseStatement

    const outcomes = cases[i + 1]
    if (outcomes === undefined) return

    if (outcomes.hasOwnProperty(caseStatement)) {
      return outcomes[caseStatement]
    }
  }
}

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}
