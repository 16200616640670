import { useMutation, gql } from '@apollo/client';
// import { Theme } from '@mui/system';
import * as React from 'react';
import { UserContext } from '../app/UserApp';
import AuthScreen from './AuthScreen';

const GQL_REGISTER = gql`
  mutation ($email: String!, $name: String!) {
    auth_register(email: $email, name: $name) {
      ok
      message
      profile {
        id
        name
      }
    }
  }
`;

export default function SignIn() {
  const user = React.useContext(UserContext);

  const [register, { loading, data }] = useMutation(GQL_REGISTER);

  const response = data?.auth_register;

  const onSubmit = (data: FormData) => {
    register({
      variables: {
        email: data.get("email"),
        name: data.get("name"),
      },
    });
  };

  return (
    <AuthScreen
      title={user.translate("label_signup")}
      actionTitle={user.translate("button_signup")}
      onSubmit={onSubmit}
      error={response?.ok === false && response?.message}
      info={response?.ok === true && response?.message}
      isLoading={loading || response?.ok === true}
      fields={[
        { name: "email", label: user.translate("label_email_address") },
        { name: "name", label: user.translate("label_name") },
      ]}
      actions={response?.ok !== true ? [{ name: "login", label: user.translate("button_sign_in_account") }] : []}
    />
  );
}
