import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress } from '@mui/material'
import Button from '@mui/material/Button'
import React, { useContext, useRef, useState } from 'react'
import FadeIn from 'react-fade-in'
import { UserContext } from '../app/UserApp'

interface PromiseHandler<T> {
  resolve: (t: T) => void
  reject: () => void
}

interface DialogAction {
  title: string
  onClick?: () => void
}

const CANCEL_ACTION: DialogAction = { title: 'cancel' }

interface DialogParams {
  title: string
  message: string
  positiveAction?: DialogAction
  negativeAction?: DialogAction
  neutralAction?: DialogAction
  cancelButton?: boolean
}

const useConfirmDialog = () => {
  const user = useContext(UserContext)

  const promiseRef = useRef<PromiseHandler<boolean>>()
  const [showDialog, setShowDialog] = useState<DialogParams>()
  const [loading, setLoading] = useState(false)

  const onClose = () => {
    setShowDialog(undefined)
    setLoading(false)
    if (promiseRef.current) {
      promiseRef.current.reject()
      promiseRef.current = undefined
    }
  }

  const onShow = (props: DialogParams) => {
    return new Promise((resolve, reject) => {
      promiseRef.current = { resolve, reject }
      setShowDialog(props)
      setLoading(false)
    })
  }

  const onShowConfirm = (action: string, context: string, callback: () => void | Promise<any>, ...params: string[]) =>
    onShow({
      title: user.translate(action),
      message: user.translate(`${action}_accept_${context}`, ...params),

      positiveAction: {
        title: user.translate(action).toUpperCase(),
      },
      cancelButton: true,
    })
      .then(callback)
      .then(() => {
        promiseRef.current = undefined
        setLoading(false)
        setShowDialog(undefined)
      })
      .catch((e) => {
        console.error('canceled', e)
        setLoading(false)
        if (e?.message) {
          alert(e.message)
        }
        setShowDialog(undefined)
      })

  const handleAction = (action: DialogAction) => () => {
    if (action.onClick) {
      action.onClick()
    }
    if (promiseRef.current === undefined) return

    if (action === CANCEL_ACTION) {
      onClose()
      return
    }

    setLoading(true)

    if (action === showDialog?.positiveAction) {
      promiseRef.current.resolve(true)
    } else if (action === showDialog?.negativeAction) {
      promiseRef.current.resolve(false)
    } else {
      promiseRef.current.reject()
    }
  }

  const render = () =>
    showDialog && (
      <Dialog open={Boolean(showDialog)} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{showDialog.title.toUpperCase()}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{showDialog.message.capitalize()}</DialogContentText>
        </DialogContent>
        <FadeIn visible={loading} transitionDuration={200} delay={150}>
          <LinearProgress color="primary" />
        </FadeIn>
        <DialogActions>
          {showDialog.negativeAction && (
            <Button onClick={handleAction(showDialog.negativeAction)} color="secondary" disabled={loading}>
              {showDialog.negativeAction.title}
            </Button>
          )}
          {showDialog.neutralAction && (
            <Button onClick={handleAction(showDialog.neutralAction)} color="primary" disabled={loading}>
              {showDialog.neutralAction.title}
            </Button>
          )}
          {showDialog.cancelButton && (
            <Button onClick={handleAction(CANCEL_ACTION)} color="primary" disabled={loading}>
              {user.translate('cancel')}
            </Button>
          )}
          {showDialog.positiveAction && (
            <Button onClick={handleAction(showDialog.positiveAction)} disabled={loading}>
              {showDialog.positiveAction.title}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )

  return {
    render: render,
    show: onShow,
    showConfirm: onShowConfirm,
  }
}

export default useConfirmDialog
