import { gql, useQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import moment from 'moment'
import 'moment/locale/mk'
import 'moment/locale/tr'
import { Navigate, Route, Routes } from 'react-router-dom'
import './App.css'
import Home from './app/Home'
import OrganisationDashboard from './app/OrganisationDashboard'
import UserApp from './app/UserApp'
import Forgot from './login/Forgot'
import Register from './login/Register'
import ResetPassword from './login/ResetPassword'
import SignIn from './login/SignIn'

const theme = createTheme({
  palette: {
    primary: {
      // main: "#111c38",
      // main: "#182340",
      main: '#283454', // main: "#003454",
      // main: "#003e38",
    },
    secondary: {
      main: '#f50057',
    },
  },
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
})

const GQL_ME = gql`
  {
    me {
      id
      lang
      profile {
        _id
        name
        email
        isAdmin
      }
      employeeOrganisations {
        role
        organisationId
        organisation {
          id
          name {
            id
            mk
          }
        }
      }
    }
  }
`

function App() {
  const { data, loading, error, refetch } = useQuery(GQL_ME, {
    errorPolicy: 'ignore',
  })

  if (loading) {
    return (
      <div className="App-Full">
        <CircularProgress style={{ margin: 'auto' }} />
      </div>
    )
  }

  let me = data && data.me && data.me.profile ? data.me : false

  const organisations = me && me?.employeeOrganisations ? me?.employeeOrganisations : []

  if (me.lang) {
    moment.locale(me.lang.toLowerCase())
  }

  if (error) {
    return <div className="App-Full">Server Error</div>
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Routes>
          <Route
            path="/"
            element={<Navigate to={me ? (organisations.length === 0 ? '/home' : `o/${organisations[0]?.organisationId}`) : 'login'} />}
          />
          <Route path="/login" element={<SignIn onLogin={refetch} />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/reset-pass/:codeWithVerifier" element={<ResetPassword />} />
          <Route
            path="/home"
            element={
              <UserApp me={me}>
                <Home companies={organisations} refresh={refetch} />
              </UserApp>
            }
          />
          <Route
            path="/home/*"
            element={
              <UserApp me={me}>
                <Home companies={organisations} refresh={refetch} />
              </UserApp>
            }
          />
          <Route
            path="/o/:id/:page/:itemId/*"
            element={<OrganisationDashboard me={me} organisations={organisations.map((it: any) => it.organisation)} theme={theme} />}
          />
          <Route
            path="/o/:id/:page"
            element={<OrganisationDashboard me={me} organisations={organisations.map((it: any) => it.organisation)} theme={theme} />}
          />
          <Route
            path="/o/:id/"
            element={<OrganisationDashboard me={me} organisations={organisations.map((it: any) => it.organisation)} theme={theme} />}
          />
        </Routes>
      </ThemeProvider>
    </div>
  )
}

export default App
