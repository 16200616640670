const configs = {
    local: {
        apiUrl: "http://192.168.100.112:8080",
        apiImagesUrl: "http://192.168.100.112:8080/image",
        fbAppId: "",
        isTest: true
    },
    dev: {
        apiUrl: "https://dev-api.wtouristic.app",
        apiImagesUrl: "https://dev-images.wtouristic.app",
        fbAppId: "",
        isTest: true
    },
    test: {
        apiUrl: "https://test-api.wtouristic.app",
        apiImagesUrl: "https://test-images.wtouristic.app",
        fbAppId: "",
        isTest: true
    },
    production: {
        apiUrl: "https://api.wtouristic.app",
        apiImagesUrl: "https://images.wtouristic.app",
        fbAppId: "",
        isTest: false
        
    }
};

const config = configs[process.env.REACT_APP_STAGE];

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};