import { IconName } from '@fortawesome/fontawesome-svg-core'
import { fas as faFree } from '@fortawesome/free-solid-svg-icons'
import { fas as faPro } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Autocomplete, TextField, Theme } from '@mui/material'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import React, { useContext, useState } from 'react'
import { UserContext } from '../app/UserApp'
import { icons as iconKeywords } from './FAKeywords'
import { FormField } from './useFormQuery'
import { callOrGet, preventPropagation } from './utils'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-grid',
    margin: theme.spacing(1),
    minWidth: '18ch',
    flexGrow: 1,
    '& > .MuiTextField-root': {
      display: 'grid',
      width: '-webkit-fill-available',
    },
  },
}))

interface FormFieldProps extends FormField<string> {
  fullWidth?: boolean
  color?: string
  onClear?: () => void
}

const allIcons = Array.from(
  new Set(
    Object.values(faFree)
      .concat(Object.values(faPro))
      .map((icon) => icon.iconName),
  ),
)

const FAIconField = (props: FormFieldProps) => {
  const classes = useStyles()

  const user = useContext(UserContext)

  const [isOpen, setIsOpen] = useState(false)

  const filterOptions = (options: string[], params: { inputValue: string }) => {
    const input = params.inputValue
    console.log(input, input === '', options.length)
    if (input === '') return options
    return options
      .filter((option) => {
        if (option.indexOf(input) > -1) return true
        const iconData = iconKeywords[option]
        if (!iconData) return false
        return iconData.keywords.find((keyword) => keyword.indexOf(input) > -1) != undefined
      })
      .slice(0, 50)
  }

  const onChange = props.onChange !== undefined ? props.onChange : () => {}
  const fieldSize = props.size === 'small' ? 'small' : 'medium'

  const item = props.item ?? props.rowData
  const value = callOrGet(props.value, item)

  return (
    <Autocomplete
      className={classes.root}
      onOpen={() => {
        setIsOpen(true)
      }}
      onClose={() => {
        setIsOpen(false)
      }}
      autoHighlight
      selectOnFocus
      fullWidth={props.fullWidth}
      handleHomeEndKeys
      options={allIcons}
      value={value}
      onChange={(event, value) => (props.nullable || value ? onChange(value) : onChange(''))}
      onInputChange={(e, value) => value.length === 0 && props.onClear && props.onClear()}
      onSubmit={props.onSubmit}
      disabled={callOrGet(props.disabled, value, item)}
      size={fieldSize}
      filterOptions={filterOptions}
      onKeyDown={(event) => preventPropagation(event, isOpen)}
      renderOption={(props, option) => (
        <Box key={option} component="li" sx={{ '& > svg': { mr: 2, flexShrink: 0 } }} {...props}>
          <FontAwesomeIcon icon={option as IconName} />
          <span style={{ fontSize: 10, whiteSpace: 'nowrap' }}>{option}</span>
        </Box>
      )}
      renderInput={(params) => {
        params.inputProps.autoCorrect = 'off'
        params.inputProps.autoCapitalize = 'off'
        params.inputProps.style = { fontSize: props.size == 'small' ? 12 : 14 }
        return (
          <TextField
            {...params}
            label={
              props.isForm &&
              (React.isValidElement(props.label) ? props.label : user.translate(props.label?.toString() || props.name.snakeCase()))
            }
            error={callOrGet(props.error, value)}
            variant={props.isForm ? 'outlined' : 'standard'}
            size={fieldSize}
            fullWidth={props.fullWidth}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <React.Fragment>
                  <FontAwesomeIcon icon={(params.inputProps as any).value as IconName} />
                  &nbsp;{params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )
      }}
    />
  )
}

FAIconField.render = (props: FormFieldProps, rowValue: string) => {
  return (
    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginLeft: 4 }}>
      <FontAwesomeIcon icon={rowValue as IconName} size="lg" color={props.color} />
    </span>
  )
}

FAIconField.gql = 'String'

export default FAIconField
