import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import useLazyEffect from '../hooks/useLazyEffect'
import generateGql from './generateGql'
import { Field, GqlFetchQuery, GqlFilter, QueryOptions } from './interface'

export function useCustomQuery(methodName: string, fields: Field<any>[], argField?: string, argValue?: string, options: QueryOptions = {}) {
  const isPageVisible = true // usePageVisibility()
  const allFields = fields || []
  const gqlFields = allFields.filter((field) => field.gql !== undefined)

  const gqlGetList = useMemo(() => {
    let filter: GqlFilter | undefined = undefined
    const addFilter = (filterOptions: GqlFilter) => {
      if (filter) {
        filterOptions.and = filter
      }
      filter = filterOptions
    }
    if (options.dateRangeFilter)
      addFilter({
        by: options.dateRangeField || 'created',
        gte: options.dateRangeFilter.startDate.getTime() || 0,
        lte: options.dateRangeFilter.endDate.getTime() || new Date().getTime(),
      })
    if (options.filter) addFilter(options.filter)

    const args = { ...options.args }
    if (argField !== undefined) {
      args['$' + argField] = 'String!'
    }

    if (filter) {
      args.filter = filter
    }

    return gql(
      generateGql({
        [methodName]: {
          args,
          select: gqlFields.toMapBy(
            (field) => field.name,
            (field) => field.subSelection || true,
          ),
        },
      }),
    )
  }, [options.dateRangeFilter, options.dateRangeField, options.filter, options.args, argField, methodName, gqlFields])

  const runEffect = useLazyEffect()

  const onLoad = options.onLoad

  const {
    data: dataItems,
    loading: loadingItems,
    refetch: refetchItems,
  } = useQuery(gqlGetList, {
    pollInterval: isPageVisible ? (options.pollInterval !== undefined ? options.pollInterval : 8000) : 0,
    skip: options.skip,
    fetchPolicy: options.fetchPolicy,
    onCompleted:
      onLoad &&
      ((data) => {
        runEffect(() => onLoad(data, methodName))
      }),
    variables: argField
      ? {
          [argField]: argValue,
        }
      : undefined,
  })

  const dataField = options.single ? 'item' : 'items'

  return {
    isLoading: loadingItems,
    isLoadingAction: false,
    [dataField]: dataItems && dataItems[methodName],
    refresh: refetchItems,
  } as GqlFetchQuery<any>
}
