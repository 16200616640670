import { useCallback, useRef, useState } from 'react'

export default function useViewSize() {
  const viewSizeRef = useRef({ width: 0, height: 0 })
  const [viewSize, setViewSize] = useState(viewSizeRef.current)

  const elementRef = useRef<HTMLDivElement>()

  const handleResize = useCallback(() => {
    const element = elementRef.current

    let count = 0
    const interval = setInterval(() => {
      let width = element?.clientWidth ?? 0
      let height = element?.clientHeight ?? 0
      let newSize = { width, height }

      let currentSize = viewSizeRef.current
      if (currentSize.width === newSize.width && currentSize.height === newSize.height) {
        if (count++ > 5) clearInterval(interval)
        return
      }

      viewSizeRef.current = newSize
      setViewSize(newSize)
      if (count++ > 5) clearInterval(interval)
    }, 100)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const viewRef = useCallback(
    (node: HTMLDivElement | null) => {
      window.removeEventListener('mouseup', handleResize)
      window.removeEventListener('resize', handleResize)

      if (node === null) {
        elementRef.current = undefined
        return
      }

      window.addEventListener('mouseup', handleResize)
      window.addEventListener('resize', handleResize)
      elementRef.current = node

      handleResize()
    },
    [handleResize],
  )

  return { viewSize, viewRef }
}
