import * as React from 'react'
import { useEffect } from 'react'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { SvgIconComponent } from '@mui/icons-material'

interface Props {
  options: string[]
  colors?: ('primary' | 'secondary' | 'info' | 'success' | 'warning')[]
  Icons?: SvgIconComponent[]
  onClick: (index: number, option: string) => void
  variant?: 'text' | 'outlined' | 'contained'
  selectedDefault?: number
  resetOnChange?: any
  disabled?: boolean
}

export default function SplitButton({ options, onClick, variant, colors, selectedDefault, Icons, resetOnChange, disabled }: Props) {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const [selectedIndex, setSelectedIndex] = React.useState<number>()
  const selected = selectedIndex ?? selectedDefault ?? 0

  useEffect(() => {
    if (selectedIndex !== undefined) setSelectedIndex(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetOnChange])

  const handleClick = () => {
    onClick(selected, options[selected])
  }

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const color = (colors && colors[selected]) || 'primary'

  const Icon = Icons && Icons[selected]

  return (
    <React.Fragment>
      <ButtonGroup variant={variant ?? 'contained'} color={color} ref={anchorRef} aria-label="split button" disabled={disabled}>
        <Button style={{ minWidth: 160 }} color={color} startIcon={Icon && <Icon />} onClick={handleClick}>
          {options[selected]}
        </Button>
        <Button
          size="small"
          color={color}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper style={{ zIndex: 99 }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => {
                    const Icon = Icons && Icons[index]
                    return (
                      <MenuItem
                        key={option}
                        style={{ minWidth: 160 }}
                        disabled={index === 2}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {Icon && (
                          <>
                            <Icon fontSize={'small'} />
                            &nbsp;
                          </>
                        )}
                        {option}
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
}
