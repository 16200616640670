import { AccessTime } from '@mui/icons-material'
import { TimePicker } from '@mui/lab'
import { TextField, TextFieldProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import React, { useContext } from 'react'
import Moment from 'react-moment'
import { UserContext } from '../app/UserApp'
import { FormField } from './useFormQuery'
import { callOrGet } from './utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-grid',
      minWidth: '8ch',
      flexGrow: 1,
      '& .MuiIconButton-root': {
        marginRight: 0.25,
        '& > *': {
          fontSize: '1.2rem',
        },
      },
    },
  }),
)

interface FormFieldProps extends FormField<number> {}

const EditTime = (props: FormFieldProps) => {
  const classes = useStyles()

  const user = useContext(UserContext)

  const value = props.value || props.default
  const item = props.item ?? props.rowData
  const valueNum = value !== undefined ? callOrGet(value, item) : 1000

  const dateValue = new Date()
  dateValue.setHours((valueNum / 10000) % 100)
  dateValue.setMinutes((valueNum / 100) % 100)
  dateValue.setSeconds(valueNum % 100)

  const onChange = props.onChange ? props.onChange : (date: number) => {}

  const fontSize = props.size !== 'normal' ? 14 : 16
  return (
    <TimePicker
      label={props.isForm && (props.label || user.translate(props.name))}
      value={dateValue}
      disabled={callOrGet(props.disabled, value, item)}
      ampm={false}
      onChange={(date: Date | null) => {
        if (!date || date === null) return
        return onChange(date.getHours() * 10000 + date.getMinutes() * 100 + date.getSeconds())
      }}
      minutesStep={5}
      renderInput={(inputProps: JSX.IntrinsicAttributes & TextFieldProps) => (
        <TextField
          {...inputProps}
          sx={{ m: props.isForm ? 1 : 0, mb: '3px', ml: 0 }}
          className={classes.root}
          inputProps={{
            ...inputProps.inputProps,
            style: { fontSize: fontSize, paddingLeft: 4, paddingRight: 4 },
          }}
          onSubmit={props.onSubmit}
          variant={props.isForm ? 'outlined' : 'standard'}
          fullWidth={props.fullWidth}
          error={callOrGet(props.error, value)}
        />
      )}
    />
  )
}

EditTime.gql = 'Long'

EditTime.render = (props: FormFieldProps, value: number) => {
  const valueNum = value !== undefined ? value : 100000

  const dateValue = new Date()
  dateValue.setHours((valueNum / 10000) % 100)
  dateValue.setMinutes((valueNum / 100) % 100)
  dateValue.setSeconds(valueNum % 100)

  const fontSize = props.size !== 'normal' ? 14 : 16

  return (
    <div style={{ fontSize: fontSize, display: 'flex', alignItems: 'center' }}>
      <AccessTime style={{ fontSize: 16, marginRight: 4 }} htmlColor="#666" />
      <Moment date={dateValue} format="hh:mm" interval={0} />
    </div>
  )
}
export default EditTime
