import { useContext, useMemo } from 'react'
import { Field } from '../../gql/interface'
import { useEntityRelationReadOnlyQuery } from '../../gql/useEntityRelationReadOnlyQuery'
import { UserContext } from '../UserApp'

export interface IconItem {
  id: string
  icon: string
  name: { [key: string]: string }
}

export interface EntityIconItem {
  id: string
  icon: string
  name: string
}

export const useSimpleEntityIconItems = (entity: string, relField: string, relValue?: string, selection?: Field<any>[]) => {
  const user = useContext(UserContext)
  const fields = useMemo(
    () => [
      { name: 'id', gql: 'String!' },
      { name: 'icon', gql: 'String!' },
      {
        name: 'name',
        gql: 'Translation!',
        subSelection: { [user.lang.toLocaleLowerCase()]: true },
      },
      ...(selection ?? []),
    ],
    [selection, user.lang],
  )
  const query = useEntityRelationReadOnlyQuery<IconItem>(entity, fields, relField, relValue, {
    skip: relValue === undefined,
  })

  return useMemo(
    () => ({ loading: query.isLoading, items: query.items?.map((it): EntityIconItem => ({ ...it, name: it.name[user.lang] })) ?? [] }),
    [query.isLoading, query.items, user.lang],
  )
}
