import { Box } from '@mui/system'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Route, Routes, useNavigate, useParams } from 'react-router'
import PageContainer from '../../../../common/PageContainer'
import { IconNameEntity } from '../../../hooks/useIconNameEntityItems'
import { ZeroApiContext } from '../../../Organisation'
import { UserContext, UserContextParams } from '../../../UserApp'
import { useDynamicGqlMutation } from '../../../../gql/useDynamicGql'
import { CircularProgress, IconButton, Tooltip, Typography } from '@mui/material'
import Show from '../../../../common/Show'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import PlainTable, { GqlTableColumn } from '../../../../table/PlainTable'
import SplitButton from '../../../../common/SplitButton'
import EditTextDateEpoch from '../../../../form/EditTextDateEpoch'
import EntityAutoComplete from '../../../../form/EntityAutoComplete'
import WarningIcon from '@mui/icons-material/Warning'
import { Info, SvgIconComponent } from '@mui/icons-material'
import LabelImportantIcon from '@mui/icons-material/LabelImportant'
import BarChartIcon from '@mui/icons-material/Preview'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import CheckIcon from '@mui/icons-material/Check'
import ErrorIcon from '@mui/icons-material/Error'
import { CellStyle } from '@material-table/core'
import BuildIcon from '@mui/icons-material/Build'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import AddBoxIcon from '@mui/icons-material/AddBox'
import CreateCity from '../../../common/CreateCity'
import CreateCountry from '../../../common/CreateCountry'
import CreateLocation from '../../../common/CreateLocation'
import CreateSubject from '../../../common/CreateSubject'
import SelectOption from '../../../../form/SelectOption'

type Color = 'inherit' | 'action' | 'disabled' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'

interface Props {
  saleType?: IconNameEntity
}

interface ReportItem {
  type: string
  code: string
  name: string
}

interface OptionItemValue {
  id: string
  title: string
  description: string
}

interface OptionItem {
  type: string
  code: string
  name: string
  items: OptionItemValue[]
}

const INFO_TYPES = ['RESERVATIONS_COUNT', 'CUSTOMERS_COUNT', 'SUB_GROUPS_COUNT', 'DATE_FROM', 'DATE_TO']

function getTitle(user: UserContextParams, Icon: SvgIconComponent | undefined, color: Color, title: string, tooltip: string) {
  return (
    <Tooltip title={user.translate(tooltip)}>
      <div style={{ display: 'flex' }}>
        {Icon && <Icon color={color ?? 'info'} sx={{ mt: 0.5, mb: 0.5, ml: 0, mr: 1 }} />}
        <Typography variant={'h6'}>{user.translate(title)}</Typography>
      </div>
    </Tooltip>
  )
}

// eslint-disable-next-line no-empty-pattern
export function SaleImportPage({}: Props) {
  const user = React.useContext(UserContext)
  const organisation = React.useContext(ZeroApiContext)
  const navigate = useNavigate()

  const { file } = useParams()

  const [selectedArrId, setSelectedArrId] = useState<string | null>(null)

  const [response, setResponse] = useState<any>()
  const [showCreateItem, setShowCreateItem] = useState<any>()

  const ServiceLocationGroup = {
    LOCATION: user.translate('location'),
    CITY: user.translate('city'),
    COUNTY: user.translate('county'),
    COUNTRY: user.translate('country'),
  }

  const [locationGrouping, setLocationGrouping] = useState<string>('COUNTY')

  const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: string }>({})

  const [importSejzure, { loading }] = useDynamicGqlMutation(
    'auth_importSale',
    {
      organisationId: organisation.id,
      $fileName: 'String!',
      $tourArrangementId: 'String',
      $dryRun: 'Boolean!',
      grouping: locationGrouping,
      options: Object.values(selectedOptions).filter((it) => it.length > 0),
    },
    {
      ok: true,
      message: true,
      result: {
        saleIds: true,
        items: { type: true, code: true, name: true, status: true },
        options: {
          type: true,
          code: true,
          name: true,
          items: {
            id: true,
            title: true,
            description: true,
          },
        },
        groups: {
          name: true,
          services: {
            serviceClassId: true,
            serviceTypeId: true,
            fromDate: { formatted: true, timeEpoch: true },
            toDate: { formatted: true },
            fromLocationId: true,
            fromLocation: true,
            toLocationId: true,
          },
        },
      },
    },
  )

  useEffect(() => {
    if (!file || file.length === 0) return
    // noinspection JSIgnoredPromiseFromCall
    importSejzure({ variables: { fileName: file, dryRun: true } })
      .then(setResponse)
      .catch(() => setResponse(undefined))
  }, [file, importSejzure, selectedOptions, locationGrouping])

  const importResult = response?.ok ? response?.result : undefined

  const importItems = (importResult?.items ?? []) as ReportItem[]
  const optionItems = (importResult?.options ?? []) as OptionItem[]

  const MISSING_ITEMS: { [key: string]: number } = {
    SERVICE_PRICE: 0,
  }
  const missingItems = importItems
    .filter((it) => MISSING_ITEMS[it.type] !== undefined)
    .sort((a, b) => (a.code < b.code ? -1 : 0))
    .sort((a, b) => MISSING_ITEMS[b.type] - MISSING_ITEMS[a.type])

  const WILL_CREATE_ITEMS: { [key: string]: number } = {
    SUBJECT: 0,
    SERVICE_TYPE: 1,
    COUNTRY: 2,
    CITY: 3,
    LOCATION: 4,
  }
  const newItems = importItems
    .filter((it) => WILL_CREATE_ITEMS[it.type] !== undefined)
    .sort((a, b) => WILL_CREATE_ITEMS[a.type] - WILL_CREATE_ITEMS[b.type])

  const groupItems = importItems.filter((it) => INFO_TYPES.indexOf(it.type) > -1).groupBy((it) => it.code)

  const groupInfo: { [key: string]: any }[] = Object.entries(groupItems).map(([groupCode, items]) => ({
    ...items.toMapBy(
      (it) => it.type,
      (it) => it.name,
    ),
    name: groupCode,
  }))
  const infoColumns: GqlTableColumn<any>[] = [
    {
      title: user.translate('name'),
      field: 'name',
      align: 'left',
      cellStyle: { whiteSpace: 'nowrap' },
    },
    {
      title: user.translate('sub_groups_count'),
      field: 'SUB_GROUPS_COUNT',
      cellStyle: { whiteSpace: 'nowrap' },
    },
    {
      title: user.translate('customers_count'),
      field: 'CUSTOMERS_COUNT',
      cellStyle: { whiteSpace: 'nowrap' },
    },
    {
      title: user.translate('reservations_count'),
      field: 'RESERVATIONS_COUNT',
      cellStyle: { whiteSpace: 'nowrap' },
    },
    {
      title: user.translate('date_from'),
      field: 'DATE_FROM',
      cellStyle: { whiteSpace: 'nowrap' },
      render: (row) => EditTextDateEpoch.render({ name: 'date_to' }, row.DATE_FROM),
    },
    {
      title: user.translate('date_to'),
      field: 'DATE_TO',
      cellStyle: { whiteSpace: 'nowrap' },
      render: (row) => EditTextDateEpoch.render({ name: 'date_to' }, row.DATE_TO),
    },
  ]

  const reportCellStyle: CellStyle<any> = { whiteSpace: 'nowrap', paddingTop: 2, paddingBottom: 2 }
  const reportColumns: GqlTableColumn<ReportItem>[] = [
    {
      title: <HelpOutlineIcon color={'secondary'} />,
      field: 'status',
      cellStyle: { whiteSpace: 'nowrap', paddingTop: 0, paddingBottom: 0 },
      width: 1,
      render: (item: any) =>
        item.status === 'OK' ? (
          <CheckIcon color={'success'} fontSize={'small'} />
        ) : item.status === 'WARNING' ? (
          <WarningIcon color={'warning'} fontSize={'small'} />
        ) : (
          <ErrorIcon color={'error'} fontSize={'small'} />
        ),
    },
    {
      title: user.translate('type'),
      field: 'type',
      align: 'left',
      width: 1,
      cellStyle: reportCellStyle,
      render: (row) => user.translate(row.type.toLowerCase()),
    },
    {
      title: user.translate('code_name'),
      field: 'code',
      cellStyle: reportCellStyle,
    },
    {
      title: user.translate('name'),
      field: 'name',
      cellStyle: reportCellStyle,
    },
    {
      title: <BuildIcon color={'secondary'} fontSize={'small'} style={{ width: 40 }} />,
      field: 'status',
      cellStyle: { whiteSpace: 'nowrap', paddingTop: 0, paddingBottom: 0 },
      width: 2,
      render: (item: any) => {
        return (
          <>
            <IconButton size="small" color="primary" onClick={() => onItemClick(item)}>
              <FindInPageIcon />
            </IconButton>
            <Show if={item.status !== 'OK'}>
              <IconButton size="small" color="success" onClick={() => setShowCreateItem(item)}>
                <AddBoxIcon />
              </IconButton>
            </Show>
          </>
        )
      },
    },
  ]

  const optionsColumns: GqlTableColumn<OptionItem>[] = [
    {
      title: user.translate('type'),
      field: 'type',
      align: 'left',
      width: 1,
      cellStyle: reportCellStyle,
      render: (row) => user.translate(row.type.toLowerCase()),
    },
    {
      title: user.translate('code_name'),
      field: 'code',
      cellStyle: reportCellStyle,
    },
    {
      title: user.translate('name'),
      field: 'name',
      cellStyle: reportCellStyle,
    },
    {
      title: 'options',
      field: 'items',
      cellStyle: { whiteSpace: 'nowrap', paddingTop: 0, paddingBottom: 0 },
      width: 2,
      render: (data: OptionItem) => {
        const optionId = data.code + data.type
        return (
          <SelectOption
            options={data.items.map((it) => ({ value: it.id, name: it.title, desc: it.description }))}
            name={'items'}
            value={() => selectedOptions[optionId] ?? data.items[0].id}
            onChange={(value) => {
              setSelectedOptions((state) => ({ ...state, [data.code + data.type]: value ?? '' }))
            }}
          />
        )
      },
    },
  ]

  const serviceCellStyle: CellStyle<any> = { whiteSpace: 'nowrap', paddingTop: 4, paddingBottom: 4 }
  const servicesColumns: GqlTableColumn<any>[] = [
    {
      title: user.translate('service_class'),
      align: 'left',
      cellStyle: serviceCellStyle,
      render: (row) => EntityAutoComplete.render({ name: 'serviceClassId', entity: 'ServiceClass' }, row.serviceClassId, row),
    },
    {
      title: user.translate('service_type'),
      align: 'left',
      cellStyle: serviceCellStyle,
      render: (row) => EntityAutoComplete.render({ name: 'serviceTypeId', entity: 'ServiceType' }, row.serviceTypeId, row),
    },
    {
      title: user.translate('date_from'),
      field: 'fromDate.formatted',
      cellStyle: serviceCellStyle,
      render: (row) => row.fromDate.formatted.split('T')[0],
    },
    {
      title: user.translate('date_to'),
      field: 'toDate.formatted',
      cellStyle: serviceCellStyle,
      render: (row) => row.toDate.formatted.split('T')[0],
    }, // {
    //   title: user.translate('location'),
    //   field: 'fromLocationId',
    //   render: (row) => EntityAutoComplete.render({ name: 'fromLocationId', entity: 'Location' }, row.fromLocationId, row),
    // },
    {
      title: user.translate('location'),
      field: 'fromLocation',
      width: 24,
      cellStyle: serviceCellStyle,
    }, // {
    //   title: user.translate('location'),
    //   field: 'toLocationId',
    //   render: (row) => EntityAutoComplete.render({ name: 'toLocationId', entity: 'Location' }, row.toLocationId, row),
    // },
  ]

  const onItemClick = (row?: ReportItem) => {
    if (row?.type === 'SUBJECT') {
      navigate(`/o/${organisation.id}/subjects_hotel?q=` + encodeURIComponent(row?.code))
    } else if (row?.type === 'SERVICE_PRICE') {
      navigate(`/o/${organisation.id}/prices_accommodation?q=` + encodeURIComponent(row?.code))
    }
  }

  let startDate = groupInfo.maxOf((it) => parseInt(it.DATE_FROM)) ?? 0

  const newItemsTitle = getTitle(
    user,
    newItems.any((it: any) => it.status !== 'OK') ? WarningIcon : undefined,
    'warning',
    'will_create',
    'import_will_create_tooltip',
  )
  const missingTitle = getTitle(
    user,
    missingItems.any((it: any) => it.status !== 'OK') ? WarningIcon : undefined,
    'warning',
    'missing',
    'import_missing_tooltip',
  )
  const optionsTitle = getTitle(
    user,
    missingItems.any((it: any) => it.status !== 'OK') ? WarningIcon : undefined,
    'warning',
    'options',
    'import_options_tooltip',
  )
  const onCreateClose = (item?: any) => {
    setShowCreateItem(undefined)
    if (!item) {
      return
    }
    importSejzure({ variables: { fileName: file, dryRun: true } })
      .then(setResponse)
      .catch(() => setResponse(undefined))
  }

  const onLocationGroupingChange = (value: string | null) => setLocationGrouping(value ?? 'COUNTY')

  return (
    <PageContainer visible={Boolean(file)} animate="slide">
      <Box sx={{ p: 1 }} style={{ display: 'flex', alignItems: 'center', backgroundColor: 'white' }}>
        <UploadFileIcon sx={{ m: 1 }} />
        {file}
        <div style={{ flex: 1 }} />
        <SelectOption
          options={ServiceLocationGroup}
          name={'location_grouping'}
          value={locationGrouping}
          onChange={onLocationGroupingChange}
          size={'small'}
          isInline
        />
        <EntityAutoComplete
          entity={'TourArrangement'}
          name={'arrangement'}
          value={selectedArrId ?? undefined}
          onChange={setSelectedArrId}
          filter={
            startDate > 0
              ? {
                  by: 'validFrom',
                  lte: startDate,
                  and: { by: 'validTo', gte: startDate },
                }
              : undefined
          }
          isForm
          size={'small'}
        />
        <SplitButton
          options={[user.translate('import'), user.translate('preview')]}
          colors={['warning', 'success']}
          Icons={[LabelImportantIcon, BarChartIcon]}
          selectedDefault={1}
          disabled={loading}
          resetOnChange={selectedArrId}
          onClick={(index) => {
            importSejzure({ variables: { fileName: file, dryRun: index === 1, tourArrangementId: selectedArrId } })
              .then((data) => {
                if (data.ok && data.result.saleIds.length > 0) {
                  navigate(`./../../${data.result.saleIds[0]}`)
                  return undefined
                }
                if (!data.ok) {
                  return undefined
                }
                return data
              })
              .then(setResponse)
              .catch(() => setResponse(undefined))
          }}
        />
        <Show if={loading}>
          <CircularProgress size={20} sx={{ ml: 1, position: 'absolute', right: 124, zIndex: 999 }} />
        </Show>
      </Box>
      <Show if={response?.ok === false}>{response?.message}</Show>
      <Show if={importResult?.items}>
        <br />
        <PlainTable title={'groups'} items={groupInfo} columns={infoColumns} noSearch noExport noPaging dense />
      </Show>
      <Show if={importResult?.groups && importResult?.groups?.length !== 0}>
        <br />
        <PlainTable
          title={getTitle(user, Info, 'info', 'services', 'import_services_tooltip')}
          items={(importResult?.groups ?? [])[0]?.services?.orderBy((it: any) => it.fromDate.timeEpoch) ?? []}
          columns={servicesColumns}
          deps={[response]}
          noSearch
          noExport
          noPaging
          dense
        />
      </Show>
      <Show if={optionItems.length > 0}>
        <br />
        <PlainTable
          title={optionsTitle}
          items={optionItems}
          columns={optionsColumns}
          deps={[optionItems, selectedOptions]}
          dense
          noExport
          noPaging
        />
      </Show>
      <Show if={missingItems.length > 0}>
        <br />
        <PlainTable title={missingTitle} items={missingItems} columns={reportColumns} deps={[missingItems]} dense noExport noPaging />
      </Show>
      <Show if={newItems.length > 0}>
        <br />
        <PlainTable title={newItemsTitle} items={newItems} columns={reportColumns} deps={[newItems]} dense noExport noPaging />
      </Show>
      <Show if={showCreateItem?.type === 'CITY'}>
        <CreateCity value={showCreateItem?.code} onClose={onCreateClose} />
      </Show>
      <Show if={showCreateItem?.type === 'COUNTRY'}>
        <CreateCountry value={showCreateItem?.code} onClose={onCreateClose} />
      </Show>
      <Show if={showCreateItem?.type === 'LOCATION'}>
        <CreateLocation value={showCreateItem?.code} onClose={onCreateClose} />
      </Show>
      <Show if={showCreateItem?.type === 'SUBJECT'}>
        <CreateSubject value={showCreateItem?.name} code={showCreateItem?.code} onClose={onCreateClose} />
      </Show>
    </PageContainer>
  )
}

export default function SaleImportPageRoute(props: Props) {
  return (
    <Routes>
      <Route path="/:file" element={<SaleImportPage {...props} />} />
    </Routes>
  )
}
