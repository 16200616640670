export const icons: { [key: string]: { name: string, inherit?: string[], keywords: string[] } } = {
  glass: { name: "glass", keywords: ["cocktail", "alcohol", "beer", "wine", "liquor", "martini"] },
  music: { name: "music", keywords: ["song", "radio", "singing", "note", "musical", "sounds"] },
  search: { name: "search", keywords: ["magnifying glass", "zoom", "find", "query"] },
  "envelope-o": { name: "envelope-o", inherit: ["outline"], keywords: ["mail", "email", "send", "postal", "notes"] },
  heart: { name: "heart", keywords: ["love", "valentines", "kisses", "romance"] },
  star: { name: "star", keywords: ["rating", "planet"] },
  "star-o": { name: "star-o", inherit: ["outline"], keywords: ["rating", "planet"] },
  user: { name: "user", keywords: ["client", "person", "body", "man", "human", "woman"] },
  film: { name: "film", keywords: ["filming", "strip", "photography", "negative", "camera", "picture", "image"] },
  "th-large": {
    name: "th-large",
    keywords: [
      "windows",
      "fenestrations",
      "layout",
      "table",
      "grid",
      "column",
      "cells",
      "rows",
      "td",
      "scaffolding",
      "big",
      "quad",
      "half",
    ],
  },
  th: {
    name: "th",
    keywords: ["windows", "fenestrations", "layout", "table", "grid", "column", "cells", "rows", "td", "scaffolding"],
  },
  "th-list": {
    name: "th-list",
    keywords: ["items", "bullets", "layout", "table", "grid", "rows", "scaffolding", "ol", "ul", "li"],
  },
  check: { name: "check", keywords: ["checkmark", "done", "completed", "checkbox", "ready"] },
  times: {
    name: "times",
    keywords: [
      "x",
      "multiply",
      "multiplication",
      "mathematics",
      "arithmetics",
      "exit",
      "close",
      "no",
      "none",
      "crossed out",
      "zero",
      "letter",
    ],
  },
  "search-plus": { name: "search-plus", keywords: ["magnifying glass", "zoom in"] },
  "search-minus": { name: "search-minus", keywords: ["magnifying glass", "zoom out"] },
  "power-off": { name: "power-off", keywords: [] },
  signal: { name: "signal", keywords: [] },
  cog: { name: "cog", keywords: ["gear", "settings"] },
  "trash-o": { name: "trash-o", inherit: ["outline"], keywords: [] },
  home: { name: "home", keywords: [] },
  "file-o": { name: "file-o", inherit: ["outline"], keywords: [] },
  "clock-o": { name: "clock-o", inherit: ["outline"], keywords: [] },
  road: { name: "road", keywords: [] },
  download: { name: "download", keywords: [] },
  "arrow-circle-o-down": { name: "arrow-circle-o-down", keywords: [] },
  "arrow-circle-o-up": { name: "arrow-circle-o-up", keywords: [] },
  inbox: { name: "inbox", keywords: [] },
  "play-circle-o": { name: "play-circle-o", inherit: ["outline"], keywords: [] },
  repeat: { name: "repeat", keywords: [] },
  refresh: { name: "refresh", keywords: [] },
  "list-alt": { name: "list-alt", keywords: [] },
  lock: { name: "lock", keywords: [] },
  flag: { name: "flag", keywords: [] },
  headphones: { name: "headphones", keywords: [] },
  "volume-off": { name: "volume-off", keywords: [] },
  "volume-down": { name: "volume-down", keywords: [] },
  "volume-up": { name: "volume-up", keywords: [] },
  qrcode: { name: "qrcode", keywords: [] },
  barcode: { name: "barcode", keywords: [] },
  tag: { name: "tag", keywords: [] },
  tags: { name: "tags", keywords: [] },
  book: { name: "book", keywords: [] },
  bookmark: { name: "bookmark", keywords: [] },
  print: { name: "print", keywords: [] },
  camera: { name: "camera", keywords: [] },
  font: { name: "font", keywords: [] },
  bold: { name: "bold", keywords: [] },
  italic: { name: "italic", keywords: [] },
  "text-height": { name: "text-height", keywords: [] },
  "text-width": { name: "text-width", keywords: [] },
  "align-left": { name: "align-left", keywords: [] },
  "align-center": { name: "align-center", keywords: [] },
  "align-right": { name: "align-right", keywords: [] },
  "align-justify": { name: "align-justify", keywords: [] },
  list: { name: "list", keywords: [] },
  outdent: { name: "outdent", keywords: [] },
  indent: { name: "indent", keywords: [] },
  "video-camera": { name: "video-camera", keywords: [] },
  "picture-o": { name: "picture-o", inherit: ["outline"], keywords: [] },
  pencil: {
    name: "pencil",
    keywords: [
      "utensil",
      "writer",
      "written",
      "draw",
      "edit",
      "pen",
      "ink",
      "lead",
      "sharpen",
      "literature",
      "artist",
      "sketch",
      "trace",
    ],
  },
  "map-marker": { name: "map-marker", keywords: [] },
  adjust: { name: "adjust", keywords: [] },
  tint: { name: "tint", keywords: [] },
  "pencil-square-o": { name: "pencil-square-o", inherit: ["pencil", "square"], keywords: [] },
  "share-square-o": { name: "share-square-o", inherit: ["outline"], keywords: [] },
  "check-square-o": { name: "check-square-o", inherit: ["outline"], keywords: [] },
  arrows: {
    name: "arrows",
    keywords: ["point", "up", "down", "left", "right", "directions", "vertical", "horizontal"],
  },
  "step-backward": { name: "step-backward", keywords: [] },
  "fast-backward": { name: "fast-backward", keywords: [] },
  backward: { name: "backward", keywords: [] },
  play: { name: "play", keywords: [] },
  pause: { name: "pause", keywords: [] },
  stop: { name: "stop", keywords: [] },
  forward: { name: "forward", keywords: [] },
  "fast-forward": { name: "fast-forward", keywords: [] },
  "step-forward": { name: "step-forward", keywords: [] },
  eject: { name: "eject", keywords: [] },
  "chevron-left": { name: "chevron-left", keywords: [] },
  "chevron-right": { name: "chevron-right", keywords: [] },
  "plus-circle": { name: "plus-circle", keywords: [] },
  "minus-circle": { name: "minus-circle", keywords: [] },
  "times-circle": { name: "times-circle", inherit: ["times"], keywords: [] },
  "check-circle": { name: "check-circle", keywords: [] },
  "question-circle": { name: "question-circle", keywords: [] },
  "info-circle": { name: "info-circle", keywords: [] },
  crosshairs: { name: "crosshairs", keywords: [] },
  "times-circle-o": { name: "times-circle-o", inherit: ["times", "circle"], keywords: [] },
  "check-circle-o": { name: "check-circle-o", inherit: ["check", "circle"], keywords: [] },
  ban: { name: "ban", keywords: [] },
  "arrow-left": { name: "arrow-left", keywords: [] },
  "arrow-right": { name: "arrow-right", keywords: [] },
  "arrow-up": { name: "arrow-up", keywords: [] },
  "arrow-down": { name: "arrow-down", keywords: [] },
  share: { name: "share", keywords: [] },
  expand: { name: "expand", keywords: [] },
  compress: { name: "compress", keywords: [] },
  plus: { name: "plus", keywords: [] },
  minus: { name: "minus", keywords: [] },
  asterisk: { name: "asterisk", keywords: [] },
  "exclamation-circle": { name: "exclamation-circle", keywords: [] },
  gift: { name: "gift", keywords: [] },
  leaf: { name: "leaf", keywords: [] },
  fire: { name: "fire", keywords: [] },
  eye: { name: "eye", keywords: [] },
  "eye-slash": { name: "eye-slash", keywords: [] },
  "exclamation-triangle": { name: "exclamation-triangle", keywords: [] },
  plane: { name: "plane", keywords: [] },
  calendar: { name: "calendar", keywords: [] },
  random: { name: "random", keywords: [] },
  comment: { name: "comment", keywords: [] },
  magnet: { name: "magnet", keywords: [] },
  "chevron-up": { name: "chevron-up", keywords: [] },
  "chevron-down": { name: "chevron-down", keywords: [] },
  retweet: { name: "retweet", keywords: [] },
  "shopping-cart": { name: "shopping-cart", keywords: [] },
  folder: { name: "folder", keywords: [] },
  "folder-open": { name: "folder-open", keywords: [] },
  "arrows-v": { name: "arrows-v", keywords: [] },
  "arrows-h": { name: "arrows-h", keywords: [] },
  "bar-chart-o": { name: "bar-chart-o", inherit: ["outline"], keywords: [] },
  "twitter-square": { name: "twitter-square", keywords: [] },
  "facebook-square": { name: "facebook-square", keywords: [] },
  "camera-retro": { name: "camera-retro", keywords: [] },
  key: { name: "key", keywords: [] },
  cogs: { name: "cogs", keywords: [] },
  comments: { name: "comments", keywords: [] },
  "thumbs-o-up": { name: "thumbs-o-up", keywords: [] },
  "thumbs-o-down": { name: "thumbs-o-down", keywords: [] },
  "star-half": { name: "star-half", keywords: [] },
  "heart-o": { name: "heart-o", inherit: ["outline"], keywords: [] },
  "sign-out": { name: "sign-out", keywords: [] },
  "linkedin-square": { name: "linkedin-square", keywords: [] },
  "thumb-tack": { name: "thumb-tack", keywords: [] },
  "external-link": { name: "external-link", keywords: [] },
  "sign-in": { name: "sign-in", keywords: [] },
  trophy: { name: "trophy", keywords: [] },
  "github-square": { name: "github-square", keywords: [] },
  upload: { name: "upload", keywords: [] },
  "lemon-o": { name: "lemon-o", inherit: ["outline"], keywords: [] },
  phone: { name: "phone", keywords: [] },
  "square-o": { name: "square-o", inherit: ["outline"], keywords: [] },
  "bookmark-o": { name: "bookmark-o", inherit: ["outline"], keywords: [] },
  "phone-square": { name: "phone-square", keywords: [] },
  twitter: { name: "twitter", keywords: [] },
  facebook: { name: "facebook", keywords: [] },
  github: { name: "github", keywords: [] },
  unlock: { name: "unlock", keywords: [] },
  "credit-card": { name: "credit-card", keywords: [] },
  rss: { name: "rss", keywords: [] },
  "hdd-o": { name: "hdd-o", inherit: ["outline"], keywords: [] },
  bullhorn: { name: "bullhorn", keywords: [] },
  bell: { name: "bell", keywords: [] },
  certificate: { name: "certificate", keywords: [] },
  "hand-o-right": { name: "hand-o-right", keywords: [] },
  "hand-o-left": { name: "hand-o-left", keywords: [] },
  "hand-o-up": { name: "hand-o-up", keywords: [] },
  "hand-o-down": { name: "hand-o-down", keywords: [] },
  "arrow-circle-left": { name: "arrow-circle-left", keywords: [] },
  "arrow-circle-right": { name: "arrow-circle-right", keywords: [] },
  "arrow-circle-up": { name: "arrow-circle-up", keywords: [] },
  "arrow-circle-down": { name: "arrow-circle-down", keywords: [] },
  globe: { name: "globe", keywords: [] },
  wrench: { name: "wrench", keywords: [] },
  tasks: { name: "tasks", keywords: [] },
  filter: { name: "filter", keywords: [] },
  briefcase: { name: "briefcase", keywords: [] },
  "arrows-alt": { name: "arrows-alt", keywords: [] },
  users: { name: "users", inherit: ["user"], keywords: [] },
  link: { name: "link", keywords: [] },
  cloud: { name: "cloud", keywords: [] },
  flask: { name: "flask", keywords: [] },
  scissors: { name: "scissors", keywords: [] },
  "files-o": { name: "files-o", inherit: ["outline"], keywords: [] },
  paperclip: { name: "paperclip", keywords: [] },
  "floppy-o": { name: "floppy-o", inherit: ["outline"], keywords: [] },
  square: { name: "square", keywords: ["geometry", "shape", "polygon", "box", "cube"] },
  bars: { name: "bars", keywords: [] },
  "list-ul": { name: "list-ul", keywords: [] },
  "list-ol": { name: "list-ol", keywords: [] },
  strikethrough: { name: "strikethrough", keywords: [] },
  underline: { name: "underline", keywords: [] },
  table: { name: "table", keywords: [] },
  magic: { name: "magic", keywords: [] },
  truck: { name: "truck", keywords: [] },
  pinterest: { name: "pinterest", keywords: [] },
  "pinterest-square": { name: "pinterest-square", keywords: [] },
  "google-plus-square": { name: "google-plus-square", keywords: [] },
  "google-plus": { name: "google-plus", keywords: [] },
  money: { name: "money", keywords: [] },
  "caret-down": { name: "caret-down", keywords: [] },
  "caret-up": { name: "caret-up", keywords: [] },
  "caret-left": { name: "caret-left", keywords: [] },
  "caret-right": { name: "caret-right", keywords: [] },
  columns: { name: "columns", keywords: [] },
  sort: { name: "sort", keywords: [] },
  "sort-asc": { name: "sort-asc", keywords: [] },
  "sort-desc": { name: "sort-desc", keywords: [] },
  envelope: { name: "envelope", keywords: [] },
  linkedin: { name: "linkedin", keywords: [] },
  undo: { name: "undo", keywords: [] },
  gavel: { name: "gavel", keywords: [] },
  tachometer: { name: "tachometer", keywords: [] },
  "comment-o": { name: "comment-o", inherit: ["outline"], keywords: [] },
  "comments-o": { name: "comments-o", inherit: ["outline"], keywords: [] },
  bolt: { name: "bolt", keywords: [] },
  sitemap: { name: "sitemap", keywords: [] },
  umbrella: { name: "umbrella", keywords: [] },
  clipboard: { name: "clipboard", keywords: [] },
  "lightbulb-o": { name: "lightbulb-o", inherit: ["outline"], keywords: [] },
  exchange: { name: "exchange", inherit: ["upload", "download"], keywords: [] },
  "cloud-download": { name: "cloud-download", inherit: ["cloud", "download"], keywords: [] },
  "cloud-upload": { name: "cloud-upload", inherit: ["cloud", "upload"], keywords: [] },
  "user-md": { name: "user-md", inherit: ["user", "stethoscope"], keywords: [] },
  stethoscope: { name: "stethoscope", keywords: ["medical", "doctor", "healthcare", "nurse", "hospital"] },
  suitcase: { name: "suitcase", keywords: ["work", "business", "briefcase", "traveling", "vacation", "bag"] },
  "bell-o": { name: "bell-o", inherit: ["outline"], keywords: [] },
  coffee: { name: "coffee", keywords: [] },
  cutlery: { name: "cutlery", keywords: [] },
  "file-text-o": { name: "file-text-o", inherit: ["outline"], keywords: [] },
  "building-o": { name: "building-o", inherit: ["outline"], keywords: [] },
  "hospital-o": { name: "hospital-o", inherit: ["outline"], keywords: [] },
  ambulance: { name: "ambulance", keywords: [] },
  medkit: { name: "medkit", keywords: [] },
  "fighter-jet": { name: "fighter-jet", keywords: [] },
  beer: { name: "beer", keywords: [] },
  "h-square": { name: "h-square", keywords: [] },
  "plus-square": { name: "plus-square", keywords: [] },
  "angle-double-left": { name: "angle-double-left", keywords: [] },
  "angle-double-right": { name: "angle-double-right", keywords: [] },
  "angle-double-up": { name: "angle-double-up", keywords: [] },
  "angle-double-down": { name: "angle-double-down", keywords: [] },
  "angle-left": { name: "angle-left", keywords: [] },
  "angle-right": { name: "angle-right", keywords: [] },
  "angle-up": { name: "angle-up", keywords: [] },
  "angle-down": { name: "angle-down", keywords: [] },
  desktop: { name: "desktop", keywords: [] },
  laptop: { name: "laptop", keywords: [] },
  tablet: { name: "tablet", keywords: [] },
  mobile: { name: "mobile", keywords: [] },
  "circle-o": { name: "circle-o", inherit: ["outline"], keywords: ["not required", "optional"] },
  "quote-left": { name: "quote-left", keywords: [] },
  "quote-right": { name: "quote-right", keywords: [] },
  spinner: { name: "spinner", keywords: ["loader", "gif", "animated", "loading", "wait", "circle", "dots"] },
  circle: { name: "circle", keywords: [] },
  reply: { name: "reply", keywords: [] },
  "github-alt": { name: "github-alt", keywords: [] },
  "folder-o": { name: "folder-o", inherit: ["outline"], keywords: [] },
  "folder-open-o": { name: "folder-open-o", inherit: ["outline"], keywords: [] },
  "smile-o": { name: "smile-o", inherit: ["outline"], keywords: [] },
  "frown-o": { name: "frown-o", inherit: ["outline"], keywords: [] },
  "meh-o": { name: "meh-o", inherit: ["outline"], keywords: [] },
  gamepad: { name: "gamepad", keywords: [] },
  "keyboard-o": { name: "keyboard-o", inherit: ["outline"], keywords: [] },
  "flag-o": { name: "flag-o", inherit: ["outline"], keywords: [] },
  "flag-checkered": { name: "flag-checkered", keywords: [] },
  terminal: { name: "terminal", keywords: [] },
  code: { name: "code", keywords: [] },
  "reply-all": { name: "reply-all", keywords: [] },
  "mail-reply-all": { name: "mail-reply-all", keywords: [] },
  "star-half-o": { name: "star-half-o", inherit: ["outline"], keywords: [] },
  "location-arrow": { name: "location-arrow", keywords: [] },
  crop: { name: "crop", keywords: [] },
  "code-fork": { name: "code-fork", keywords: [] },
  "chain-broken": { name: "chain-broken", keywords: [] },
  question: { name: "question", keywords: [] },
  info: { name: "info", keywords: [] },
  exclamation: { name: "exclamation", keywords: [] },
  superscript: { name: "superscript", keywords: [] },
  subscript: { name: "subscript", keywords: [] },
  eraser: { name: "eraser", keywords: [] },
  "puzzle-piece": { name: "puzzle-piece", keywords: [] },
  microphone: { name: "microphone", keywords: [] },
  "microphone-slash": { name: "microphone-slash", keywords: [] },
  shield: { name: "shield", keywords: [] },
  "calendar-o": { name: "calendar-o", inherit: ["outline"], keywords: [] },
  "fire-extinguisher": { name: "fire-extinguisher", keywords: [] },
  rocket: { name: "rocket", keywords: [] },
  maxcdn: { name: "maxcdn", keywords: [] },
  "chevron-circle-left": { name: "chevron-circle-left", keywords: [] },
  "chevron-circle-right": { name: "chevron-circle-right", keywords: [] },
  "chevron-circle-up": { name: "chevron-circle-up", keywords: [] },
  "chevron-circle-down": { name: "chevron-circle-down", keywords: [] },
  html5: { name: "html5", keywords: [] },
  css3: { name: "css3", keywords: [] },
  anchor: { name: "anchor", keywords: [] },
  "unlock-alt": { name: "unlock-alt", keywords: [] },
  bullseye: { name: "bullseye", keywords: [] },
  "ellipsis-h": { name: "ellipsis-h", keywords: [] },
  "ellipsis-v": { name: "ellipsis-v", keywords: [] },
  "rss-square": { name: "rss-square", keywords: [] },
  "play-circle": { name: "play-circle", keywords: [] },
  ticket: { name: "ticket", keywords: [] },
  "minus-square": { name: "minus-square", keywords: [] },
  "minus-square-o": { name: "minus-square-o", inherit: ["outline"], keywords: [] },
  "level-up": { name: "level-up", keywords: [] },
  "level-down": { name: "level-down", keywords: [] },
  "check-square": { name: "check-square", keywords: [] },
  "pencil-square": { name: "pencil-square", inherit: ["pencil", "square"], keywords: [] },
  "external-link-square": { name: "external-link-square", keywords: [] },
  "share-square": { name: "share-square", keywords: [] },
  compass: { name: "compass", keywords: [] },
  "caret-square-o-down": { name: "caret-square-o-down", keywords: [] },
  "caret-square-o-up": { name: "caret-square-o-up", keywords: [] },
  "caret-square-o-right": { name: "caret-square-o-right", keywords: [] },
  eur: { name: "eur", keywords: [] },
  gbp: { name: "gbp", keywords: [] },
  usd: { name: "usd", keywords: [] },
  inr: { name: "inr", keywords: [] },
  jpy: { name: "jpy", keywords: [] },
  rub: { name: "rub", keywords: [] },
  krw: { name: "krw", keywords: [] },
  btc: { name: "btc", keywords: [] },
  file: { name: "file", keywords: [] },
  "file-text": { name: "file-text", keywords: [] },
  "sort-alpha-asc": { name: "sort-alpha-asc", keywords: [] },
  "sort-alpha-desc": { name: "sort-alpha-desc", keywords: [] },
  "sort-amount-asc": { name: "sort-amount-asc", keywords: [] },
  "sort-amount-desc": { name: "sort-amount-desc", keywords: [] },
  "sort-numeric-asc": { name: "sort-numeric-asc", keywords: [] },
  "sort-numeric-desc": { name: "sort-numeric-desc", keywords: [] },
  "thumbs-up": { name: "thumbs-up", keywords: [] },
  "thumbs-down": { name: "thumbs-down", keywords: [] },
  "youtube-square": { name: "youtube-square", keywords: [] },
  youtube: { name: "youtube", keywords: [] },
  xing: { name: "xing", keywords: [] },
  "xing-square": { name: "xing-square", keywords: [] },
  "youtube-play": { name: "youtube-play", keywords: [] },
  dropbox: { name: "dropbox", keywords: [] },
  "stack-overflow": { name: "stack-overflow", keywords: [] },
  instagram: { name: "instagram", keywords: [] },
  flickr: { name: "flickr", keywords: [] },
  adn: { name: "adn", keywords: [] },
  bitbucket: { name: "bitbucket", keywords: [] },
  "bitbucket-square": { name: "bitbucket-square", keywords: [] },
  tumblr: { name: "tumblr", keywords: [] },
  "tumblr-square": { name: "tumblr-square", keywords: [] },
  "long-arrow-down": { name: "long-arrow-down", keywords: [] },
  "long-arrow-up": { name: "long-arrow-up", keywords: [] },
  "long-arrow-left": { name: "long-arrow-left", keywords: [] },
  "long-arrow-right": { name: "long-arrow-right", keywords: [] },
  apple: { name: "apple", keywords: [] },
  windows: { name: "windows", keywords: [] },
  android: { name: "android", keywords: [] },
  linux: { name: "linux", keywords: [] },
  dribbble: { name: "dribbble", keywords: [] },
  skype: { name: "skype", keywords: [] },
  foursquare: { name: "foursquare", keywords: [] },
  trello: { name: "trello", keywords: [] },
  female: { name: "female", keywords: [] },
  male: { name: "male", keywords: [] },
  gittip: { name: "gittip", keywords: [] },
  "sun-o": { name: "sun-o", inherit: ["outline"], keywords: [] },
  "moon-o": { name: "moon-o", inherit: ["outline"], keywords: [] },
  archive: { name: "archive", keywords: [] },
  bug: { name: "bug", keywords: [] },
  vk: { name: "vk", keywords: [] },
  weibo: { name: "weibo", keywords: [] },
  renren: { name: "renren", keywords: [] },
  pagelines: { name: "pagelines", keywords: [] },
  "stack-exchange": { name: "stack-exchange", keywords: [] },
  "arrow-circle-o-right": { name: "arrow-circle-o-right", keywords: [] },
  "arrow-circle-o-left": { name: "arrow-circle-o-left", keywords: [] },
  "caret-square-o-left": { name: "caret-square-o-left", keywords: [] },
  "dot-circle-o": { name: "dot-circle-o", inherit: ["outline"], keywords: [] },
  wheelchair: { name: "wheelchair", keywords: [] },
  "vimeo-square": { name: "vimeo-square", keywords: [] },
  try: { name: "try", keywords: [] },
  "plus-square-o": { name: "plus-square-o", inherit: ["outline"], keywords: [] },
  "500px": { name: "500px", keywords: [] },
  "address-book": { name: "address-book", keywords: [] },
  "address-book-o": { name: "address-book-o", inherit: ["outline"], keywords: [] },
  "address-card": { name: "address-card", keywords: [] },
  "address-card-o": { name: "address-card-o", inherit: ["outline"], keywords: [] },
  amazon: { name: "amazon", keywords: [] },
  "american-sign-language-interpreting": { name: "american-sign-language-interpreting", keywords: [] },
  angellist: { name: "angellist", keywords: [] },
  "area-chart": { name: "area-chart", keywords: [] },
  "asl-interpreting": { name: "asl-interpreting", keywords: [] },
  "assistive-listening-systems": { name: "assistive-listening-systems", keywords: [] },
  at: { name: "at", keywords: [] },
  "audio-description": { name: "audio-description", keywords: [] },
  automobile: { name: "automobile", keywords: [] },
  "balance-scale": { name: "balance-scale", keywords: [] },
  bandcamp: { name: "bandcamp", keywords: [] },
  bank: { name: "bank", keywords: [] },
  "bar-chart": { name: "bar-chart", keywords: [] },
  bath: { name: "bath", keywords: [] },
  bathtub: { name: "bathtub", keywords: [] },
  battery: { name: "battery", keywords: [] },
  "battery-0": { name: "battery-0", keywords: [] },
  "battery-1": { name: "battery-1", keywords: [] },
  "battery-2": { name: "battery-2", keywords: [] },
  "battery-3": { name: "battery-3", keywords: [] },
  "battery-4": { name: "battery-4", keywords: [] },
  "battery-empty": { name: "battery-empty", keywords: [] },
  "battery-full": { name: "battery-full", keywords: [] },
  "battery-half": { name: "battery-half", keywords: [] },
  "battery-quarter": { name: "battery-quarter", keywords: [] },
  "battery-three-quarters": { name: "battery-three-quarters", keywords: [] },
  bed: { name: "bed", keywords: [] },
  behance: { name: "behance", keywords: [] },
  "behance-square": { name: "behance-square", keywords: [] },
  "bell-slash": { name: "bell-slash", keywords: [] },
  "bell-slash-o": { name: "bell-slash-o", inherit: ["outline"], keywords: [] },
  bicycle: { name: "bicycle", keywords: [] },
  binoculars: { name: "binoculars", keywords: [] },
  "birthday-cake": { name: "birthday-cake", keywords: [] },
  bitcoin: { name: "bitcoin", keywords: [] },
  "black-tie": { name: "black-tie", keywords: [] },
  blind: { name: "blind", keywords: [] },
  bluetooth: { name: "bluetooth", keywords: [] },
  "bluetooth-b": { name: "bluetooth-b", keywords: [] },
  bomb: { name: "bomb", keywords: [] },
  braille: { name: "braille", keywords: [] },
  building: { name: "building", keywords: [] },
  bus: { name: "bus", keywords: [] },
  buysellads: { name: "buysellads", keywords: [] },
  cab: { name: "cab", keywords: [] },
  calculator: { name: "calculator", keywords: [] },
  "calendar-check-o": { name: "calendar-check-o", inherit: ["outline"], keywords: [] },
  "calendar-minus-o": { name: "calendar-minus-o", inherit: ["outline"], keywords: [] },
  "calendar-plus-o": { name: "calendar-plus-o", inherit: ["outline"], keywords: [] },
  "calendar-times-o": { name: "calendar-times-o", inherit: ["outline"], keywords: [] },
  car: { name: "car", keywords: [] },
  "cart-arrow-down": { name: "cart-arrow-down", keywords: [] },
  "cart-plus": { name: "cart-plus", keywords: [] },
  cc: { name: "cc", keywords: [] },
  "cc-amex": { name: "cc-amex", keywords: [] },
  "cc-diners-club": { name: "cc-diners-club", keywords: [] },
  "cc-discover": { name: "cc-discover", keywords: [] },
  "cc-jcb": { name: "cc-jcb", keywords: [] },
  "cc-mastercard": { name: "cc-mastercard", keywords: [] },
  "cc-paypal": { name: "cc-paypal", keywords: [] },
  "cc-stripe": { name: "cc-stripe", keywords: [] },
  "cc-visa": { name: "cc-visa", keywords: [] },
  chain: { name: "chain", keywords: [] },
  child: { name: "child", keywords: [] },
  chrome: { name: "chrome", keywords: [] },
  "circle-o-notch": { name: "circle-o-notch", keywords: [] },
  "circle-thin": { name: "circle-thin", keywords: [] },
  clone: { name: "clone", keywords: [] },
  close: { name: "close", keywords: [] },
  cny: { name: "cny", keywords: [] },
  codepen: { name: "codepen", keywords: [] },
  codiepie: { name: "codiepie", keywords: [] },
  commenting: { name: "commenting", keywords: [] },
  "commenting-o": { name: "commenting-o", inherit: ["outline"], keywords: [] },
  connectdevelop: { name: "connectdevelop", keywords: [] },
  contao: { name: "contao", keywords: [] },
  copy: { name: "copy", keywords: [] },
  copyright: { name: "copyright", keywords: [] },
  "creative-commons": { name: "creative-commons", keywords: [] },
  "credit-card-alt": { name: "credit-card-alt", keywords: [] },
  cube: { name: "cube", keywords: [] },
  cubes: { name: "cubes", keywords: [] },
  cut: { name: "cut", keywords: [] },
  dashboard: { name: "dashboard", keywords: [] },
  dashcube: { name: "dashcube", keywords: [] },
  database: { name: "database", keywords: [] },
  deaf: { name: "deaf", keywords: [] },
  deafness: { name: "deafness", keywords: [] },
  dedent: { name: "dedent", keywords: [] },
  delicious: { name: "delicious", keywords: [] },
  deviantart: { name: "deviantart", keywords: [] },
  diamond: { name: "diamond", keywords: [] },
  digg: { name: "digg", keywords: [] },
  dollar: { name: "dollar", keywords: [] },
  "drivers-license": { name: "drivers-license", keywords: [] },
  "drivers-license-o": { name: "drivers-license-o", inherit: ["outline"], keywords: [] },
  drupal: { name: "drupal", keywords: [] },
  edge: { name: "edge", keywords: [] },
  edit: { name: "edit", keywords: [] },
  eercast: { name: "eercast", keywords: [] },
  empire: { name: "empire", keywords: [] },
  "envelope-open": { name: "envelope-open", keywords: [] },
  "envelope-open-o": { name: "envelope-open-o", inherit: ["outline"], keywords: [] },
  "envelope-square": { name: "envelope-square", keywords: [] },
  envira: { name: "envira", keywords: [] },
  etsy: { name: "etsy", keywords: [] },
  euro: { name: "euro", keywords: [] },
  expeditedssl: { name: "expeditedssl", keywords: [] },
  eyedropper: { name: "eyedropper", keywords: [] },
  fa: { name: "fa", keywords: [] },
  "facebook-f": { name: "facebook-f", keywords: [] },
  "facebook-official": { name: "facebook-official", keywords: [] },
  fax: { name: "fax", keywords: [] },
  feed: { name: "feed", keywords: [] },
  "file-archive-o": { name: "file-archive-o", inherit: ["outline"], keywords: [] },
  "file-audio-o": { name: "file-audio-o", inherit: ["outline"], keywords: [] },
  "file-code-o": { name: "file-code-o", inherit: ["outline"], keywords: [] },
  "file-excel-o": { name: "file-excel-o", inherit: ["outline"], keywords: [] },
  "file-image-o": { name: "file-image-o", inherit: ["outline"], keywords: [] },
  "file-movie-o": { name: "file-movie-o", inherit: ["outline"], keywords: [] },
  "file-pdf-o": { name: "file-pdf-o", inherit: ["outline"], keywords: [] },
  "file-photo-o": { name: "file-photo-o", inherit: ["outline"], keywords: [] },
  "file-picture-o": { name: "file-picture-o", inherit: ["outline"], keywords: [] },
  "file-powerpoint-o": { name: "file-powerpoint-o", inherit: ["outline"], keywords: [] },
  "file-sound-o": { name: "file-sound-o", inherit: ["outline"], keywords: [] },
  "file-video-o": { name: "file-video-o", inherit: ["outline"], keywords: [] },
  "file-word-o": { name: "file-word-o", inherit: ["outline"], keywords: [] },
  "file-zip-o": { name: "file-zip-o", inherit: ["outline"], keywords: [] },
  firefox: { name: "firefox", keywords: [] },
  "first-order": { name: "first-order", keywords: [] },
  flash: { name: "flash", keywords: [] },
  "font-awesome": { name: "font-awesome", keywords: [] },
  fonticons: { name: "fonticons", keywords: [] },
  "fort-awesome": { name: "fort-awesome", keywords: [] },
  forumbee: { name: "forumbee", keywords: [] },
  "free-code-camp": { name: "free-code-camp", keywords: [] },
  "futbol-o": { name: "futbol-o", inherit: ["outline"], keywords: [] },
  ge: { name: "ge", keywords: [] },
  gear: { name: "gear", keywords: [] },
  gears: { name: "gears", keywords: [] },
  genderless: { name: "genderless", keywords: [] },
  "get-pocket": { name: "get-pocket", keywords: [] },
  gg: { name: "gg", keywords: [] },
  "gg-circle": { name: "gg-circle", keywords: [] },
  git: { name: "git", keywords: [] },
  "git-square": { name: "git-square", keywords: [] },
  gitlab: { name: "gitlab", keywords: [] },
  glide: { name: "glide", keywords: [] },
  "glide-g": { name: "glide-g", keywords: [] },
  google: { name: "google", keywords: [] },
  "google-plus-circle": { name: "google-plus-circle", keywords: [] },
  "google-plus-official": { name: "google-plus-official", keywords: [] },
  "google-wallet": { name: "google-wallet", keywords: [] },
  "graduation-cap": { name: "graduation-cap", keywords: [] },
  gratipay: { name: "gratipay", keywords: [] },
  grav: { name: "grav", keywords: [] },
  group: { name: "group", keywords: [] },
  "hacker-news": { name: "hacker-news", keywords: [] },
  "hand-grab-o": { name: "hand-grab-o", inherit: ["outline"], keywords: [] },
  "hand-lizard-o": { name: "hand-lizard-o", inherit: ["outline"], keywords: [] },
  "hand-paper-o": { name: "hand-paper-o", inherit: ["outline"], keywords: [] },
  "hand-peace-o": { name: "hand-peace-o", inherit: ["outline"], keywords: [] },
  "hand-pointer-o": { name: "hand-pointer-o", inherit: ["outline"], keywords: [] },
  "hand-rock-o": { name: "hand-rock-o", inherit: ["outline"], keywords: [] },
  "hand-scissors-o": { name: "hand-scissors-o", inherit: ["outline"], keywords: [] },
  "hand-spock-o": { name: "hand-spock-o", inherit: ["outline"], keywords: [] },
  "hand-stop-o": { name: "hand-stop-o", inherit: ["outline"], keywords: [] },
  "handshake-o": { name: "handshake-o", inherit: ["outline"], keywords: [] },
  "hard-of-hearing": { name: "hard-of-hearing", keywords: [] },
  hashtag: { name: "hashtag", keywords: [] },
  header: { name: "header", keywords: [] },
  heartbeat: { name: "heartbeat", keywords: [] },
  history: { name: "history", keywords: [] },
  hotel: { name: "hotel", keywords: [] },
  hourglass: { name: "hourglass", keywords: [] },
  "hourglass-1": { name: "hourglass-1", keywords: [] },
  "hourglass-2": { name: "hourglass-2", keywords: [] },
  "hourglass-3": { name: "hourglass-3", keywords: [] },
  "hourglass-end": { name: "hourglass-end", keywords: [] },
  "hourglass-half": { name: "hourglass-half", keywords: [] },
  "hourglass-o": { name: "hourglass-o", inherit: ["outline"], keywords: [] },
  "hourglass-start": { name: "hourglass-start", keywords: [] },
  houzz: { name: "houzz", keywords: [] },
  "i-cursor": { name: "i-cursor", keywords: [] },
  "id-badge": { name: "id-badge", keywords: [] },
  "id-card": { name: "id-card", keywords: [] },
  "id-card-o": { name: "id-card-o", inherit: ["outline"], keywords: [] },
  ils: { name: "ils", keywords: [] },
  image: { name: "image", keywords: [] },
  imdb: { name: "imdb", keywords: [] },
  industry: { name: "industry", keywords: [] },
  institution: { name: "institution", keywords: [] },
  "internet-explorer": { name: "internet-explorer", keywords: [] },
  intersex: { name: "intersex", keywords: [] },
  ioxhost: { name: "ioxhost", keywords: [] },
  joomla: { name: "joomla", keywords: [] },
  jsfiddle: { name: "jsfiddle", keywords: [] },
  language: { name: "language", keywords: [] },
  lastfm: { name: "lastfm", keywords: [] },
  "lastfm-square": { name: "lastfm-square", keywords: [] },
  leanpub: { name: "leanpub", keywords: [] },
  legal: { name: "legal", keywords: [] },
  "life-bouy": { name: "life-bouy", keywords: [] },
  "life-buoy": { name: "life-buoy", keywords: [] },
  "life-ring": { name: "life-ring", keywords: [] },
  "life-saver": { name: "life-saver", keywords: [] },
  "line-chart": { name: "line-chart", keywords: [] },
  linode: { name: "linode", keywords: [] },
  "low-vision": { name: "low-vision", keywords: [] },
  "mail-forward": { name: "mail-forward", keywords: [] },
  "mail-reply": { name: "mail-reply", keywords: [] },
  map: { name: "map", keywords: [] },
  "map-o": { name: "map-o", inherit: ["outline"], keywords: [] },
  "map-pin": { name: "map-pin", keywords: [] },
  "map-signs": { name: "map-signs", keywords: [] },
  mars: { name: "mars", keywords: [] },
  "mars-double": { name: "mars-double", keywords: [] },
  "mars-stroke": { name: "mars-stroke", keywords: [] },
  "mars-stroke-h": { name: "mars-stroke-h", keywords: [] },
  "mars-stroke-v": { name: "mars-stroke-v", keywords: [] },
  meanpath: { name: "meanpath", keywords: [] },
  medium: { name: "medium", keywords: [] },
  meetup: { name: "meetup", keywords: [] },
  mercury: { name: "mercury", keywords: [] },
  microchip: { name: "microchip", keywords: [] },
  mixcloud: { name: "mixcloud", keywords: [] },
  "mobile-phone": { name: "mobile-phone", keywords: [] },
  modx: { name: "modx", keywords: [] },
  "mortar-board": { name: "mortar-board", keywords: [] },
  motorcycle: { name: "motorcycle", keywords: [] },
  "mouse-pointer": { name: "mouse-pointer", keywords: [] },
  navicon: { name: "navicon", keywords: [] },
  neuter: { name: "neuter", keywords: [] },
  "newspaper-o": { name: "newspaper-o", inherit: ["outline"], keywords: [] },
  "object-group": { name: "object-group", keywords: [] },
  "object-ungroup": { name: "object-ungroup", keywords: [] },
  odnoklassniki: { name: "odnoklassniki", keywords: [] },
  "odnoklassniki-square": { name: "odnoklassniki-square", keywords: [] },
  opencart: { name: "opencart", keywords: [] },
  openid: { name: "openid", keywords: [] },
  opera: { name: "opera", keywords: [] },
  "optin-monster": { name: "optin-monster", keywords: [] },
  "paint-brush": { name: "paint-brush", keywords: [] },
  "paper-plane": { name: "paper-plane", keywords: [] },
  "paper-plane-o": { name: "paper-plane-o", inherit: ["outline"], keywords: [] },
  paragraph: { name: "paragraph", keywords: [] },
  paste: { name: "paste", keywords: [] },
  "pause-circle": { name: "pause-circle", keywords: [] },
  "pause-circle-o": { name: "pause-circle-o", inherit: ["outline"], keywords: [] },
  paw: { name: "paw", keywords: [] },
  paypal: { name: "paypal", keywords: [] },
  percent: { name: "percent", keywords: [] },
  photo: { name: "photo", keywords: [] },
  "pie-chart": { name: "pie-chart", keywords: [] },
  "pied-piper": { name: "pied-piper", keywords: [] },
  "pied-piper-alt": { name: "pied-piper-alt", keywords: [] },
  "pied-piper-pp": { name: "pied-piper-pp", keywords: [] },
  "pinterest-p": { name: "pinterest-p", keywords: [] },
  plug: { name: "plug", keywords: [] },
  podcast: { name: "podcast", keywords: [] },
  "product-hunt": { name: "product-hunt", keywords: [] },
  qq: { name: "qq", keywords: [] },
  "question-circle-o": { name: "question-circle-o", inherit: ["outline"], keywords: [] },
  quora: { name: "quora", keywords: [] },
  ra: { name: "ra", keywords: [] },
  ravelry: { name: "ravelry", keywords: [] },
  rebel: { name: "rebel", keywords: [] },
  recycle: { name: "recycle", keywords: [] },
  reddit: { name: "reddit", keywords: [] },
  "reddit-alien": { name: "reddit-alien", keywords: [] },
  "reddit-square": { name: "reddit-square", keywords: [] },
  registered: { name: "registered", keywords: [] },
  remove: { name: "remove", keywords: [] },
  reorder: { name: "reorder", keywords: [] },
  resistance: { name: "resistance", keywords: [] },
  rmb: { name: "rmb", keywords: [] },
  "rotate-left": { name: "rotate-left", keywords: [] },
  "rotate-right": { name: "rotate-right", keywords: [] },
  rouble: { name: "rouble", keywords: [] },
  ruble: { name: "ruble", keywords: [] },
  rupee: { name: "rupee", keywords: [] },
  s15: { name: "s15", keywords: [] },
  safari: { name: "safari", keywords: [] },
  save: { name: "save", keywords: [] },
  scribd: { name: "scribd", keywords: [] },
  sellsy: { name: "sellsy", keywords: [] },
  send: { name: "send", keywords: [] },
  "send-o": { name: "send-o", inherit: ["outline"], keywords: [] },
  server: { name: "server", keywords: [] },
  "share-alt": { name: "share-alt", keywords: [] },
  "share-alt-square": { name: "share-alt-square", keywords: [] },
  shekel: { name: "shekel", keywords: [] },
  sheqel: { name: "sheqel", keywords: [] },
  ship: { name: "ship", keywords: [] },
  shirtsinbulk: { name: "shirtsinbulk", keywords: [] },
  "shopping-bag": { name: "shopping-bag", keywords: [] },
  "shopping-basket": { name: "shopping-basket", keywords: [] },
  shower: { name: "shower", keywords: [] },
  "sign-language": { name: "sign-language", keywords: [] },
  signing: { name: "signing", keywords: [] },
  simplybuilt: { name: "simplybuilt", keywords: [] },
  skyatlas: { name: "skyatlas", keywords: [] },
  slack: { name: "slack", keywords: [] },
  sliders: { name: "sliders", keywords: [] },
  slideshare: { name: "slideshare", keywords: [] },
  snapchat: { name: "snapchat", keywords: [] },
  "snapchat-ghost": { name: "snapchat-ghost", keywords: [] },
  "snapchat-square": { name: "snapchat-square", keywords: [] },
  "snowflake-o": { name: "snowflake-o", inherit: ["outline"], keywords: [] },
  "soccer-ball-o": { name: "soccer-ball-o", inherit: ["outline"], keywords: [] },
  "sort-down": { name: "sort-down", keywords: [] },
  "sort-up": { name: "sort-up", keywords: [] },
  soundcloud: { name: "soundcloud", keywords: [] },
  "space-shuttle": { name: "space-shuttle", keywords: [] },
  spoon: { name: "spoon", keywords: [] },
  spotify: { name: "spotify", keywords: [] },
  "star-half-empty": { name: "star-half-empty", keywords: [] },
  "star-half-full": { name: "star-half-full", keywords: [] },
  steam: { name: "steam", keywords: [] },
  "steam-square": { name: "steam-square", keywords: [] },
  "sticky-note": { name: "sticky-note", keywords: [] },
  "sticky-note-o": { name: "sticky-note-o", inherit: ["outline"], keywords: [] },
  "stop-circle": { name: "stop-circle", keywords: [] },
  "stop-circle-o": { name: "stop-circle-o", inherit: ["outline"], keywords: [] },
  "street-view": { name: "street-view", keywords: [] },
  stumbleupon: { name: "stumbleupon", keywords: [] },
  "stumbleupon-circle": { name: "stumbleupon-circle", keywords: [] },
  subway: { name: "subway", keywords: [] },
  superpowers: { name: "superpowers", keywords: [] },
  support: { name: "support", keywords: [] },
  taxi: { name: "taxi", keywords: [] },
  telegram: { name: "telegram", keywords: [] },
  television: { name: "television", keywords: [] },
  "tencent-weibo": { name: "tencent-weibo", keywords: [] },
  themeisle: { name: "themeisle", keywords: [] },
  thermometer: { name: "thermometer", keywords: [] },
  "thermometer-0": { name: "thermometer-0", keywords: [] },
  "thermometer-1": { name: "thermometer-1", keywords: [] },
  "thermometer-2": { name: "thermometer-2", keywords: [] },
  "thermometer-3": { name: "thermometer-3", keywords: [] },
  "thermometer-4": { name: "thermometer-4", keywords: [] },
  "thermometer-empty": { name: "thermometer-empty", keywords: [] },
  "thermometer-full": { name: "thermometer-full", keywords: [] },
  "thermometer-half": { name: "thermometer-half", keywords: [] },
  "thermometer-quarter": { name: "thermometer-quarter", keywords: [] },
  "thermometer-three-quarters": { name: "thermometer-three-quarters", keywords: [] },
  "times-rectangle": { name: "times-rectangle", keywords: [] },
  "times-rectangle-o": { name: "times-rectangle-o", inherit: ["outline"], keywords: [] },
  "toggle-down": { name: "toggle-down", keywords: [] },
  "toggle-left": { name: "toggle-left", keywords: [] },
  "toggle-off": { name: "toggle-off", keywords: [] },
  "toggle-on": { name: "toggle-on", keywords: [] },
  "toggle-right": { name: "toggle-right", keywords: [] },
  "toggle-up": { name: "toggle-up", keywords: [] },
  trademark: { name: "trademark", keywords: [] },
  train: { name: "train", keywords: [] },
  transgender: { name: "transgender", keywords: [] },
  "transgender-alt": { name: "transgender-alt", keywords: [] },
  trash: { name: "trash", keywords: [] },
  tree: { name: "tree", keywords: [] },
  tripadvisor: { name: "tripadvisor", keywords: [] },
  tty: { name: "tty", keywords: [] },
  "turkish-lira": { name: "turkish-lira", keywords: [] },
  tv: { name: "tv", keywords: [] },
  twitch: { name: "twitch", keywords: [] },
  "universal-access": { name: "universal-access", keywords: [] },
  university: { name: "university", keywords: [] },
  unlink: { name: "unlink", keywords: [] },
  unsorted: { name: "unsorted", keywords: [] },
  usb: { name: "usb", keywords: [] },
  "user-circle": { name: "user-circle", inherit: ["user"], keywords: [] },
  "user-circle-o": { name: "user-circle-o", inherit: ["user"], keywords: [] },
  "user-o": { name: "user-o", inherit: ["user"], keywords: [] },
  "user-plus": { name: "user-plus", inherit: ["user"], keywords: ["add", "new", "create"] },
  "user-secret": { name: "user-secret", inherit: ["user"], keywords: ["private", "anonymous"] },
  "user-times": { name: "user-times", inherit: ["user", "times"], keywords: [] },
  vcard: { name: "vcard", keywords: [] },
  "vcard-o": { name: "vcard-o", inherit: ["outline"], keywords: [] },
  venus: { name: "venus", keywords: [] },
  "venus-double": { name: "venus-double", keywords: [] },
  "venus-mars": { name: "venus-mars", keywords: [] },
  viacoin: { name: "viacoin", keywords: [] },
  viadeo: { name: "viadeo", keywords: [] },
  "viadeo-square": { name: "viadeo-square", keywords: [] },
  vimeo: { name: "vimeo", keywords: [] },
  vine: { name: "vine", keywords: [] },
  "volume-control-phone": { name: "volume-control-phone", keywords: [] },
  warning: { name: "warning", keywords: [] },
  wechat: { name: "wechat", keywords: [] },
  weixin: { name: "weixin", keywords: [] },
  whatsapp: { name: "whatsapp", keywords: [] },
  "wheelchair-alt": { name: "wheelchair-alt", keywords: [] },
  wifi: { name: "wifi", keywords: [] },
  "wikipedia-w": { name: "wikipedia-w", keywords: [] },
  "window-close": { name: "window-close", keywords: [] },
  "window-close-o": { name: "window-close-o", inherit: ["outline"], keywords: [] },
  "window-maximize": { name: "window-maximize", keywords: [] },
  "window-minimize": { name: "window-minimize", keywords: [] },
  "window-restore": { name: "window-restore", keywords: [] },
  won: { name: "won", keywords: [] },
  wordpress: { name: "wordpress", keywords: [] },
  wpbeginner: { name: "wpbeginner", keywords: [] },
  wpexplorer: { name: "wpexplorer", keywords: [] },
  wpforms: { name: "wpforms", keywords: [] },
  "y-combinator": { name: "y-combinator", keywords: [] },
  "y-combinator-square": { name: "y-combinator-square", keywords: [] },
  yahoo: { name: "yahoo", keywords: [] },
  yc: { name: "yc", keywords: [] },
  "yc-square": { name: "yc-square", keywords: [] },
  yelp: { name: "yelp", keywords: [] },
  yen: { name: "yen", keywords: [] },
  yoast: { name: "yoast", keywords: [] },
}
