import { Box, Paper } from '@mui/material'
import {
  AppDialog,
  CurrencyAmountAutoComplete,
  CurrencyAmountField,
  EditDate,
  EditTextFloat,
  EditTextInt,
  EntityAutoComplete,
  GqlForm,
  GqlFormTable,
  HiddenField,
  LabelField,
  NestedItemForm,
  SelectOption,
  TemplateEntity,
  useUID,
  useZeroApiContext,
} from '@zeroapi-io/zeroapireact'
import moment from 'moment'
import React, { useContext } from 'react'
import { UserContext } from '../../../../UserApp'
import CreateSubject from '../../../../common/CreateSubject'
import { useEntityIconItem } from '../../../../hooks/useEntityIconItem'
import { Sale } from '../SaleForm'

interface Props {
  templateId?: string
  sale?: Sale
  saleServiceId?: string
  onClose: VoidFunction
}

export const TemplateServiceSaleDialog: React.FC<Props> = ({ templateId, sale, saleServiceId, onClose }) => {
  const user = useContext(UserContext)
  const context = useZeroApiContext()

  const newUid = useUID()

  const itemId = saleServiceId ?? newUid

  const { loading: templateLoading, item: template } = useEntityIconItem('SaleTypeTemplate', 'id', templateId, [
    {
      name: 'serviceClassId',
      gql: 'String',
    },
    {
      name: 'providerFieldTitle',
      gql: 'Translation!',
      subSelection: { [user.lang.toLocaleLowerCase()]: true },
    },
    {
      name: 'requireProvider',
      gql: 'String',
    },
    {
      name: 'singleCustomer',
      gql: 'Long',
    },
  ])

  const PayType = {
    CASH: user.translate('cash'),
    CARD: user.translate('card'),
    INVOICE: user.translate('invoice'),
  }

  const providerTitle = template?.providerFieldTitle?.[user.lang.toLocaleLowerCase()] ?? 'komitent'
  const requireProvider = (template?.requireProvider ?? 'MANDATORY') as 'MANDATORY' | 'OPTIONAL' | 'NULL'
  const singleCustomer = template?.singleCustomer === 1

  return (
    <AppDialog title={template?.name ?? ''} open onClose={() => onClose()} background={'#ececf3'} maxWidth="xl" fullWidth>
      <Box>
        <GqlForm
          entity="SaleService"
          entityRelFieldName="id"
          entityRelFieldValue={itemId}
          dense="dense"
          isLoading={templateLoading}
          onSave={(_, isNew) => {
            if (isNew) {
              onClose()
            }
          }}
        >
          <HiddenField name="id" id />
          <HiddenField name="organisationId" value={context.id} />
          <HiddenField name="saleId" value={sale?.id} />
          <HiddenField name="templateId" value={templateId} />

          <HiddenField name="classId" value={template?.serviceClassId} />
          <HiddenField name="dateTo" value={(item: any) => item?.dateFrom} />

          <EntityAutoComplete
            name="providerId"
            label={providerTitle}
            entity="Subject"
            freeSolo={CreateSubject}
            nullable={requireProvider !== 'MANDATORY'}
            hidden={requireProvider === 'NULL'}
            // default={requireProvider === 'NULL' ? null : undefined}
            flexGrow
          >
            <HiddenField name="cityId" />
          </EntityAutoComplete>
          <EntityAutoComplete
            name="locationId"
            label="location"
            entity="City"
            value={(item) => {
              return item?.provider?.cityId
            }}
            flexGrow
          />

          <EditDate
            name="dateFrom"
            label="date_time"
            gql="String"
            size="dense"
            default={sale?.activityDate as string | undefined}
            defaultTo={new Date(sale?.activityDate || 0)}
            minDate={new Date(sale?.activityDate || 0)}
            toMinDate={(row) =>
              moment(row?.dateFrom || 0)
                .add(1, 'days')
                .toDate()
            }
            date
          />

          <GqlFormTable
            name="serviceSaleCustomerServices"
            gql="CustomerService"
            container={{ component: Paper, props: { elevation: 3, sx: { p: 2, m: 1, mb: 2 } } }}
            aggregated
            noDivider
          >
            <HiddenField name="id" id />
            <HiddenField name="serviceSaleId" valueFromParent="id" />
            <TemplateEntity name="SaleTypeTemplateService" relField="templateId" value={templateId}>
              <HiddenField name="serviceTypeId" targetField="typeId" />
            </TemplateEntity>
            <EntityAutoComplete
              name="typeId"
              label="type"
              entity="ServiceType"
              showAs="label"
              align="left"
              labelProps={{ variant: 'subtitle2', align: 'right' }}
            />
            <NestedItemForm name="customer">
              <EditTextInt
                name="id"
                label="count"
                aggregate={(data) => (singleCustomer ? 1 : data.length)}
                disaggregateCreateId="id"
                minValue={singleCustomer ? 1 : 0}
                maxValue={singleCustomer ? 1 : undefined}
                forceValue={singleCustomer ? 1 : undefined}
                value={singleCustomer ? 1 : undefined}
                hidden={singleCustomer}
              />
              <HiddenField name="organisationId" value={context.id} />
              <HiddenField name="firstName" value="" />
              <HiddenField name="lastName" value="" />
              <HiddenField name="intName" value="" />
              <HiddenField name="title" value="" />
            </NestedItemForm>
            <HiddenField name="providerId" valueFromParent="providerId" />
            <HiddenField name="dateFrom" valueFromParent="dateFrom" />
            <HiddenField name="dateTo" valueFromParent="dateFrom" />
            <HiddenField name="cost" value={0} />
            <HiddenField name="currency" label="currency" default="EUR" value={sale?.currency ?? 'EUR'} />
            <CurrencyAmountAutoComplete
              name="price"
              label="price_per_guest"
              toCurrencyField="currency"
              toCurrency={sale?.currency ?? 'EUR'}
              currencyField="priceCurrency"
              exchRateField="priceExchRate"
              dateField="dateFrom"
              group="price"
              exchRateMethod={{ name: 'auth_getExchangeRates' }}
              default={0}
              showConversion
              showCurrency
            />
            <LabelField
              name="total"
              label="total"
              value={(item: any) => {
                return ((item?.customer?.id ?? (singleCustomer ? 1 : 0)) * (item?.price ?? 0) * (item?.priceExchRate ?? 0)).formatCurrency(
                  sale?.currency ?? 'EUR',
                )
              }}
              variant="body2"
              align="right"
              virtual
            />
          </GqlFormTable>

          <SelectOption name="payType" label="expenseType" options={PayType} default={PayType.CASH} width={2} />
          <EditTextFloat
            name="discount"
            maxValue={(item) => {
              const sales = item?.serviceSaleCustomerServices as any[] | undefined

              if (!sales?.length) return 0

              return sales.sumOf(
                (item) => (item?.customer?.id ?? (singleCustomer ? 1 : 0)) * (item?.price ?? 0) * (item?.priceExchRate ?? 0),
              )
            }}
            label={'discount'}
            style={{ width: 140, flex: 0, flexGrow: 0, marginLeft: 340 }}
            default={0}
          />
          <CurrencyAmountField
            name="amount"
            label={'amount'}
            value={(item: any) => {
              const sales = item?.serviceSaleCustomerServices as any[] | undefined

              if (!sales?.length) return 0

              const total = sales.sumOf(
                (item) => (item?.customer?.id ?? (singleCustomer ? 1 : 0)) * (item?.price ?? 0) * (item?.priceExchRate ?? 0),
              )
              return total - item.discount
            }}
            currency={sale?.currency ?? 'EUR'}
            disabled
          />
          <CurrencyAmountField name="total" label={'total_deal'} default={0} currency={sale?.currency ?? 'EUR'} />
        </GqlForm>
      </Box>
    </AppDialog>
  )
}
