import { Button } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { createStyles, CSSProperties, makeStyles } from '@mui/styles'
import moment from 'moment'
import * as React from 'react'
import { useContext, useMemo } from 'react'
import { objMap } from '../../../../../common/Extensions'
import { GqlTableColumn, PlainTableMemo } from '../../../../../table/PlainTable'
import { UserContext } from '../../../../UserApp'
import { ProviderOptions } from './queries/useServicePricesByDate'
import { SaleCustomerServices, SelectedServiceTime } from './ServicePriceChooserDialog'
import { SaleService } from '../../Types'
import { between } from '../../../../../common/Utils'
import Show from '../../../../../common/Show'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ['& .MuiBox-root > div > div > div > table']: {
        width: 'auto',
      },
      ['& .MuiBox-root > div > div > div:nth-child(2)']: {
        width: '100px !important',
      },
    },
    offerItem: {
      fontSize: 12,
      borderRadius: 2,
      width: '100%',
      height: '100%',
      lineHeight: 1,
      padding: 4,
      textTransform: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      background: '#EFE',
      transition: 'background 0.3s 0s ease',
      ['&:hover']: {
        background: '#DED',
      },
    },
    offerItemPriceRow: {
      display: 'flex',
      justifyContent: 'center',
      paddingLeft: 4,
      paddingRight: 4,
      fontSize: 12,
      fontWeight: 400,
    },
    offerItemCustomerRow: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      fontSize: 16,
      fontWeight: 600,
      fontVariantCaps: 'all-small-caps',
      color: theme.palette.success.main,
    },
  }),
)

interface Props {
  saleService: SaleService
  providerOptions: ProviderOptions[]
  customers?: SaleCustomerServices[]
  onSelectServiceTime: (data: SelectedServiceTime) => void
}

export default function SaleServiceChooserTable({ saleService, customers, providerOptions, onSelectServiceTime }: Props) {
  const classes = useStyles()
  const user = useContext(UserContext)

  const momDateTo = moment(saleService.dateTo)
  const momDateFrom = moment(saleService.dateFrom)

  const columns: GqlTableColumn<ProviderOptions>[] = []

  columns.push({
    title: user.translate('provider'),
    field: 'provider',
    align: 'left',
    cellStyle: {
      height: 60,
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 8,
      paddingLeft: 8,
      maxWidth: 150,
    },
    headerStyle: {
      lineHeight: 1.2,
      minWidth: 120,
      maxWidth: 150,
      position: 'sticky',
      whiteSpace: 'nowrap',
      padding: 8, //component.noPadding ? 0 : 8,
      // background: "#798b97",
      color: '#41667e',
      verticalAlign: 'bottom',
    },
    width: 150,
    render: (row) => (
      <div style={{ lineHeight: 1.1, maxWidth: 150 }}>
        {row.provider}
        <br />
        <span style={{ fontSize: 12, fontWeight: 300, textAlign: 'center' }}>
          {row.offers.length} {user.translate('offers')}
        </span>
      </div>
    ),
  })

  const headerGroupStyles: { [key: string]: CSSProperties } = {}

  const times = useMemo(() => {
    const momDateTo = moment(saleService.dateTo)
    const momDateFrom = moment(saleService.dateFrom)

    if (saleService.class.uom === 'DAY') {
      const times: moment.Moment[] = []
      for (let mIt = momDateFrom.clone(); mIt.diff(momDateTo, 'days') < 0; mIt.add(1, 'days')) {
        times.push(mIt.clone())
      }
      return times
    } else {
      return [momDateFrom]
    }
  }, [saleService.class.uom, saleService.dateFrom, saleService.dateTo])

  const startMonth = momDateFrom.year() * 12 + momDateFrom.month()
  times.forEach((m) => {
    const month = m.year() * 12 + m.month()

    const dayIndex = m.diff(momDateFrom, 'days')
    const weekIndex = m.diff(momDateFrom, 'weeks')
    const monthIndex = month - startMonth
    // console.log(m.format("YYYY-MM-DD"), monthIndex, m.diff(momDateFrom, "weeks"))

    const groupName = m.format('MMM YYYY').capitalize()

    if (!headerGroupStyles[groupName]) {
      headerGroupStyles[groupName] = {
        background: monthIndex % 2 === 0 ? '#fcfaff' : '#f7f4fb',
      }
    }

    const priceColumnWidth = 160
    columns.push({
      groupName,
      title: (
        <>
          {m.date()}
          <br />
          <span style={{ fontWeight: 300, fontSize: 11 }}>{m.format('dddd')}</span>
        </>
      ),
      field: 'options',
      align: 'center',
      cellStyle: { height: 60, padding: 1 },
      width: priceColumnWidth,
      headerStyle: {
        lineHeight: 1,
        position: 'sticky',
        whiteSpace: 'nowrap',
        padding: 8,
        paddingTop: 4,
        paddingBottom: 4,
        maxWidth: priceColumnWidth,
        width: priceColumnWidth,
        minWidth: priceColumnWidth,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: m.weekday() > 4 ? '#e1e1e1' : '#d3f3f2', //dayIndex % 2 === 0 ? "#f9f4ff" : "#f0e9f7",
      },
      render: (props) => {
        const providerId = props.providerId as string
        const options = props.options[dayIndex]
        const bookings = props.bookings[dayIndex]

        if (!options || !customers) return <div>&nbsp;</div>

        const eligibleOffers = options.filter((offer: any) => {
          return offer.minQuantity <= customers.length
        })

        if (eligibleOffers.length === 0) return <div>&nbsp;</div>

        const byCurrency = eligibleOffers.groupBy((offer: any) => offer.currency)
        const currencyOffers = objMap(byCurrency, (key, offers: any[]) => ({
          currency: key,
          min: offers.minOf((offer: any) => offer.price),
          max: offers.maxOf((offer: any) => offer.price),
          options: offers,
        }))

        return (
          <Button
            style={{ width: '100%', height: '100%', padding: 0, marginRight: 1 }}
            onClick={() =>
              onSelectServiceTime({
                dayIndex,
                providerId: props.providerId,
                startDate: between(options.minOf((it) => moment(it.dateFrom)) ?? m, momDateFrom, m),
                endDate: between(options.maxOf((it) => moment(it.dateTo)) ?? m, m, momDateTo) ?? m,
              })
            }
          >
            <div className={classes.offerItem}>
              {Object.values(bookings.groupBy((it) => it.service.currency + it.service.price + it.service.typeId)).map(
                (customerServices) => {
                  const service = customerServices[0].service
                  const customers = customerServices.map((it) => it.customer)

                  return (
                    <div className={classes.offerItemCustomerRow}>
                      <div>
                        {customers.length} {user.translateQty(customers.length, '_packs')}
                      </div>
                      <div>{service.price.formatCurrency(service.currency)}</div>
                    </div>
                  )
                },
              )}
              <Show if={bookings.length > 0}>
                <div style={{ flex: 1 }} />
              </Show>
              {currencyOffers.map((offersSet: any) => {
                const minPrice = offersSet.min.formatCurrency(offersSet.currency)
                const maxPrice = offersSet.max.formatCurrency(offersSet.currency)
                return (
                  <div key={offersSet.currency} className={classes.offerItemPriceRow}>
                    {minPrice !== maxPrice ? (
                      <>
                        <div style={{ alignSelf: 'start', color: '#11763a' }}>{minPrice.substring(0, minPrice.length - 1)}</div>
                        &nbsp;-&nbsp;
                        <div style={{ alignSelf: 'end', color: '#117676' }}>{maxPrice}</div>
                      </>
                    ) : (
                      <div style={{ alignSelf: 'center', color: '#11765f', fontSize: 12, fontWeight: 400 }}>{maxPrice}</div>
                    )}
                  </div>
                )
              })}
            </div>
          </Button>
        )
      },
    })
  })

  return (
    <div className={classes.root}>
      <PlainTableMemo
        title={(customers && customers.length) + ' ' + user.translate('customers')}
        key={`service_pricing_${customers?.joinOf('_', (it) => it.saleCustomer.id)}`}
        items={providerOptions}
        columns={columns}
        headerGroupStyles={headerGroupStyles}
        // fixedLeft={1}
        noPaging
        noAutoWidth
      />
    </div>
  )
}
