import OfferIcon from '@mui/icons-material/LocalOffer'
import { Box, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router'
import useConfirmDialog from '../../../common/ConfirmDialog'
import PageContainer from '../../../common/PageContainer'
import EditDateRange from '../../../form/EditDateRange'
import EditTextFloat from '../../../form/EditTextFloat'
import EditTextFloatUnit from '../../../form/EditTextFloatUnit'
import EditTextInt from '../../../form/EditTextInt'
import EntityAutoComplete from '../../../form/EntityAutoComplete'
import { HiddenField } from '../../../form/HiddenField'
import FilterField from '../../../form/FilterField'
import QuantityIconInfo from '../../../form/QuantityIconInfo'
import SelectCurrency from '../../../form/SelectCurrency'
import SelectOption from '../../../form/SelectOption'
import GqlDetailPanel from '../../../table/GqlDetailPanel'
import GqlTable from '../../../table/GqlTable'
import CreateLocation from '../../common/CreateLocation'
import { IconNameEntity } from '../../hooks/useIconNameEntityItems'
import { ZeroApiContext } from '../../Organisation'
import { UserContext } from '../../UserApp'
import CreateSubject from '../../common/CreateSubject'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    list: {
      ['&:first-child']: {
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
      },
      ['&:last-child']: {
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
      },
    },
    item: {
      width: 'unset',
      background: '#FFF',
      borderRadius: 2,
      boxShadow: '#AAA 1px 2px 4px',
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: 0,
    },
  }),
)

interface ServiceType {
  id: string
  codeName: string
  name: string
  icon: string
  classId: string
  parentTypeId?: string
}

interface Props {
  serviceClass: IconNameEntity
}

export default function ServicePriceList({ serviceClass }: Props) {
  const classes = useStyles()

  const navigate = useNavigate()
  const { itemId } = useParams()

  const user = React.useContext(UserContext)
  const organisation = React.useContext(ZeroApiContext)

  const confirmDialog = useConfirmDialog()

  const serviceClassId = serviceClass.id

  const byClass = serviceClassId ? { by: 'classId', eqStr: serviceClassId } : undefined
  const byType = (row: any) => (row && row.typeId ? { by: 'parentTypeId', eqStr: row.typeId as string } : byClass)

  const GratisTypes = {
    DISCOUNT: user.translate('discount'),
    QUANTITY: user.translate('gratis'),
  }

  const GratisEligibleTypes = {
    FOR_EACH: user.translate('for_each'),
    MIN_QUANTITY: user.translate('min_quantity'),
  }

  const GratisEligibleUnit = {
    PACK: user.translate('packs'),
    SERVICE: user.translate('service'),
  }

  const detailTitle = (
    <Typography variant="h6" color="#777">
      {user.translate('gratis_discount')}
    </Typography>
  )
  const detailStyle: React.CSSProperties = {
    borderRadius: 0,
    boxShadow: 'none',
    background: 'none',
  }

  const hasStart = (serviceClass as any).location !== 'NONE'
  const hasEnd = (serviceClass as any).location === 'FROM_TO'

  const locGroup = hasStart && hasEnd ? 'location' : undefined

  const startPointProps = hasStart ? { group: locGroup, label: hasEnd ? 'start_point' : 'location' } : { hidden: true }
  const endPointProps = hasEnd ? { group: locGroup, label: hasStart ? 'end_point' : 'location' } : { hidden: true }

  const getGratisEligibleUnits = (row: any, parentRow: any) => {
    const classType = parentRow.type
    if (!classType) return GratisEligibleUnit

    const classTypeName = classType.name[user.lang]
    const parentTypeName = classType.parentType ? classType.parentType.name[user.lang] : undefined

    const serviceName = parentTypeName ? (
      <>
        <span>{classTypeName}</span> (<span style={{ color: 'gray' }}>{parentTypeName}</span>)
      </>
    ) : (
      classTypeName
    )
    return { ...GratisEligibleUnit, SERVICE: serviceName }
  }

  const serviceTypeGqlSelection = {
    name: { [user.lang]: true },
    parentType: {
      name: { [user.lang]: true },
    },
  }

  const pricePerProvider = (serviceClass as any).pricePerProvider ?? false
  const pricePerProviderType = (serviceClass as any).pricePerProviderType ?? false

  return (
    <PageContainer visible={!Boolean(itemId)} animate="fade">
      {confirmDialog.render()}

      {/* <Box sx={{ display: "flex", flexDirection: "row", m: 1 }}>
        <div style={{ flex: 1 }} />
        <Button variant="contained">{user.translate("add_new")}</Button>
      </Box> */}

      <Box sx={{ p: 1 }}>
        <GqlTable title={serviceClass.name} entity="ServicePrice" dense>
          <HiddenField name="id" id />
          <HiddenField name="dateTo" gql="Long" />
          <HiddenField name="typeId" gql="ServiceType" subSelection={serviceTypeGqlSelection} readOnly />
          <FilterField name="classId" value={serviceClassId} />
          <EntityAutoComplete name="providerTypeId" entity="SubjectType" width={16} hidden={!pricePerProviderType} nullable />
          <EntityAutoComplete
            name="providerId"
            entity="Subject"
            width={16}
            hidden={!pricePerProvider}
            freeSolo={CreateSubject}
            nullable
          />
          {/* <EntityAutoComplete name="optionId" label="service_option" entity="ServiceOption" nullable /> */}

          <EditDateRange name="dateFrom" nameTo="dateTo" label="date_range" width={24} />

          <EntityAutoComplete name="startPointId" entity="Location" freeSolo={CreateLocation} {...startPointProps} />
          <EntityAutoComplete name="endPointId" entity="Location" freeSolo={CreateLocation} {...endPointProps} nullable />

          <EntityAutoComplete name="typeId" label="type" entity="ServiceType" filter={byClass} align="center" nullable auto />
          {/* <EntityAutoComplete name="subTypeId" label="sub_type" entity="ServiceType" filter={byType} nullable auto /> */}

          <SelectCurrency name="currency" label="currency" group="price" default="EUR" width={2} />
          <EditTextFloat name="cost" label="cost" default={0} currency group="price" />
          <EditTextFloat name="price" label="sell" default={0} currency group="price" />

          <EditTextInt name="minQuantity" label="min_resv" default={1} minValue={1} />

          <EditTextInt name="capGroups" label="groups" group="capacity" default={1} minValue={0} />
          <EditTextInt name="capTourists" label="tourists" group="capacity" default={0} minValue={0} />

          <QuantityIconInfo name="priceServicePriceGratissCount" label=" " icon={<OfferIcon />} width={2} readOnly />

          <GqlDetailPanel>
            <GqlTable title={detailTitle} entity="ServicePriceGratis" style={detailStyle} dense noSearch noPaging>
              <HiddenField name="id" id />
              <FilterField name="priceId" valueFromParent="id" />
              <EntityAutoComplete
                name="targetId"
                label="subject_type"
                entity="SubjectType"
                nullLabel="customer"
                nullIcon="user"
                icon
                nullable
              />
              <SelectOption name="eligibleType" options={GratisEligibleTypes} default="FOR_EACH" />
              <EditTextInt name="eligibleValue" default={10} width={1} align="right" />
              <SelectOption name="eligibleUnit" label="type" options={getGratisEligibleUnits} default="PACK" width={1} />
              <SelectOption name="type" label="discount_type" options={GratisTypes} align="right" />
              <EditTextFloatUnit
                name="amount"
                label="discount_amount"
                default={1}
                unit={(row) => (row?.type === 'QUANTITY' ? 'PIECE' : 'PERCENTAGE')}
              />
            </GqlTable>
          </GqlDetailPanel>
        </GqlTable>
      </Box>
    </PageContainer>
  )
}
