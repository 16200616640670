// noinspection JSUnusedGlobalSymbols

import moment from "moment";

export function atMost(value: moment.Moment | undefined, maxValue: moment.Moment) {
  return !value || value > maxValue ? maxValue : value
}

export function atLeast(value: moment.Moment | undefined, minValue: moment.Moment) {
  return !value || value < minValue ? minValue : value
}

export function between(value: moment.Moment | undefined, minValue: moment.Moment, maxValue: moment.Moment) {
  if (!value) return minValue
  if (value < minValue) return minValue
  if (value > maxValue) return maxValue
  return value
}

export function greatest<T>(...values: T[]) {
  let max = values[0]
  for (let i = 1; i < values.length; i++) {
    const val = values[i]
    if (val > max) max = val
  }
  return max
}

export function lowest<T>(...values: T[]) {
  let min = values[0]
  for (let i = 1; i < values.length; i++) {
    const val = values[i]
    if (val < min) min = val
  }
  return min
}


export function printdiv(printpage: any, noprint?: string, newWindow?: boolean) {
  // let oldHtml = document.body.outerHTML;
  let elements = Array.from(document.querySelectorAll('[class*="MTableToolbar-searchField"],[class*="MTableToolbar-actions"]')) as HTMLElement[];
  let elementsDisplay = elements.map((el) => (el as HTMLElement).style.display);
  elements.forEach((el) => ((el as HTMLElement).style.display = "none"));

  let hideElems = (noprint !== undefined && noprint.length > 0 ? document.querySelectorAll(`.${noprint}`) : []) as HTMLElement[];

  hideElems.forEach((el) => {
    if (typeof el.style != "undefined") {
      el.style.display = "none";
    }
  });

  if (newWindow) {
    const w = window.open();
    if (w !== null) {
      w.document.head.outerHTML = document.head.outerHTML;
      w.document.body.innerHTML = document.getElementById(printpage)?.innerHTML ?? "error";
      w.print();

      setTimeout(() => {
        w.close();
      }, 100);

      hideElems.forEach((el) => {
        if (typeof el.style != "undefined") {
          el.style.display = "none";
        }
      });
      return true;
    }
  }

  document.body.innerHTML = document.getElementById(printpage)?.innerHTML ?? "error";
  window.print();

  setTimeout(() => {
    document.location.reload();
  }, 100);

  elements.forEach((el, idx) => {
    el.style.display = elementsDisplay[idx] || "block";
  });

  return true;
}


