import { Autocomplete, autocompleteClasses, Popper, TextField, Theme } from '@mui/material'
import { makeStyles, styled } from '@mui/styles'
import React, { useContext, useEffect, useState } from 'react'
import { UserContext, UserContextParams } from '../app/UserApp'
import { callOrGet, ifNull, preventPropagation } from './utils'
import { GqlTabletField } from '../table/GqlTable'
import { HiddenField } from './HiddenField'
import { useCustomQuery } from '../gql/useCustomQuery'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-grid',
    margin: theme.spacing(1),
    minWidth: '18ch',
    flexGrow: 1,
    '& > .MuiTextField-root': {
      display: 'grid',
      width: '-webkit-fill-available',
    },
  },
}))

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
    '& li': {
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 4,
      paddingBottom: 4,
      margin: 0,
      fontSize: 11.5,
      fontWeight: 400,
      whiteSpace: 'nowrap',
      textAlign: 'right',
    },
  },
})

interface CurrencyOption {
  from: string
  to: string
  rate: number
}

interface FormFieldProps extends GqlTabletField<number> {
  currencyField: string
  toCurrency?: string
  toCurrencyField?: string
  exchRate?: string
  exchRateField?: string
  dateField?: string
  date?: string
}

export default function CurrencyAmountAutoComplete(props: FormFieldProps) {
  const classes = useStyles()

  const user = useContext(UserContext)

  const item = props.item || props.rowData
  const propsValue = props.name && props.value && typeof props.value == 'object' ? props.value[props.name] : props.value
  const value = ifNull(propsValue, callOrGet(props.default, item))

  const currency: string | undefined = item[props.currencyField]?.toString()

  const [isOpen, setIsOpen] = useState(false)
  const [rawInputValue, setRawInputValue] = useState(currency ? `${value} ${currency}` : value.toString())

  const onChange = props.onChange !== undefined ? props.onChange : () => {}

  const date = props.date ?? (props.dateField ? item[props.dateField] : undefined)
  const toCurrency: string = (props.toCurrency ?? (props.toCurrencyField ? item[props.toCurrencyField] : undefined))?.toString() ?? ''

  const exchRateFields = [
    { name: 'id', gql: 'String' },
    {
      name: 'currency',
      gql: 'String',
    },
    { name: 'rate', gql: 'Float' },
  ]
  const { items: currencies, isLoading } = useCustomQuery('auth_getExchangeRates', exchRateFields, undefined, undefined, {
    args: { date, toCurrency },
    skip: !date && !toCurrency,
    fetchPolicy: 'network-only',
  })

  const valueParts = rawInputValue.split(' ')
  const inputAmount = parseFloat(valueParts[0])
  const inputCurrency = isNaN(inputAmount) || valueParts.length <= 1 ? toCurrency : valueParts[1].cyrillicToLatin()

  const options: CurrencyOption[] =
    inputCurrency !== undefined && currencies !== undefined
      ? currencies.map(
          (item) =>
            ({
              to: toCurrency,
              from: item.currency.toString(),
              rate: item.rate as number,
            } as CurrencyOption),
        )
      : [{ to: toCurrency, from: toCurrency, rate: 1 }]

  const selected: CurrencyOption | undefined = options ? options.find((it) => it.from.cyrillicToLatin() === inputCurrency) : undefined
  const fieldSize = props.size === 'small' ? 'small' : 'medium'

  const inputValue = selected ? `${valueParts[0]} ${inputCurrency?.toUpperCase()}` : rawInputValue

  function getOptionLabel(option?: CurrencyOption | string) {
    if (typeof option === 'string') return inputValue
    if (!option?.rate) return inputValue
    if (option.from === option.to) return inputAmount.toString()
    return `${inputAmount.formatCurrency(option.from).replace(/([\d.,]+)/g, '$1 ')} = ${(inputAmount * option.rate).formatCurrency(
      option.to,
    )}`
  }

  useEffect(() => {
    if (isNaN(inputAmount)) return
    item[props.currencyField] = selected?.from
    if (props.exchRateField) item[props.exchRateField] = selected?.rate ?? 1
    item[props.name] = inputAmount
    onChange(inputAmount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected?.rate, toCurrency, inputAmount])

  return (
    <Autocomplete
      className={classes.root}
      onOpen={() => {
        setIsOpen(true)
      }}
      onClose={() => {
        setIsOpen(false)
      }}
      loading={date && toCurrency && isLoading}
      PopperComponent={StyledPopper}
      fullWidth={props.fullWidth}
      options={options ?? []}
      inputValue={inputValue}
      onChange={(event, value) => {
        if (typeof value === 'string') {
          if (isNaN(inputAmount)) {
            setRawInputValue('')
          } else {
            setRawInputValue(inputAmount.toString())
          }
          return
        }
        setRawInputValue(`${inputAmount} ${(value as any).from}`)
      }}
      onInputChange={(e, value) => {
        if (value.indexOf('=') === -1) setRawInputValue(value)
      }}
      onSubmit={props.onSubmit}
      disabled={callOrGet(props.disabled, value, item)}
      size={fieldSize}
      onKeyDown={(event) => preventPropagation(event, isOpen)}
      filterOptions={(options) => {
        if (inputCurrency === undefined) return []
        if (inputCurrency.length === 0) return options
        return options?.filter((it) => it.from.cyrillicToLatin().indexOf(inputCurrency) > -1)
      }}
      isOptionEqualToValue={(option, value) => value && option.from === value.from && option.to === value.to}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => {
        params.inputProps.autoCorrect = 'off'
        params.inputProps.autoCapitalize = 'off'
        params.inputProps.style = { fontSize: props.size === 'small' ? 12 : 14 }
        return (
          <TextField
            {...params}
            label={
              props.isForm
                ? React.isValidElement(props.label)
                  ? props.label
                  : user.translate(props.label?.toString() || props.name.snakeCase())
                : undefined
            }
            error={callOrGet(props.error, inputValue)}
            variant={props.isForm ? 'outlined' : 'standard'}
            size={fieldSize}
            fullWidth={props.fullWidth}
            InputProps={{
              ...params.InputProps,
              startAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
            }}
          />
        )
      }}
      handleHomeEndKeys
      autoHighlight
      selectOnFocus
      freeSolo
    />
  )
}
CurrencyAmountAutoComplete.fields = (props: FormFieldProps, user: UserContextParams) => {
  return [
    <HiddenField name={props.name} nullable={props.nullable} gql={props.gql || 'Float'} />,
    <HiddenField name={props.currencyField || 'currency'} nullable={props.nullable} gql="String" />,
    <HiddenField name={props.exchRateField || 'exchRate'} nullable={props.nullable} gql="Float" />,
  ]
}
CurrencyAmountAutoComplete.gql = 'Float'
CurrencyAmountAutoComplete.align = 'right'

const Render = (props: FormFieldProps) => {
  const item = props.rowData ?? props.item
  const currency = item[props.currencyField]?.toString()
  const toCurrency = props.toCurrency ?? (props.toCurrencyField ? item[props.toCurrencyField] : undefined)
  const exchRate = props.exchRate ?? (props.exchRateField ? item[props.exchRateField] : 1)

  const propsValue = props.name && props.value && typeof props.value == 'object' ? props.value[props.name] : props.value
  const value = ifNull(propsValue, callOrGet(props.default, props.item))
  if (currency === undefined || !toCurrency)
    return <div style={{ display: 'inline-block' }}>{value.formatCurrency(toCurrency ?? currency)}</div>

  if (!currency || !exchRate || currency === toCurrency)
    return <div style={{ display: 'inline-block' }}>{value.formatCurrency(currency || toCurrency)}</div>

  return (
    <div style={{ display: 'inline-block' }}>
      <span style={{ opacity: 0.7 }}>{value.formatCurrency(currency)} = </span>
      {(value * exchRate).formatCurrency(toCurrency)}
    </div>
  )
}

CurrencyAmountAutoComplete.render = (props: FormFieldProps, rowValue: number, row: any) => {
  return <Render {...props} value={rowValue} rowData={row} />
}
