import { FormControlLabel, Switch, Typography } from '@mui/material'
import * as React from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router'
import CompositeField from '../../../../form/CompositeField'
import EditDate from '../../../../form/EditDate'
import EditDateRange from '../../../../form/EditDateRange'
import EditTextFloat from '../../../../form/EditTextFloat'
import EntityAutoComplete from '../../../../form/EntityAutoComplete'
import { HiddenField } from '../../../../form/HiddenField'
import FilterField from '../../../../form/FilterField'
import FormButton from '../../../../form/FormButton'
import SelectCurrency from '../../../../form/SelectCurrency'
import SelectOption from '../../../../form/SelectOption'
import { Field, GqlFilter } from '../../../../gql/interface'
import GqlDetailPanel from '../../../../table/GqlDetailPanel'
import GqlTable from '../../../../table/GqlTable'
import { ZeroApiContext } from '../../../Organisation'
import { UserContext } from '../../../UserApp'
import { SaleService } from '../Types'
import CreateLocation from '../../../common/CreateLocation'
import { SaleCustomer } from '../Models'
import { useEntityRelationReadOnlyQuery as useERROQuery } from '../../../../gql/useEntityRelationReadOnlyQuery'
import { Sale } from './SaleForm'
import moment from 'moment'
import GroupIcon from '@mui/icons-material/Group'
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check'
import OptionsAutoComplete from '../../../../form/OptionsAutoComplete'
import EditTextInt from '../../../../form/EditTextInt'
import { IconNameEntity } from '../../../hooks/useIconNameEntityItems'
import PersonIcon from '@mui/icons-material/Person'
import CurrencyAmountAutoComplete from '../../../../form/CurrencyAmountAutoComplete'

interface SaleTypeService {
  saleTypeId: string
  serviceClassId?: string
  serviceTypeId?: string
}

interface Props {
  customers: SaleCustomer[]
  saleType?: IconNameEntity
  sale?: Sale
  onItemClick: (id: string) => void
  itemsRef?: React.MutableRefObject<SaleService[]>
}

export default function SaleServiceTable({ sale, saleType, customers, itemsRef, onItemClick }: Props) {
  const { itemId: saleId } = useParams()

  const user = React.useContext(UserContext)
  const organisation = React.useContext(ZeroApiContext)

  const customersRef = useRef(customers)
  customersRef.current = customers

  const saleTypeAggregated = (saleType as any | undefined)?.groupByCustomer ?? false
  const [aggregated, setAggregated] = useState(saleTypeAggregated)

  useEffect(() => {
    setAggregated(Boolean(saleTypeAggregated))
  }, [saleTypeAggregated])

  const detailTitle = (
    <Typography variant="h6" color="#777">
      {user.translate('reservations')}
    </Typography>
  )

  const detailStyle: React.CSSProperties = {
    borderRadius: 0,
    boxShadow: 'none',
    background: 'none',
  }

  const typesByClassId = (row: any, parent?: any): GqlFilter => ({
    by: 'classId',
    eqStr: row?.classId,
    and: {
      by: 'parentTypeId',
      eqStr: null,
    },
  })

  const typesByParentTypeId = (row: any, parent?: any): GqlFilter =>
    parent?.typeId
      ? {
          by: 'parentTypeId',
          eqStr: parent?.typeId,
          or: { by: 'id', eqStr: parent?.typeId },
        }
      : { by: 'classId', eqStr: parent?.classId, and: { by: 'parentTypeId', eqStr: null } }

  const saleTypeFields: Field<any>[] = [
    { name: 'id', gql: 'String' },
    { name: 'serviceClassId', gql: 'String' },
    {
      name: 'serviceTypeId',
      gql: 'String',
    },
  ]

  const saleTypeServices =
    useERROQuery<SaleTypeService>('SaleTypeService', saleTypeFields, 'saleTypeId', sale?.saleTypeId, {
      skip: !sale?.saleTypeId,
    }).items ?? []

  const allowedServices =
    saleTypeServices.length === 0 || saleTypeServices.any((it) => !it.serviceClassId)
      ? undefined
      : saleTypeServices.filter((it) => Boolean(it.serviceClassId)).map((it) => it.serviceClassId ?? '')

  const customersOptions = useMemo(() => {
    return customers.map((it) => ({
      id: it.customer.id,
      name: it.customer.intName.ifEmpty(it.customer.firstName + ' ' + it.customer.lastName),
    }))
  }, [customers])

  const customersCount = sale?.saleCustomersCount ?? 0

  const serviceClassGqlSelection = {
    name: { [user.lang]: true },
    location: true,
    time: true,
    uom: true,
  }

  const PayType = {
    CASH: user.translate('cash'),
    CARD: user.translate('card'),
    INVOICE: user.translate('invoice'),
  }

  const PayStatus = {
    NOT_PAYED: user.translate('not_payed'),
    PAYED: user.translate('payed'),
  }

  // const serviceTypeGqlSelection = {
  //   name: { [user.lang]: true },
  //   parentType: {
  //     name: { [user.lang]: true },
  //   },
  // }

  function getTitleActions() {
    return (
      <>
        <div style={{ flex: 1 }}>&nbsp;</div>
        <FormControlLabel
          control={<Switch checked={aggregated} onChange={(e) => setAggregated(e.target.checked)} />}
          label={user.translate('grouping')}
        />
      </>
    )
  }

  return (
    <GqlTable
      key={JSON.stringify(sale)}
      deps={[allowedServices, customersOptions, aggregated]}
      title={'services'}
      entity="SaleService"
      // onItems={(items) => {
      //   // TODO: test only, remove it
      //   if (items.length > 0) onItemClick(items[0].id)
      // }}
      actions={[
        {
          icon: () => (aggregated ? <GroupIcon /> : <PersonIcon />),
          tooltip: user.translate('grouping'),
          isFreeAction: true,
          position: 'toolbar',
          onClick: () => setAggregated(!aggregated),
        },
      ]}
      refItems={itemsRef}
      dense
      noPaging
      noSearch
      noExport
    >
      <HiddenField name="id" id />
      <HiddenField name="organisationId" value={organisation.id} />
      <FilterField name="saleId" value={saleId} />

      <HiddenField name="class" subSelection={serviceClassGqlSelection} readOnly />
      <HiddenField name="dateTo" value={new Date(sale?.activityDate || 0).getTime().toString()} />

      <EntityAutoComplete name="classId" label="service_class" entity="ServiceClass" filterOptions={allowedServices}>
        <HiddenField name="location" hidden />
        <HiddenField name="time" hidden />
        <HiddenField name="uom" hidden />
      </EntityAutoComplete>

      <EntityAutoComplete name="typeId" label="service_type" entity="ServiceType" filter={typesByClassId} nullable />

      <EditDate
        name="dateFrom"
        nameTo="dateTo"
        label="date_time"
        gql="String"
        default={sale?.activityDate as string | undefined}
        defaultTo={new Date(sale?.activityDate || 0)}
        minDate={new Date(sale?.activityDate || 0)}
        toMinDate={(row) =>
          moment(row.dateFrom || 0)
            .add(1, 'days')
            .toDate()
        }
        range={(row) => row?.class?.uom === 'DAY' || row?._classId?.uom === 'DAY'}
        date
      />
      <CompositeField name="location" width={32}>
        <EntityAutoComplete name="startPointId" label="location" entity="Location" freeSolo={CreateLocation} width={32} />
        <EntityAutoComplete
          name="endPointId"
          entity="Location"
          hidden={(row) => row?.class?.location !== 'FROM_TO' && row?._classId?.location !== 'FROM_TO'}
          freeSolo={CreateLocation}
          nullable
        />
      </CompositeField>

      <EntityAutoComplete name="billById" entity="Subject" nullable />
      <SelectOption name="payType" options={PayType} default={PayType.CASH} width={2} />
      <SelectOption name="payStatus" options={PayStatus} default={PayStatus.NOT_PAYED} width={2} />

      <FormButton
        name="serviceSaleCustomerServicesCount"
        label="reservations"
        deps={[customersCount]}
        endIcon={(value) => (value !== customersCount ? <EditIcon /> : <CheckIcon />)}
        title={(value) => (value !== customersCount ? (customersCount - value).toString() : value)}
        enabled={() => customersCount > 0}
        color={(value) => (value < customersCount ? 'success' : value > customersCount ? 'error' : 'primary')}
        variant={(value) => (value === customersCount ? 'outlined' : 'contained')}
        contentWidth={60}
        justifyContent="right"
        onClick={(item) => onItemClick(item.id)}
        width={2}
        disableOnEdit
      />

      <GqlDetailPanel refreshParent>
        <GqlTable
          title={detailTitle}
          entity="CustomerService"
          style={detailStyle}
          deps={[customersOptions, aggregated]}
          aggregated={aggregated}
          noPaging={aggregated || customersCount < 20}
          dense
          noSearch
        >
          <HiddenField
            name="id"
            disaggregate={(item) => {
              const count = parseInt(item.customerId)
              const values = (item._agrItems ?? []).map((it: any) => it.id)
              if (values.length === count) return values
              if (values.length > count) return values.slice(0, count)
              return values.concat(Array(count - values.length).fill(null))
            }}
            id
          />
          <FilterField name="serviceSaleId" valueFromParent="id" />
          <OptionsAutoComplete name="customerId" label="customer" options={customersOptions} ignoreOnAggregate />
          <EditTextInt
            name="customerId"
            label={<GroupIcon fontSize={'small'} color={'primary'} />}
            align={'right'}
            valueFromParent={(parent) => {
              const count = parent.serviceSaleCustomerServicesCount as number
              return (count < customersCount ? customersCount - count : count).toString()
            }}
            minValue={0}
            maxValue={(item) =>
              (item._agrItems ?? [])
                .map((it: any) => it.customerId)
                .concat(customersRef.current.map((it: any) => it.customer?.id))
                .mapDistinct((it: any) => it).length
            }
            aggregate={(data) => data.length}
            disaggregate={(item) => {
              const count = parseInt(item.customerId)
              const values = item._agrItems?.slice(0, count)?.map((it: any) => it.customerId) ?? []
              if (values.length === count) return values
              if (values.length > count) return values.slice(0, count)

              return values
                .concat(customersRef.current.map((it: any) => it.customer?.id))
                .mapDistinct((it: any) => it)
                .slice(0, count)
            }}
            width={3}
            ignoreOnNonAggregate
          />
          <EntityAutoComplete name="providerId" label="komitent" entity="Subject" width={24} />
          <EntityAutoComplete
            name="typeId"
            label="service_type"
            entity="ServiceType"
            filter={typesByParentTypeId}
            default={(item, parent) => parent?.typeId}
            nullable
          />
          {/* <EntityAutoComplete name="subTypeId" entity="ServiceType" label="sub_type" filter={subTypes} nullable /> */}

          <HiddenField name="dateTo" gql="String!" valueFromParent="dateTo" nullable />
          <EditDateRange
            name="dateFrom"
            valueFromParent="dateFrom"
            nameTo="dateTo"
            label="date_range"
            gql="String"
            minDate={(row, parent) => new Date(parent.dateFrom)}
            maxDate={(row, parent) => new Date(parent.dateTo)}
            date
          />

          <SelectCurrency name="currency" label="currency" default={'EUR'} />
          <CurrencyAmountAutoComplete
            name="cost"
            toCurrencyField="currency"
            currencyField="costCurrency"
            exchRateField="costExchRate"
            dateField="dateFrom"
            group="price"
            default={0}
          />
          <CurrencyAmountAutoComplete
            name="price"
            toCurrencyField="currency"
            currencyField="priceCurrency"
            exchRateField="priceExchRate"
            dateField="dateFrom"
            label="sell"
            group="price"
            default={0}
          />
        </GqlTable>
      </GqlDetailPanel>
    </GqlTable>
  )
}
