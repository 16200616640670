import * as React from 'react'
import EditText from '../../../form/EditText'
import { HiddenField } from '../../../form/HiddenField'
import FormDialog from '../../../form/FormDialog'
import { ZeroApiContext } from '../../Organisation'
import SelectOption from '../../../form/SelectOption'
import { UserContext } from "../../UserApp";

type Props = {
  show: boolean
  value?: string
  onRefresh: () => void
  onClose: (t: any) => void
}

const response = { ok: true, message: true }

export default function InviteDialog({ show, value, onRefresh, onClose }: Props) {
  const organisation = React.useContext(ZeroApiContext)
  const user = React.useContext(UserContext)

  const roles = {
    NONE: user.translate('role_none'),
    ASSOCIATE: user.translate('role_associate'),
    EMPLOYEE: user.translate('role_employee'),
    MANAGER: user.translate('role_manager'),
    OWNER: user.translate('role_owner'),
  }

  const onSend = async (data: any) => {
    if (data.ok) {
      onRefresh()
      onClose && onClose(data)
      return
    }
    alert(data.message)
  }

  return (
    <FormDialog
      title="invite"
      customQuery="auth_invite"
      args={response}
      item={show}
      onClose={onClose}
      onRefresh={onSend}
      keys={['organisationId']}
      noDelete
    >
      <HiddenField name="organisationId" value={organisation.id} id />
      <EditText name="email" label="email" gql="String" value={value} fullWidth />
      <SelectOption options={roles} name={'role'} gql="EmployeeRole" />
    </FormDialog>
  )
}
