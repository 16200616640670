import * as React from 'react'
import { HiddenField } from '../../../form/HiddenField'
import FormDialog from '../../../form/FormDialog'
import SelectOption from '../../../form/SelectOption'
import { ZeroApiContext } from '../../Organisation'
import { UserContext } from '../../UserApp'
import EditText from '../../../form/EditText'
import NestedItemForm from '../../../form/NestedItemForm'

interface OrganisationEmployee {
  id: string
  organisationId: string
  employeeId: string
  role: string
  lang: string
  employee: {
    profile: {
      name: true
      email: true
      phone: true
      birthday: true
    }
  }
}

interface Props {
  employeeId?: string
  onRefresh?: () => void
  onClose: () => void
}

export default function EmployeesDialog({ employeeId, onRefresh, onClose }: Props) {
  const user = React.useContext(UserContext)
  const organisation = React.useContext(ZeroApiContext)

  const roles = {
    NONE: user.translate('role_none'),
    ASSOCIATE: user.translate('role_associate'),
    EMPLOYEE: user.translate('role_employee'),
    MANAGER: user.translate('role_manager'),
    OWNER: user.translate('role_owner'),
  }

  const canEdit = employeeId === user.userId
  const canChangeRole = organisation.role === 'MANAGER' || organisation.role === 'OWNER'

  return (
    <FormDialog
      entity="OrganisationEmployee"
      title="Кориснички Профил"
      keys={['organisationId', 'employeeId']}
      relFieldName="organisationId"
      item={Boolean(employeeId) && organisation.id}
      onClose={onClose}
      onRefresh={async () => {
        onRefresh && onRefresh()
      }}
      filter={{
        by: 'employeeId',
        eqStr: employeeId,
      }}
      autoClose
      autoRefresh
    >
      <HiddenField name="organisationId" gql="String" value={organisation.id} />
      <NestedItemForm name={'employee'}>
        <NestedItemForm name={'profile'} keys={['userId']} dependent>
          <HiddenField name={'userId'} id />
          <EditText name={'name'} disabled={!canEdit} fullWidth />
          <EditText name={'phone'} disabled={!canEdit} type={'tel'} nullable />
          <EditText name={'email'} disabled={!canEdit} type={'email'} nullable />
        </NestedItemForm>
      </NestedItemForm>
      <SelectOption name="role" label="role" gql="String" options={roles} default="" disabled={!canChangeRole} fullWidth />
    </FormDialog>
  )
}
