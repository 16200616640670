import { DatePicker } from '@mui/x-date-pickers'

import { TextField, TextFieldProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import React, { useContext, useState } from 'react'
import Moment from 'react-moment'
import { UserContext } from '../app/UserApp'
import { FormField } from './useFormQuery'
import { callOrGet } from './utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-grid',
      minWidth: '20ch',
      flexGrow: 1,
    },
  }),
)

interface FormFieldProps extends FormField<string> {
  date?: boolean
}

const EditTextDateEpoch = (props: FormFieldProps) => {
  const classes = useStyles()

  const user = useContext(UserContext)
  const [isOpen, setIsOpen] = useState(false)

  const parseDate = props.date ? (value: any) => new Date(value) : (value: any) => new Date(parseInt(value))
  const formatDate = props.date ? (value: Date) => value.toISODate() : (value: Date) => value.getTime()

  const item = props.item || props.rowData

  let srcDate = props.value ? parseDate(props.value) : null
  if (!srcDate) {
    srcDate = parseDate(callOrGet(props.default, item) || '')
  }
  if (!srcDate) srcDate = new Date()

  const onChange = props.onChange ? props.onChange : (date: string) => {}

  return (
    <DatePicker
      label={
        props.isForm &&
        (React.isValidElement(props.label) ? props.label : user.translate(props.label?.toString() || props.name.snakeCase()))
      }
      value={srcDate}
      disabled={callOrGet(props.disabled, srcDate, item)}
      onChange={(date: Date | null) => {
        if (!date || date === null) return
        return onChange(formatDate(date).toString())
      }}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      inputFormat={'dd-MM-yyyy'}
      renderInput={(inputProps: JSX.IntrinsicAttributes & TextFieldProps) => (
        <TextField
          {...inputProps}
          sx={{ m: props.isForm ? 1 : 0 }}
          className={classes.root}
          style={{ fontSize: props.size !== 'small' ? 16 : 14 }}
          onSubmit={props.onSubmit}
          variant={props.isForm ? 'outlined' : 'standard'}
          fullWidth={props.fullWidth}
          inputProps={{
            ...inputProps.inputProps,
            style: {
              fontSize: props.size !== 'small' ? 16 : 14,
            },
          }}
          error={callOrGet(props.error, srcDate)}
          size={props.size !== 'small' ? 'medium' : 'small'}
          onFocus={() => setIsOpen(true)}
          onBlur={() => setIsOpen(false)}
        />
      )}
    />
  )
}

EditTextDateEpoch.gql = 'Long'

EditTextDateEpoch.render = (props: FormFieldProps, value: string) => {
  if (!value || value === '0000-00-00') return ''
  const parseDate = props.date ? (value: any) => new Date(value) : (value: any) => new Date(parseInt(value))
  return <Moment format="D MMM YYYY">{parseDate(value)}</Moment>
}

export default EditTextDateEpoch
