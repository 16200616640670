import { gql, useQuery } from "@apollo/client"
import { useMemo } from "react"
import useLazyEffect from "../hooks/useLazyEffect"
import generateGql from "./generateGql"
import { Field, GqlFetchQuery, GqlFilter, QueryOptions } from "./interface"

export function useEntityRelationReadOnlyQuery<T>(
  entity: string,
  fields: Field<any>[],
  relField: string = "organisationId",
  relValue: any,
  options: QueryOptions = {}
) {
  const isPageVisible = true // usePageVisibility()
  const allFields = fields || []
  const gqlFields = allFields.filter((field) => field.gql !== undefined)

  const entityName = options.single ? entity : `${entity}s`
  const getItemsMethodName = options.all
    ? `getAll${entity}s`
    : relField === "organisationId"
    ? `get${entity}sByContextOrganisationId`
    : `get${entityName}By${relField.capitalize()}`

  const gqlGetList = useMemo(() => {
    if (options.mode === "editOnly" || gqlFields.length === 0) return gql``

    let filter: GqlFilter | undefined = undefined
    const addFilter = (filterOptions: GqlFilter) => {
      if (filter) {
        filterOptions.and = filter
      }
      filter = filterOptions
    }
    if (options.dateRangeFilter)
      addFilter({
        by: options.dateRangeField || "created",
        gte: options.dateRangeFilter.startDate.getTime() || 0,
        lte: options.dateRangeFilter.endDate.getTime() || new Date().getTime(),
      })
    if (options.filter) addFilter(options.filter)

    const args = { ...options.args }
    if (!options.all) {
      args["$" + relField] = "String!"
    }

    if (filter) {
      args.filter = filter
    }
    const unwrapArgs: (container: Field<any>[]) => any = (container: Field<any>[]) => {
      return container.toMapBy(
        (field) => field.name,
        (field) => {
          if (field.nested) {
            if (!field.subSelection) throw Error("field.nested == true, requires subSelection")
            return unwrapArgs(Object.values(field.subSelection))
          }
          return field.subSelection || true
        }
      )
    }

    const gqlContent = generateGql({
      [getItemsMethodName]: {
        args,
        select: unwrapArgs(gqlFields),
      },
    })

    return gql(gqlContent)
  }, [entityName, JSON.stringifySafe(fields), relField, relValue, JSON.stringify(options.filter)])

  const variables = options.all ? {} : { [relField]: relValue }

  const runEffect = useLazyEffect()

  const onLoad = options.onLoad

  const {
    data: dataItems,
    loading: loadingItems,
    refetch: refetchItems,
  } = useQuery(gqlGetList, {
    pollInterval: isPageVisible ? (options.pollInterval !== undefined ? options.pollInterval : 8000) : 0,
    skip: options.skip || relValue === undefined || relValue == null,
    onCompleted:
      onLoad &&
      ((data) => {
        runEffect(() => onLoad(data[getItemsMethodName]))
      }),
    variables: variables,
  })

  const dataField = options.single ? "item" : "items"

  return {
    isLoading: loadingItems,
    [dataField]: dataItems && dataItems[getItemsMethodName],
    refresh: refetchItems,
  } as GqlFetchQuery<T>
}
