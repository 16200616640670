import { Button, CircularProgress, LinearProgress } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import React, { useContext, useRef, useState } from 'react'
import FadeIn from 'react-fade-in'
import { UserContext } from '../app/UserApp'
import { UseBulkCreateMutation, BulkMutateCallback } from '../gql/UseBulkMutation'
import { HiddenField } from './HiddenField'
import { FormField } from './useFormQuery'
import { callOrGet, ifNull } from './utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
    },
    loading: {
      position: 'absolute',
      left: 0,
      right: 0,
      margin: 'auto',
      bottom: 0,
      top: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#ffffff80',
      zIndex: 1,
    },
    footer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: theme.spacing(1),
    },
    spacer: {
      flex: 1,
    },
  }),
)

interface Props {
  items?: any[]
  topOffset?: number
  loadingVariant?: 'linear' | 'circular'
  loadingBar?: JSX.Element
  footer?: JSX.Element
  onSubmit: (data: any) => any[]
  onChange?: (data: any) => void
  onSave?: (data: any) => void
  entity: string

  isLoading?: boolean
  keys?: string[]
  dense?: boolean
  children: JSX.Element | JSX.Element[]
  content?: JSX.Element | JSX.Element[]
  className?: string
}

const getProgressBar = (props: Props) => {
  if (props.loadingBar != undefined) return props.loadingBar
  if (props.loadingVariant == 'circular') return <CircularProgress />
  return <LinearProgress variant="indeterminate" style={{ width: 220 }} />
}

export default (props: Props) => {
  const classes = useStyles()

  const user = useContext(UserContext)

  const bulkMutationRef = useRef<BulkMutateCallback>()

  const formData = props.items || []

  const [itemData, setItemData] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)

  const item = itemData || formData

  const onChange = (name: string) => (value: any) => {
    setItemData({ ...item, [name]: value, _orgState: item._orgState || item })
  }

  const doSubmit = () => {
    const data: { [key: string]: any } = {}
    React.Children.forEach(props.children, (child) => {
      const childProps = child.props
      data[childProps.name] = ifNull(item[childProps.name], childProps.value, callOrGet(childProps.default, item))
    })

    const propsResult = props?.onSubmit && props.onSubmit(data)
    if (propsResult instanceof Promise) return { data, promise: propsResult as Promise<any> }
    if (!propsResult) throw Error('onSubmit returned invalid value: ' + propsResult)

    if (propsResult instanceof Array && propsResult.length === 0)
      return {
        data,
        promise: new Promise<any>((resolve) => resolve([])),
      }

    const promise = bulkMutationRef.current && bulkMutationRef.current(propsResult)
    if (!promise) throw Error('Failed to execute: ' + promise)

    return { data, promise }
  }

  const onSubmit = () => {
    setIsLoading(true)
    const { data, promise } = doSubmit()
    setItemData({ ...item, _orgState: undefined })
    if (promise) {
      promise
        .then((result) => {
          const resultData = { ...data, ...result }
          if (props.onSave) return props.onSave(resultData)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      const resultData = { ...data, promise }
      if (props.onSave) props.onSave(resultData)
      setIsLoading(false)
    }
  }

  const onEdit = (data: any) => {
    setItemData(data)
  }

  const fields: { name: string }[] = []
  let hasError = false
  const children = React.Children.map(props.children, (child: React.ReactElement<FormField<any>>) => {
    if (!React.isValidElement(child)) return child
    const component = child.type as any
    if (typeof child.type === 'string') return child
    if (component === HiddenField) return null

    const childProps = child.props as FormField<any>
    const fieldName = childProps.name
    if (fieldName === undefined) return child

    const value = ifNull(item[fieldName], childProps.value, callOrGet(childProps.default, item))

    if (callOrGet(childProps.hidden, value, item) == true) return null

    const error =
      (childProps.nullable !== true && !component.readOnly && (value == undefined || value?.length == 0)) ||
      callOrGet(component.validate, childProps, value)

    if (error) hasError = true

    fields.push({ name: fieldName })

    return React.cloneElement(child, {
      isForm: true,
      key: childProps.key || fieldName,
      label: React.isValidElement(childProps.label)
        ? childProps.label
        : user.translate(childProps.label?.toString() || fieldName.snakeCase().replace(/_id$/g, '')),
      value: value,
      hidden: false,
      item: item,
      disabled: childProps.disabled || isLoading,
      error: error,
      size: props.dense ? 'small' : 'normal',
      onChange: onChange(fieldName),
      onSubmit: onSubmit,
      style: {
        flexGrow: 1,
      },
      parentEntity: props.entity,
    })
  })

  const isItemCreated = props.items != undefined

  if (props.content) {
    children.push(callOrGet(props.content, item, isItemCreated))
  }

  children.push(
    <FadeIn key="loading" transitionDuration={200} delay={100} visible={isLoading}>
      <div key="loading" className={classes.loading} style={{ top: props.topOffset || 64 }}>
        {isLoading && getProgressBar(props)}
      </div>
    </FadeIn>,
  )

  const footer =
    props.footer ||
    ((item: any, status: any) => (
      <div key="footer" className={classes.footer}>
        <div className={classes.spacer} />
        <Button key="button_save" disabled={status.hasError || !status.isChanged} onClick={status.onSubmit}>
          {user.translate('save')}
        </Button>
      </div>
    ))
  if (footer) {
    children.push(
      callOrGet(footer, item, {
        hasError,
        onSubmit,
        onEdit,
        isCreated: isItemCreated,
        isChanged: item._orgState && fields.find((field) => item._orgState[field.name] != item[field.name]) != undefined,
      }),
    )
  }

  const rootClases = [classes.root]
  if (props.className) rootClases.push(props.className)

  return (
    <form className={rootClases.join(' ')} noValidate autoComplete="off">
      {children}
      <UseBulkCreateMutation entity={props.entity} bulkMutationRef={bulkMutationRef} />
    </form>
  )
}
