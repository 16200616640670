import { CurrencyAmountField, EditText, EditTextInt, FilterField, GqlReport, HiddenField } from '@zeroapi-io/zeroapireact'
import * as React from 'react'
import { useParams } from 'react-router'
import useConfirmDialog from '../../../common/ConfirmDialog'
import PageContainer from '../../../common/PageContainer'

export const SaleTypeTemplateReport: React.FC = () => {
  const { itemId } = useParams()

  const confirmDialog = useConfirmDialog()

  return (
    <PageContainer visible={!Boolean(itemId)} animate="fade">
      {confirmDialog.render()}
      <GqlReport entity="report_saleTypeTemplate">
        <FilterField name="templateId" value="1681844727564564521" skipGql />
        <HiddenField name="saleId" />
        <EditText name="employee" />
        <EditText name="subjectName" />
        <EditText name="groupCode" />
        <EditTextInt name="customersCount" />
        <CurrencyAmountField name="amount" />
        <CurrencyAmountField name="serviceAmounts" />
        <CurrencyAmountField name="amount" />
        <CurrencyAmountField name="invoiced" />
        <CurrencyAmountField name="profit" />
      </GqlReport>
    </PageContainer>
  )
}
