import * as React from 'react'
import EditText from '../../form/EditText'
import EntityAutoComplete from '../../form/EntityAutoComplete'
import { HiddenField } from '../../form/HiddenField'
import FormDialog from '../../form/FormDialog'
import NestedItemForm from '../../form/NestedItemForm'
import { ZeroApiContext } from '../Organisation'
import { UserContext } from '../UserApp'
import CreateCity from './CreateCity'

type LocationProps = {
  value: string
  cityId?: string
  onClose: (t: any) => void
}
export default function CreateLocation({ value, cityId, onClose }: LocationProps) {
  const user = React.useContext(UserContext)
  const organisation = React.useContext(ZeroApiContext)
  return (
    <FormDialog entity="Location" item={true} onClose={onClose} autoClose>
      <HiddenField name="id" id />
      <HiddenField name="organisationId" gql="String" value={organisation.id} />
      <NestedItemForm name="name" gql="Translation">
        <EditText name={user.lang} label="name" gql="String" value={value} fullWidth />
        <HiddenField name={user.secLang} value={(row: any) => (row ? row[user.lang] ?? row[`name_${user.lang}`] : '')} />
      </NestedItemForm>
      <EntityAutoComplete name="cityId" label="city" entity="City" default={cityId} freeSolo={CreateCity} gqlSearchMinChars={3} all>
        {/*<HiddenField name="timezone" gql="String" />*/}
        <HiddenField name="country" gql="Country" gqlSelection={['name', user.lang]} />
      </EntityAutoComplete>
    </FormDialog>
  )
}
