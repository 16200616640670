import { Translations } from '@zeroapi-io/zeroapireact'

export const translations: Translations = {
  welcome: 'Welcome',
  home: 'Home',
  macedonia: 'Macedonia',
  english: 'English',
  macedonian: 'Македонски',
  en: 'English',
  mk: 'Macedonian',
  config: 'Config',
  setting: 'Setting',
  settings: 'Settings',
  staff: 'Staff',
  items: 'Items',
  item: 'Item',
  komitents: 'Komitents',
  komitent: 'Komitent',
  associates_legal: 'Legal Entities',
  associates_persons: 'Person Entities',
  info_no_companies: "You don't have access to any organisations. Ask your administrator to give you an access!",
  info_no_company: "You don't have access to this organisation. Ask your administrator to give you an access!",
  title_request_reset_pass: 'Request Password Reset',
  button_request_reset_pass: 'Send Reset Link',
  label_email_address: 'Email Address',
  button_sign_in: 'Sign In',
  button_sign_in_back: 'Did you remember? Sign In',
  button_sign_in_account: 'Already have an account? Sign In',
  button_sign_up_back: 'Sign Up Instead',
  button_signup: 'Create an Account',
  label_signup: 'Create an Account',
  label_name: 'Name',
  button_continue_to_login: 'Continue To Login',
  label_password: 'Password',
  title_reset_password: 'Change/Set Your Password',
  button_reset_password: 'Reset Password',
  button_forgot_password: 'Forgot password?',
  title_sign_in: 'Sign In',
  reports: 'Reports',
  providerFieldTitle: 'Provider Title',
  require_provider: 'Provider',
  require_provider_mandatory: 'Require',
  require_provider_optional: 'Optional',
  require_provider_null: 'None',
  single_customer: 'No customer count',
}
