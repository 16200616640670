import { useContext } from 'react'
import { useEntityRelationReadOnlyQuery } from '../../gql/useEntityRelationReadOnlyQuery'
import { UserContext } from '../UserApp'

export interface Item {
  id: string
  name: { [key: string]: string }
}

export default function useSimpleEntityItem(entity: string, relField: string, relValue?: string) {
  const user = useContext(UserContext)
  const fields = [
    { name: 'id', gql: 'String!' },
    { name: 'name', gql: 'Translation!', subSelection: { [user.lang.toLocaleLowerCase()]: true } },
  ]
  const query = useEntityRelationReadOnlyQuery<Item>(entity, fields, relField, relValue, {
    skip: relValue === undefined,
    single: true,
  })
  if (query.isLoading) return { loading: true }
  const item = query.item || ({} as Item)
  return { loading: false, id: item.id, name: item.name && item.name[user.lang.toLocaleLowerCase()] }
}
