import LanguageIcon from '@mui/icons-material/Language'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { IconName, library } from '@fortawesome/fontawesome-svg-core'
import { fas as faFree } from '@fortawesome/free-solid-svg-icons'
import { fas as faPro } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tour } from '@mui/icons-material'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import ServicesIcon from '@mui/icons-material/AccountTree'
import ReportsIcon from '@mui/icons-material/Analytics'
import BallotIcon from '@mui/icons-material/Ballot'
import AnalyticsIcon from '@mui/icons-material/BarChart'
import Bolt from '@mui/icons-material/Bolt'
import BusinessIcon from '@mui/icons-material/Business'
import SubjectIcon from '@mui/icons-material/BusinessCenter'
import DescriptionIcon from '@mui/icons-material/Description'
import ArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import ArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import EmployesIcon from '@mui/icons-material/People'
import PersonIcon from '@mui/icons-material/Person'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import PriceIcon from '@mui/icons-material/PriceCheck'
import ParameterizingIcon from '@mui/icons-material/SettingsApplications'
import TimelineIcon from '@mui/icons-material/Timeline'
import { CircularProgress } from '@mui/material'
import * as React from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import MergeIcon from '../common/MergeIcon'
import Navigation, { AppBarMenuIcon, AppBarMenuItem, DrawerItem } from '../common/Navigation'
import Show from '../common/Show'
import TourArrangement from './dashboard/arrangament/TourArrangement'
import TourArrangementList from './dashboard/arrangament/TourArrangementList'
import EmployeesList from './dashboard/parameterizing/EmployeesList'
import ServiceClassItem from './dashboard/parameterizing/ServiceClassItem'
import { ServiceClassList } from './dashboard/parameterizing/ServiceClassList'
import SubjectTypesList from './dashboard/parameterizing/SubjectTypesList'
import TourArrGroupsList from './dashboard/parameterizing/TourArrGroupsList'
import ServicePriceList from './dashboard/pricing/ServicePriceList'
import SaleListPage from './dashboard/sale/booking/SaleListPage'
import SalePage from './dashboard/sale/booking/SalePage'
import TouristList from './dashboard/sale/TouristList'
import SubjectTypeList from './dashboard/subjects/SubjectTypeList'
import useIconNameEntityItems from './hooks/useIconNameEntityItems'
import useSubjectTypes from './hooks/useSubjectTypes'
import { ZeroApiContext } from './Organisation'
import { UserContext } from './UserApp'
import SaleTypeListPage from './dashboard/parameterizing/SaleTypeListPage'
import SaleTypePage from './dashboard/parameterizing/SaleTypePage'
import EmployeesDialog from './dashboard/parameterizing/EmployeesDialog'
import SaleImportPage from './dashboard/sale/booking/SaleImportPage'
import MapIcon from '@mui/icons-material/Map'
import CountriesList from './dashboard/parameterizing/CountriesList'
import { SaleTypeTemplateReport, TotalReport } from './dashboard/reports'

// @ts-ignore
library.add(faFree, faPro)

interface DashboardProps {}

export default function Dashboard(props: DashboardProps) {
  const user = React.useContext(UserContext)
  const company = React.useContext(ZeroApiContext)

  const navigate = useNavigate()

  const { page } = useParams()

  const [showProfile, setShowProfile] = useState<boolean | undefined>()

  const { items: subjectTypes } = useSubjectTypes()
  const { items: serviceClasses } = useIconNameEntityItems('ServiceClass', undefined, {
    location: 'String',
    pricePerProvider: 'Long',
    pricePerProviderType: 'Long',
  })
  const { items: tourArrGroups } = useIconNameEntityItems('TourArrangementGroup', { by: 'active', eq: 1 })
  const { items: saleTypes } = useIconNameEntityItems(
    'SaleType',
    { by: 'active', eq: 1 },
    {
      groupByCustomer: 'Boolean',
      simple: 'Boolean',
    },
  )

  const changeLanguage = (item: AppBarMenuItem) => user.setLanguage(item.key)

  const logout = () => {
    user.logout().then(() => {
      window.location.href = '/'
    })
  }

  const menu: AppBarMenuIcon[] = [
    {
      key: 'lang',
      icon: <LanguageIcon />,
      items: [
        { key: 'mk', title: 'Македонски', onClick: changeLanguage, selected: user.lang === 'mk' },
        {
          key: 'en',
          title: 'English',
          onClick: changeLanguage,
          selected: user.lang === 'en',
        },
      ],
    },
    {
      key: 'account',
      icon: <AccountCircleIcon />,
      items: [{ key: 'profile', onClick: () => setShowProfile(true) }, { divider: true }, { key: 'logout', onClick: logout }],
    },
  ]

  const drawer: DrawerItem[] = [
    {
      key: 'reports',
      title: user.translate('reports'),
      icon: <ReportsIcon />,
      children: [
        {
          key: 'total_reports',
          title: user.translate('total_reports'),
          icon: <AnalyticsIcon />,
        },
        {
          key: 'sake_type_template_reports',
          title: user.translate('sake_type_template_reports'),
          icon: <AnalyticsIcon />,
        },
      ],
    },
    {
      key: 'parameterizing',
      title: user.translate('parameterizing'),
      icon: <ParameterizingIcon />,
      children: [
        { key: 'countries', title: user.translate('locations'), icon: <MapIcon /> },
        {
          key: 'services',
          title: user.translate('services'),
          icon: <ServicesIcon />,
        },
        {
          key: 'subjects',
          title: user.translate('subjects'),
          icon: <SubjectIcon />,
        },
        { key: 'employees', title: user.translate('employees'), icon: <EmployesIcon /> },
        {
          key: 'products',
          title: user.translate('products'),
          icon: <Tour />,
        },
        { key: 'sale-types', title: user.translate('sale_types'), icon: <PointOfSaleIcon /> },
      ],
    },
    {
      key: 'associates',
      title: user.translate('komitents'),
      icon: (
        <MergeIcon>
          <BusinessIcon />
          <PersonIcon />
        </MergeIcon>
      ),
      children: subjectTypes.map((subjectType) => ({
        key: `subjects_${subjectType.codeName}`,
        title: subjectType.name,
        icon: <FontAwesomeIcon icon={subjectType.icon as IconName} />,
      })),
    },
    {
      key: 'prices',
      title: user.translate('prices'),
      icon: <PriceIcon />,
      children: serviceClasses.map((item) => ({
        key: `prices_${item.codeName}`,
        title: item.name,
        icon: <FontAwesomeIcon icon={item.icon as IconName} />,
      })),
    },
    {
      key: 'products',
      title: user.translate('products'),
      icon: <TimelineIcon />,
      children: tourArrGroups.map((item) => ({
        key: `arr_group_${item.codeName}`,
        title: item.name,
        icon: item.icon && <FontAwesomeIcon icon={item.icon as IconName} />,
      })),
    },
    {
      key: 'sales',
      title: user.translate('sales'),
      icon: <PointOfSaleIcon />,
      children: saleTypes.map((item) => ({
        key: `sale_${item.codeName}`,
        title: item.name,
        icon: item.icon && <FontAwesomeIcon icon={item.icon as IconName} />,
      })),
    },
    {
      key: 'finance',
      title: user.translate('finance'),
      icon: <Bolt />,
      children: [
        { key: 'bank_accounts', title: user.translate('accounts'), icon: <AccountBalanceIcon /> },
        {
          key: 'pending_finance',
          title: user.translate('pending'),
          icon: <PendingActionsIcon />,
        },
        {
          key: 'invoice_in',
          title: user.translate('invoice_in'),
          icon: (
            <MergeIcon>
              <DescriptionIcon />
              <ArrowLeftIcon />
            </MergeIcon>
          ),
        },
        {
          key: 'invoice_out',
          title: user.translate('invoice_out'),
          icon: (
            <MergeIcon>
              <DescriptionIcon />
              <ArrowRightIcon />
            </MergeIcon>
          ),
        },
        { key: 'ios', title: user.translate('ios'), icon: <BallotIcon /> },
      ],
    },
  ]

  const SubjectTypePage = () => {
    const subjectType = subjectTypes.find((it) => page === `subjects_${it.codeName}`)
    if (!subjectType) return <CircularProgress />
    return <SubjectTypeList subjectType={subjectType} />
  }

  const ServiceClassPage = () => {
    const item = serviceClasses.find((it) => page === `prices_${it.codeName}`)
    if (!item) return <CircularProgress />
    return <ServicePriceList serviceClass={item} />
  }

  const getPageTitle = () => {
    if (page?.startsWith('subjects_') === true) {
      return user.translate('subjects')
      // return subjectTypes?.find((it) => page === `subjects_${it.codeName}`)?.name || page
    }
    if (page?.startsWith('prices_') === true) {
      return user.translate('prices')
      // return serviceClasses?.find((it) => page === `prices_${it.codeName}`)?.name || page
    }
    if (page?.startsWith('arr_group_') === true) {
      return user.translate('products')
    }
    if (page?.startsWith('sale_') === true) {
      return saleTypes.find((it) => page === `sale_${it.codeName}`)?.name ?? user.translate('sales')
    }
    return user.translate(page || '')
  }

  const closeProfileDialog = () => {
    if (showProfile) {
      setShowProfile(false)
    } else {
      navigate('./../employees')
    }
  }

  const pageTitle = getPageTitle()
  const title = company.name.mk || 'test'

  return (
    <Navigation title={title} drawer={drawer} menu={menu} drawerSelected={page} basePath={`/o/${company.id}`} pageTitle={pageTitle}>
      <Show if={page === 'total_reports'}><TotalReport /></Show>
      <Show if={page === 'sake_type_template_reports'}><SaleTypeTemplateReport /></Show>
      <Show if={page === 'services'}>
        <ServiceClassList />
        <ServiceClassItem />
      </Show>
      <Show if={page === 'subjects'} component={<SubjectTypesList />} />
      <Show if={page === 'employees'} component={<EmployeesList />} />
      <Show if={page === 'products'} component={<TourArrGroupsList />} />
      <Show if={page === 'countries'} component={<CountriesList />} />
      <Show if={page === 'tourists'} component={<TouristList />} />
      <Show if={page === 'sale-types'}>
        <SaleTypeListPage />
        <SaleTypePage />
      </Show>
      <Show if={page?.startsWith('subjects_') === true} component={<SubjectTypePage />} />
      <Show if={page?.startsWith('prices_') === true} component={<ServiceClassPage />} />
      <Show if={page?.startsWith('arr_group_') === true}>
        <TourArrangementList group={tourArrGroups.find((it) => page === `arr_group_${it.codeName}`)} />
        <TourArrangement group={tourArrGroups.find((it) => page === `arr_group_${it.codeName}`)} />
      </Show>
      <Show if={page?.startsWith('sale_') === true}>
        <SaleListPage saleType={saleTypes.find((it) => page === `sale_${it.codeName}`)} />
        <SalePage saleType={saleTypes.find((it) => page === `sale_${it.codeName}`)} />
        <SaleImportPage saleType={saleTypes.find((it) => page === `sale_${it.codeName}`)} />
      </Show>
      <Show if={showProfile !== undefined || page === 'profile'}>
        <EmployeesDialog employeeId={showProfile || page === 'profile' ? user.userId : undefined} onClose={closeProfileDialog} />
      </Show>
    </Navigation>
  )
}
