import { Button, ButtonGroup } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { createStyles, CSSProperties, makeStyles } from '@mui/styles'
import moment from 'moment'
import * as React from 'react'
import { useContext, useMemo, useRef } from 'react'
import { GqlTableColumn, PlainTableMemo } from '../../../../../../table/PlainTable'
import { UserContext } from '../../../../../UserApp'
import { SaleService, ServicePrice } from '../../../Types'
import SaleCustomersToolbarTitle from '../../customers/SaleCustomersToolbarTitle'
import { CustomerService, SaleCustomerServices, SelectedServiceTime } from '../ServicePriceChooserDialog'
import SaleServiceGroupRow from './SaleServiceGroupRow'
import { SaleCustomer } from '../../../Models'
import { between } from '../../../../../../common/Utils'
import { Delete } from '@mui/icons-material'
import useConfirmDialog from '../../../../../../common/ConfirmDialog'
import UseBulkMutation, { MultipleBulkMutateCallback } from '../../../../../../gql/UseBulkMutation'
import Show from '../../../../../../common/Show'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ['& .MuiBox-root > div > div > div > table']: {
        width: 'auto',
      },
      ['& .MuiBox-root > div > div > div:nth-child(2)']: {
        width: '100px !important',
      },
    },
    offerItem: {
      fontSize: 12,
      fontWeight: 500,
      borderRadius: 2,
      width: '100%',
      height: '100%',
      lineHeight: 1,
      padding: 4,
      textTransform: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      background: '#EFE',
      transition: 'background 0.3s 0s ease',
      ['&:hover']: {
        background: '#DED',
      },
    },
    providerName: {
      maxWidth: '100%',
      fontWeight: 600,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'center',
      paddingLeft: theme.spacing(0.3),
      paddingRight: theme.spacing(0.3),
    },
  }),
)

interface Item {
  slots: { services: CustomerService[]; prices: ServicePrice[]; servicesHash: string; pricesHash: string }[]
  saleCustomer: SaleCustomer
  services: CustomerService[]
}

interface Props {
  saleId?: string
  saleService: SaleService
  customers?: SaleCustomerServices[]
  servicePrices: ServicePrice[]
  onSelect?: (data: SaleCustomerServices[], rowData?: SaleCustomerServices) => void
  onSelectServiceTime: (data: SelectedServiceTime) => void
  onUpdate: () => Promise<any> | undefined
}

export default function SaleServiceCustomersTable({
  saleId,
  saleService,
  customers,
  servicePrices,
  onSelect,
  onSelectServiceTime,
  onUpdate,
}: Props) {
  const classes = useStyles()
  const user = useContext(UserContext)

  const confirmDialog = useConfirmDialog()

  const bulkMutationRef = React.useRef<MultipleBulkMutateCallback>()

  const onSelectCallbackRef = useRef(onSelectServiceTime)
  onSelectCallbackRef.current = onSelectServiceTime

  const servicePricesKey = JSON.stringify(servicePrices, (key: string, value: any) => (key.charAt(0) === '_' ? undefined : value))
  const customersKey = customers?.joinOf(';', (it) => it.saleCustomer.id + it.services.joinOf(';', (it) => it.id))

  const times = useMemo(() => {
    const momDateTo = moment(saleService.dateTo)
    const momDateFrom = moment(saleService.dateFrom)

    if (saleService.class.uom === 'DAY') {
      const times: moment.Moment[] = []
      for (let mIt = momDateFrom.clone(); mIt.diff(momDateTo, 'days') < 0; mIt.add(1, 'days')) {
        times.push(mIt.clone())
      }
      return times
    } else {
      return [momDateFrom]
    }
  }, [saleService.dateFrom, saleService.dateTo])

  const servicePricesByTime = useMemo(() => {
    return times.map((time) => {
      return servicePrices.filter((service) => {
        return moment(service.dateFrom).isSameOrBefore(time, 'date') && moment(service.dateTo).isSameOrAfter(time, 'date')
      })
    })
  }, [servicePrices, times])

  const onDeleteCustomerServices = (rowData: Item, dayIndex: number) => {
    const services = rowData.slots[dayIndex].services
    confirmDialog
      .showConfirm('delete', 'customer_service', () => {
        if (bulkMutationRef.current) {
          return bulkMutationRef.current({
            delete: services.map((it) => ({ id: it.id })),
          })
        }
      })
      .then(onUpdate)
  }

  const onSelectCustomerServices = (rowData: Item, dayIndex: number) => {
    const colInfo = rowData.slots[dayIndex]
    let fromDay = dayIndex
    while (fromDay > 0) {
      const prevCol = rowData.slots[fromDay - 1]
      if (colInfo.services.length > 0 && prevCol.servicesHash !== colInfo.servicesHash) break
      if (colInfo.services.length === 0 && (prevCol.services.length !== 0 || prevCol.pricesHash !== colInfo.pricesHash)) break
      fromDay--
    }

    let toDay = dayIndex
    while (toDay < rowData.slots.length - 1) {
      const nextCol = rowData.slots[toDay + 1]
      if (colInfo.services.length > 0 && nextCol.servicesHash !== colInfo.servicesHash) break
      if (colInfo.services.length === 0 && (nextCol.services.length !== 0 || nextCol.pricesHash !== colInfo.pricesHash)) break
      toDay++
    }
    console.log('fromDay', fromDay, toDay)
    onSelectCallbackRef.current({
      dayIndex,
      startDate: times[fromDay],
      endDate: times[toDay],
      customers: [rowData],
    })
  }

  const [columns, headerGroupStyles] = React.useMemo<[GqlTableColumn<Item>[], { [key: string]: CSSProperties }]>(() => {
    const momDateTo = moment(saleService.dateTo)
    const momDateFrom = moment(saleService.dateFrom)

    const columns: GqlTableColumn<Item>[] = []
    const labelGroup = user.translate('group')

    columns.push({
      title: labelGroup,
      field: 'saleCustomer.group',
      align: 'left',
      defaultGroupOrder: 1,
      cellStyle: { whiteSpace: 'nowrap' },
      groupTitle: <span style={{ fontSize: 12 }}>{labelGroup}</span>,
      render: (rowData: any) => (
        <SaleServiceGroupRow
          name={rowData as string}
          customers={customers}
          startDate={momDateFrom}
          endDate={momDateTo}
          onSelectServiceTime={onSelectCallbackRef.current}
        />
      ),
    })

    columns.push({
      title: user.translate('name'),
      field: 'name',
      align: 'left',
      cellStyle: {
        height: 40,
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 8,
        paddingLeft: 8,
        maxWidth: 140,
      },
      headerStyle: {
        lineHeight: 1.2,
        minWidth: 100,
        maxWidth: 100,
        position: 'sticky',
        whiteSpace: 'nowrap',
        padding: 8, //component.noPadding ? 0 : 8,
        // background: "#798b97",
        color: '#41667e',
        verticalAlign: 'bottom',
      },
      width: 100,
      render: (row) => {
        const customer = row.saleCustomer.customer
        return customer.intName && customer.intName.length > 0 ? customer.intName : customer.firstName + ' ' + customer.lastName
      },
    })

    const headerGroupStyles: { [key: string]: CSSProperties } = {}

    const startMonth = momDateFrom.year() * 12 + momDateFrom.month()
    times.forEach((time, index) => {
      const month = time.year() * 12 + time.month()

      const dayIndex = time.diff(momDateFrom, 'days')
      const weekIndex = time.diff(momDateFrom, 'weeks')
      const monthIndex = month - startMonth
      // console.log(m.format("YYYY-MM-DD"), monthIndex, m.diff(momDateFrom, "weeks"))

      const groupName = time.format('MMM YYYY').capitalize()

      if (!headerGroupStyles[groupName]) {
        headerGroupStyles[groupName] = {
          background: monthIndex % 2 === 0 ? '#fcfaff' : '#f7f4fb',
        }
      }

      const title = (
        <div style={{ flex: 1 }}>
          {time.date()}
          <br />
          <span style={{ fontWeight: 300, fontSize: 11 }}>{time.format('dddd')}</span>
        </div>
      )

      let getColSpan = (row: any) => {
        const colInfo = row.slots[index]

        if (index > 0) {
          const prevCol = row.slots[index - 1]
          if (colInfo.services.length > 0 && prevCol.servicesHash === colInfo.servicesHash) return 0
          if (colInfo.services.length === 0 && prevCol.services.length === 0 && prevCol.pricesHash === colInfo.pricesHash) return 0
        }

        let spanTo = index + 1
        while (spanTo < times.length) {
          const nextColInfo = row.slots[spanTo]
          if (colInfo.services.length > 0 && nextColInfo.servicesHash !== colInfo.servicesHash) break
          if (colInfo.services.length === 0 && (nextColInfo.services.length !== 0 || nextColInfo.pricesHash !== colInfo.pricesHash)) break

          spanTo++
        }

        return spanTo - index
      }

      columns.push({
        colSpan: getColSpan,
        title: title,
        align: 'center',
        cellStyle: { padding: 1 },
        width: 140,
        headerStyle: {
          lineHeight: 1,
          position: 'sticky',
          whiteSpace: 'nowrap',
          padding: 8,
          paddingTop: 4,
          paddingBottom: 4,
          maxWidth: 140,
          width: 90,
          minWidth: 90,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          background: time.weekday() > 4 ? '#e1e1e1' : '#d3f3f2', //dayIndex % 2 === 0 ? "#f9f4ff" : "#f0e9f7",
        },
        render: (rowData) => {
          const customerServices = rowData.slots[dayIndex]

          if (customerServices.services.length > 0) {
            const colSpan = getColSpan(rowData)
            return (
              <ButtonGroup variant="outlined" aria-label="outlined button group" style={{ width: '100%', height: '100%' }}>
                <Button
                  color={'success'}
                  sx={{ p: 0 }}
                  style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'space-around' }}
                  onClick={() => onSelectCustomerServices(rowData, dayIndex)}
                >
                  {customerServices.services.map((service) => (
                    <div style={{ lineHeight: 'normal', fontVariantCaps: 'all-petite-caps', width: '100%' }}>
                      <div className={classes.providerName}>{service.provider.name[user.lang]}</div>
                      <div style={{ fontWeight: 400 }}>
                        <span>{service.price.formatCurrency(service.currency)}</span>
                        <Show if={colSpan > 1}>
                          <span>&nbsp;/&nbsp;{(service.price * colSpan).formatCurrency(service.currency)}</span>
                        </Show>
                      </div>
                    </div>
                  ))}
                </Button>
                <Button color={'error'} sx={{ p: 0 }} onClick={() => onDeleteCustomerServices(rowData, dayIndex)}>
                  <Delete />
                </Button>
              </ButtonGroup>
            )
          }

          if (customerServices.prices.length === 0) return ''

          return (
            <Button
              variant={'contained'}
              color={'info'}
              style={{ width: '100%', height: '100%' }}
              sx={{ p: 0.5 }}
              onClick={() =>
                onSelectCallbackRef.current({
                  dayIndex,
                  startDate: between(
                    customerServices.prices.maxOf((it) => moment(it.dateFrom)),
                    momDateFrom,
                    time,
                  ),
                  endDate: between(
                    customerServices.prices.minOf((it) => moment(it.dateTo)),
                    time,
                    momDateTo,
                  ),
                  customers: [rowData],
                })
              }
            >
              {customerServices.prices.length} {user.translate('offers')}
            </Button>
          )
        },
      })
    })

    columns.push({
      title: user.translate('total'),
      align: 'right',
      width: 1,
      cellStyle: { whiteSpace: 'nowrap' },
      render: (rowData) => {
        // TODO: unify currency
        const servicesByCurrency = rowData.slots.flatMap((customerService) => customerService.services).groupBy((it) => it.currency)
        const entries = Object.entries(servicesByCurrency)
        if (entries.length === 0) return ''
        return entries.joinOf(' | ', ([currency, services]) => services.sumOf((it) => it.price).formatCurrency(currency))
      },
    })

    return [columns, headerGroupStyles]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersKey, saleService.dateFrom, saleService.dateTo, servicePricesKey, user.lang])

  const items = useMemo<Item[]>(() => {
    return (
      customers?.map((customer) => {
        return {
          ...customer,
          slots: times.map((time, index) => {
            const services = customer.services.filter((service: any) => {
              return moment(service.dateFrom).isSameOrBefore(time, 'date') && moment(service.dateTo).isAfter(time, 'date')
            })
            const prices = servicePricesByTime[index]
            const servicesHash = services.joinOf(';', (it) => it.id)
            const pricesHash = prices.joinOf(';', (it) => it.id)
            return { services, prices, servicesHash, pricesHash }
          }),
        }
      }) ?? []
    )
  }, [customers, servicePricesByTime, times])

  return (
    <div className={classes.root}>
      <PlainTableMemo
        key="service_pricing"
        items={items || []}
        columns={columns}
        headerGroupStyles={headerGroupStyles}
        onSelectionChange={onSelect}
        title={<SaleCustomersToolbarTitle saleId={saleId} />}
        options={{
          showSelectGroupCheckbox: true,
        }}
        noPaging
        noAutoWidth
        dense
        noSearch
        noExport
        noTitle
      />
      <UseBulkMutation entity="CustomerService" bulkMutationRef={bulkMutationRef} />
      {confirmDialog.render()}
    </div>
  )
}
