import { Box } from '@mui/material'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router'
import useConfirmDialog from '../../../common/ConfirmDialog'
import PageContainer from '../../../common/PageContainer'
import EditText from '../../../form/EditText'
import FAIconField from '../../../form/FAIconField'
import { HiddenField } from '../../../form/HiddenField'
import FormSwitch from '../../../form/FormSwitch'
import NestedItemForm from '../../../form/NestedItemForm'
import GqlTable from '../../../table/GqlTable'
import { ZeroApiContext } from '../../Organisation'
import { UserContext } from '../../UserApp'

export default function SaleTypeListPage() {
  const navigate = useNavigate()
  const { itemId } = useParams()

  const user = React.useContext(UserContext)
  const organisation = React.useContext(ZeroApiContext)

  const confirmDialog = useConfirmDialog()

  const onItemClick = (e: any, row: any) => {
    navigate(`./../sale-types/${row.id}`)
  }

  return (
    <PageContainer visible={!Boolean(itemId)} animate="fade">
      {confirmDialog.render()}

      <Box sx={{ p: 1 }}>
        <GqlTable entity="SaleType" title="sale_type" canDelete={(it) => !it.isCoreType} onItemClick={onItemClick} dense>
          <HiddenField name="organisationId" gql="String" value={organisation.id} />
          <EditText name="codeName" label="code_name" gql="String" default="" width={1} />
          <NestedItemForm name="name" gql="Translation">
            <EditText name={user.lang} label="name" gql="String" default="" width={24} />
            <HiddenField name={user.secLang} value={(row: any) => (row ? row[user.lang] ?? row[`name_${user.lang}`] : '')} />
          </NestedItemForm>
          <FAIconField name="icon" label="icon" gql="String" default={'shopping-cart'} color="#66A" width={1} />
          <FormSwitch name="groupByCustomer" label="grouping" gql="Long" default={false} />
          <FormSwitch name="simple" label="simple" gql="Long" default={false} />
          <FormSwitch name="isCoreType" label="core" gql="Long" default={false} />
          <FormSwitch name="active" label="active" gql="Long" default={true} />
        </GqlTable>
      </Box>
    </PageContainer>
  )
}
