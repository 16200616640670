import * as React from 'react'
import EditText from '../../form/EditText'
import EntityAutoComplete from '../../form/EntityAutoComplete'
import { HiddenField } from '../../form/HiddenField'
import FormDialog from '../../form/FormDialog'
import NestedItemForm from '../../form/NestedItemForm'
import SelectTimezone from '../../form/SelectTimezone'
import { UserContext } from '../UserApp'
import CreateCountry from './CreateCountry'

type Props = {
  value: string
  onClose: (t: any) => void
}
export default function CreateCity({ value, onClose }: Props) {
  const user = React.useContext(UserContext)
  return (
    <FormDialog entity="City" item={true} onClose={onClose} autoClose>
      <HiddenField name="id" id />
      <NestedItemForm name="name" gql="Translation">
        <EditText name={user.lang} label="name" gql="String" value={value} fullWidth />
        <HiddenField name={user.secLang} value={(row: any) => (row ? row[user.lang] ?? row[`name_${user.lang}`] : '')} />
      </NestedItemForm>
      <EntityAutoComplete name="countryId" label="country" entity="Country" all freeSolo={CreateCountry}>
        <HiddenField name="timezone" gql="String" />
      </EntityAutoComplete>
      <SelectTimezone name="timezone" gql="String" default={(row: any) => row?._countryId?.timezone} />
    </FormDialog>
  )
}
