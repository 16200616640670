export const flattenTreeData = (data: any, result: any = {}, path: string[] = []) => {
  for (const key in data) {
    if (!data.hasOwnProperty(key)) continue
    const val = data[key]
    const entryPath = path.concat([key])
    const valPrototype = val !== undefined && val !== null ? Object.getPrototypeOf(val) : null

    if (valPrototype === Object.prototype) {
      flattenTreeData(val, result, entryPath)
    } else if (valPrototype === Boolean.prototype) {
      result[entryPath.join('_')] = val ? 1 : 0
    } else if (val === null) {
      result[entryPath.join('_')] = null
    } else {
      result[entryPath.join('_')] = val
    }
  }
  return result
}
