import { CompositeField, CurrencyAmountField, EditText, FilterField, GqlTable, HiddenField, SelectOption } from '@zeroapi-io/zeroapireact'
import * as React from 'react'
import { useParams } from 'react-router'
import { ZeroApiContext } from '../../../../Organisation'
import { UserContext } from '../../../../UserApp'
import { Sale } from '../SaleForm'

interface Props {
  sale?: Sale
}

export default function ServiceSalesSummary({ sale }: Props) {
  const { itemId: saleId } = useParams()

  const user = React.useContext(UserContext)
  const organisation = React.useContext(ZeroApiContext)

  const PayType = {
    CASH: user.translate('cash'),
    CARD: user.translate('card'),
    INVOICE: user.translate('invoice'),
    SS: 'SS',
  }

  const defaultCurrency = sale?.currency ?? 'EUR'

  return (
    <>
      <GqlTable title={'services'} entity="SaleService" dense="dense" noPaging noSearch noExport noTitle aggregated>
        <FilterField name="saleId" value={saleId} />

        <SelectOption
          name="payType"
          options={PayType}
          summary={() => ({ value: 'Фактуриран Износ', style: { fontSize: 12, padding: 8, fontWeight: 'bold' } })}
        />
        <HiddenField name="amount" />
        <CurrencyAmountField
          name="total"
          label={'total_deal'}
          currency={defaultCurrency}
          aggregate={(items) => items.sumOf((it) => it?.total || it?.amount)}
          summary={(items) => {
            const value = items.sumOf((it) => it?.total || it?.amount)
            return {
              value: `${value} ${defaultCurrency}`,
              style: { fontWeight: 'bold', padding: 8, fontSize: 14 },
            }
          }}
        />
        <CurrencyAmountField
          name="invoiced"
          currency={defaultCurrency}
          aggregate={(items) => items.sumOf((it) => it.invoiced)}
          summary={() => ({ value: 'Ќар/зарар', style: { fontSize: 12, padding: 8, fontWeight: 'bold' } })}
        />
        <CurrencyAmountField
          name="profitLoss"
          value={(item) => (item?.invoiced || 0) - (item?.total || item?.amount || 0)}
          currency={defaultCurrency}
          cellStyle={(_value, item) => ((item?.total || item?.amount || 0) < (item?.invoiced || 0) ? { color: 'red' } : {})}
          summary={(data) => {
            const value = -data.sumOf((item) => (item.total || item.amount || 0) - item.invoiced)
            return {
              value: `${value} ${defaultCurrency}`,
              style: { fontWeight: 'bold', padding: 8, fontSize: 14, color: value < 0 ? 'red' : 'black' },
            }
          }}
          derived
        />
      </GqlTable>
    </>
  )
}
