import { gql, useMutation } from '@apollo/client'
import { createContext } from 'react'
import { Navigate } from 'react-router'
import generateGql from '../gql/generateGql'
import { useDynamicGqlMutation } from '../gql/useDynamicGql'
import { translations as translationsEN } from '../language/en'
import { translations as translationsMK } from '../language/mk'
import { OrganisationInfo } from './Organisation'
import { Translations } from '@zeroapi-io/zeroapireact'

const translations: { [key: string]: Translations } = {
  mk: translationsMK,
  en: translationsEN,
}

const logout = async () => {
  document.cookie.split(';').forEach((c) => {
    document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString())
  })
}

export type UserContextParams = {
  userId: string
  employeeOrganisations: { organisationId: string; organisation: OrganisationInfo; role: string }[]
  profile: any
  lang: string | 'en' | 'mk'
  secLang: string | 'en' | 'mk'
  translate: (key: string, ...params: string[]) => string
  translateQty: (qty: number, key: string, ...params: string[]) => string
  setLanguage: (lang: string) => void
  logout: () => Promise<any>
}

export const UserContext = createContext<UserContextParams>({
  userId: '',
  employeeOrganisations: [],
  profile: {},
  lang: 'mk',
  secLang: 'en',
  translate: (key: string, ...params: string[]) => {
    const value = translationsMK[key] || translationsMK[key] || key
    return params.reduce((result, param) => result.replace('%s', param), value.toString())
  },
  translateQty: (qty, key, ...params) => {
    let value = translationsMK[key] || translationsMK[key] || key
    if (value instanceof Array) {
      value = value[qty]
      // if (qty === 0) {
      //   value = value.zero ?? value.any ?? key
      // } else if (qty === 1) {
      //   value = value.one ?? value.any ?? key
      // } else if (qty > 1) {
      //   value = value.many ?? value.any ?? key
      // } else {
      //   value = value.any ?? key
      // }
    }
    return params.reduce((result, param) => result.replace('%s', param), value.toString())
  },
  setLanguage: (lang) => console.log('setLanguage not implemented', lang),
  logout,
})

const editUserQuery = { args: { input: { $id: 'String!', $lang: 'String!' } }, select: { id: true, lang: true } }

export interface Me {
  id: string
  lang: string
  employeeOrganisations: []
  profile: any
}

export default function UserApp({ children, me }: { children: JSX.Element; me: Me }) {
  const translation = (me && translations[me.lang.toLowerCase()]) || translationsEN

  const translate = (key: string, ...params: string[]) => {
    const value = translation[key] || translationsEN[key] || key
    return params.reduce((result, param) => result.replace('%s', param), value.toString())
  }

  const translateQty = (qty: number, key: string, ...params: string[]) => {
    let value = translation[key] || translationsEN[key] || key
    if (value instanceof Array) {
      value = value[qty]
    }
    return params.reduce((result, param) => result.replace('%s', param), value.toString())
  }

  const [changeLanguage] = useMutation(gql(generateGql({ [`editUser`]: editUserQuery }, 'mutation')))

  const setLanguage = (lang: string) => {
    changeLanguage({ variables: { id: me.id, lang } })
  }

  const [authLogout] = useDynamicGqlMutation('auth_logout', {})

  if (!me) {
    return <Navigate to="/login" />
  }

  const lang = me ? me.lang.toLowerCase() : 'en'
  return (
    <UserContext.Provider
      value={{
        userId: me.id,
        employeeOrganisations: me.employeeOrganisations,
        profile: me.profile,
        lang: lang,
        secLang: lang === 'en' ? 'mk' : 'en',
        translate,
        translateQty,
        setLanguage,
        logout: authLogout,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
