import { MutableRefObject, useCallback, useRef } from 'react'
import generateGql from './generateGql'
import { GqlQuery } from './interface'
import UseMutation, { MutateCallback } from './UseMutation'

export type MultipleBulk = { [key in 'create' | 'edit' | 'delete' | 'hardDelete']?: any[] }

const generateBulkGql = (entity: string, bulkItems: MultipleBulk) => {
  const queries: { [key: string]: GqlQuery } = {}

  let counter = 0

  Object.entries(bulkItems).forEach(([action, items]) => {
    const select = action === 'delete' || action === 'hardDelete' ? undefined : { id: true }
    const mutationName = `${action.camelCase()}${entity.capitalize()}`
    if (action === 'delete' || action === 'hardDelete') {
      items.forEach((item) => {
        queries[`q${counter++}`] = { args: item, alias: mutationName, select }
      })
    } else {
      items.forEach((item) => {
        queries[`q${counter++}`] = { args: { input: item }, alias: mutationName, select }
      })
    }
  })

  console.log('queries', queries)
  return generateGql(queries, 'mutation')
}

export type MultipleBulkMutateCallback = (items: MultipleBulk) => Promise<any>

interface Props {
  entity: string
  bulkMutationRef: MutableRefObject<MultipleBulkMutateCallback | undefined>
}

export default function UseBulkMutation({ entity, bulkMutationRef }: Props) {
  const runRef = useRef<MutateCallback>(() => new Promise((resolve) => resolve(true)))

  const callback = useCallback<MultipleBulkMutateCallback>(
    (items: MultipleBulk) => runRef.current && runRef.current(generateBulkGql(entity, items)),
    [entity],
  )
  if (bulkMutationRef) {
    bulkMutationRef.current = callback
  }

  return <UseMutation runRef={runRef} />
}

export type BulkMutateCallback = (items: any[]) => Promise<any>

interface ActionProps {
  entity: string
  bulkMutationRef: MutableRefObject<BulkMutateCallback | undefined>
}

export function UseBulkCreateMutation({ entity, bulkMutationRef }: ActionProps) {
  const runRef = useRef<MutateCallback>(() => new Promise((resolve) => resolve(true)))

  const callback = useCallback<BulkMutateCallback>(
    (items: any[]) => runRef.current && runRef.current(generateBulkGql(entity, { create: items })),
    [entity],
  )
  if (bulkMutationRef) {
    bulkMutationRef.current = callback
  }

  return <UseMutation runRef={runRef} />
}
