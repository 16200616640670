import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import config from './config'
import './index.css'
import reportWebVitals from './reportWebVitals'

import('./common/Extensions')

const cache = new InMemoryCache({
  typePolicies: {
    ReportFilterOptions: {
      keyFields: ['_id'],
    },
    ProfileByEmail: {
      keyFields: ['_id'],
    },
  },
})
const link = new HttpLink({
  uri: `${config.apiUrl}/graphql/main`,
  credentials: 'include',
  withCredentials: true,
  fetchOptions: {
    credentials: 'include',
  },
})

const client = new ApolloClient({
  cache,
  link,
})

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
