import { Theme } from '@mui/material'
import Dashboard from './Dashboard'
import Company, { OrganisationInfo } from './Organisation'
import UserApp, { Me } from './UserApp'

interface Props {
  me: Me
  organisations: OrganisationInfo[]
  theme: Theme
}

export default function OrganisationDashboard({ me, organisations, theme }: Props) {
  return (
    <UserApp me={me}>
      <Company organisations={organisations} theme={theme} lang={me.lang}>
        <Dashboard />
      </Company>
    </UserApp>
  )
}
