import MaterialTable, {
  Action,
  Column,
  DetailPanel,
  MTableBodyRow,
  MTableCell,
  MTableHeader,
  MTableToolbar,
  Options,
} from '@material-table/core'
import { ExportCsv } from '@material-table/exporters'
import { ArrowBack } from '@mui/icons-material'
import BoltIcon from '@mui/icons-material/Bolt'
import { alpha, IconButton, TableCell, TableHead, TableRow, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { CSSProperties, useContext } from 'react'
import { NavigateFunction, useNavigate } from 'react-router'
import { UserContext } from '../app/UserApp'
import ExportPdf from './ExportPdf'
import { TABLE_ICONS } from './TableIcons'
import { callOrGet } from '../form/utils'
import MaterialTableGroupRowSmall from './MaterialTableGroupRowSmall'

const useStyles = makeStyles((theme: Theme) => ({
  fixToolbarNoTitlePadding: {
    '& :first-child': {
      paddingLeft: 0,
    },
  },
  fixToolbarWithBack: {
    '& * span > div': { height: '100%' },
    '& > div': { paddingLeft: 12 },
    '& * div > div': { width: '100%' },
  },
  fixToolbar: {
    '& * span > div': { height: '100%' },
    '& * div > div': { width: '100%' },
  },
  tableRow: {
    '& .css-mf1cb5-MuiButtonBase-root-MuiIconButton-root': {
      width: 32,
      height: 32,
    },
  },
  tableRowDetailed: {
    '& * > .css-mf1cb5-MuiButtonBase-root-MuiIconButton-root': {
      width: 32,
      height: 32,
      alpha: 0.5,
    },
    '&:hover': { backgroundColor: '#d3e9df !important' },
  },
  headerRow: {
    lineHeight: 1.2,
    position: 'sticky',
    whiteSpace: 'nowrap',
    padding: 0,
  },
  header: {
    lineHeight: 1.2,
    position: 'sticky',
    whiteSpace: 'nowrap',
    padding: 4,
  },
}))

interface KeyMapNullable {
  [key: string]: string | undefined
}

export interface GqlTableColumn<T extends object> extends Column<T> {
  groupName?: string
  colSpan?: number | ((rowData: T, value: any) => number)
}

export interface Props<T extends object> {
  deps?: any[]
  items: T[]
  columns: GqlTableColumn<T>[]
  detailPanel?: DetailPanel<any>[]
  isLoading?: boolean
  options?: Options<any>
  headerGroupStyles?: { [key: string]: CSSProperties }
  noAutoWidth?: boolean

  onItemClick?: (e?: React.MouseEvent, row?: T) => void
  onRowAdd?: (data: KeyMapNullable) => Promise<any>
  onRowUpdate?: (data: KeyMapNullable) => Promise<any>
  onRowDelete?: (data: KeyMapNullable) => Promise<any>
  refresh?: (data: KeyMapNullable) => Promise<any>
  dense?: boolean
  grouping?: boolean
  search?: string
  noTitlePadding?: boolean
  noSearch?: boolean
  noPaging?: boolean
  noTitle?: boolean
  noExport?: boolean
  title?: string | React.ReactElement<any>
  parentTitle?: string | React.ReactElement<any>
  fixedLeft?: number
  fixedRight?: number
  filterRows?: (rows: any[]) => any
  style?: React.CSSProperties
  hasBack?: boolean
  onItems?: (items: any[]) => void
  onSelectionChange?: (data: any[], rowData?: any) => void
  onLoad?: () => void
  canDelete?: (row: any) => boolean
  treeItemsLookup?: (row: any, rows: any[]) => any[]
  titleActions?: React.ReactNode
  actions?: (Action<T> | ((T: T) => Action<T>) | { action: (T: T) => Action<T>; position: string })[]
}

export interface HeaderColumn {
  name: string
  title: string | JSX.Element
  align?: 'left' | 'right' | 'center'
  width?: number | string
  span?: number
  actions?: boolean
  hidden?: boolean
  columns?: HeaderColumn[]
}

const headerStyle: React.CSSProperties = {
  lineHeight: 1.2,
  position: 'sticky',
  whiteSpace: 'nowrap',
  padding: 4,
}

function getTitle(props: Props<any>, navigate: NavigateFunction, fullTitle: JSX.Element | string | undefined) {
  if (props.hasBack) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={() => navigate('./..')}>
          <ArrowBack color="primary" />
        </IconButton>
        <Typography variant="h6">{fullTitle}</Typography>
        {props.titleActions ?? null}
      </div>
    )
  }

  if (props.titleActions) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6">{fullTitle}</Typography>
        {props.titleActions ?? null}
      </div>
    )
  }

  return fullTitle
}

export default function PlainTable<T extends object>(props: Props<T>) {
  const classes = useStyles()
  const user = useContext(UserContext)

  const navigate = useNavigate()

  const items = props.items || []

  const treeItemsLookup = props.treeItemsLookup

  const detailPanel: DetailPanel<any>[] = props.detailPanel || []

  const headerGroups: HeaderColumn[] = []
  if (props.onRowUpdate || props.onRowDelete) {
    headerGroups.push({
      name: 'actions',
      actions: true,
      span: 1,
      align: 'center',
      title: <BoltIcon color="action" sx={{ opacity: 0.5 }} />,
    })
  }
  let headerRows = 1

  const totalWidth = props.columns.sumOf((col) => (col.hidden ? 0 : typeof col.width === 'number' ? (col.width as number) : 12))

  const columns = props.columns.map((column) => {
    const { groupName, title, hidden, width } = column

    if (hidden) return { ...column }

    const columnTitle = (title as string) || ' '
    if (groupName) {
      const group = headerGroups[headerGroups.length - 1]
      if (group && group.name === groupName) {
        if (group.columns) {
          group.columns.push({ name: columnTitle, title: columnTitle, hidden, width })
        } else {
          group.columns = [{ name: columnTitle, title: columnTitle, hidden, width }]
        }
      } else {
        headerGroups.push({
          name: groupName,
          title: user.translate(groupName),
          columns: [{ name: columnTitle, title: columnTitle, hidden, width }],
        })
      }
      headerRows = 2
    } else {
      headerGroups.push({ name: columnTitle, title: columnTitle, hidden, width })
    }
    if (!props.noAutoWidth) {
      const width = typeof column.width !== 'number' ? 12 : column.width < 1 ? 12 : column.width
      const columnWidth = Math.floor((width * 100) / totalWidth) + '%'
      return { ...column, width: columnWidth, headerStyle: { ...column.headerStyle, width: columnWidth } } as Column<T>
    }
    return column as Column<T>
  })

  const hasDetails = detailPanel.length > 0

  if (hasDetails || treeItemsLookup) {
    headerGroups.unshift({ name: 'details', title: '', width: 5, actions: true })
  }

  const titleProp = props.title
  const titleContent = typeof titleProp === 'string' ? user.translate(titleProp) : titleProp

  const parentTitle = typeof props.parentTitle === 'string' ? user.translate(props.parentTitle) : undefined

  const fullTitle = parentTitle ? (
    <>
      <span style={{ color: '#888' }}>{parentTitle}</span> / {titleContent}
    </>
  ) : (
    titleContent
  )

  const title = getTitle(props, navigate, fullTitle)

  const headerGroupStyles = props.headerGroupStyles || {}

  return (
    // @ts-ignore
    <MaterialTable
      style={props.style}
      onRowClick={props.onItemClick}
      editable={{
        isDeletable: props.canDelete,
        onRowAdd: props.onRowAdd,
        onRowUpdate: props.onRowUpdate,
        onRowDelete: props.onRowDelete,
      }}
      actions={props.actions}
      icons={TABLE_ICONS}
      title={title}
      isLoading={props.isLoading}
      columns={columns}
      data={props.filterRows ? props.filterRows(items) : items}
      detailPanel={hasDetails ? detailPanel : undefined}
      onSelectionChange={props.onSelectionChange}
      options={{
        ...props.options,
        selection: Boolean(props.onSelectionChange),
        emptyRowsWhenPaging: false,
        search: !props.noSearch,
        searchText: props.search,
        paging: !props.noPaging,
        grouping: props.grouping,
        exportMenu: props.noExport
          ? []
          : [
              {
                label: 'Export PDF',
                exportFunc: (cols, datas) =>
                  ExportPdf(
                    cols.map((it) => ({
                      title: it.title?.toString() ?? it?.field?.toString() ?? '-',
                    })),
                    datas,
                    'myPdfFileName',
                  ),
              },
              {
                label: 'Export CSV',
                exportFunc: (cols, datas) => ExportCsv(cols, datas, 'myCsvFileName'),
              },
            ],
        fixedColumns: {
          left: props.fixedLeft,
          right: props.fixedRight,
        },
        pageSize: 20,
        pageSizeOptions: [20, 50, 100, 200],
        padding: props.dense ? 'dense' : 'default',
        rowStyle: (data, index, level) => {
          // @ts-ignore
          const isExpanded = data.tableData?.showDetailPanel || false
          return {
            fontSize: props.dense ? 12 : 14,
            backgroundColor: level > 0 ? alpha('#089', level * 0.15) : undefined,
            boxShadow: isExpanded ? '0px 0px 6px' : undefined,
            background: Boolean(detailPanel.length) ? (isExpanded ? '#f8fbf9' : '#eaf1f1') : undefined,
            transition: 'boxShadow 0.3s ease-out, background 0.3s ease-out',
            fontWeight: isExpanded ? 'bold' : undefined,
          }
        },
        actionsCellStyle: {
          // padding: 0,
          // width: "5%",
        },
      }}
      components={{
        Header:
          headerRows && headerRows > 1
            ? (headerProps) => {
                let columnIdx = 0
                let columnIdx2 = 0
                return (
                  <TableHead {...headerProps}>
                    <TableRow className={classes.headerRow}>
                      {headerGroups.map((headerGroup, index) => {
                        const columns = headerGroup.columns
                        if (columns === undefined) {
                          const columnProps = headerGroup.actions ? {} : headerProps.columns[columnIdx++] || {}
                          const style = { ...headerStyle, ...columnProps.headerStyle }
                          if (headerGroup.width) {
                            style.width = headerGroup.width
                          } else if (columnProps.width) {
                            style.width = columnProps.width
                          }

                          return (
                            <TableCell
                              style={style}
                              rowSpan={2}
                              colSpan={headerGroup.span || 1}
                              hidden={headerGroup.hidden}
                              align={columnProps.align || headerGroup.align || 'left'}
                            >
                              {headerGroup.title}
                            </TableCell>
                          )
                        } else {
                          columnIdx += columns.length
                          const newHeaderGroup = headerGroups[index + 1]
                          const isNextGroup = newHeaderGroup && newHeaderGroup.columns !== undefined

                          return (
                            <TableCell
                              style={{
                                ...headerStyle,
                                ...headerGroupStyles[headerGroup.name],
                                borderRight: isNextGroup ? '1px solid rgba(224, 224, 224, 1)' : undefined,
                              }}
                              colSpan={columns.length}
                              align="center"
                              hidden={headerGroup.hidden}
                            >
                              {headerGroup.title}
                            </TableCell>
                          )
                        }
                      })}
                    </TableRow>
                    <TableRow className={classes.headerRow}>
                      {headerGroups.flatMap((headerGroup) => {
                        if (headerGroup.actions) return []
                        const columns = headerGroup.columns
                        if (columns === undefined) {
                          columnIdx2++
                          return []
                        }
                        return columns.map((column) => {
                          const columnProps = headerProps.columns[columnIdx2++] || {}

                          return (
                            <TableCell
                              style={{ ...headerStyle, ...columnProps.headerStyle }}
                              align={columnProps.align || 'center'}
                              hidden={headerGroup.hidden}
                            >
                              {column.title}
                            </TableCell>
                          )
                        })
                      })}
                    </TableRow>
                  </TableHead>
                )
              }
            : (headerProps) => <MTableHeader {...headerProps} />,
        Toolbar: (toolbarProps) => {
          if (props.noTitle) return <span />
          if (props.noTitlePadding) {
            return (
              <div className={classes.fixToolbarNoTitlePadding}>
                <MTableToolbar {...toolbarProps} />
              </div>
            )
          }
          return (
            <div className={props.hasBack ? classes.fixToolbarWithBack : classes.fixToolbar}>
              <MTableToolbar {...toolbarProps} />
            </div>
          )
        },
        Row: (props) => <MTableBodyRow {...props} className={hasDetails ? classes.tableRowDetailed : classes.tableRow} />,
        GroupRow: props.dense ? (props) => <MaterialTableGroupRowSmall {...props} /> : undefined,
        Cell: (props) => {
          if (props.columnDef.colSpan === undefined) return <MTableCell {...props} />
          const span = callOrGet(props.columnDef.colSpan, props.rowData, props.value)
          if (span === 0) return null
          return <MTableCell colSpan={span} {...props} />
        },
      }}
      parentChildData={treeItemsLookup}
      localization={{
        header: {
          actions: <BoltIcon color="action" sx={{ opacity: 0.5 }} />, //user.translate("actions"),
        },
        body: {
          emptyDataSourceMessage: <span style={{ color: '#777' }}>{user.translate('no_records_display')}</span>,
          addTooltip: user.translate('add'),
          deleteTooltip: user.translate('delete'),
          editTooltip: user.translate('edit'),
          editRow: {
            deleteText: user.translate('delete_text_row'),
            cancelTooltip: user.translate('cancel'),
            saveTooltip: user.translate('save'),
          },
        },
        toolbar: {
          exportCSVName: user.translate('export_csv_name'),
          exportPDFName: user.translate('export_pdf_name'),
          exportTitle: user.translate('export'),
          searchAriaLabel: user.translate('search'),
          searchTooltip: user.translate('search'),
          searchPlaceholder: user.translate('search'),
        },
        pagination: {
          firstTooltip: user.translate('first_page'),
          lastTooltip: user.translate('last_page'),
          previousTooltip: user.translate('previous_page'),
          nextTooltip: user.translate('next_page'),
        },
      }}
    />
  )
}

export const PlainTableMemo = React.memo(
  (props: Props<any>) => {
    return <PlainTable {...props} />
  },
  (prev, next) => {
    return prev.items === next.items && prev.columns === next.columns
  },
)
