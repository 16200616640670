import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { Theme } from '@mui/material'
import { TranslationProvider, ZeroApiContextProvider } from '@zeroapi-io/zeroapireact'
import * as React from 'react'
import { useParams } from 'react-router'
import config from '../config'
import translations from '../language'
import { UserContext } from './UserApp'

export type OrganisationInfo = {
  id: string
  name: { mk: string; en: string; [key: string]: string }
  role?: string
}

export const ZeroApiContext = React.createContext<OrganisationInfo>({
  id: '',
  name: { mk: '', en: '' },
})

interface Props {
  organisations: OrganisationInfo[]
  children: React.ReactNode | React.ReactNode[]
  theme: Theme
  lang: string
}

export default function Organisation({ organisations, children, theme, lang }: Props) {
  const user = React.useContext(UserContext)

  const { id } = useParams()

  if (organisations.length === 0) {
    return (
      <div>
        {user
          .translate('info_no_organisations')
          .split('\n')
          .map((line: string) => (
            <div>{line}</div>
          ))}
      </div>
    )
  }

  const role = user.employeeOrganisations.find((organisation) => organisation.organisationId === id)?.role
  const organisation = organisations.find((organisation) => organisation.id === id)
  if (!organisation) {
    return (
      <div>
        {user
          .translate('info_no_company')
          .split('\n')
          .map((line: string) => (
            <div>{line}</div>
          ))}
      </div>
    )
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ZeroApiContext.Provider value={{ ...organisation, role: role }}>
        <ZeroApiContextProvider
          id={organisation.id}
          name="Organisation"
          lang={lang?.toLowerCase() ?? 'mk'}
          apiUrl={`${config.apiUrl}/graphql/main`}
          theme={theme}
        >
          <TranslationProvider
            locale={lang?.toLowerCase() ?? 'mk'}
            defaultTranslations={{}}
            defaultLocale="mk"
            translationsFactory={async (locale) => translations[locale] ?? {}}
          >
            {children}
          </TranslationProvider>
        </ZeroApiContextProvider>
      </ZeroApiContext.Provider>
    </LocalizationProvider>
  )
}
