import Delete from '@mui/icons-material/Delete'
import { Button, CircularProgress, IconButton, LinearProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import FadeIn from 'react-fade-in'
import { UserContext } from '../app/UserApp'
import { HiddenField } from './HiddenField'
import useFormQuery from './useFormQuery'
import { callOrGet, ifNull } from './utils'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    margin: 'auto',
    bottom: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff80',
    zIndex: 1,
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
  },
  spacer: {
    flex: 1,
  },
}))

const getFieldsData = (fields) => {
  return fields.toMapBy(
    (field) => field.name,
    (field) => {
      if (field.subSelection) {
        return getFieldsData(Object.values(field.subSelection))
      } else {
        return ifNull(callOrGet(field.value), callOrGet(field.default))
      }
    },
  )
}

export function ItemFormChildren(props) {
  const { fields, fieldsMap, query, updateRelValue } = useFormQuery(props)

  if (props.queryRef) {
    props.queryRef.current = query
  }

  const user = useContext(UserContext)

  const dataItem = query.item instanceof Array ? query.item[0] : query.item

  const data = dataItem || props.item || getFieldsData(fields)

  const [isFirstLoad, setIsFirstLoad] = useState(Boolean(props.entityRelFieldValue))
  const [itemData, setItemData] = useState()
  const item = itemData || data

  useEffect(() => {
    if (query.isLoading) return
    if (isFirstLoad) {
      setItemData(undefined)
      setIsFirstLoad(false)
    } else {
      if (itemData) {
        setItemData({ ...data, itemData })
      }
    }
  }, [query.isLoading])

  const onChange = (name) => (value) => {
    setItemData({ ...item, [name]: value, _orgState: item._orgState || item })
  }

  const doSubmit = () => {
    const data = {}
    const extractData =
      (item, prefix = '') =>
      (field) => {
        const value = ifNull(item[field.name], callOrGet(field.value, item), callOrGet(field.default, item))
        if (field.nested) {
          Object.values(field.subSelection).forEach(extractData(value, prefix + field.name + '_'))
        } else {
          data[prefix + field.name] = value
        }
      }
    fields.forEach(extractData(item))

    const propsResult = props?.onSubmit && props.onSubmit(data)
    if (propsResult instanceof Promise) return { data, promise: propsResult }
    if (propsResult) return { data }

    return { data, promise: query.saveItem && query.saveItem(data, false) }
  }

  const onSubmit = async () => {
    const { data, promise } = doSubmit()
    setItemData({ ...item, _orgState: undefined })
    const result = await promise
    console.log("onSubmit", data, result)
    const resultData = { ...data, ...result }
    if (props.onSave) await props.onSave(resultData)
    if (props.refresh) await props.refresh(resultData)
    updateRelValue(resultData[props.entityRelFieldName])

    if (props.autoRefresh) {
      await query.refresh()
    }
  }

  const onDelete = () => {
    query.deleteItem(item).then(() => {
      if (props.onDelete) props.onDelete(item)
      if (props.refresh) props.refresh(item)
    })
  }

  const onEdit = (data) => {
    setItemData(data)
  }

  const isLoading = query.isLoading || query.isLoadingAction

  let hasError = false
  const children = React.Children.map(props.children, (child) => {
    if (!React.isValidElement(child)) return child
    if (child.type === HiddenField) return null

    const childProps = child.props
    const fieldName = childProps.name
    if (fieldName === undefined) return child

    const field = fieldsMap[fieldName]
    if (field === undefined) return child

    const value = ifNull(item[fieldName], field.value, callOrGet(field.default, item))

    if (callOrGet(childProps.hidden, value, item) === true) return null

    const error =
      (childProps.nullable !== true && !child.type.readOnly && (value === undefined || value?.length === 0)) ||
      callOrGet(child.type.validate, childProps, value)

    if (error) hasError = true

    return React.cloneElement(child, {
      isForm: true,
      key: childProps.key || fieldName,
      label: user.translate(childProps.label || fieldName.snakeCase().replace(/_id$/g, '')),
      value: value,
      hidden: false,
      item: item,
      disabled: childProps.disabled || isLoading,
      error: error,
      size: props.dense ? 'small' : 'medium',
      onChange: onChange(fieldName),
      onSubmit,
      style: {
        flexGrow: 1,
      },
      parentEntity: props.entity,
    })
  })

  const isItemCreated = (props.item || query.item) !== undefined

  if (props.content) {
    children.push(callOrGet(props.content, item, isItemCreated))
  }

  if (props.footer) {
    children.push(
      callOrGet(props.footer, item, {
        hasError,
        onSubmit,
        onDelete,
        onEdit,
        isCreated: isItemCreated,
        isChanged: item._orgState && fields.find((field) => item._orgState[field.name] !== item[field.name]) !== undefined,
      }),
    )
  }

  if (typeof props.loading == 'function') {
    children.push(callOrGet(props.loading, isLoading))
  } else if (isLoading) {
    children.push(callOrGet(props.loading, isLoading))
  }
  return children
}

const getProgressBar = (props) => {
  if (props.loadingBar !== undefined) return props.loadingBar
  if (props.loadingVariant === 'circular') return <CircularProgress />
  return <LinearProgress variant="indeterminate" style={{ width: 220 }} />
}

export default function ItemForm(props) {
  const classes = useStyles()

  const user = useContext(UserContext)

  const loading = (isLoading) => (
    <FadeIn key="loading" transitionDuration={200} delay={100} visible={isLoading}>
      <div key="loading" className={classes.loading} style={{ top: props.topOffset || 64 }}>
        {isLoading && getProgressBar(props)}
      </div>
    </FadeIn>
  )

  const footer = (item, status) => (
    <div key="footer" className={classes.footer}>
      {props.noDelete !== true && (
        <IconButton key="button_delete" disabled={!status.isCreated} color="secondary" onClick={status.onDelete}>
          <Delete />
        </IconButton>
      )}
      <div className={classes.spacer} />
      <Button key="button_save" disabled={status.hasError || !status.isChanged} onClick={status.onSubmit}>
        {user.translate('save')}
      </Button>
    </div>
  )

  const rootClasses = [classes.root]
  if (props.className) rootClasses.push(props.className)
  return (
    <form className={rootClasses} noValidate autoComplete="off">
      <ItemFormChildren {...props} loading={props.loading || loading} content={props.content} footer={props.footer || footer} />
    </form>
  )
}

ItemForm.propTypes = {
  topOffset: PropTypes.number,
  loadingVariant: PropTypes.oneOf(['linear', 'circular']),
  loadingBar: PropTypes.element,
  footer: PropTypes.element,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onLoad: PropTypes.func,
  entity: PropTypes.string.isRequired,
  customQuery: PropTypes.string,
  entityRelFieldName: PropTypes.string,
  entityRelFieldValue: PropTypes.string,

  isLoading: PropTypes.bool,
  dateRangeFilter: PropTypes.object,
  filter: PropTypes.object,
  keys: PropTypes.array,
  args: PropTypes.object,
  dense: PropTypes.bool,
  children: PropTypes.node.isRequired,
  refresh: PropTypes.func,
  queryRef: PropTypes.object,
  autoRefresh: PropTypes.bool,
  noDelete: PropTypes.bool,
  all: PropTypes.bool,
}
