import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { default as React, useContext } from 'react'
import { UserContext } from '../app/UserApp'
import Show from '../common/Show'
import { callOrGet, ifNull, preventPropagation } from './utils'
import { GqlTabletField } from '../table/GqlTable'

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    display: 'inline-grid',
    flexGrow: 1,
    minWidth: 80,
    verticalAlign: 'baseline',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

interface FormFieldProps extends GqlTabletField<string> {
  onClear?: () => void
}

const SelectCurrency = (props: FormFieldProps) => {
  const classes = useStyles()
  const user = useContext(UserContext)

  let options = [
    { val: 'MKD', name: user.translate('mkd') },
    { val: 'EUR', name: user.translate('eur') },
    {
      val: 'USD',
      name: user.translate('usd'),
    },
  ]

  const item = props.item ?? props.rowData
  const value = ifNull(props.value, callOrGet(props.default, item))

  const onChange = props.onChange ? props.onChange : () => {}

  return (
    <FormControl
      className={classes.formControl}
      sx={{ m: props.isForm ? 1 : 0, ml: 1, mb: 0, mt: 0 }}
      style={!props.fullWidth ? { flexGrow: 1 } : { flexGrow: 1 }}
      size={props.size === 'small' ? 'small' : 'medium'}
    >
      <Show if={props.isForm}>
        <InputLabel htmlFor={`select-${props.name}`} variant={props.isForm ? 'outlined' : 'standard'}>
          {React.isValidElement(props.label) ? props.label : user.translate(props.label?.toString() || props.name.snakeCase())}
        </InputLabel>
      </Show>
      <Select
        fullWidth={props.fullWidth}
        style={{ fontSize: props.size === 'small' ? 12 : 14, marginBottom: 0 }}
        value={value}
        label={
          props.isForm
            ? React.isValidElement(props.label)
              ? props.label
              : user.translate(props.label?.toString() || props.name.snakeCase())
            : undefined
        }
        variant={props.isForm ? 'outlined' : 'standard'}
        onKeyDown={preventPropagation}
        inputProps={{
          id: `select-${props.name}`,
        }}
        error={callOrGet(props.error, value)}
        disabled={callOrGet(props.disabled, value, item)}
        onChange={(event: SelectChangeEvent<any>, child: React.ReactNode) => {
          const option = options.find((opts) => opts.val === event.target.value)
          onChange(option?.val ? option?.val : null)
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.val} value={option.val} onKeyDown={preventPropagation}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
SelectCurrency.gql = 'String'
SelectCurrency.align = 'center'

const Render = (props: FormFieldProps) => {
  const user = useContext(UserContext)

  let options = [
    { val: 'MKD', name: user.translate('mkd') },
    { val: 'EUR', name: user.translate('eur') },
    {
      val: 'USD',
      name: user.translate('usd'),
    },
  ]

  const propsValue = props.name && props.value && typeof props.value == 'object' ? props.value[props.name] : props.value
  const value = ifNull(propsValue, callOrGet(props.default, props.item))

  const option = options.find((opts) => opts.val == value)

  return <div style={{ display: 'inline-block' }}>{option?.name}</div>
}

SelectCurrency.render = (props: FormFieldProps, rowValue: string, row: any) => {
  return <Render {...props} value={rowValue} rowData={row} />
}

export default SelectCurrency
