import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import {
  EditText,
  EntityAutoComplete,
  FAIconField,
  FilterField,
  FormCreators,
  FormSwitch,
  GqlDetailPanel,
  GqlTable,
  HiddenField,
  NestedItemForm,
} from '@zeroapi-io/zeroapireact'
import * as React from 'react'
import { useParams } from 'react-router'
import PageContainer from '../../../common/PageContainer'
import EntityAutoCompleteLegacy from '../../../form/EntityAutoComplete'
import FilterFieldLegacy from '../../../form/FilterField'
import FormCreatorsLegacy from '../../../form/FormCreators'
import { HiddenField as HiddenFieldLegacy } from '../../../form/HiddenField'
import SelectOption from '../../../form/SelectOption'
import GqlTableLegacy from '../../../table/GqlTable'
import { UserContext } from '../../UserApp'
import useSimpleEntityIconItem from '../../hooks/useSimpleEntityIconItem'

export default function SaleTypePage() {
  const user = React.useContext(UserContext)

  const { itemId } = useParams()

  const { loading, name: saleTypeName, icon: classIcon } = useSimpleEntityIconItem('SaleType', 'id', itemId)

  const filterByClass = (row: any, parent: any) => {
    return parent?.serviceClassId ? { by: 'classId', eqStr: parent.serviceClassId as string } : undefined
  }

  const title = loading ? (
    <>...</>
  ) : (
    <>
      {saleTypeName}&nbsp; <FontAwesomeIcon icon={classIcon as IconName} size="sm" color="#666" />
    </>
  )

  const roles = {
    NONE: user.translate('role_none'),
    ASSOCIATE: user.translate('role_associate'),
    EMPLOYEE: user.translate('role_employee'),
    MANAGER: user.translate('role_manager'),
    OWNER: user.translate('role_owner'),
  }

  const requireProviderOptions = React.useMemo(
    () => ({
      MANDATORY: user.translate('require_provider_mandatory'),
      OPTIONAL: user.translate('require_provider_optional'),
      NULL: user.translate('require_provider_null'),
    }),
    [user],
  )

  return (
    <PageContainer visible={Boolean(itemId)} animate="slide">
      <Box sx={{ p: 1 }}>
        <GqlTableLegacy entity="SaleTypeService" title={title} hasBack noPaging noSearch noExport>
          <HiddenFieldLegacy name="id" gql="String" id />
          <FilterFieldLegacy name="saleTypeId" gql="String" value={itemId} />
          <EntityAutoCompleteLegacy
            name="serviceClassId"
            label="service_class"
            entity="ServiceClass"
            nullLabel={user.translate('all')}
            nullable
          />
          <EntityAutoCompleteLegacy
            name="serviceTypeId"
            filter={filterByClass}
            entity="ServiceType"
            nullLabel={user.translate('all')}
            nullable
          />
        </GqlTableLegacy>

        <br />
        <GqlTableLegacy entity="SaleTypeEmployee" title="employees" noPaging noSearch noExport>
          <HiddenFieldLegacy name="id" gql="String" id />
          <FilterFieldLegacy name="saleTypeId" gql="String" value={itemId} />

          <SelectOption name="role" label="role" gql="String" options={roles} default="" />
          <EntityAutoCompleteLegacy name="employeeId" entity="OrganisationEmployee" pk="employeeId" nullable fullWidth>
            <HiddenFieldLegacy name="employee" gql="User" gqlSelection={['profile', 'name']} isName />
            <HiddenFieldLegacy name="role" gql="String" />
          </EntityAutoCompleteLegacy>

          <FormCreatorsLegacy name="id" param={user} width={1} />
        </GqlTableLegacy>

        <br />
        <GqlTable entity="SaleTypeTemplate" title="templates" noPaging noSearch noExport>
          <HiddenField name="id" gql="String" id />
          <FilterField name="saleTypeId" gql="String" value={itemId} />

          <NestedItemForm name="name" gql="Translation">
            <EditText name={user.lang} label="name" gql="String" default="" width={24} />
            <HiddenField name={user.secLang} value={(row: any) => (row ? row[user.lang] ?? row[`name_${user.lang}`] : '')} />
          </NestedItemForm>

          <NestedItemForm name="providerFieldTitle" gql="Translation">
            <EditText name={user.lang} label="providerFieldTitle" gql="String" default="" width={24} />
            <HiddenField name={user.secLang} value={(row: any) => (row ? row[user.lang] ?? row[`providerFieldTitle_${user.lang}`] : '')} />
          </NestedItemForm>
          <EntityAutoCompleteLegacy name="serviceClassId" label="service_class" entity="ServiceClass" />
          <FAIconField name="icon" label="icon" gql="String" default={'shopping-cart'} color="#66A" width={1} />

          <SelectOption name="requireProvider" gql="String" options={requireProviderOptions} default="MANDATORY" />
          <FormSwitch name="singleCustomer" gql="Long" default={0} />

          <FormCreators name="id" userId={user.userId} width={1} />

          <GqlDetailPanel>
            <GqlTable title="services" entity="SaleTypeTemplateService" dense="dense" noSearch noPaging>
              <HiddenField name="id" id />
              <FilterField name="templateId" valueFromParent="id" />
              <EntityAutoComplete
                name="serviceTypeId"
                filter={filterByClass}
                entity="ServiceType"
                nullLabel={user.translate('all')}
                nullable
              />
              <FormSwitch name="mandatory" label="mandatory" gql="Long" default={1} />
            </GqlTable>
          </GqlDetailPanel>
        </GqlTable>
      </Box>
    </PageContainer>
  )
}
