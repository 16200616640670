import { Theme } from "@mui/material/styles"
import { createStyles, makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ["& > *"]: {
        ["&:first-child"]: {
          position: "absolute",
          opacity: 0.5,
          transform: "scale(0.8)",
          transformOrigin: "left top",
        },
        ["&:last-child"]: {
          opacity: 1,
          transform: "scale(0.7)",
          transformOrigin: "right bottom",
        },
      },
    },
  })
)
interface Props {
  children: JSX.Element[]
}
export default ({ children }: Props) => {
  const classes = useStyles()
  return <div className={classes.root}>{children}</div>
}
