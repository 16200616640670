import { ArrowRightAlt } from "@mui/icons-material";
import * as React from "react";
import { useContext } from "react";
import { UserContext } from "../../../../../UserApp";
import { SaleService, ServicePrice } from "../../../Types";

interface Props {
  option: ServicePrice,
  saleService: SaleService,
  isSelected: boolean
}

export default function ProviderOptionLocation({ option, saleService, isSelected }: Props) {
  const user = useContext(UserContext)

  if (saleService.class.location === "SINGLE") {
    return <div>{option.startPoint?.name[user.lang]}</div>
  } else if (saleService.class.location === "FROM_TO") {
    return (<>
      <div style={{ maxWidth: 160, paddingRight: 8, flex: 1, textAlign: "left" }}>
        {option.startPoint?.name[user.lang] || "/"}
      </div>
      <ArrowRightAlt fontSize="small" color={isSelected ? "info" : "action"}/>
      <div style={{ maxWidth: 160, flex: 1, textAlign: "right" }}>{option.endPoint?.name[user.lang] || "-"}</div>
    </>)
  } else {
    return null
  }
}