import * as React from "react";
import { useContext } from "react";
import { SaleService, ServicePrice, StringMap } from "../../../Types";
import moment from "moment";
import { SaleCustomer } from "../../../Models";
import {
  CustomerService,
  SaleCustomerServices,
} from "../ServicePriceChooserDialog";
import { useEntityContextReadOnlyQuery } from "../../../../../../gql/useEntityQuery";
import { UserContext } from "../../../../../UserApp";
import { GqlFilter } from "../../../../../../gql/interface";

export default function useServicePricesByDate(
  classId?: string,
  dateFrom?: string,
  dateTo?: string,
  startPoint?: string,
  endPoint?: string
) {
  const user = useContext(UserContext);
  const gqlNameId = { id: true, name: { [user.lang]: true } };

  const fields = [
    { name: "id", gql: "String" },
    { name: "provider", gql: "Subject", subSelection: gqlNameId },
    {
      name: "type",
      gql: "ServiceType",
      subSelection: {
        ...gqlNameId,
        capacity: true,
        icon: true,
        parentType: { ...gqlNameId, icon: true },
      },
    },
    { name: "startPoint", gql: "Location", subSelection: gqlNameId },
    {
      name: "endPoint",
      gql: "Location",
      subSelection: gqlNameId,
    },
    { name: "dateFrom", gql: "String" },
    { name: "dateTo", gql: "String" },
    {
      name: "currency",
      gql: "String",
    },
    { name: "cost", gql: "String" },
    { name: "price", gql: "String" },
    {
      name: "minQuantity",
      gql: "Long",
    },
    { name: "capTourists", gql: "Long" },
    { name: "capGroups", gql: "Long" },
  ];

  let filter: GqlFilter | undefined = undefined;
  const addFilter = (filterOptions: GqlFilter) => {
    if (filter) {
      filterOptions.and = filter;
    }
    filter = filterOptions;
  };

  if (endPoint) addFilter({ by: "endPointId", eqStr: endPoint });
  if (startPoint) addFilter({ by: "startPointId", eqStr: startPoint });
  if (dateTo)
    addFilter({ by: "dateTo", gte: new Date(dateTo || "").getTime() });
  if (dateFrom)
    addFilter({ by: "dateFrom", lte: new Date(dateFrom || "").getTime() });

  addFilter({ by: "classId", eqStr: classId });

  const query = useEntityContextReadOnlyQuery<ServicePrice>(
    "ServicePrice",
    fields,
    { skip: classId === undefined, filter: filter }
  );

  return {
    servicePrices: (query.items || []) as ServicePrice[],
    refreshProviderOffers: query.refresh,
  };
}

export function useGroupServicePricesByProvider(offers: ServicePrice[]) {
  return React.useMemo(
    () => offers.groupBy((item) => item.provider?.id ?? ""),
    [offers]
  );
}

export interface ServiceBooking {
  customer: SaleCustomer;
  service: CustomerService;
}

export interface ProviderOptions {
  providerId: string;
  provider: string;
  offers: ServicePrice[];
  options: { [key: number]: ServicePrice[] };
  bookings: { [key: number]: ServiceBooking[] };
}

export function useProviderServiceOptions(
  saleService: SaleService | undefined,
  customers: SaleCustomerServices[],
  providerOffers: StringMap<ServicePrice[]>
) {
  const user = useContext(UserContext);

  return React.useMemo<ProviderOptions[]>(() => {
    if (!saleService) return [];
    const momDateTo = moment(saleService.dateTo);
    const momDateFrom = moment(saleService.dateFrom);

    return Object.values(providerOffers).map((offers) => {
      const provider = offers[0].provider;

      const options: { [key: number]: ServicePrice[] } = {};

      offers.forEach((offer) => {
        let offerStart = moment(offer.dateFrom);
        if (offerStart.isBefore(momDateFrom)) {
          offerStart = momDateFrom.clone();
        }
        let offerEnd = moment(offer.dateTo);
        if (offerEnd.isAfter(momDateTo)) {
          offerEnd = momDateTo.clone();
        }

        for (
          let m = offerStart.clone();
          m.diff(offerEnd, "days") <= 0;
          m.add(1, "days")
        ) {
          const dayIndex = m.diff(momDateFrom, "days");

          const dayOption = options[dayIndex];
          if (dayOption) {
            dayOption.push(offer);
          } else {
            options[dayIndex] = [offer];
          }
        }
      });

      const bookings: { [key: number]: ServiceBooking[] } = {};

      const customerBookedServices =
        customers?.flatMap((it) => {
          return it.services
            .filter((it) => it.providerId === provider?.id)
            .map((service) => ({
              service: service,
              customer: it.saleCustomer,
            }));
        }) ?? [];

      for (
        const m = momDateFrom.clone();
        m.diff(momDateTo, "days") <= 0;
        m.add(1, "days")
      ) {
        const dayIndex = m.diff(momDateFrom, "days");

        bookings[dayIndex] = customerBookedServices.filter((it) => {
          return (
            m.isSameOrAfter(moment(it.service.dateFrom), "date") &&
            m.isSameOrBefore(moment(it.service.dateTo), "date")
          );
        });
      }

      return {
        providerId: provider?.id ?? "",
        provider: provider ? provider.name[user.lang] : "-",
        offers,
        options,
        bookings,
      };
    });
  }, [saleService, providerOffers, customers, user.lang]);
}
