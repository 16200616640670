import { Button, Link, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import * as React from 'react'
import FadeIn from 'react-fade-in'
import { UserContext } from './UserApp'
import { useCustomQuery } from '../gql/useCustomQuery'
import Show from '../common/Show'
import Moment from 'react-moment'
import { useDynamicGqlMutation } from '../gql/useDynamicGql'
import { useNavigate } from 'react-router'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  }),
)

interface NavigationProps {
  companies: { organisationId: string; organisation: { name: { mk: string } } }[]
  refresh: () => Promise<any>
}

export default function Home(props: NavigationProps) {
  const classes = useStyles()

  const user = React.useContext(UserContext)

  const navigate = useNavigate()

  const { items: inviteItems } = useCustomQuery('auth_getUserInvites', [
    { name: 'code', gql: 'String' },
    { name: 'organisationId', gql: 'String' },
    {
      name: 'organisationName',
      gql: 'String',
    },
    { name: 'role', gql: 'String' },
    { name: 'created', gql: 'Long' },
    { name: 'valid', gql: 'Long' },
  ])
  const invites = inviteItems ?? []

  const [acceptInvite] = useDynamicGqlMutation('auth_acceptInvite', { $code: 'String!' }, { ok: true, organisationId: true })

  const onAcceptInvite = (invite: any) => () => {
    acceptInvite({
      variables: {
        code: invite.code,
      },
    }).then(
      ({
        data: {
          auth_acceptInvite: { ok, organisationId },
        },
      }) => {
        if (!ok) {
          return alert('Системска грешка.')
        }

        props.refresh().finally(() => {
          if (user.profile.name && user.profile.name.length > 0) {
            navigate(`/o/${organisationId}`)
          } else {
            navigate(`/o/${organisationId}/profile`)
          }
        })
      },
    )
  }

  return (
    <Container component="main" maxWidth="xs" className={classes.root}>
      <CssBaseline />
      <Show if={props.companies.length === 0}>
        <FadeIn>
          <div style={{ padding: 16 }}>
            {user
              .translate('info_no_companies')
              .split('\n')
              .map((line: string) => (
                <div>{line}</div>
              ))}
          </div>
        </FadeIn>
      </Show>
      <Show if={invites.length > 0}>
        <FadeIn>
          <div>
            <br />
            <Typography variant={'h6'}>{user.translate('invites')}:</Typography>
            <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {invites.map((item) => (
                <Button variant={'contained'} size={'large'} sx={{ width: 240 }} onClick={onAcceptInvite(item)}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                      {item.organisationName} &nbsp;{' '}
                      <span style={{ fontVariant: 'all-small-caps' }}>({user.translate(`role_${item.role}`.toLowerCase())})</span>
                    </div>
                    <div style={{ fontVariant: 'all-small-caps', fontWeight: 'normal', fontSize: 14 }}>
                      {user.translate('valid_to')}: <Moment format="D MMM YYYY" date={item.valid} />
                    </div>
                  </div>
                </Button>
              ))}
            </Box>
          </div>
        </FadeIn>
      </Show>
      <Show if={props.companies.length > 0}>
        <FadeIn>
          <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {props.companies.map((item) => (
              <div>
                <Link href={`/o/${item.organisationId}`}>{item.organisation.name.mk}</Link>
              </div>
            ))}
          </Box>
        </FadeIn>
      </Show>
    </Container>
  )
}
