import { gql, useMutation } from '@apollo/client'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router'
import { UserContext } from '../app/UserApp'
import AuthScreen from './AuthScreen'

const GQL_RESET = gql`
  mutation ($codeWithVerifier: String!, $password: String!) {
    auth_resetPassword(codeWithVerifier: $codeWithVerifier, password: $password) {
      ok
      message
      profile {
        id
        name
      }
    }
  }
`

export default function ResetPassword() {
  const user = React.useContext(UserContext)

  const navigate = useNavigate()

  const { codeWithVerifier } = useParams()

  const [resetPassword, { loading, data }] = useMutation(GQL_RESET)
  const response = data?.auth_resetPassword

  const onSubmit = (data: FormData) => {
    resetPassword({
      variables: {
        codeWithVerifier: codeWithVerifier,
        password: data.get('password'),
      },
    }).then(({ data }) => {
      const response = data.auth_resetPassword

      if (response.ok) {
        const redirect = new URL(window.location.href).searchParams.get('redirect')
        setTimeout(() => {
          navigate(redirect ? '/login?redirect=' + encodeURIComponent(redirect) : '/login')
        }, 500)
      }
    })
  }

  return (
    <AuthScreen
      title={user.translate('title_reset_password')}
      actionTitle={user.translate('button_reset_password')}
      onSubmit={onSubmit}
      error={response?.ok === false && response?.message}
      info={response?.ok === true && response?.message}
      isLoading={loading || response?.ok === true}
      fields={[{ name: 'password', label: user.translate('label_password'), type: 'password' }]}
      actions={
        response?.ok !== true
          ? [
              { name: 'login', label: user.translate('button_sign_in_back') },
              {
                name: 'register',
                label: user.translate('button_sign_up_back'),
              },
            ]
          : [{ name: 'login', label: user.translate('button_continue_to_login') }]
      }
    />
  )
}
