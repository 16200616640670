import { ArrowBack, Cancel, Check, CheckCircle, CreditScore, DoneAll, Edit, Send } from '@mui/icons-material'
import { CircularProgress, Divider, IconButton, Paper } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import { Box } from '@mui/system'
import * as React from 'react'
import { Route, Routes, useNavigate, useParams } from 'react-router'
import useConfirmDialog from '../../../../common/ConfirmDialog'
import PageContainer from '../../../../common/PageContainer'
import Show from '../../../../common/Show'
import { GqlFetchQuery } from '../../../../gql/interface'
import { useDynamicGqlMutation } from '../../../../gql/useDynamicGql'
import { UserContext } from '../../../UserApp'
import { IconNameEntity } from '../../../hooks/useIconNameEntityItems'
import { SaleCustomer } from '../Models'
import { SaleService } from '../Types'
import SaleForm, { Sale } from './SaleForm'
import SaleServiceTable from './SaleServiceTable'
import SaleCustomersTable from './customers/SaleCustomersTable'
import ServicePriceChooserDialog from './services/ServicePriceChooserDialog'
import { ServiceSalesByTemplate } from './template/ServiceSalesByTemplate'
import ServiceSalesSummary from './template/ServiceSalesSummary'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      boxShadow: '0px 0px 4px',
      transition: 'background 0.5s ease',
    },
    articlesTitle: {
      textAlign: 'left',
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      marginBottom: theme.spacing(-1),
      fontSize: 18,
      color: '#454',
    },
  }),
)

interface Props {
  saleType?: IconNameEntity
}

const saturation = '50%'
const luminance = '82%'
const backgroundColors: { [key: string]: string | undefined } = {
  DRAFT: `hsl(60deg ${saturation} ${luminance})`,
  CREATED: `hsl(120deg ${saturation} ${luminance})`,
  SENT: `hsl(160deg ${saturation} ${luminance})`,
  APPROVED: `hsl(190deg ${saturation} ${luminance})`,
  FINISHED: `hsl(240deg ${saturation} ${luminance})`,
  PAYED: `#FFF`,
  CANCELED: `#e5d8d8`,
  REJECTED: `hsl(0deg ${saturation} ${luminance})`,
}

export default function SalePage({ saleType }: Props) {
  const classes = useStyles()

  const navigate = useNavigate()
  const { itemId: saleId } = useParams()

  const saleIdVal = saleId !== 'import' ? saleId : undefined

  const user = React.useContext(UserContext)

  const [customers, setCustomers] = React.useState<SaleCustomer[]>([])
  const [sale, setSale] = React.useState<Sale>()
  const [showPriceChooser, setShowPriceChooser] = React.useState<string>()

  const saleQueryRef = React.useRef<GqlFetchQuery<any>>()
  const saleServicesRef = React.useRef<SaleService[]>([])

  const confirmDialog = useConfirmDialog()

  const [changeStateQuery, { loading: isChangeStateLoading }] = useDynamicGqlMutation(
    'editSale',
    {
      input: {
        $id: 'String!',
        $state: 'String!',
      },
    },
    { state: true },
  )

  const changeState = (state: string) => {
    changeStateQuery({ variables: { id: saleIdVal, state: state } }).then(saleQueryRef.current?.refresh)
  }

  const onCreate = () => {}

  const ActionButton = ({ state, style }: { state?: any; style: React.CSSProperties }) => {
    if (sale?.state === 'DRAFT') {
      return (
        <IconButton style={style} onClick={() => changeState('CREATED')}>
          <Check />
        </IconButton>
      )
    }
    if (sale?.state === 'CREATED') {
      return (
        <IconButton style={style} onClick={() => changeState('SENT')}>
          <Send />
        </IconButton>
      )
    }
    if (sale?.state === 'SENT') {
      return (
        <IconButton style={style} onClick={() => changeState('APPROVED')}>
          <DoneAll />
        </IconButton>
      )
    }
    if (sale?.state === 'APPROVED') {
      return (
        <IconButton style={style} onClick={() => changeState('FINISHED')}>
          <CheckCircle />
        </IconButton>
      )
    }
    if (sale?.state === 'FINISHED') {
      return (
        <IconButton style={style} onClick={() => changeState('PAYED')}>
          <CreditScore />
        </IconButton>
      )
    }
    if (sale?.state === 'PAYED') {
      return (
        <IconButton style={style} onClick={() => changeState('REJECTED')}>
          <Cancel />
        </IconButton>
      )
    }
    if (sale?.state === 'REJECTED') {
      return (
        <IconButton style={style} onClick={() => changeState('DRAFT')}>
          <Edit />
        </IconButton>
      )
    }

    return null
  }

  const isStateLoading = saleQueryRef.current?.isLoading || isChangeStateLoading

  return (
    <PageContainer visible={Boolean(saleIdVal)} animate="slide" padding={0}>
      {confirmDialog.render()}

      <Box
        className={classes.root}
        style={{ background: sale?.state ? backgroundColors[sale.state as string] || '#d8d8e5' : undefined }}
        sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}
      >
        <IconButton onClick={() => navigate('./../')}>
          <ArrowBack />
        </IconButton>

        <div>
          <Show if={isStateLoading}>
            <CircularProgress size={24} style={{ position: 'absolute', margin: 8, zIndex: 9 }} />
          </Show>
          <ActionButton state={sale?.state} style={{ opacity: isStateLoading ? 0.5 : 1 }} />
        </div>
      </Box>

      <Box sx={{ p: 1 }}>
        <Box sx={{ p: 1 }}>
          <Paper elevation={4} sx={{ p: 1 }}>
            <SaleForm onCreate={onCreate} setSale={setSale} queryRef={saleQueryRef} />
          </Paper>
        </Box>

        <Divider sx={{ mt: 1 }} />

        <Show if={Boolean(saleId) && saleType?.simple === 0}>
          <Box sx={{ p: 1 }}>
            <SaleCustomersTable saleId={saleId} customers={customers} setCustomers={setCustomers} />
            <br />
            <SaleServiceTable
              sale={sale}
              saleType={saleType}
              customers={customers}
              itemsRef={saleServicesRef}
              onItemClick={(saleServiceId) => navigate(`./service/${saleServiceId}`)}
            />
          </Box>
        </Show>
        <Show if={Boolean(saleId) && saleType?.simple === 1}>
          <ServiceSalesByTemplate sale={sale} saleType={saleType} />
        </Show>
      </Box>

      <Routes>
        <Route
          path="service/:saleServiceId"
          element={<ServicePriceChooserDialogWrapper saleServices={saleServicesRef.current} customers={customers} />}
        />
      </Routes>
    </PageContainer>
  )
}

interface ServicePriceChooserProps {
  saleServices?: SaleService[]
  customers?: SaleCustomer[]
}

const ServicePriceChooserDialogWrapper = ({ saleServices, customers }: ServicePriceChooserProps) => {
  const { itemId: saleId, saleServiceId } = useParams()
  const navigate = useNavigate()

  if (!saleServices || !customers || !saleServiceId) return null

  const saleService = saleServices?.find((service) => service.id === saleServiceId)

  return (
    <ServicePriceChooserDialog saleId={saleId} saleService={saleService} saleCustomers={customers} onClose={() => navigate('./../../')} />
  )
}
