import { Box, Typography } from "@mui/material"
import { Theme } from "@mui/material/styles"
import { createStyles, makeStyles } from "@mui/styles"
import * as React from "react"
import { useNavigate, useParams } from "react-router"
import useConfirmDialog from "../../../common/ConfirmDialog"
import PageContainer from "../../../common/PageContainer"
import EditDateRange from "../../../form/EditDateRange"
import EditText from "../../../form/EditText"
import EditTextFloat from "../../../form/EditTextFloat"
import EditTextInt from "../../../form/EditTextInt"
import EntityAutoComplete from "../../../form/EntityAutoComplete"
import { HiddenField } from "../../../form/HiddenField"
import FilterField from "../../../form/FilterField"
import NestedItemForm from "../../../form/NestedItemForm"
import SelectCurrency from "../../../form/SelectCurrency"
import GqlDetailPanel from "../../../table/GqlDetailPanel"
import GqlTable from "../../../table/GqlTable"
import CreateLocation from "../../common/CreateLocation"
import { IconNameEntity } from "../../hooks/useIconNameEntityItems"
import { ZeroApiContext } from "../../Organisation"
import { UserContext } from "../../UserApp"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    list: {
      ["&:first-child"]: {
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
      },
      ["&:last-child"]: {
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
      },
    },
    item: {
      width: "unset",
      background: "#FFF",
      borderRadius: 2,
      boxShadow: "#AAA 1px 2px 4px",
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: 0,
    },
  })
)

interface Props {
  group?: IconNameEntity
}

export default ({ group }: Props) => {
  const classes = useStyles()

  const navigate = useNavigate()
  const { itemId } = useParams()

  const user = React.useContext(UserContext)
  const organisation = React.useContext(ZeroApiContext)

  const confirmDialog = useConfirmDialog()

  if (!group) return null
  const groupId = group.id

  const onItemClick = (e: any, row: any) => {
    navigate(`./${row.id}`)
  }

  const detailTitle = (
    <Typography variant="h6" color="#777">
      {user.translate("prices")}
    </Typography>
  )
  const detailStyle: React.CSSProperties = {
    borderRadius: 0,
    boxShadow: "none",
    background: "none",
  }

  return (
    <PageContainer visible={!Boolean(itemId)} animate="fade">
      {confirmDialog.render()}

      <Box sx={{ p: 1 }}>
        <GqlTable title={group.name} entity="TourArrangement" onItemClick={onItemClick} dense>
          <HiddenField name="id" id />
          <HiddenField name="validTo" gql="Long" />
          <FilterField name="groupId" value={groupId} />

          <NestedItemForm name="name" gql="Translation">
            <EditText name={user.lang} label="name" gql="String" />
            <HiddenField name={user.secLang} value={(row: any) => (row ? row[user.lang] ?? row[`name_${user.lang}`] : '')} />
          </NestedItemForm>

          <EditDateRange name="validFrom" nameTo="validTo" label="date_range" align="center" />

          <EntityAutoComplete
            name="startPointId"
            group="location"
            entity="Location"
            freeSolo={CreateLocation}
            nullable
          />
          <EntityAutoComplete name="endPointId" group="location" entity="Location" nullable freeSolo={CreateLocation} />

          <SelectCurrency name="currency" group="price" default={"EUR"} />
          <EditTextFloat name="price" label="sellPrice" group="price" default={0} />
          <EditText name="groupNamePattern" default="yyyy-mmdd-hhmm" align="center" />

          <GqlDetailPanel>
            <GqlTable title={detailTitle} entity="TourArrangementPrice" style={detailStyle} dense noSearch noPaging>
              <HiddenField name="id" id />
              <FilterField name="arrangementId" valueFromParent="id" />

              <HiddenField name="validTo" gql="Long" />
              <EditDateRange name="validFrom" nameTo="validTo" label="date_range" />
              <EditTextFloat name="price" default={0} />

              <EditTextInt name="minOrder" default={1} />
              <EditTextInt name="maxOrder" default={1} />

              <EditText name="comment" default="" width={44} />
            </GqlTable>
          </GqlDetailPanel>
        </GqlTable>
      </Box>
    </PageContainer>
  )
}
