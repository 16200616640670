import { Box } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import * as React from 'react'
import { useParams } from 'react-router'
import useConfirmDialog from '../../../common/ConfirmDialog'
import PageContainer from '../../../common/PageContainer'
import EditTextInt from '../../../form/EditTextInt'
import EditTime from '../../../form/EditTime'
import EntityAutoComplete from '../../../form/EntityAutoComplete'
import { HiddenField } from '../../../form/HiddenField'
import FilterField from '../../../form/FilterField'
import SelectOption from '../../../form/SelectOption'
import GqlTable from '../../../table/GqlTable'
import CreateLocation from '../../common/CreateLocation'
import { IconNameEntity } from '../../hooks/useIconNameEntityItems'
import useSimpleEntityItem from '../../hooks/useSimpleEntityItem'
import { UserContext } from '../../UserApp'

const useStyles = makeStyles((theme: Theme) => createStyles({}))

interface Props {
  group?: IconNameEntity
}

export default function TourArrangement({ group }: Props) {
  const classes = useStyles()

  const { itemId: arrangementId } = useParams()

  const user = React.useContext(UserContext)

  const confirmDialog = useConfirmDialog()

  const TourGrouping = {
    RANDOM_SPLIT: user.translate('random_split'),
  }

  const byHasParent = { by: 'parentTypeId', neq: null }
  const byType = (row: any) => (row && row.typeId ? { by: 'parentTypeId', eqStr: row.typeId as string } : byHasParent)

  const rootTypesOnly = { by: 'parentTypeId', eqStr: null }

  const { loading: arrLoading, name: arrangementName } = useSimpleEntityItem('TourArrangement', 'id', arrangementId)

  const parentTitle = group?.name || '...'
  const title = arrLoading ? '...' : arrangementName || '...'

  return (
    <PageContainer visible={Boolean(arrangementId)} animate="slide">
      {confirmDialog.render()}

      <Box sx={{ p: 1 }}>
        <GqlTable title={title} parentTitle={parentTitle} entity="TourArrangementService" dense hasBack>
          <HiddenField name="id" id />
          <FilterField name="arrangementId" value={arrangementId} />

          <EditTextInt name="dayFrom" label="day" group="start" default={1} width={2} />
          <EditTime name="localTimeFrom" label="time" group="start" default={100000} />

          <EditTextInt name="dayTo" label="day" group="end" gql="Long" default={2} />
          <EditTime name="localTimeTo" label="time" group="end" gql="Long" default={120000} />

          <EntityAutoComplete name="startPointId" group="location" entity="Location" freeSolo={CreateLocation}>
            <HiddenField name="city" gql="City" gqlSelection="timezone" />
          </EntityAutoComplete>
          <EntityAutoComplete name="endPointId" group="location" entity="Location" freeSolo={CreateLocation} nullable>
            <HiddenField name="city" gql="City" gqlSelection="timezone" />
          </EntityAutoComplete>

          <EntityAutoComplete name="classId" label="service_class" entity="ServiceClass" />
          <EntityAutoComplete name="typeId" label="service_type" entity="ServiceType" filter={rootTypesOnly} />
          {/* <EntityAutoComplete name="subTypeId" label="sub_type" entity="ServiceType" filter={byType} /> */}

          <EntityAutoComplete name="providerId" label="komitent" entity="Subject" nullable />

          <SelectOption name="grouping" options={TourGrouping} />
          <EditTextInt name="groupSize" default={50} />
        </GqlTable>
      </Box>
    </PageContainer>
  )
}
