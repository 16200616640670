import moment, { Moment } from "moment";
import * as React from "react";
import { useContext } from "react";
import { SaleCustomerServices, SelectedServiceTime } from "../ServicePriceChooserDialog";
import { UserContext } from "../../../../../UserApp";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { Button } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: "inline-flex", alignItems: "baseline", fontSize: 14
  }, title: {
    fontSize: 12,
    width: 140
  }, booked: {
    width: 40, paddingRight: 8, textAlign: "right", fontSize: 12, color: "#777",
  }, providers: {
    display: "flex", paddingRight: 8, fontSize: 12, color: "#777"
  }, provider: {
    // padding: theme.spacing(0.5)
    fontSize: 12
  }
}))

interface Props {
  name: string
  customers?: SaleCustomerServices[]
  startDate: Moment
  endDate: Moment
  onSelectServiceTime: (data: SelectedServiceTime) => void
}

export default function SaleServiceGroupRow({ name, customers, startDate, endDate, onSelectServiceTime }: Props) {
  const classes = useStyles()
  const user = useContext(UserContext)

  const daysCount = endDate.diff(startDate, "days", true) + 1

  const groupCustomers = customers?.filter((it) => it.saleCustomer.group === name) || []
  const reservationsByProvider = groupCustomers
    .flatMap((it) => it.services || [])
    .groupBy((it) => it.providerId)

  const reservationsByProviderDetails = Object.values(reservationsByProvider).map((providerReservations) => ({
    providerId: providerReservations[0].providerId,
    providerName: providerReservations[0].provider.name[user.lang],
    booked: providerReservations.sumOf((service) => moment(service.dateTo).diff(moment(service.dateFrom), "days", true) + 1),
    bookings: providerReservations
  }))
  const totalBooked = reservationsByProviderDetails.sumOf((it) => it.booked)

  return (<div className={classes.root}>
    <div className={classes.title}>
      {name} ({groupCustomers.length})
    </div>
    <div className={classes.booked}>
      {Math.floor((totalBooked * 100) / (groupCustomers.length * daysCount))}
      %
    </div>


    <div className={classes.providers}>
      {reservationsByProviderDetails.map((providerReservations) => <Button
        color={"success"}
        onClick={() => onSelectServiceTime({
          dayIndex: 0,
          startDate: providerReservations.bookings.minOf(it => moment(it.dateFrom)) ?? startDate,
          endDate: providerReservations.bookings.maxOf(it => moment(it.dateFrom)) ?? endDate,
          customers: groupCustomers.filter(it => it.services.any(it => it.providerId === providerReservations.providerId)),
          providerId: providerReservations.providerId
        })}
        key={providerReservations.providerId}
        className={classes.provider}>
        {providerReservations.providerName} ({providerReservations.booked})
      </Button>)}
    </div>
  </div>)

}