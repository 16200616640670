import {
  CompositeField,
  CurrencyAmountField,
  EditDate,
  EditText,
  EntityAutoComplete,
  FilterField,
  GqlQuery,
  GqlTable,
  HiddenField,
  SelectOption,
} from '@zeroapi-io/zeroapireact'
import moment from 'moment'
import * as React from 'react'
import { useParams } from 'react-router'
import { useEntityRelationReadOnlyQuery as useERROQuery } from '../../../../../gql/useEntityRelationReadOnlyQuery'
import { ZeroApiContext } from '../../../../Organisation'
import { UserContext } from '../../../../UserApp'
import { IconNameEntity } from '../../../../hooks/useIconNameEntityItems'
import { SaleService } from '../../Types'
import { Sale } from '../SaleForm'
import { Field } from '../../../../../gql/interface'
import { EntityIconItem } from '../../../../hooks/useSimpleEntityIconItems'
import { getProfitLoss, getProfitLossPercentage, getTotalOrAmount, isProfitLossSignificant } from '../lib'

interface SaleTypeService {
  saleTypeId: string
  serviceClassId?: string
  serviceTypeId?: string
}

interface Props {
  saleType?: IconNameEntity
  sale?: Sale
  template?: EntityIconItem
  onItemClick: (item: any) => void
  itemsRef?: React.MutableRefObject<SaleService[]>
  queryRef?: React.MutableRefObject<GqlQuery<any> | undefined>
}

export default function TemplateServiceSale({ sale, template, saleType, itemsRef, onItemClick, queryRef }: Props) {
  const { itemId: saleId } = useParams()

  const user = React.useContext(UserContext)
  const organisation = React.useContext(ZeroApiContext)

  const aggregated = (saleType as any | undefined)?.groupByCustomer ?? true

  const providerTitle = (template as any)?.providerFieldTitle?.[user.lang.toLocaleLowerCase()] ?? 'komitent'
  const requireProvider = ((template as any)?.requireProvider ?? 'MANDATORY') as 'MANDATORY' | 'OPTIONAL' | 'NULL'

  const saleTypeFields: Field<any>[] = [
    { name: 'id', gql: 'String' },
    { name: 'serviceClassId', gql: 'String' },
    {
      name: 'serviceTypeId',
      gql: 'String',
    },
  ]

  const saleTypeServices =
    useERROQuery<SaleTypeService>('SaleTypeService', saleTypeFields, 'saleTypeId', sale?.saleTypeId, {
      skip: !sale?.saleTypeId,
    }).items ?? []

  const allowedServices =
    saleTypeServices.length === 0 || saleTypeServices.any((it) => !it.serviceClassId)
      ? undefined
      : saleTypeServices.filter((it) => Boolean(it.serviceClassId)).map((it) => it.serviceClassId ?? '')

  const serviceClassGqlSelection = {
    name: { [user.lang]: true },
    location: true,
    time: true,
    uom: true,
  }

  const PayType = {
    CASH: user.translate('cash'),
    CARD: user.translate('card'),
    INVOICE: user.translate('invoice'),
  }

  const PayStatus = {
    NOT_PAYED: user.translate('not_payed'),
    PAYED: user.translate('payed'),
  }

  return (
    <GqlTable
      deps={[allowedServices, aggregated]}
      title={'services'}
      entity="SaleService"
      refItems={itemsRef}
      onItemClick={(e, row) => onItemClick?.(row)}
      dense="dense"
      noPaging
      noSearch
      noExport
      noTitle
      filterRows={(items) => items.filter((item) => item.templateId === template?.id)}
      queryRef={queryRef}
    >
      <HiddenField name="id" id />
      <HiddenField name="organisationId" value={organisation.id} />
      <FilterField name="saleId" value={saleId} />
      <HiddenField name="templateId" value={template?.id} />

      <HiddenField name="class" subSelection={serviceClassGqlSelection} readOnly />
      <HiddenField name="dateTo" value={new Date(sale?.activityDate || 0).getTime().toString()} />

      <EntityAutoComplete name="classId" label="service_class" entity="ServiceClass" filterOptions={allowedServices}>
        <HiddenField name="location" hidden />
        <HiddenField name="time" hidden />
        <HiddenField name="uom" hidden />
      </EntityAutoComplete>

      {/* <EntityAutoComplete name="typeId" label="service_type" entity="ServiceType" filter={typesByClassId} nullable /> */}

      <EntityAutoComplete name="providerId" label={providerTitle} entity="Subject" hidden={requireProvider === 'NULL'} disabled />

      <CompositeField name="time_place">
        <EditDate
          name="dateFrom"
          nameTo="dateTo"
          label="date_time"
          gql="String"
          default={sale?.activityDate as string | undefined}
          defaultTo={new Date(sale?.activityDate || 0)}
          minDate={new Date(sale?.activityDate || 0)}
          toMinDate={(row) =>
            moment(row.dateFrom || 0)
              .add(1, 'days')
              .toDate()
          }
          range={(row) => row?.class?.uom === 'DAY' || row?._classId?.uom === 'DAY'}
          date
          style={{ marginRight: 8 }}
          readOnly
        />
        <span style={{ paddingLeft: 4 }}>{'('}</span>
        <EntityAutoComplete name="locationId" label="location" entity="City" nullable />
        <span>{')'}</span>
      </CompositeField>

      <SelectOption name="payType" options={PayType} default={PayType.CASH} width={2} />
      <CompositeField name="total_deal">
        <HiddenField name="amount" />
        <CurrencyAmountField
          name="total"
          label={'total_deal'}
          value={getTotalOrAmount}
          default={(item) => item?.total || item?.amount}
          currency={sale?.currency ?? 'EUR'}
        />
      </CompositeField>
      <CurrencyAmountField name="invoiced" currency={sale?.currency ?? 'EUR'} />
      <CurrencyAmountField
        name="profitLoss"
        value={getProfitLoss}
        currency={sale?.currency ?? 'EUR'}
        cellStyle={(_value, item) => (getProfitLossPercentage(item) > 0 ? { color: 'red' } : {})}
        derived
      />

      <EditText name="comment" validate={(value, item) => (isProfitLossSignificant(item) && !value?.length ? 'Внеси појаснување' : true)} />

      {/* <SelectOption name="payStatus" options={PayStatus} default={PayStatus.NOT_PAYED} width={2} /> */}
    </GqlTable>
  )
}
