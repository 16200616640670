import { Badge, ListItem, ListItemIcon, ListItemText } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import React from "react"
import { Link } from "react-router-dom"
import useWindowSize from "../hooks/useWindowSize"
import { DrawerItem } from "./Navigation"
import Show from "./Show"
import useStyles from "./useStyles"

interface DrawerItemProps {
  item: DrawerItem
  selected: string | undefined
  drawerOpen: boolean
  basePath: string
}

export default ({ item, selected, drawerOpen, basePath }: DrawerItemProps) => {
  const classes = useStyles()

  const isMobile = useWindowSize().isCompact
  const isSelected = item.key === selected

  return (
    <Link to={`${basePath}/${item.key}`} key={item.key} style={{ textDecoration: "none" }}>
      <ListItem
        key={item.key}
        dense={isMobile || drawerOpen}
        button
        className={[classes.nested, classes.drawerItem].join(" ")}
        style={{
          paddingLeft: drawerOpen ? 32 : 24,
          height: 37,
          textDecoration: "none",
        }}
        selected={item.key === selected}
      >
        <Show if={item.icon}>
          <Tooltip title={item.title} placement="bottom" aria-label={item.title}>
            <ListItemIcon style={{ minWidth: 24, paddingRight: 12, color: isSelected ? "#669df6" : "#ffffffcc" }}>
              <Badge badgeContent={item.notifications} color="error">
                {item.icon}
              </Badge>
            </ListItemIcon>
          </Tooltip>
        </Show>
        {/* <Show if={!item.icon && !drawerOpen}>
          <Tooltip title={item.title} placement="bottom" aria-label={item.title}>
            <ListItemIcon style={{ minWidth: 24, paddingRight: 12, color: isSelected ? "#669df6" : "#ffffffcc" }}>
              <Badge badgeContent={item.notifications} color="error">
                <span style={{ width: 24, overflow: "hidden", textOverflow: "clip" }}>{item.title}</span>
              </Badge>
            </ListItemIcon>
          </Tooltip>
        </Show> */}
        <ListItemText
          primary={item.title}
          secondary={item.description}
          primaryTypographyProps={{
            variant: item.children ? "button" : "subtitle2",
            style: {
              textDecoration: "none",
              color: isSelected ? "#669df6" : "#ffffffcc",
              overflow: "auto",
              textOverflow: "ellipsis",
              fontSize: item.children ? 16 : 14,
              opacity: drawerOpen || !item.icon ? 1 : 0,
              transitionDelay: drawerOpen ? "0.1s" : "0s",
              transition: "opacity 0.3s ease-out",
              fontWeight: isSelected ? 500 : 400,
            },
          }}
          secondaryTypographyProps={{
            variant: "subtitle1",
            style: {
              textDecoration: "none",
              lineHeight: 0.8,
              fontSize: 12,
              paddingBottom: 10,
              opacity: drawerOpen ? 1 : 0,
              transitionDelay: drawerOpen ? "0.1s" : "0s",
              transition: "opacity 0.3s ease-out",
            },
          }}
        />
      </ListItem>
    </Link>
  )
}
