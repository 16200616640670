import { IconName } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router'
import useConfirmDialog from '../../../common/ConfirmDialog'
import PageContainer from '../../../common/PageContainer'
import Show from '../../../common/Show'
import EditText from '../../../form/EditText'
import FAIconField from '../../../form/FAIconField'
import { HiddenField } from '../../../form/HiddenField'
import FormDialog from '../../../form/FormDialog'
import NestedItemForm from '../../../form/NestedItemForm'
import SelectOption from '../../../form/SelectOption'
import { useEntityContextQuery } from '../../../gql/useEntityQuery'
import { ZeroApiContext } from '../../Organisation'
import { UserContext } from '../../UserApp'
import FormSwitch from '../../../form/FormSwitch'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    list: {
      '&:first-child': {
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
      },
      '&:last-child': {
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
      },
    },
    item: {
      width: 'unset',
      background: '#FFF',
      borderRadius: 2,
      boxShadow: '#AAA 1px 2px 4px',
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: 0,
    },
  }),
)

interface ServiceClass {
  id: string
  icon: string
  name: { [key: string]: string }
  classServiceTypes: {
    name: { [key: string]: string }
  }[]
}

export const ServiceClassList: React.FC = () => {
  const classes = useStyles()

  const navigate = useNavigate()
  const { itemId } = useParams()

  const user = React.useContext(UserContext)
  const company = React.useContext(ZeroApiContext)

  const [addOrCreate, setAddOrCreate] = React.useState<boolean | string>(false)
  const confirmDialog = useConfirmDialog()

  const gqlLang = { [user.lang.toLocaleLowerCase()]: true }
  const query = useEntityContextQuery(
    'ServiceClass',
    [
      { name: 'id', gql: 'String!' },
      { name: 'organisationId', gql: 'String!' },
      {
        name: 'codeName',
        gql: 'String!',
      },
      { name: 'nameId', gql: 'String!' },
      { name: 'icon', gql: 'String!' },
      {
        name: 'name',
        gql: 'Translation!',
        subSelection: gqlLang,
        readOnly: true,
      },
      {
        name: 'classServiceTypes',
        gql: 'ServiceType',
        subSelection: { name: gqlLang, parentTypeServiceTypes: { name: gqlLang } },
        readOnly: true,
      },
    ],
    {
      pollInterval: 30000, // mode: "readOnly",
    },
  )

  const onEditClick = (item: ServiceClass) => () => {
    setAddOrCreate(item.id)
  }

  const onDeleteClick = (item: ServiceClass) => () => {
    confirmDialog.showConfirm('delete', 'service_class', () => query.deleteItem(item).then(query.refresh))
  }

  const ServiceLocationType = {
    NONE: user.translate('none'),
    SINGLE: user.translate('single'),
    FROM_TO: user.translate('from_to'),
  }

  const ServiceTimeType = {
    DAY: user.translate('day'),
    HOUR: user.translate('hour'),
  }

  const ServiceUnit = {
    DAY: user.translate('day'),
    PIECE: user.translate('piece'),
  }

  const items = query.items || []

  return (
    <PageContainer visible={!Boolean(itemId)} animate="fade">
      {confirmDialog.render()}
      <FormDialog entity="ServiceClass" item={addOrCreate} onClose={() => setAddOrCreate(false)} onRefresh={query.refresh} autoClose>
        <HiddenField name="organisationId" gql="String" value={company.id} />
        <EditText name="codeName" label="code_name" gql="String" default="" />
        <FAIconField name="icon" label="icon" />
        <NestedItemForm name="name" gql="Translation">
          <EditText name={user.lang} label="name" gql="String" default="" fullWidth />
          <HiddenField name={user.secLang} value={(row: any) => (row ? row[user.lang] ?? row[`name_${user.lang}`] : '')} />
        </NestedItemForm>
        <SelectOption name="location" options={ServiceLocationType} />
        <SelectOption name="time" options={ServiceTimeType} />
        <SelectOption name="uom" options={ServiceUnit} />
        <div style={{ display: 'block', width: '100%' }} />
        <FormSwitch name="pricePerProvider" />
        <FormSwitch name="pricePerProviderType" />
      </FormDialog>

      <Box sx={{ display: 'flex', flexDirection: 'row', m: 1 }}>
        <div style={{ flex: 1 }} />
        <Button variant="contained" onClick={() => setAddOrCreate(true)}>
          {user.translate('add_new')}
        </Button>
      </Box>

      <List>
        <Show if={items.length === 0}>
          <Typography>{user.translate('no_items')}</Typography>
        </Show>
        {items.map((item: ServiceClass, index: number) => (
          <ListItem key={item.id} className={classes.item}>
            <ListItemButton key={item.id} onClick={() => navigate(item.id)}>
              <ListItemIcon sx={{ ml: 1 }}>
                <FontAwesomeIcon icon={item.icon as IconName} size="lg" />
              </ListItemIcon>
              <ListItemText
                primary={item.name[user.lang]}
                secondary={item.classServiceTypes.joinOf(', ', (item) => item.name[user.lang])}
                primaryTypographyProps={{
                  color: 'primary',
                  style: { fontSize: 18, fontWeight: 500, letterSpacing: 0 },
                }}
                secondaryTypographyProps={{ style: { fontSize: 12, fontWeight: 400 } }}
              />
            </ListItemButton>
            <Button sx={{ width: 72, height: 72 }} onClick={onEditClick(item)}>
              <EditIcon color="action" />
            </Button>
            <Button sx={{ width: 72, height: 72 }} onClick={onDeleteClick(item)}>
              <DeleteIcon color="action" />
            </Button>
          </ListItem>
        ))}
      </List>
    </PageContainer>
  )
}
