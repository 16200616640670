import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router'
import useConfirmDialog from '../../../common/ConfirmDialog'
import PageContainer from '../../../common/PageContainer'
import EditText from '../../../form/EditText'
import FAIconField from '../../../form/FAIconField'
import { HiddenField } from '../../../form/HiddenField'
import FormSwitch from '../../../form/FormSwitch'
import NestedItemForm from '../../../form/NestedItemForm'
import GqlTable from '../../../table/GqlTable'
import { ZeroApiContext } from '../../Organisation'
import { UserContext } from '../../UserApp'

const useStyles = makeStyles((theme) => ({
  root: {},
}))

export default function TourArrGroupsList() {
  const classes = useStyles()

  const navigate = useNavigate()
  const { itemId } = useParams()

  const user = React.useContext(UserContext)
  const organisation = React.useContext(ZeroApiContext)

  const confirmDialog = useConfirmDialog()

  const onItemClick = (e: any, row: any) => {
    console.log(row)
    navigate(`./../arr_group_${row.codeName}`)
  }

  return (
    <PageContainer visible={!Boolean(itemId)} animate="fade">
      {confirmDialog.render()}

      <Box sx={{ p: 1 }}>
        <GqlTable entity="TourArrangementGroup" onItemClick={onItemClick} dense>
          <HiddenField name="organisationId" gql="String" value={organisation.id} />
          <EditText name="codeName" label="code_name" gql="String" default="" width={1} />
          <NestedItemForm name="name" gql="Translation">
            <EditText name={user.lang} label="name" gql="String" default="" />
            <HiddenField name={user.secLang} value={(row: any) => (row ? row[user.lang] ?? row[`name_${user.lang}`] : '')} />
          </NestedItemForm>
          <FAIconField name="icon" label="icon" default={'tree-palm'} width={1} />
          <FormSwitch name="active" label="active" gql="Long" default={true} />
        </GqlTable>
      </Box>
    </PageContainer>
  )
}
