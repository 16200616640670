import { Field, useEntityQuery } from '@zeroapi-io/zeroapireact'
import { useContext, useMemo } from 'react'
import { UserContext } from '../UserApp'

export interface IconItem {
  id: string
  icon: string
  name: { [key: string]: string }
}

const EMPTY = {} as IconItem

export const useEntityIconItem = (entity: string, relField: string, relValue?: string, selection: Field<any>[] | undefined = undefined) => {
  const user = useContext(UserContext)
  const fields = useMemo<Field<any>[]>(() => {
    return [
      { name: 'id', gql: 'String!' },
      { name: 'icon', gql: 'String!' },
      {
        name: 'name',
        gql: 'Translation!',
        subSelection: { [user.lang.toLocaleLowerCase()]: true },
      },
      ...(selection ?? []),
    ]
  }, [selection, user.lang])

  const query = useEntityQuery<IconItem & any>(entity, fields, relField, relValue, {
    skip: relValue === undefined,
    single: true,
  })

  if (query.isLoading) return { loading: true }

  const item = query.item ?? EMPTY

  return { loading: false, item: { ...item, name: item.name && item.name[user.lang] } }
}
