import React, { useState } from 'react'
import EditText from './EditText'
import { GqlTabletField } from '../table/GqlTable'
import { callOrGet } from './utils'

type FormFieldProps = GqlTabletField<number> & {
  minValue?: number | ((item: any) => number)
  maxValue?: number | ((item: any) => number)
  hint?: string
  type?: React.InputHTMLAttributes<unknown>['type']
}

const EditTextInt = (props: FormFieldProps) => {
  const [error, setError] = useState(false)

  const minValue = callOrGet(props.minValue, props.item ?? props.rowData)
  const maxValue = callOrGet(props.maxValue, props.item ?? props.rowData)

  const onChange = (value: any | null) => {
    if (value === null && !props.nullable) {
      setError(true)
      return true
    } else if (isNaN(value)) {
      setError(true)
      return true
    } else if (minValue && value < minValue) {
      setError(true)
      return true
    } else if (maxValue && value > maxValue) {
      setError(true)
      return true
    }
    if (error) {
      setError(false)
    }
    return props.onChange && props.onChange(value)
  }

  return <EditText {...props} error={error} onChange={onChange} intNumber />
}

EditTextInt.gql = 'Long'

EditTextInt.align = 'center'
EditTextInt.noPadding = true

// EditTextInt.cellStyle = { width: '10%'}

EditTextInt.validate = (props: FormFieldProps, value: number) => {
  if (props.minValue !== undefined && value < props.minValue) return true
  if (props.maxValue !== undefined && value > props.maxValue) return true
  return false
}

export default EditTextInt
