import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { UserContext } from "../app/UserApp";
import AuthScreen from "./AuthScreen";

const GQL_FORGOT = gql`
  mutation ($email: String!, $phone: String) {
    auth_requestResetPassword(email: $email, phone: $phone) {
      ok
      message
      verifier
      profile {
        id
        name
      }
    }
  }
`;

export default function Forgot() {
  const user = React.useContext(UserContext);

  const [requestResetPassword, { loading, data }] = useMutation(GQL_FORGOT);
  const response = data?.auth_requestResetPassword;

  const onSubmit = (data: FormData) => {
    requestResetPassword({
      variables: {
        email: data.get("email"),
      },
    });
  };

  return (
    <AuthScreen
      title={user.translate("title_request_reset_pass")}
      actionTitle={user.translate("button_request_reset_pass")}
      onSubmit={onSubmit}
      error={response?.ok === false && response?.message}
      info={response?.ok === true && response?.message}
      isLoading={loading || response?.ok === true}
      fields={[{ name: "email", label: user.translate("label_email_address") }]}
      actions={[
        { name: "login", label: user.translate("button_sign_in_back") },
        { name: "register", label: user.translate("button_sign_up_back") },
      ]}
    />
  );
}
