import { InputAdornment, TextField } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import React, { HTMLInputTypeAttribute, useContext } from 'react'
import { UserContext } from '../app/UserApp'
import { callOrGet, ifNull, parseDecimal, parseInteger } from './utils'
import { GqlTabletField } from '../table/GqlTable'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-grid',
    margin: theme.spacing(1),
    minWidth: '12ch',
    flexGrow: 1,
    '& > .MuiTextField-root': {
      width: '-webkit-fill-available',
    },
  },
}))

interface FormFieldProps extends GqlTabletField<any> {
  validate?: (value: any) => boolean
  hint?: string
  type?: HTMLInputTypeAttribute
  intNumber?: boolean
  decimalNumber?: boolean
  standard?: boolean
  endAdr?: string | JSX.Element
}

export default function EditText(props: FormFieldProps) {
  const classes = useStyles()
  const user = useContext(UserContext)

  const item = props.item || props.rowData;
  let value = ifNull(props.value, callOrGet(props.default, item))
  if (value === undefined) {
    if (props.valueFromParent && props.parentRowData) {
      value = callOrGet(props.valueFromParent, props.parentRowData)
      const item = props.rowData ?? props.item
      if (item) {
        item[props.name] = value
      }
    }
  }

  const onChange = props.onChange ? props.onChange : () => {}

  const inputStyles: any = { style: { fontSize: props.size !== 'small' ? 16 : 14 } }
  if (!props.isForm) {
    inputStyles.style.padding = 4
  }
  if (props.align) {
    inputStyles.style = { textAlign: props.align }
  }

  const styles: React.CSSProperties = { ...props.style }
  if (props.isForm) {
    styles.fontSize = 16
  } else {
    styles.fontSize = 14
    styles.margin = 0
  }

  return (
    <TextField
      className={classes.root}
      fullWidth={props.fullWidth}
      style={styles}
      type={props.type ?? (props.intNumber ? 'number' : 'text')}
      label={props.isForm ? props.label || user.translate(props.name) : undefined}
      variant={props.isForm && !props.standard ? 'outlined' : 'standard'}
      size={props.size !== 'small' ? 'medium' : 'small'}
      error={callOrGet(props.error, value)}
      aria-errormessage={'Invalid data'}
      disabled={callOrGet(props.disabled, value, item)}
      onSubmit={props.onSubmit}
      inputProps={inputStyles}
      InputProps={{
        endAdornment: props.endAdr ? <InputAdornment position="end">{props.endAdr}</InputAdornment> : undefined,
      }}
      onChange={(e) => {
        const valueStr = e.target.value
        if (valueStr.length === 0) return onChange(valueStr !== undefined ? valueStr : null)
        if (props.intNumber) return onChange(parseInteger(valueStr))
        if (props.decimalNumber) return onChange(parseDecimal(valueStr))
        return onChange(valueStr)
      }}
      value={value || ''}
      required={props.nullable !== true}
    />
  )
}
EditText.gql = 'String'
EditText.align = 'left'

EditText.cellStyle = {}

EditText.renderComposite = (props: any, value: any, row: any) => {
  return value
}
EditText.renderGroup = (props: any, value: any, row: any) => {
  return value
}
