import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Button, ButtonGroup, CircularProgress, IconButton, Tooltip } from '@mui/material'
import * as React from 'react'
import { useContext } from 'react'
import Show from '../../../../../../common/Show'
import { UserContext } from '../../../../../UserApp'
import { SaleService, ServicePrice } from '../../../Types'
import ProviderOptionLocation from './ProviderOptionLocation'
import ProviderOptionTime from './ProviderOptionTime'
import { ServiceBooking } from '../queries/useServicePricesByDate'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import Delete from '@mui/icons-material/Delete'
import UseBulkMutation, { MultipleBulkMutateCallback } from '../../../../../../gql/UseBulkMutation'

interface Props {
  option: ServicePrice
  bookings: ServiceBooking[]
  saleService: SaleService
  isSelected: boolean
  isMobile: boolean
  onSelect: () => void
  onUpdate?: (data?: any) => Promise<any | void> | undefined
}

export default function ProviderOption({ option, bookings, saleService, isSelected, isMobile, onSelect, onUpdate }: Props) {
  const user = useContext(UserContext)
  const [isLoading, setIsLoading] = React.useState(false)
  const bulkMutationRef = React.useRef<MultipleBulkMutateCallback>()

  const pricePerPerson = (option.price / (option.type?.capacity || 1)).formatCurrency(option.currency)

  const pricePerUnit = option.price.formatCurrency(option.currency)

  const priceCost = option.cost.formatCurrency(option.currency)

  const serviceName = option.type?.name[user.lang] || saleService.class.name[user.lang]

  const onDeleteServicesClick = async () => {
    setIsLoading(true)

    if (!bulkMutationRef.current) return

    try {
      await bulkMutationRef.current({ delete: bookings.map((it) => ({ id: it.service.id })) })

      onUpdate && (await onUpdate())
    } catch (e) {
      alert(user.translate('operation_failed'))
      console.log('err', e)
    }

    setIsLoading(false)
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Badge
        style={{ flex: 1 }}
        color="warning"
        key={option.id}
        badgeContent={option.minQuantity < 2 ? 0 : `${option.minQuantity}+`}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        componentsProps={{ badge: { style: { bottom: 7, right: 6, background: 'rgb(201 141 93)' } } }}
      >
        <Button
          key={option.id}
          style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
          sx={{ p: 1, mt: 0.5, mb: 0.5 }}
          variant={isSelected ? 'contained' : 'outlined'}
          onClick={onSelect}
        >
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1, fontSize: 12, width: '100%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                fontSize: 12,
              }}
            >
              {/*<Show if={!option.type}>*/}
              {/*  <div style={{ color: "#888", fontStyle: "italic" }}>{saleService.class.name[user.lang]}</div>*/}
              {/*</Show>*/}
              <Show if={option.type}>
                <div style={{ fontSize: 13 }}>
                  <Show if={option.type?.parentType}>
                    <FontAwesomeIcon icon={option.type?.parentType?.icon as IconName} style={{ marginRight: 8 }} />
                    <span>{option.type?.parentType?.name[user.lang]} &middot; </span>
                  </Show>
                  <FontAwesomeIcon icon={option.type?.icon as IconName} style={{ marginRight: 8 }} />
                  {option.type?.name[user.lang]}
                </div>
                <div>
                  {option.type?.capacity} {user.translate(option.type?.capacity === 1 ? 'pack' : 'packs')}
                </div>
              </Show>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                fontWeight: 400,
              }}
            >
              <ProviderOptionLocation option={option} saleService={saleService} isSelected={isSelected} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                fontWeight: 400,
                opacity: 0.8,
                fontSize: 11,
              }}
            >
              <ProviderOptionTime option={option} saleService={saleService} isMobile={isMobile} />
            </div>
          </div>
          <div
            style={{
              width: 80,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'end',
              textTransform: 'none',
              lineHeight: 1.6,
            }}
          >
            <Tooltip title={user.translate('price_per_person')}>
              <div>{option.type ? pricePerPerson : null}</div>
            </Tooltip>
            <Tooltip title={user.translate('price_per_unit', serviceName)}>
              <div style={{ fontWeight: 400 }}>{pricePerUnit}</div>
            </Tooltip>
            <Tooltip title={user.translate('cost') + ' ' + user.translate('price')}>
              <div style={{ fontWeight: 400, opacity: 0.6, textTransform: 'none' }}>{priceCost}</div>
            </Tooltip>
          </div>
        </Button>
      </Badge>
      <Show if={bookings.length > 0}>
        <ButtonGroup variant={'contained'} color={'success'} sx={{ ml: 1 }}>
          <Button sx={{ pl: 1.5, pr: 1.5 }} style={{ minWidth: 32 }}>
            {bookings.length}&nbsp;
            <PersonOutlineIcon fontSize={'small'} />
          </Button>
          <IconButton color={'error'} onClick={onDeleteServicesClick} disabled={isLoading}>
            <Show if={!isLoading}>
              <Delete />
            </Show>
            <Show if={isLoading}>
              <CircularProgress size={24} />
            </Show>
          </IconButton>
        </ButtonGroup>
      </Show>
      <UseBulkMutation entity="CustomerService" bulkMutationRef={bulkMutationRef} />
    </div>
  )
}
