import MenuIcon from '@mui/icons-material/Menu'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { Divider, List, Toolbar, Typography } from '@mui/material'
import type { AppBarProps } from '@mui/material/AppBar'
import MuiAppBar from '@mui/material/AppBar'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import clsx from 'clsx'
import React, { KeyboardEvent, MouseEvent, ReactNode, useContext, useEffect, useState } from 'react'
import { UserContext } from '../app/UserApp'
import useWindowSize from '../hooks/useWindowSize'
import DrawerGroupItem from './DrawerGroupItem'
import { DrawerHeader } from './NavigationComponents'
import useStyles, { drawerWidth } from './useStyles'

interface AppBarOpenProps extends AppBarProps {
  open: boolean
}

const AppBarOpen = (props: AppBarOpenProps) => <MuiAppBar {...props} />

export const AppBar = styled(AppBarOpen, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

export type DrawerItem = {
  key: string
  title: string
  description?: string
  icon?: ReactNode
  notifications?: number
  children?: DrawerItem[]
}

export interface AppBarMenuIcon {
  key: string
  icon: ReactNode
  items: (AppBarMenuItem | AppBarMenuDivider)[]
}

export interface AppBarMenuDivider {
  divider: true
}

export interface AppBarMenuItem {
  divider?: false
  key: string
  title?: string
  icon?: ReactNode
  onClick: (that: AppBarMenuItem) => void
  selected?: boolean
}

type Props = {
  children?: ReactNode
  drawer: DrawerItem[]
  menu: AppBarMenuIcon[]
  drawerSelected?: string
  title: string
  pageTitle: string
  basePath: string
  search?: string
  notifications?: number
  onOpenNotifications?: () => void
}

export default function Navigation(props: Props) {
  const classes = useStyles()
  const user = useContext(UserContext)
  const size = useWindowSize()

  const isMobile = size.isCompact

  const [open, setOpen] = useState(!isMobile && localStorage.getItem('drawer_open') !== 'false')

  const [selectedMenuItem, setSelectedMenuItem] = useState<{ anchor?: Element; menu: AppBarMenuIcon }>()

  useEffect(() => {
    localStorage.setItem('drawer_open', open.toString())
  }, [open])

  useEffect(() => {
    if (!isMobile && localStorage.getItem('drawer_open') === null) {
      setOpen(true)
    } else if (isMobile) {
      setOpen(false)
    }
  }, [isMobile])

  let selectedDrawerMenu = props.drawerSelected

  const toggleDrawer = () => {
    setOpen(!open)
  }

  const closeDrawer = (event: KeyboardEvent | MouseEvent) => {
    // if (!isMobile || !open || (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift"))) {
    //   return;
    // }

    setOpen(false)
  }
  const handleMenuOpen = (item: AppBarMenuIcon) => (event: React.MouseEvent) => {
    setSelectedMenuItem({ anchor: event.currentTarget, menu: item })
  }

  const hideMenu = () => setSelectedMenuItem(selectedMenuItem ? { menu: selectedMenuItem.menu } : undefined)

  const onMenuItemClick = (item: AppBarMenuItem) => () => {
    hideMenu()
    item.onClick(item)
  }

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={!isMobile && open} className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
            }}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" style={{ fontWeight: 400 }}>
            {props.pageTitle}
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton
              aria-label={`show ${props.notifications} new notifications`}
              color="inherit"
              style={{ display: props.notifications ? 'block' : 'none' }}
              onClick={props.onOpenNotifications}
            >
              <Badge badgeContent={props.notifications} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </div>

          {props.menu.map((item, index) => (
            <IconButton
              key={item.key}
              edge={index === props.menu.length - 1 && 'end'}
              aria-label="language of current user"
              aria-controls={`menu_${item.key}`}
              aria-haspopup="true"
              onClick={handleMenuOpen(item)}
              color="inherit"
            >
              {item.icon}
            </IconButton>
          ))}
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        variant={!isMobile ? 'permanent' : 'temporary'}
        open={open}
        className={clsx(classes.drawer, classes.drawerContent, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <DrawerHeader style={{ paddingBottom: 0 }}>
          <Typography variant="h5" noWrap component="div">
            &nbsp;&nbsp;{props.title}
          </Typography>
          {/* <IconButton onClick={() => setOpen(false)}>
            <ChevronLeftIcon />
          </IconButton> */}
        </DrawerHeader>
        <List className={classes.menu} disablePadding key={`list_drawer`}>
          {props.drawer.map((item) => (
            <DrawerGroupItem key={item.key} item={item} selected={selectedDrawerMenu} drawerOpen={open} basePath={props.basePath} />
          ))}
        </List>
      </SwipeableDrawer>
      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
        <DrawerHeader />
        <div style={{ display: 'flex', position: 'relative', flexDirection: 'column', width: '100%', flex: 1 }}>{props.children}</div>
      </Box>
      <Menu
        anchorEl={selectedMenuItem?.anchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        id={`menu_${selectedMenuItem?.menu?.key}`}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={Boolean(selectedMenuItem?.anchor)}
        onClose={() => hideMenu()}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {selectedMenuItem?.menu?.items?.map((item) =>
          item.divider ? (
            <Divider />
          ) : (
            <MenuItem key={item.key} onClick={onMenuItemClick(item)} selected={item.selected}>
              {item.title ?? user.translate(item.key)}
            </MenuItem>
          ),
        )}
      </Menu>
    </Box>

    // <div className={classes.root} onClick={closeDrawer} onKeyDown={closeDrawer}>
    //   {config.apiUrl.indexOf("dev") > -1 && <span style={{ backgroundColor: "#ffc6d6" }}>DEV SERVER</span>}
    //   {config.apiUrl.indexOf("dev") === -1 && config.isTest === true && (
    //     <span style={{ backgroundColor: "#ffe599" }}>TEST SERVER</span>
    //   )}

    // </div>
  )
}
