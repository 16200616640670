import { Box, CircularProgress } from '@mui/material'
import { useCallback, useContext, useState } from 'react'
import Show from '../../../../../common/Show'
import { IconNameEntity } from '../../../../hooks/useIconNameEntityItems'
import { useSimpleEntityIconItems } from '../../../../hooks/useSimpleEntityIconItems'
import { Sale } from '../SaleForm'
import { TemplateServiceSaleDialog } from './TemplateServiceSaleDialog'
import { TemplateServiceSaleItem } from './TemplateServiceSaleItem'
import { UserContext } from '../../../../UserApp'
import ServiceSalesSummary from './ServiceSalesSummary'

interface Props {
  sale?: Sale
  saleType?: IconNameEntity
}

export const ServiceSalesByTemplate: React.FC<Props> = ({ sale, saleType }) => {
  const user = useContext(UserContext)

  const { loading, items: templates } = useSimpleEntityIconItems('SaleTypeTemplate', 'saleTypeId', sale?.saleTypeId, [
    {
      name: 'providerFieldTitle',
      gql: 'Translation!',
      subSelection: { [user.lang.toLocaleLowerCase()]: true },
    },
    {
      name: 'requireProvider',
      gql: 'String',
    },
    {
      name: 'singleCustomer',
      gql: 'Long',
    },
  ])

  const [openTemplateAction, setOpenTemplateAction] = useState<{
    templateId: string
    saleServiceId?: string
    refreshCallback?: VoidFunction
  }>()

  const onClick = useCallback((templateId: string, saleServiceId?: string, refreshCallback?: VoidFunction) => {
    setOpenTemplateAction({ templateId, saleServiceId, refreshCallback })
  }, [])

  const onDialogClose = useCallback(() => {
    setOpenTemplateAction((currentAction) => {
      currentAction?.refreshCallback?.()
      return undefined
    })
  }, [])

  if (loading) {
    return <CircularProgress />
  }

  if (!templates.length || !sale) return null

  return (
    <>
      {templates.map((template) => {
        return <TemplateServiceSaleItem sale={sale} saleType={saleType} template={template} onClick={onClick} />
      })}
      <Box sx={{ p: 2, textAlign: 'start' }}>
        <ServiceSalesSummary sale={sale} />
      </Box>
      <Show if={openTemplateAction && sale}>
        <TemplateServiceSaleDialog {...openTemplateAction} sale={sale} onClose={onDialogClose} />
      </Show>
    </>
  )
}
