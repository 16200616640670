import React, { useContext } from 'react'
import { UserContext } from '../app/UserApp'
import { FormField } from './useFormQuery'
import { callOrGet } from './utils'

export interface ChildProps extends FormField<any> {}

interface FormFieldProps extends FormField<string> {
  children: React.ReactElement<ChildProps> | React.ReactElement<ChildProps>[]
  glue?: JSX.Element | ((row: any) => JSX.Element | string | null)
}

export default function CompositeField(props: FormFieldProps) {
  const user = useContext(UserContext)

  const item = props.item || props.rowData

  const onChange = (field: string) => (value: any) => {
    if (!props.onChange) return

    item[field] = value

    return props.onChange('')
  }

  return (
    <div style={{ display: 'flex' }}>
      {React.Children.map(props.children, (child) => {
        const childType = child.type
        if (!childType || !React.isValidElement(child)) return null

        if (callOrGet(child.props.hidden, item)) return null

        if (typeof childType === 'string') return React.cloneElement(child, child.props)

        return React.cloneElement(child, {
          ...child?.props,
          rowData: item,
          value: item[child?.props?.name || ''],
          onChange: onChange(child?.props?.name || ''),
        })
      })}
    </div>
  )
}

CompositeField.render = (props: FormFieldProps, value: string, row: any) => {
  const item = row || props.item || props.rowData
  let renderedItems = 0
  return (
    <div style={{ display: 'flex' }}>
      {React.Children.map(props.children, (child) => {
        const childType = child.type
        if (!childType || !React.isValidElement(child)) return null

        if (callOrGet(child.props.hidden, item)) return null

        if (typeof childType === 'string') return React.cloneElement(child, child.props)

        const childValue = item[child?.props?.name || '']

        const render = (childType as any)?.render ?? (childType as any)?.renderComposite

        const childComponent = render
          ? render(child.props, childValue, row)
          : React.cloneElement(child, {
              ...child?.props,
              rowData: item,
              value: item[child?.props?.name || ''],
            })

        if (renderedItems++ > 0) {
          const glue = props.glue ? callOrGet(props.glue, row) : <>&nbsp;-&nbsp;</>
          if (glue) {
            return (
              <>
                {glue}
                {childComponent}
              </>
            )
          }
        }
        return childComponent
      })}
    </div>
  )
}
