import { Action, DetailPanel, EditComponentProps, Options } from '@material-table/core'
import { CSSProperties, default as React, memo, MutableRefObject, useContext, useEffect, useMemo } from 'react'
import { ZeroApiContext } from '../app/Organisation'
import { UserContext } from '../app/UserApp'
import { HiddenField } from '../form/HiddenField'
import FilterField from '../form/FilterField'
import NestedItemForm from '../form/NestedItemForm'
import useFormQuery, { FieldMap, FormField, FormQueryProps } from '../form/useFormQuery'
import { callOrGet, ifNull } from '../form/utils'
import { GqlFetchQuery, GqlRWQuery } from '../gql/interface'
import GqlDetailPanel, { Props as GqlDetailPanelProps } from './GqlDetailPanel'
import PlainTable, { GqlTableColumn, Props as PlainTableProps } from './PlainTable'
import { flattenTreeData } from './flattenTreeData'
import UseBulkMutation, { MultipleBulk, MultipleBulkMutateCallback } from '../gql/UseBulkMutation'

export interface KeyMapNullable {
  [key: string]: string | undefined
}

interface ColumnGetValue {
  fieldPathName: string
  getValue: (row: any) => any
  default?: any
  isValueFromParent: boolean
}

export interface GqlTabletField<T> extends FormField<T> {
  aggregate?: (data: any[]) => any
  disaggregate?: (item: any) => any[]
  ignoreOnAggregate?: boolean
  ignoreOnNonAggregate?: boolean
}

interface Props extends FormQueryProps {
  deps?: any[]
  children: React.ReactElement<GqlTabletField<any>>[]
  onItemClick?: (e?: React.MouseEvent, row?: any) => void
  onSubmit?: (data: KeyMapNullable) => Promise<any> | boolean | undefined
  onSave?: (data: KeyMapNullable) => Promise<any> | boolean | undefined
  onDelete?: (data: KeyMapNullable) => Promise<any> | boolean | undefined
  refresh?: (data: KeyMapNullable) => Promise<any> | boolean | undefined
  dense?: boolean
  grouping?: boolean
  noSearch?: boolean
  noPaging?: boolean
  noTitle?: boolean
  noExport?: boolean
  title?: string | React.ReactElement<any>
  parentTitle?: string | React.ReactElement<any>
  titleActions?: React.ReactNode
  fixedLeft?: number
  fixedRight?: number
  treeLookup?: (row: any, parent: any) => boolean
  filterRows?: (rows: any[]) => any
  rowData?: any
  search?: string
  style?: React.CSSProperties
  options?: Options<any>
  hasBack?: boolean
  readOnly?: boolean
  aggregated?: boolean
  refItems?: MutableRefObject<any[]>
  onItems?: (items: any[]) => void
  remapItems?: (items: any[]) => any[]
  onSelectionChange?: (data: any[], rowData?: any) => void
  queryRef?: MutableRefObject<GqlFetchQuery<any> | GqlRWQuery<any> | undefined>
  actions?: (Action<any> | ((rowData: any) => Action<any>) | { action: (rowData: any) => Action<any>; position: string })[]
}

const AsyncVoid = async () => {}

export default function GqlTable(props: Props) {
  const user = useContext(UserContext)
  const context = useContext(ZeroApiContext)

  const parentRowData = props.rowData
  const getParentValue = (value: string | ((rowData: any) => any) | undefined) => {
    if (parentRowData === undefined || value === undefined) return undefined
    if (value instanceof Function) return value(parentRowData)
    return parentRowData[value]
  }

  const { fieldsMap, query } = useFormQuery({
    ...props,
    list: true,
    all: props.all,
    readOnly: props.readOnly || props.aggregated,
    entityRelFieldName: props.entityRelFieldName || 'organisationId',
    entityRelFieldValue: props.entityRelFieldValue || context.id,
    getParentValue,
  })

  const bulkMutationRef = React.useRef<MultipleBulkMutateCallback>()

  const rwQuery = query as GqlRWQuery<any>

  if (props.queryRef) {
    props.queryRef.current = query
  }

  const columns: GqlTableColumn<any>[] = []

  const propsTreeLookup = props.treeLookup
  let treeItemsLookup: ((row: any, rows: any[]) => any[]) | undefined = propsTreeLookup
    ? (row: any, rows: any[]) => row && rows && rows.find((a) => a && propsTreeLookup(a, row))
    : undefined

  const columnGetValues: ColumnGetValue[] = []

  const aggregateColumns: [string, (items: any[]) => any][] = []
  const aggregatorColumns: string[] = []
  const disaggregateColumns: { [key: string]: ((item: any) => any[]) | undefined } = {}

  let groupingFields = 0

  const detailPanel: DetailPanel<any>[] = []

  const generateColumns =
    (fieldsMapParent: FieldMap, path: string[], parentEntity?: string) =>
    (child: React.ReactElement<GqlTabletField<any> | GqlDetailPanelProps>) => {
      if (!React.isValidElement(child)) {
        console.log('Invalid child', child)
        return child
      }
      const component = child.type as any

      if (component === GqlDetailPanel) {
        detailPanel.push({
          render: ({ rowData }) => React.cloneElement(child, { ...child.props, rowData, refresh: query.refresh } as GqlDetailPanelProps),
        })
        return null
      }

      const childProps = child.props as GqlTabletField<any>
      const fieldName = childProps.name
      if (fieldName === undefined) return child

      if (props.aggregated && childProps.ignoreOnAggregate) return null
      if (!props.aggregated && childProps.ignoreOnNonAggregate) return null

      const field = fieldsMapParent[fieldName]

      if (field === undefined && !component.aggregator) return child

      if (component === NestedItemForm) {
        if (!field) {
          console.warn("NestedItemForm got null 'field'")
          return
        }
        if (!childProps.children) {
          console.warn('NestedItemForm with no children')
          return
        }
        if (!field.subSelection) {
          console.warn('field.subSelection unexpetidely is undefined')
          return
        }
        const columnGenerator = generateColumns(field.subSelection as FieldMap, path.concat([fieldName]), childProps.gql ?? 'String')
        React.Children.forEach(childProps.children, columnGenerator)
        return
      }

      const hasError = (value: any) =>
        (childProps.nullable !== true && !component.readOnly && (value === undefined || value?.length === 0)) ||
        callOrGet(component.validate, childProps, value)

      // if (error) hasError = true

      const getValue =
        childProps.valueFromParent !== undefined
          ? (row: any) => getParentValue(childProps.valueFromParent)
          : path.length === 0
          ? (row: any) => ifNull(row[fieldName], field ? field.value : row)
          : (row: any) => {
              let value = row
              for (let i = 0; i < path.length; i++) {
                value = value[path[i]] || {}
              }
              return ifNull(value[fieldName], field ? field.value : row)
            }

      const render = component.render
      const fieldPathName = path.length > 0 ? path.concat(childProps.name).join('.') : childProps.name

      columnGetValues.push({
        fieldPathName,
        getValue,
        default: childProps.default,
        isValueFromParent: childProps.valueFromParent !== undefined,
      })

      if (childProps.treeLookup) {
        const otherField = columnGetValues.find((it) => it.fieldPathName === childProps.treeLookup)
        if (otherField) {
          const otherGetValue = otherField.getValue
          treeItemsLookup = (row, rows) => {
            if (!row || !rows) return undefined
            const rowValue = getValue(row)
            return rows.find((a) => otherGetValue(a) === rowValue)
          }
        } else {
          console.error(`Can not find field ${childProps.treeLookup} in ${columnGetValues.map((it) => it.fieldPathName)}`)
        }
      }

      const aggregate = props.aggregated ? childProps.aggregate ?? (component.aggregate as undefined | ((data: any[]) => any)) : undefined

      if (props.aggregated && !(component === HiddenField || component === FilterField)) {
        if (aggregate) {
          aggregateColumns.push([fieldPathName, aggregate])
        } else {
          aggregatorColumns.push(fieldPathName)
        }
      }
      if (childProps.disaggregate) {
        disaggregateColumns[fieldPathName] = childProps.disaggregate
      }

      if (component === HiddenField || component === FilterField) return null

      const renderCol = (row: any, type: 'group' | 'row') => {
        if (type === 'group') {
          if (childProps.renderGroup) {
            return childProps.renderGroup(row)
          }
          return render(childProps, row, { group: row }, type)
        }

        return render({ ...childProps, parentEntity, parentRowData }, getValue(row), row, type)
      }

      const renderEdit = (props: EditComponentProps<any>) =>
        React.cloneElement(child, {
          ...childProps,
          ...props,
          key: fieldPathName,
          size: 'small',
          error: hasError,
          parentEntity,
          parentRowData,
          hidden: callOrGet(childProps.hidden, props.rowData),
        })

      const columnTitle = React.isValidElement(childProps.label)
        ? childProps.label
        : user.translate(childProps.label?.toString() || childProps.name.snakeCase().replace(/_id$/g, ''))

      const cellStyle = { paddingTop: 0, paddingBottom: 0, paddingRight: 8, paddingLeft: 8, ...component.cellStyle }
      const headerStyle: CSSProperties = { lineHeight: 1.2, position: 'sticky', whiteSpace: 'nowrap', padding: 8 }

      columns.push({
        groupName: childProps.group,
        title: columnTitle,
        field: fieldPathName,
        align: childProps.align ?? component.align,
        hidden: component === HiddenField || component === FilterField || callOrGet(childProps.hidden),
        defaultGroupOrder: childProps.grouping ? groupingFields++ : undefined,
        initialEditValue: callOrGet(childProps.value || childProps.default),
        width: childProps.width !== undefined ? childProps.width : component.width,
        render: render || childProps.renderGroup ? renderCol : undefined,
        editComponent: renderEdit,
        cellStyle: cellStyle,
        headerStyle: headerStyle,
        searchable: component.noSearch || (field ?? childProps)?.noSearch,
        customFilterAndSearch: (filterValue, row) => {
          const value = getValue(row)

          const filter = component.filter
          if (filter) {
            return filter(childProps, filterValue, value, row)
          }
          return value && value.toString().cyrillicToLatin().indexOf(filterValue.cyrillicToLatin()) > -1
        },
      })
    }
  React.Children.forEach(props.children, generateColumns(fieldsMap, [], props.entity))

  const disAgrColsHash = JSON.stringify(Object.keys(disaggregateColumns))

  const bulkEdit = useMemo(() => {
    if (!props.aggregated) return AsyncVoid

    return async (item: any, autoRefresh?: boolean) => {
      if (!bulkMutationRef.current) return

      columnGetValues.forEach((value) => {
        if (!value.isValueFromParent) return
        if (!item[value.fieldPathName]) {
          item[value.fieldPathName] = value.getValue(item)
        }
      })

      let result: any[] = []
      Object.entries(disaggregateColumns).forEach(([column, disaggregate]) => {
        if (!disaggregate) return
        if (result.length <= 0) {
          result = disaggregate(item).map((value) => ({ ...item, _id: undefined, [column]: value }))
        } else {
          disaggregate(item).forEach((value, index) => {
            const itemData = result[index]
            if (!itemData) return
            itemData[column] = value
          })
        }
      })

      const prevData = item._agrItems ?? []
      const mutation: MultipleBulk = {}

      prevData.forEach((prevItem: any) => {
        const newItem = result?.find((it) => it.id === prevItem.id)
        if (newItem === undefined) {
          const deleteItems = mutation.delete
          if (deleteItems) {
            deleteItems.push({ id: prevItem.id })
          } else mutation.delete = [{ id: prevItem.id }]
        } else if (JSON.stringifySafe(newItem) !== JSON.stringifySafe(prevItem)) {
          const editItems = mutation.edit
          if (editItems) {
            editItems.push({ ...newItem, id: prevItem.id })
          } else mutation.edit = [{ ...newItem, id: prevItem.id }]
        }
      })

      result.forEach((newItem: any) => {
        const prevItem = prevData.find((it: any) => it.id === newItem.id)
        if (prevItem !== undefined) return
        const editItems = mutation.create
        if (editItems) {
          editItems.push(newItem)
        } else mutation.create = [newItem]
      })

      mutation.create?.cleanUpObjects((key) => key.charAt(0) === '_')
      mutation.edit?.cleanUpObjects((key) => key.charAt(0) === '_')
      mutation.delete?.cleanUpObjects((key) => key.charAt(0) === '_')

      console.log(mutation)

      if (!mutation.create && !mutation.edit && !mutation.delete) return

      bulkMutationRef.current(mutation).then(() => {
        query.refresh()
        if (props.refresh) props.refresh(item)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disAgrColsHash, props.aggregated])

  if (props.aggregated && !rwQuery.saveItem) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    rwQuery.saveItem = (e) => bulkEdit(e).catch(console.log)
  }

  const remapItems = props.remapItems

  const items = useMemo(() => {
    let result = (query.items as any[]) || []

    if (aggregatorColumns.length > 0 && result.length > 0) {
      const columnValueGetters = columnGetValues.toMapBy(
        (it) => it.fieldPathName,
        (it) => it.getValue,
      )

      const agrItems = result.groupBy((item) => {
        return aggregatorColumns.joinOf('|', (column) => columnValueGetters[column](item))
      })

      result = Object.values(agrItems).map((items) => {
        const agrItem = { ...items[0], _agrItems: items }
        aggregateColumns.forEach(([column, aggregator]) => {
          agrItem[column] = aggregator(items)
        })
        return agrItem
      })
    }

    if (remapItems) {
      return remapItems(result)
    }
    return result
  }, [query.items, remapItems, aggregateColumns])

  if (props.refItems) props.refItems.current = items

  useEffect(() => {
    if (props.onItems) props.onItems(items)
  }, [props.onItems ? JSON.stringify(items) : items.length])

  const searchQuery = props.search ?? new URL(window.location.href).searchParams.get('q') ?? undefined

  const getData = (newData: KeyMapNullable) => {
    const item = flattenTreeData(newData)
    columnGetValues.forEach((columnGetValue) => {
      if (!columnGetValue.default) return
      const key = columnGetValue.fieldPathName.replaceAll('.', '_')
      if (!item[key]) item[key] = callOrGet(columnGetValue.default, item)
    })
    return item
  }

  return (
    <>
      <PlainTableMemo
        {...(props as any)}
        search={searchQuery}
        style={props.style}
        onItemClick={props.onItemClick}
        canDelete={props.canDelete}
        onRowAdd={rwQuery.saveItem && ((newData) => rwQuery.saveItem(getData(newData)))}
        onRowUpdate={rwQuery.saveItem && ((newData) => rwQuery.saveItem(flattenTreeData(newData)))}
        onRowDelete={rwQuery.deleteItem && ((newData) => rwQuery.deleteItem(flattenTreeData(newData)))}
        title={props.title || props.entity}
        isLoading={query.isLoading || rwQuery.isLoadingAction}
        columns={columns}
        items={items}
        treeItemsLookup={treeItemsLookup}
        detailPanel={detailPanel}
        options={{ ...props.options, tableLayout: 'auto' }}
      />
      <UseBulkMutation entity={props.entity} bulkMutationRef={bulkMutationRef} />
    </>
  )
}

const compareDependencies = (prev?: any[], next?: any[]) => {
  if (prev === next) return true
  if (prev === undefined || next === undefined) return false
  for (let i = 0; i < prev.length; i++) {
    if (prev[i] !== next[i]) return false
  }
  return true
}

const PlainTableMemo = memo(
  (props: PlainTableProps<any>) => <PlainTable {...props} />,
  (prev, next) => prev.items === next.items && compareDependencies(prev.deps, next.deps),
)
