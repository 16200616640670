import moment from "moment";
import * as React from "react";
import { SaleService, ServicePrice } from "../../../Types";
import { greatest, lowest } from "../../../../../../common/Utils";

interface Params {
  option: ServicePrice,
  saleService: SaleService,
  isMobile: boolean
}

export default function ProviderOptionTime({ option, saleService, isMobile }: Params) {
  const dateFrom = greatest(moment(option.dateFrom), moment(saleService.dateFrom))
  const dateTo = lowest(moment(option.dateTo), moment(saleService.dateTo))

  if (saleService.class.uom === "DAY") {
    return (<div style={{ display: "flex" }}>
      <div style={{ minWidth: 80, maxWidth: 160, paddingRight: 8 }}>{dateFrom.format("ll")}</div>
      &nbsp;-&nbsp;
      <div style={{ minWidth: 80, maxWidth: 160 }}>{dateTo.format("ll")}</div>
    </div>)
  } else if (saleService.class.time === "DAY") {
    return <div>{dateFrom.format("ll")}</div>
  } else {
    const timeFormat = isMobile ? "lll" : "llll"
    return (<>
      <div style={{ minWidth: 120, maxWidth: 160, paddingRight: 8 }}>{dateFrom.format(timeFormat)}</div>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <div style={{ minWidth: 120, maxWidth: 160 }}>{dateTo.format(timeFormat)}</div>
    </>)
  }
}
