import PersonIcon from '@mui/icons-material/Person'
import { CircularProgress, Tooltip } from '@mui/material'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { UserContext, UserContextParams } from '../app/UserApp'
import { useEntityRelationReadOnlyQuery } from '../gql/useEntityRelationReadOnlyQuery'
import { HiddenField } from './HiddenField'
import { FormField } from './useFormQuery'
import { callOrGet, ifNull } from './utils'

export interface ItemCreators {
  createdBy?: { profile: { name: { [key: string]: string } } }
  updatedBy?: { profile: { name: { [key: string]: string } } }
  created: string
  updated: string
}

interface FormFieldProps extends FormField<any> {
  rowData?: any
  param?: any
}

export default function FormCreators(props: FormFieldProps) {
  const user = useContext(UserContext)

  const [isOpen, setIsOpen] = useState(false)

  const value = ifNull(props.value, callOrGet(props.default, props.item))

  const itemData = props.rowData || props.item || {}

  const fields = [
    { name: 'createdBy', gql: 'User', subSelection: { profile: { name: true } } },
    { name: 'updatedBy', gql: 'User', subSelection: { profile: { name: true } } },
    { name: 'created', gql: 'Long' },
    { name: 'updated', gql: 'Long' },
  ]

  const query = useEntityRelationReadOnlyQuery<ItemCreators>(props.parentEntity || '', fields, 'id', value, {
    single: true,
    skip: !isOpen,
  })

  useEffect(() => {
    if (isOpen) {
      query.refresh()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, JSON.stringify(itemData)])

  const item = query.item || ({} as ItemCreators)

  let titleItems = []
  if (item.createdBy?.profile) {
    titleItems.push(user.translate('created_by') + " '" + item.createdBy.profile.name + "'")
    if (item.created) titleItems.push(` ${user.translate('on')} ` + moment(parseInt(item.created)).format('lll'))
    titleItems.push(<br />)
  }
  if (item.updatedBy?.profile) {
    titleItems.push(user.translate('updated_by') + " '" + item.updatedBy.profile.name + "'")
    if (item.created) titleItems.push(` ${user.translate('on')} ` + moment(parseInt(item.updated)).format('lll'))
    titleItems.push(<br />)
  }
  if (titleItems.length === 0) {
    titleItems.push(user.translate('no_info'))
  }

  const title = !isOpen ? '' : query.isLoading ? <CircularProgress size={24} /> : titleItems

  const size = props.size === 'small' ? 'small' : 'medium'

  return (
    <Tooltip style={{ cursor: 'help' }} title={title} onOpen={() => setIsOpen(true)} onClick={() => setIsOpen(false)}>
      <PersonIcon fontSize={size} color="info" />
    </Tooltip>
  )
}
FormCreators.readOnly = true
FormCreators.skipGql = true
FormCreators.fields = (props: FormFieldProps, user: UserContextParams) => {
  return [
    <HiddenField name="createdById" value={user.userId} nullable />,
    <HiddenField name="created" value={Date.now()} nullable />,
    <HiddenField name="updatedById" forceValue={user.userId} />,
    <HiddenField name="updated" forceValue={Date.now()} />,
  ]
}

FormCreators.render = (props: FormFieldProps, value: string, row: any) => <FormCreators {...props} rowData={row} value={value} />
