import { useState } from "react"

export function uid() {
  let d = new Date()
  return (
    (d.getTime() * 1000 + d.getMilliseconds()).toString() +
    Math.round(Math.random() * 1000)
      .toString()
      .padStart(3, "0")
  )
}

export default () => useState(uid())[0]
