import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { Badge, Collapse, Divider, List, ListItem, ListItemIcon, ListItemText, Tooltip } from "@mui/material"
import { isSelectionNode } from "graphql"
import React, { useEffect, useState } from "react"
import useWindowSize from "../hooks/useWindowSize"
import DrawerItemView from "./DrawerItemView"
import { DrawerItem } from "./Navigation"
import Show from "./Show"
import useStyles from "./useStyles"

interface DrawerGroupItemProps {
  item: DrawerItem
  selected: string | undefined
  drawerOpen: boolean
  basePath: string
}

export default ({ item, selected, drawerOpen, basePath }: DrawerGroupItemProps) => {
  const classes = useStyles()

  const isMobile = useWindowSize().isCompact

  const [expanded, setExpanded] = useState(false)
  const [expandedSec, setExpandedSec] = useState(expanded)

  useEffect(() => {
    if (expandedSec !== expanded) {
      setTimeout(() => setExpandedSec(expanded), 100)
    }
  }, [expanded, expandedSec])

  const isItemSelected = item.children && item.children.find((child) => child.key === selected) !== undefined
  const secondary =
    !expanded && item.children
      ? item.children.flatMap((child) => [
          child.key === selected ? (
            <b key={child.title} style={{ color: "#EEE", textTransform: "uppercase", fontSize: 9 }}>
              {child.title}
            </b>
          ) : (
            child.title
          ),
          ", ",
        ])
      : undefined
  if (secondary) secondary.pop()

  let itemClasses = classes.drawerItem
  const color = isItemSelected ? "#DDF" : "#EEE"

  return (
    <>
      <Divider key={`divider_${item.key}`} />
      <ListItem
        key={item.key}
        dense={isMobile || drawerOpen}
        button
        className={itemClasses}
        onClick={() => setExpanded(!expanded)}
        style={{
          paddingLeft: 4,
          height: drawerOpen || (item.icon && !expanded) ? 60 : 0,
          padding: drawerOpen || (item.icon && !expanded) ? 8 : 4,
          textDecoration: "none",
          background: isItemSelected
            ? "linear-gradient( 45deg, #3c420f69, transparent)"
            : "linear-gradient( 45deg, #06071a69, transparent)",
        }}
      >
        <Show if={!drawerOpen && !expanded && item.icon}>
          <Tooltip title={item.title} placement="bottom" aria-label={item.title}>
            <ListItemIcon style={{ minWidth: 54, justifyContent: "center", color }}>
              <Badge badgeContent={item.notifications} color="error">
                {item.icon}
              </Badge>
            </ListItemIcon>
          </Tooltip>
        </Show>
        <ListItemText
          key={item.key}
          primary={item.title}
          secondary={secondary}
          primaryTypographyProps={{
            variant: item.children ? "button" : "subtitle2",
            style: {
              color,
              textDecoration: "none",
              fontSize: 15,
              opacity: drawerOpen ? 1 : 0,
              transitionDelay: drawerOpen ? "0.1s" : "0s",
              transition: "opacity 0.3s ease-out",
            },
          }}
          secondaryTypographyProps={{
            variant: "subtitle1",
            style: {
              textDecoration: "none",
              lineHeight: 1,
              fontSize: 11,
              paddingBottom: 4,
              whiteSpace: isItemSelected ? "pre-wrap" : "nowrap",
              overflow: "auto",
              textOverflow: "ellipsis",
              textTransform: "lowercase",
              opacity: !expandedSec && drawerOpen ? 1 : 0,
              transitionDelay: drawerOpen ? "0.1s" : "0s",
              transition: "opacity 0.3s ease-out",
              color: "#ffffff83",
            },
          }}
          style={{ paddingLeft: 12 }}
        />
        {drawerOpen && (expanded ? <ExpandLess /> : <ExpandMore />)}
      </ListItem>
      <Collapse key={`children_${item.key}`} in={expanded} timeout="auto" unmountOnExit>
        <List key={`sublist_${item.key}`} disablePadding className={classes.menu}>
          {item.children?.map((child) => (
            <DrawerItemView
              key={child.key}
              item={child}
              selected={selected}
              drawerOpen={drawerOpen}
              basePath={basePath}
            />
          ))}
        </List>
      </Collapse>
    </>
  )
}
