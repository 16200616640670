import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import { Box } from '@mui/system'
// import { DropzoneArea } from 'material-ui-dropzone'
import moment from 'moment'
import * as React from 'react'
import { CSSProperties, useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import PageContainer from '../../../../common/PageContainer'
// import EditText from '../../../../form/EditText'
// import EditTextDateEpoch from '../../../../form/EditTextDateEpoch'
// import EntityAutoComplete from '../../../../form/EntityAutoComplete'
// import { HiddenField } from '../../../../form/HiddenField'
// import FormCreators from '../../../../form/FormCreators'
// import SelectOption from '../../../../form/SelectOption'
// import GqlTable from '../../../../table/GqlTable'
import CreateSubject from '../../../common/CreateSubject'
import { IconNameEntity } from '../../../hooks/useIconNameEntityItems'
import { ZeroApiContext } from '../../../Organisation'
import { UserContext } from '../../../UserApp'
// import FilterField from '../../../../form/FilterField'
import { useDropzone } from 'react-dropzone'
import { Button, IconButton, LinearProgress, Typography } from '@mui/material'
import Show from '../../../../common/Show'
import { CloseOutlined, DeleteOutline } from '@mui/icons-material'
import config from '../../../../config'
import axios from 'axios'
import {
  EditText,
  EditTextDateEpoch,
  EntityAutoComplete,
  FilterField,
  FormCreators,
  GqlTable,
  HiddenField,
  SelectCurrency,
  SelectOption,
} from '@zeroapi-io/zeroapireact'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropzone: {
      cursor: 'pointer',
      '& .MuiDropzoneArea-root': {
        minHeight: 'unset',
      },
      '& .MuiDropzoneArea-text': {
        margin: theme.spacing(1),
        fontSize: 18,
      },
      '& .MuiDropzoneArea-textContainer': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        color: '#666',
      },
      '& .MuiDropzoneArea-icon': {
        color: '#666',
        height: 40,
        margin: theme.spacing(1),
      },
    },
  }),
)

const dropButtonStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  transition: 'border .3s ease-in-out',
}
const dropActiveColor = '#2196f3'
const dropAcceptColor = '#00e676'
const dropRejectColor = '#ff1744'

interface Props {
  saleType?: IconNameEntity
}

export default function SaleListPage({ saleType }: Props) {
  const classes = useStyles()

  const user = React.useContext(UserContext)
  const organisation = React.useContext(ZeroApiContext)
  const navigate = useNavigate()

  const { itemId: saleId } = useParams()

  const [importFile, setImportFile] = useState<{ file?: string; progress: number; error?: string }>()

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length === 0) return
    setImportFile(acceptedFiles[0])

    setImportFile({ progress: 0 })

    const data = new FormData()
    data.append('file', acceptedFiles[0])

    axios
      .post(`${config.apiUrl}/upload`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        withCredentials: true,
        onUploadProgress: (e) => {
          setImportFile({ progress: e.total ? e.loaded / e.total : 0 })
        },
      })
      .then((response) => {
        const file = response.data[0]
        setImportFile({ progress: 2, file })

        navigate(`./import/${file}`)
      })
      .catch((error) => {
        if (error.response) {
          setImportFile({ progress: -1, error: error.response })
          console.log('errorres', error.response)
        } else {
          setImportFile({ progress: -1, error: error.message })
        }
        console.log('errormsg', error.message)
      })
  }, [])

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
      'application/vnd.openxmlformats': ['.xls', '.xlsx'],
      'officedocument.spreadsheetml.sheet': ['.xls', '.xlsx'],
    },
  })

  const PayType = {
    CASH: user.translate('cash'),
    CARD: user.translate('card'),
    INVOICE: user.translate('invoice'),
  }

  const onItemClick = (e: any, row: any) => {
    navigate(`./${row.id}`)
  }

  const borderColor = isDragAccept ? dropAcceptColor : isDragActive ? dropActiveColor : isDragReject ? dropRejectColor : ''

  const importProgress = importFile?.progress ?? -2

  return (
    <PageContainer visible={!Boolean(saleId)} animate="fade">
      <Box>
        <Box sx={{ p: 1 }} className={classes.dropzone}>
          <Show if={importProgress === -2}>
            <div {...getRootProps({ style: { ...dropButtonStyle, borderColor } })}>
              <input {...getInputProps()} />
              {isDragActive ? <p>{user.translate('import_drop_file_text')}</p> : <p>{user.translate('import_drop_zone_text')}</p>}
            </div>
          </Show>
          <Show if={importProgress >= 0 && importProgress <= 1}>
            <Box sx={{ p: 2 }}>
              <Typography>{user.translate('importing_file')}</Typography>
              <LinearProgress
                value={importProgress * 100}
                variant={importProgress === 1 ? 'indeterminate' : 'determinate'}
                color={importProgress === 1 ? 'success' : 'primary'}
              />
            </Box>
          </Show>
          <Show if={importProgress === 2}>
            <div>
              <IconButton onClick={() => setImportFile(undefined)}>
                <DeleteOutline />
              </IconButton>
              <Button onClick={() => navigate(`./import/${importFile?.file}`)}>{importFile?.file ?? ''}</Button>
            </div>
          </Show>
          <Show if={importProgress === -1}>
            <div>
              <IconButton onClick={() => setImportFile(undefined)}>
                <CloseOutlined />
              </IconButton>
              {importFile?.error ?? user.translate('error')}
            </div>
          </Show>
        </Box>
        <GqlTable entity="Sale" title="sales" onItemClick={onItemClick}>
          <HiddenField name="id" id />
          <FilterField name="saleTypeId" value={saleType?.id} />
          <HiddenField name="organisationId" value={organisation.id} />
          <HiddenField name="saleCustomersCount" readOnly />
          <HiddenField name="soldById" value={user.userId} />
          <EditText name="label" label="group_code" />
          <EntityAutoComplete name="billToId" label="komitent" entity="Subject" freeSolo={CreateSubject} nullable>
            <HiddenField name="address" />
            <HiddenField name="email" hidden />
          </EntityAutoComplete>
          <EditText name="customerName" default={(item: any) => item?._billToId?.name || item?._billToId_name} />
          <EditText name="address" default={(item: any) => item?._billToId?.address || item?._billToId_address || ''} hidden />
          <EditText name="email" default={(item: any) => item?._billToId?.email || item?._billToId_email} />
          <EditTextDateEpoch name="activityDate" />
          <EditTextDateEpoch
            name="dueToDate"
            default={(item: any) =>
              item?.activityDate ? moment(parseInt(item.activityDate)).add(7, 'days').toDate().getTime().toString() : undefined
            }
          />
          <SelectOption name="payType" options={PayType} />
          <SelectCurrency name="currency" default={'EUR'} />
          <FormCreators name="id" param={user} />
        </GqlTable>
      </Box>
    </PageContainer>
  )
}
