import React, { useContext } from "react";
import { Field } from "../../../../../../gql/interface";
import { useEntityRelationReadOnlyQuery } from "../../../../../../gql/useEntityRelationReadOnlyQuery";
import { UserContext } from "../../../../../UserApp";
import { CustomerService } from "../ServicePriceChooserDialog";

export default function useCustomerServicesBySaleId(saleServiceId?: string) {
  const user = useContext(UserContext);
  const csFields: Field<any>[] = [
    { name: "id", gql: "String" },
    { name: "customerId", gql: "String" },
    { name: "servicePriceId", gql: "String" },
    {
      name: "providerId",
      gql: "String",
    },
    {
      name: "provider",
      gql: "Subject",
      subSelection: { name: { [user.lang]: true } },
    },
    { name: "typeId", gql: "String" },
    { name: "dateFrom", gql: "String" },
    { name: "dateTo", gql: "String" },
    {
      name: "startPointId",
      gql: "String",
    },
    {
      name: "endPointId",
      gql: "String",
    },
    { name: "currency", gql: "String" },
    { name: "price", gql: "Float" },
    { name: "cost", gql: "Float" },
  ];

  return useEntityRelationReadOnlyQuery<CustomerService>(
    "CustomerService",
    csFields,
    "serviceSaleId",
    saleServiceId,
    {
      skip: !saleServiceId,
    }
  );
}

export function useGroupedCustomerServicesBySaleId(saleServiceId?: string) {
  const queryCS = useCustomerServicesBySaleId(saleServiceId);

  const customerServices = React.useMemo<{
    [key: string]: CustomerService[];
  }>(() => {
    return queryCS.items?.groupBy((it) => it.customerId) ?? {};
  }, [queryCS.items]);

  return { customerServices, refresh: queryCS.refresh };
}
