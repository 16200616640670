import { gql, MutationResult, useMutation, useQuery } from '@apollo/client'
import { useCallback, useMemo } from 'react'
import generateGql from './generateGql'
import { Args, GqlQuery, GqlSelection, QueryOptions } from './interface'

export function useDynamicGqlQuery(method: string, args: Args, select: GqlSelection, options: QueryOptions = {}) {
  const query = useMemo(() => {
    return gql(
      generateGql({
        [method]: { args, select },
      }),
    )
  }, [method, args, select])

  const { data, loading, error } = useQuery(query, {
    ...options,
    errorPolicy: 'ignore',
  })

  return { data: data && data[method], error, loading }
}

export function useDynamicGqlQueries(queries: { [key: string]: GqlQuery }, options = {}) {
  const query = useMemo(() => {
    return gql(generateGql(queries))
  }, [queries])

  return useQuery(query, {
    ...options,
    errorPolicy: 'ignore',
  })
}

type GqlMutationResult = [(options?: any, variables?: any) => Promise<any>, MutationResult<any>]

export const useDynamicGqlMutation = (method: string, args: Args, select: GqlSelection = {}, options: QueryOptions = {}) => {
  const query = useMemo(() => {
    return generateGql({ [method]: { args, select } }, 'mutation')
  }, [method, args, select])

  const [call, { data, loading, error }] = useMutation(gql(query), {
    ...options,
    errorPolicy: 'ignore',
  })

  const callMutation = useCallback((variables) => call(variables).then(({ data }) => data[method]), [call, method])

  return [callMutation, { data: data && data[method], error, loading }] as GqlMutationResult
}
