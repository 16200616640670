import React, { useState } from 'react'
import useUID from '../common/useUID'
import { Field, GqlFetchQuery, GqlFilter, GqlRWQuery, GqlSelection } from '../gql/interface'
import { useCustomQuery } from '../gql/useCustomQuery'
import { useEntityQuery } from '../gql/useEntityQuery'
import { useEntityRelationReadOnlyQuery } from '../gql/useEntityRelationReadOnlyQuery'
import GqlDetailPanel from '../table/GqlDetailPanel'
import CompositeField from './CompositeField'
import FilterField from './FilterField'
import NestedItemForm from './NestedItemForm'
import { callOrGet, ifNull } from './utils'
import { useDynamicGqlMutation } from '../gql/useDynamicGql'
import { ApolloQueryResult, NetworkStatus } from '@apollo/client'

export interface FormQueryProps {
  children: React.ReactElement[]
  entity: string
  customQuery?: string
  entityRelFieldName?: string
  entityRelFieldValue?: string
  keys?: string[]
  isLoading?: boolean
  item?: any
  dateRangeFilter?: {
    startDate: Date
    endDate: Date
  }
  dateRangeField?: string
  filter?: GqlFilter
  args?: { [key: string]: string }
  onLoad?: () => void
  canDelete?: (row: any) => boolean
  list?: boolean
  all?: boolean
  getParentValue?: ((rowData: any) => any) | undefined
  readOnly?: boolean
  aggregated?: boolean
}

export interface BaseFormField<T> {
  name: string
  key?: string
  gql?: string | null
  value?: T | ((item?: any) => T | undefined)
  nullable?: boolean
  readOnly?: boolean
  virtual?: boolean
  forceValue?: T
  item?: any
  rowData?: any
  id?: boolean
  isName?: boolean
  valueFromParent?: string | ((rowData: any) => any)
  gqlSelection?: string | string[]
  subSelection?: FieldMap | GqlSelection
  deps?: any[]
}

export interface FormField<T> extends BaseFormField<T> {
  label?: string | JSX.Element
  type?: string
  align?: 'left' | 'right' | 'center'
  style?: React.CSSProperties
  lookup?: string
  auto?: boolean
  disabled?: boolean | ((value: any, row: any) => Boolean)
  default?: T | ((item: any, parent?: any) => T | undefined)
  convert?: (value: any) => any
  hidden?: boolean | ((row: any) => Boolean)
  group?: string
  nested?: boolean
  dependent?: boolean
  keys?: string[]
  grouping?: boolean
  gqlCreate?: string
  size?: 'small' | 'normal'
  children?: React.ReactElement<FormField<T>> | React.ReactElement<FormField<T>>[]
  onChange?: (value: T | null) => void
  noSearch?: boolean
  isForm?: boolean
  isInline?: boolean
  fullWidth?: boolean
  error?: boolean | ((value: any) => Boolean)
  width?: number | string
  treeLookup?: string
  renderGroup?: (group: string) => JSX.Element | string
  onSubmit?: () => void
  parentRowData?: any
  parentEntity?: string
}

export interface FieldMap {
  [key: string]: FormField<any> | undefined
}

export default function useFormQuery(props: FormQueryProps) {
  const entity = props.entity
  const customQuery = props.customQuery
  const entityRelFieldName = props.entityRelFieldName
  const entityRelFieldValue = props.entityRelFieldValue

  const fieldsMap: FieldMap = {}

  const keys = props.keys || ['id']

  const [relationValue, setRelationValue] = useState(entityRelFieldValue)

  const genUid = useUID()

  const relValue = relationValue || entityRelFieldValue || genUid

  keys.forEach((key) => {
    fieldsMap[key] = {
      name: key,
      label: key,
      gql: 'String!',
      id: key === 'id',
      value: entityRelFieldName === key ? relValue : genUid,
    }
  })

  fieldsMap._id = {
    name: '_id',
    label: '_id',
    gql: 'String!',
    readOnly: true,
  }

  let filter = props.filter

  const loadFieldMap = (root: FieldMap) => (child: JSX.Element) => {
    const childProps = child.props

    // if (child.type.fields) {
    //   console.log(childProps.name)
    //   callOrGet(child.type.fields, childProps, child.props.param).forEach(loadFieldMap(root))
    //   return
    // }

    if (child.type === 'div') return

    if (props.aggregated && childProps.ignoreOnAggregate) return
    if (!props.aggregated && childProps.ignoreOnNonAggregate) return

    if (child.type === CompositeField) {
      React.Children.forEach(child.props.children, loadFieldMap(root))
      root[childProps.name] = {
        name: childProps.name,
        virtual: true,
      }
      return
    }

    const gqlType = childProps.gql !== undefined ? childProps.gql : child.type.gql
    if (!gqlType) console.warn('gql-type not defined in ', child.type.name)

    const field: FormField<any> = {
      name: childProps.name,
      label: childProps.label,
      default: childProps.default,
      convert: childProps.convert || child.type.convert,
      value: childProps.value,
      forceValue: childProps.forceValue,
      hidden: callOrGet(childProps.hidden),
      gql: gqlType ? (childProps.nullable ? gqlType : `${gqlType}!`) : null,
      id: childProps.id,
      valueFromParent: childProps.valueFromParent,
      readOnly: ifNull(childProps.transitient, childProps.readOnly, child.type.readOnly),
    }

    if (child.type === NestedItemForm) {
      field.nested = true
      field.dependent = childProps.dependent
      field.keys = childProps.keys
      field.readOnly = ifNull(childProps.transitient, childProps.readOnly, child.type.readOnly)
      field.gqlCreate = `create${childProps.name.capitalize()}`
      field.subSelection = {}
      React.Children.forEach(child.props.children, loadFieldMap(field.subSelection as FieldMap))
    } else if (child.type === FilterField) {
      const value =
        childProps.valueFromParent && props.getParentValue ? callOrGet(props.getParentValue, childProps.valueFromParent) : childProps.value
      if (filter) {
        filter = { ...filter, and: { by: childProps.name, eqStr: value } }
      } else {
        filter = { by: childProps.name, eqStr: value }
      }
    } else if (child.type === GqlDetailPanel) {
      return
    } else {
      if (childProps.subSelection) {
        field.subSelection = childProps.subSelection
      } else if (child.type.subSelection) {
        field.subSelection = child.type.subSelection
      }
      const depFields = callOrGet(child.type.fields, childProps, childProps.param)
      if (depFields) {
        Object.keys(depFields).forEach((depField) => {
          if (!depFields.hasOwnProperty(depField)) return
          const fieldValue = depFields[depField]
          if (typeof depField === 'number') {
            root[depField] = {
              ...field,
              name: depField,
              subSelection: fieldValue === true ? undefined : fieldValue,
            }
          } else {
            loadFieldMap(root)(fieldValue)
          }
        })
      }
    }

    root[childProps.name] = field
  }

  React.Children.forEach(props.children, loadFieldMap(fieldsMap))

  const fields = Object.values(fieldsMap)

  let query: GqlFetchQuery<any> | GqlRWQuery<any>

  if (customQuery && !props.readOnly) {
    let args = fields
      .filter((it) => !it?.name?.startsWith('_'))
      .toMapBy(
        (it) => '$' + it?.name,
        (it) => it?.gql,
      )
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [call, { loading }] = useDynamicGqlMutation(customQuery, args, props.args)
    query = {
      items: [],
      refresh: async () => ({ data: [], loading: false, networkStatus: NetworkStatus.error } as ApolloQueryResult<[]>),
      hardDeleteItem: async () => {},
      deleteItem: async () => {},
      saveItem: async (item) => (await call({ variables: item })).data[customQuery],
      isLoading: false,
      isLoadingAction: loading,
    }
    return { fields, fieldsMap, query, updateRelValue: setRelationValue } as {
      fields: Field<any>[]
      fieldsMap: FieldMap
      query: GqlFetchQuery<any> | GqlRWQuery<any>
      updateRelValue?: (value: string) => void
    }
  } else {
    const factory = customQuery ? useCustomQuery : props.readOnly ? useEntityRelationReadOnlyQuery : useEntityQuery
    // TODO: check unsafe cast `fields as Field<any>[]`
    query = factory(customQuery || entity, fields as Field<any>[], entityRelFieldName, relValue, {
      keys: keys,
      skip: relationValue === undefined || props.isLoading || props.item !== undefined,
      mode: customQuery ? (props.readOnly ? 'readOnly' : 'editOnly') : undefined,
      dateRangeFilter: props.dateRangeFilter,
      dateRangeField: props.dateRangeField,
      filter: filter,
      args: props.args,
      onLoad: props.onLoad,
      single: props.list !== true,
      all: props.all,
      getParentValue: props.getParentValue,
    })
  }

  return { fields, fieldsMap, query, updateRelValue: setRelationValue } as {
    fields: Field<any>[]
    fieldsMap: FieldMap
    query: GqlFetchQuery<any> | GqlRWQuery<any>
    updateRelValue?: (value: string) => void
  }
}
