import { Box } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router'
import useConfirmDialog from '../../../common/ConfirmDialog'
import PageContainer from '../../../common/PageContainer'
import EditText from '../../../form/EditText'
import EntityAutoComplete from '../../../form/EntityAutoComplete'
import { HiddenField } from '../../../form/HiddenField'
import NestedItemForm from '../../../form/NestedItemForm'
import GqlTable from '../../../table/GqlTable'
import CreateCity from '../../common/CreateCity'
import { SubjectType } from '../../hooks/useSubjectTypes'
import { ZeroApiContext } from '../../Organisation'
import { UserContext } from '../../UserApp'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    list: {
      ['&:first-child']: {
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
      },
      ['&:last-child']: {
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
      },
    },
    item: {
      width: 'unset',
      background: '#FFF',
      borderRadius: 2,
      boxShadow: '#AAA 1px 2px 4px',
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: 0,
    },
  }),
)

interface ServiceClass {
  id: string
  name: { [key: string]: string }
  classServiceTypes: {
    name: { [key: string]: string }
  }[]
}

interface Props {
  subjectType: SubjectType
}

export default ({ subjectType }: Props) => {
  const classes = useStyles()

  const navigate = useNavigate()
  const { itemId } = useParams()

  const user = React.useContext(UserContext)
  const organisation = React.useContext(ZeroApiContext)

  const confirmDialog = useConfirmDialog()

  const subjectTypeId = subjectType.id

  const treeLookup = (a: any, b: any) => a.company.id === b.company.parentSubjectId

  const filterRows = (rows: any[]) => rows.filter((row) => row.company.typeId === subjectTypeId)

  return (
    <PageContainer visible={!Boolean(itemId)} animate="fade">
      {confirmDialog.render()}

      {/* <Box sx={{ display: "flex", flexDirection: "row", m: 1 }}>
        <div style={{ flex: 1 }} />
        <Button variant="contained">{user.translate("add_new")}</Button>
      </Box> */}

      <Box sx={{ p: 1 }}>
        <GqlTable
          title={subjectType.name}
          entity="KomitentCompany"
          entityRelFieldValue={organisation.id}
          keys={['companyId', 'organisationId']}
          treeLookup={treeLookup}
          filterRows={filterRows}
          dense
        >
          <HiddenField name="organisationId" gql="String" value={organisation.id} />
          {/* <Switch name="active" gql="Long" label=" A" default={true} /> */}
          <NestedItemForm name="company" gql="Subject">
            <HiddenField name="id" gql="String" id />
            <HiddenField name="typeId" gql="String" value={subjectTypeId} />
            <EditText name="legalName" gql="String" value="" />
            <NestedItemForm name="name" gql="Translation">
              <EditText name={user.lang} label="name" gql="String" default="" width={24} />
              <HiddenField gql="String" name={user.secLang} default={(row: any) => (row ? row[user.lang] ?? row[`company_name_${user.lang}`] : '')} />
            </NestedItemForm>
            <NestedItemForm name="city" gql="City" transitient>
              <EntityAutoComplete name="countryId" entity="Country" label="country" all />
            </NestedItemForm>
            <EntityAutoComplete
              name="cityId"
              label="city_country"
              entity="City"
              byField="countryId"
              byFieldValue={(row) => row.company && row.company.city && row.company.city.countryId}
              freeSolo={CreateCity}
            >
              <HiddenField name="country" gql="Country" gqlSelection={['name', user.lang]} />
            </EntityAutoComplete>
            <EditText name="codeName" gql="String" value="" />
            <EditText name="vatNum" label="vat_num" gql="String" value="" hidden={!subjectType.hasRegInfo} />
            <EditText name="regNumber" label="reg_num" gql="String" value="" hidden={!subjectType.hasRegInfo} />
            <EditText name="address" label="address" gql="String" default="" />
            <EditText name="phone" label="contact_phone" gql="String" default="" />
            <EditText name="email" label="contact_email" gql="String" default="" />
            <EntityAutoComplete
              name="parentSubjectId"
              label="parent"
              entity="Subject"
              treeLookup="company.id"
              hidden={!subjectType.hasParent}
              nullable
            />
          </NestedItemForm>
        </GqlTable>
      </Box>
    </PageContainer>
  )
}
