import { alpha } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const drawerWidth = 240;

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
    },
    grow: {
      flexGrow: 1,
    },
    menu: {
      marginTop: 0,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {},
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions?.create("width", {
        easing: theme.transitions?.easing.sharp,
        duration: theme.transitions?.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: 72,
      [theme.breakpoints.up("sm")]: {
        width: 72,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    appBar: {
      // width: '100vw',
      [theme.breakpoints.down("md")]: {
        zIndex: theme.zIndex.drawer - 1,
      },
      [theme.breakpoints.up("md")]: {
        zIndex: theme.zIndex.drawer + 1,
      },
    },
    drawerContent: {
      display: "flex",
      "& .MuiDrawer-paperAnchorDockedLeft,": {
        borderColor: theme.palette.primary.light,
      },
      "& .MuiDivider-root": {
        backgroundColor: "#ffffff1f",
      },
      "& .MuiListItemIcon-root": {
        color: theme.palette.primary.contrastText,
        // color: "white",
      },
      "& .MuiListItemText-primary": {
        // color: theme.palette.primary.contrastText,
        // color: "white",
      },
      "& .MuiDrawer-paper": {
        backgroundColor: theme.palette.primary.dark,
        backgroundImage: `linear-gradient(to bottom right, ${theme.palette.primary.main} , ${theme.palette.primary.dark})`,
        // backgroundImage: `linear-gradient(to bottom right, #3d5a76 , #1b2838)`,
        color: "white",
      },
      "& ul a .MuiTypography-root": {
        color: "white",
      },
      "& ul a .Mui-selected": {
        backgroundColor: "rgba(255, 255, 255, 0.08)",
      },
    },
    content: {
      maxWidth: "100%",
      flexGrow: 1,
      display: "flex", 
      flexDirection: "column", 
      width: "100%",
      padding: 0,
    },
    nested: {
      color: "GrayText",
      paddingLeft: theme.spacing(1),
    },
    drawerItem: {
      transitionProperty: "padding, background, height",
      transitionDuration: theme.transitions.duration.enteringScreen + "ms",
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.black, 0.2),
      },
    },
    drawerGroup: {
      height: 55,
    },
  })
);
