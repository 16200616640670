import { Box } from "@mui/material"
import { makeStyles } from "@mui/styles"
import * as React from "react"
import { useNavigate, useParams } from "react-router"
import useConfirmDialog from "../../../common/ConfirmDialog"
import PageContainer from "../../../common/PageContainer"
import EditText from "../../../form/EditText"
import { HiddenField } from "../../../form/HiddenField"
import SelectOption from "../../../form/SelectOption"
import GqlTable from "../../../table/GqlTable"
import { ZeroApiContext } from "../../Organisation"
import { UserContext } from "../../UserApp"

const useStyles = makeStyles((theme) => ({
  root: {},
}))

export default () => {
  const classes = useStyles()

  const navigate = useNavigate()
  const { itemId } = useParams()

  const user = React.useContext(UserContext)
  const organisation = React.useContext(ZeroApiContext)

  const confirmDialog = useConfirmDialog()

  const onItemClick = (e: any, row: any) => {
    console.log(row)
    // navigate(`./../arr_group_${row.codeName}`)
  }
  
  const CustomerTitle = {
    Mr: user.translate("Mr"),
    Mrs: user.translate("Mrs"),
    Miss: user.translate("Miss"),
    Ms: user.translate("Ms"),
    Dr: user.translate("Dr"),
    Kid: user.translate("Kid"),
  }

  return (
    <PageContainer visible={!Boolean(itemId)} animate="fade">
      {confirmDialog.render()}

      <Box sx={{ p: 1 }}>
        <GqlTable entity="Customer" title="customers" onItemClick={onItemClick} dense>
          <HiddenField name="organisationId" gql="String" value={organisation.id} />
          <EditText name="firstName" gql="String" default="" />
          <EditText name="lastName" gql="String" default="" />
          <EditText name="intName" gql="String" default="" />
          <SelectOption name="title" options={CustomerTitle} width={1} />
          <EditText name="passport" gql="String" default="" />
          <EditText name="email" gql="String" default="" />
          <EditText name="phone" gql="String" default="" />
        </GqlTable>
      </Box>
    </PageContainer>
  )
}
