import * as React from 'react'
import { useParams } from 'react-router'
// import EditText from '../../../../form/EditText'
// import EditTextDateEpoch from '../../../../form/EditTextDateEpoch'
// import EntityAutoComplete from '../../../../form/EntityAutoComplete'
// import { HiddenField } from '../../../../form/HiddenField'
// import FormCreators from '../../../../form/FormCreators'
// import ItemForm from '../../../../form/ItemForm'
// import SelectOption from '../../../../form/SelectOption'
import { GqlFetchQuery } from '../../../../gql/interface'
import CreateSubject from '../../../common/CreateSubject'
import { IconNameEntity } from '../../../hooks/useIconNameEntityItems'
import { ZeroApiContext } from '../../../Organisation'
import { UserContext } from '../../../UserApp'
// import SelectCurrency from '../../../../form/SelectCurrency'
import { EditText, EditTextDateEpoch, EntityAutoComplete, FormCreators, GqlForm, HiddenField, SelectCurrency, SelectOption } from '@zeroapi-io/zeroapireact'

export interface Sale extends IconNameEntity {
  saleTypeId: string
  state: 'DRAFT' | 'CREATED' | 'SENT' | 'APPROVED' | 'FINISHED' | 'PAYED' | 'CANCELED' | 'REJECTED'
  activityDate?: number
  saleCustomersCount: number
  currency: string
}

interface Props {
  onCreate: (id?: string) => void
  setSale: (sale: Sale) => void
  queryRef?: React.MutableRefObject<GqlFetchQuery<any> | undefined>
}

export default function SaleForm({ onCreate, setSale, queryRef }: Props) {
  const { itemId: saleId } = useParams()

  const user = React.useContext(UserContext)
  const organisation = React.useContext(ZeroApiContext)

  const PayType = {
    CASH: user.translate('cash'),
    CARD: user.translate('card'),
    INVOICE: user.translate('invoice'),
  }
  //
  // const typesByClassId = (row: any, parent?: any): GqlFilter => ({
  //   by: 'classId',
  //   eqStr: parent?.classId,
  //   and: {
  //     by: 'parentTypeId',
  //     eqStr: null,
  //   },
  // })

  return (
    <GqlForm
      key={saleId}
      loadingVariant="circular"
      entity="Sale"
      entityRelFieldName="id"
      entityRelFieldValue={saleId}
      onSave={(data) => {
        if (!saleId) {
          onCreate(data.id)
        }
      }}
      onLoad={(data) => setSale(data)}
      onDelete={() => onCreate(undefined)}
      queryRef={queryRef}
      dense='dense'
    >
      <HiddenField name="id" id value={saleId} />
      <HiddenField name="organisationId" value={organisation.id} />
      <HiddenField name="saleCustomersCount" readOnly />
      <HiddenField name="soldById" value={user.userId} />
      <HiddenField name="saleTypeId" />
      <HiddenField name="state" />
      <EditText name="label" label="group_code" />
      <EntityAutoComplete name="billToId" label="komitent" entity="Subject" freeSolo={CreateSubject} nullable>
        <HiddenField name="email" />
        <HiddenField name="address" />
      </EntityAutoComplete>
      <EditText name="customerName" default={(item: any) => item?._billToId?.name} />
      {/* <EditText name="address" default={(item: any) => item?._billToId?.address} />
      <EditText name="email" default={(item: any) => item?._billToId?.email} /> */}
      <EditTextDateEpoch name="activityDate" />
      {/* <EditTextDateEpoch name="dueToDate" /> */}
      <SelectOption name="payType" options={PayType} />
      <SelectCurrency name="currency" default={'EUR'} />
      <FormCreators name="id" param={user} />
      <EditText name="comment" nullable fullWidth isForm standard />
    </GqlForm>
  )
}
