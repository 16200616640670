import InviteIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Button, Grid, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import * as React from 'react'
import { useRef } from 'react'
import useConfirmDialog from '../../../common/ConfirmDialog'
import PageContainer from '../../../common/PageContainer'
import Show from '../../../common/Show'
import { useEntityContextQuery } from '../../../gql/useEntityQuery'
import { ZeroApiContext } from '../../Organisation'
import { UserContext } from '../../UserApp'
import EmployeesDialog from './EmployeesDialog'
import InviteDialog from './InviteDialog'
import useViewSize from '../../../hooks/useViewSize'
import GqlTable from '../../../table/GqlTable'
import EditText from '../../../form/EditText'
import EditDate from '../../../form/EditDate'
import { GqlFetchQuery } from '../../../gql/interface'
import FormButton from '../../../form/FormButton'
import { useDynamicGqlMutation } from '../../../gql/useDynamicGql'
import CompositeField from '../../../form/CompositeField'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    list: {
      '&:first-child': {
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
      },
      '&:last-child': {
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
      },
    },
    item: {
      width: 'unset',
      background: '#FFF',
      borderRadius: 2,
      boxShadow: '#AAA 1px 2px 4px',
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: 0,
    },
  }),
)

interface OrganisationEmployee {
  id: string
  organisationId: string
  employeeId: string
  role: string
  lang: string
  employee: {
    profile: {
      name: true
      email: true
      phone: true
      birthday: true
    }
  }
}

export default function EmployeesList() {
  const classes = useStyles()
  const user = React.useContext(UserContext)
  const organisation = React.useContext(ZeroApiContext)

  const [editEmployeeId, setEditEmployeeId] = React.useState<string | undefined>()
  const [addEmployee, setAddEmployee] = React.useState<boolean>()
  const confirmDialog = useConfirmDialog()

  const { viewSize, viewRef } = useViewSize()

  const invitesQueryRef = useRef<GqlFetchQuery<any>>()

  const query = useEntityContextQuery(
    'OrganisationEmployee',
    [
      { name: 'employeeId', gql: 'String!' },
      {
        name: 'organisationId',
        gql: 'String!',
      },
      { name: 'role', gql: 'String!' },
      { name: 'lang', gql: 'String!' },
      {
        name: 'employee',
        gql: 'User',
        subSelection: { profile: { name: true, email: true, phone: true, birthday: true } },
        readOnly: true,
      },
    ],
    {
      pollInterval: 30000, // mode: "readOnly",
      keys: ['employeeId', 'organisationId'],
    },
  )

  const [deleteInvite] = useDynamicGqlMutation('auth_deleteInvite', { $inviteId: 'String!' }, { ok: true })

  const onEditClick = (item: OrganisationEmployee) => () => {
    setEditEmployeeId(item.employeeId)
  }

  const onDeleteClick = (item: OrganisationEmployee) => () => {
    if (item.employeeId === user.userId) {
      confirmDialog.show({
        title: user.translate('deleting'),
        message: user.translate('no_self_delete'),
        cancelButton: true,
      })
      return
    }
    confirmDialog.showConfirm('delete', 'delete_employee', () => query.deleteItem(item).then(query.refresh))
  }

  const items = query.items || []

  const roleNames = {
    NONE: user.translate('role_none'),
    ASSOCIATE: user.translate('role_associates'),
    EMPLOYEE: user.translate('role_employees'),
    MANAGER: user.translate('role_managers'),
    OWNER: user.translate('role_owners'),
  }

  const rolesOrder = Object.keys(roleNames)

  const itemsByRole = Object.entries(items.groupBy((it) => it.role)).sort(([role]) => rolesOrder.indexOf(role))

  const itemWidth = Math.ceil((12 * 420) / viewSize.width).closestTo(1, 2, 3, 4, 6, 12)

  const onDeleteInviteClick = (item: any) => {
    deleteInvite({
      variables: {
        inviteId: item._id,
      },
    }).then(invitesQueryRef.current?.refresh)
  }

  return (
    <PageContainer animate="fade" ref={viewRef}>
      {confirmDialog.render()}
      <EmployeesDialog employeeId={editEmployeeId} onRefresh={query.refresh} onClose={() => setEditEmployeeId(undefined)} />
      <InviteDialog
        show={addEmployee ?? false}
        onRefresh={() => invitesQueryRef.current?.refresh()}
        onClose={() => setAddEmployee(undefined)}
      />

      <Box sx={{ display: 'flex', flexDirection: 'row', m: 1 }}>
        <div style={{ flex: 1 }} />
        <Button variant="contained" onClick={() => setAddEmployee(true)} startIcon={<InviteIcon />}>
          {user.translate('send_invite')}
        </Button>
      </Box>

      <Show if={items.length === 0}>
        <Typography>{user.translate('no_items')}</Typography>
      </Show>
      <Grid container spacing={1}>
        {itemsByRole.flatMap(([role, items]) => {
          const headerView = (
            <Grid key={role} item xs={12} textAlign={'start'}>
              <Typography sx={{ pl: 1 }} variant={'subtitle1'}>
                {roleNames[role as keyof typeof roleNames]}
              </Typography>
            </Grid>
          )

          const itemViews = items.map((item: OrganisationEmployee, index: number) => (
            <Grid key={item.id} item xs={itemWidth}>
              <ListItem key={item.id} className={classes.item}>
                <ListItemButton key={item.id} onClick={onEditClick(item)}>
                  <ListItemText
                    primary={item.employee.profile.name}
                    secondary={
                      <>
                        {item.role}
                        <br />
                        {item.employee.profile.email} - {item.employee.profile.phone}
                      </>
                    }
                    primaryTypographyProps={{
                      color: 'primary',
                      style: { fontSize: 18, fontWeight: 500, letterSpacing: 0 },
                    }}
                    secondaryTypographyProps={{ style: { fontSize: 12, fontWeight: 400 } }}
                  />
                  <EditIcon color="action" />
                </ListItemButton>
                <ListItemButton sx={{ flex: 1, height: 72 }} onClick={onDeleteClick(item)}>
                  <DeleteIcon color="action" />
                </ListItemButton>
              </ListItem>
            </Grid>
          ))
          return [headerView].concat(itemViews)
        })}
      </Grid>

      <br />

      <GqlTable
        queryRef={invitesQueryRef}
        style={{ margin: 8 }}
        entity="invites"
        customQuery="auth_getOrganisationInvites"
        entityRelFieldName="organisationId"
        entityRelFieldValue={organisation.id}
        keys={['email']}
        readOnly
      >
        <EditText name="email" />
        <EditText name="role" />
        <EditDate name="created" />
        <CompositeField name={'createdBy'}>
          <EditText name="createdByName" />
          <EditText name="createdByEmail" />
        </CompositeField>
        <EditDate name="valid" />
        <FormButton onClick={onDeleteInviteClick} name="_id" title="delete" />
      </GqlTable>
    </PageContainer>
  )
}
