import { Beenhere } from '@mui/icons-material'
import { Button, Divider, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import moment, { Moment } from 'moment'
import * as React from 'react'
import { useContext, useState } from 'react'
import AppDialog from '../../../../../../common/AppDialog'
import Loading from '../../../../../../common/Loading'
import Show from '../../../../../../common/Show'
import EditTextInt from '../../../../../../form/EditTextInt'
import { FormField } from '../../../../../../form/useFormQuery'
import UseBulkMutation, { MultipleBulkMutateCallback } from '../../../../../../gql/UseBulkMutation'
import useWindowSize from '../../../../../../hooks/useWindowSize'
import { UserContext } from '../../../../../UserApp'
import { SaleCustomerServices } from '../ServicePriceChooserDialog'
import ProviderOption from './ProviderOption'
import { SaleService } from '../../../Types'
import { ProviderOptions } from '../queries/useServicePricesByDate'
import EditDate from '../../../../../../form/EditDate'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ['& .MuiBox-root > div > div > div > table']: {
        width: 'auto',
      },
      ['& .MuiBox-root > div > div > div:nth-child(2)']: {
        width: '100px !important',
      },
    },
    offerItem: {
      fontSize: 12,
      fontWeight: 500,
      borderRadius: 2,
      width: '100%',
      height: '100%',
      lineHeight: 1,
      padding: 4,
      textTransform: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      background: '#EFE',
      transition: 'background 0.3s 0s ease',
      ['&:hover']: {
        background: '#DED',
      },
    },
  }),
)

interface FormState {
  option?: string
  dateFrom: number
  dateTo: number
  customers: number
}

interface Props {
  saleService?: SaleService
  customers?: SaleCustomerServices[]
  providers?: ProviderOptions[]
  dayIndex: number
  startDate?: Moment
  endDate?: Moment
  onUpdate?: (data?: any) => Promise<any | void> | undefined
  onClose: (arg?: any) => void
}

export default function ProviderServiceChooserDialog({
  saleService,
  customers,
  providers,
  dayIndex,
  startDate,
  endDate,
  onClose,
  onUpdate,
}: Props) {
  const classes = useStyles()
  const user = useContext(UserContext)
  const bulkMutationRef = React.useRef<MultipleBulkMutateCallback>()

  const size = useWindowSize()

  const [isLoading, setIsLoading] = useState(false)

  const [formState, setFormState] = useState<FormState>({
    dateFrom: startDate?.toDate()?.getTime() || Date.now(),
    dateTo: endDate?.toDate()?.getTime() || Date.now(),
    customers: customers?.length || 0,
  })

  if (!saleService) return null

  const chosenOption = providers?.firstOrNull((provider) => provider.options[dayIndex].find((it) => it.id === formState.option))

  const getFieldProps = <T extends any>(field: string): FormField<T> => ({
    name: field,
    value: (formState as any)[field] as T,
    isForm: true,
    size: 'small',
    onChange: (value: any) => {
      setFormState({ ...formState, [field]: value })
    },
  })

  const isUoMDay = saleService.class.uom !== 'DAY'

  const dateFrom = moment(parseInt(formState.dateFrom.toString()))
  const dateTo = moment(parseInt(formState.dateTo.toString()))

  const quantity = Math.max(saleService.class.uom === 'DAY' ? dateTo.diff(dateFrom, 'days') : 1, 1)

  const total = chosenOption ? chosenOption.price * Math.ceil(formState.customers / (chosenOption.type?.capacity || 1)) * quantity : 0

  const totalPrice = total.formatCurrency('EUR')

  const onBookClick = async () => {
    setIsLoading(true)

    if (!bulkMutationRef.current || !customers || !providers || !chosenOption) return

    const createItems: any[] = []
    const updateItems: any[] = []
    const deleteItems: any[] = []

    const bookFrom = moment(formState.dateFrom)
    const bookTo = moment(formState.dateTo)

    for (let index = 0; index < customers.length; index++) {
      const customer = customers[index]
      createItems.push({
        input: {
          customerId: customer.saleCustomer.customer.id,
          serviceSaleId: saleService.id,
          providerId: chosenOption.provider?.id,
          servicePriceId: chosenOption.id,
          typeId: chosenOption.type?.id,
          currency: chosenOption.currency,
          price: chosenOption.price,
          cost: chosenOption.cost,
          dateFrom: bookFrom.toDate().toISODate(),
          dateTo: bookTo.toDate().toISODate(),
        },
      })

      customer.services.forEach((service) => {
        const startsIn = moment(service.dateFrom).isBetween(bookFrom, bookTo, 'hours', '[]')
        const endsIn = moment(service.dateTo).isBetween(bookFrom, bookTo, 'hours', '[]')

        console.log('service', service.id, startsIn, endsIn, service.dateFrom, service.dateTo)
        if (startsIn && endsIn) {
          deleteItems.push({ id: service.id })
        } else if (startsIn) {
          updateItems.push({ input: { id: service.id, dateFrom: bookTo.clone().add(1, 'days').toDate().toISODate() } })
        } else if (endsIn) {
          updateItems.push({ input: { id: service.id, dateTo: bookFrom.toDate().toISODate() } })
        }
      })
    }

    try {
      const result = await bulkMutationRef.current({ create: createItems, edit: updateItems, delete: deleteItems })

      onUpdate && (await onUpdate(result))
    } catch (e) {
      alert(user.translate('operation_failed'))
      console.log('err', e)
    }

    setIsLoading(false)
  }

  const serviceName = saleService.class.name[user.lang] || '..'

  const saleDateFrom = moment(saleService.dateFrom).toDate()
  const saleDateTo = moment(saleService.dateTo).toDate()

  return (
    <AppDialog open={Boolean(providers)} onClose={onClose} title={`${serviceName} (${customers?.length})`}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <EditDate {...getFieldProps('dateFrom')} label={isUoMDay ? 'date' : 'date_from'} minDate={saleDateFrom} maxDate={saleDateTo} date />
        <Show if={saleService.class.uom === 'DAY'}>
          <EditDate
            {...getFieldProps('dateTo')}
            minDate={(row) => moment(formState.dateFrom).add(1, 'day').toDate()}
            maxDate={saleDateTo}
            date
          />
        </Show>

        <Divider style={{ width: '100%' }} />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 8,
            width: '100%',
          }}
        >
          {providers?.map((providerOptions) => {
            const options = providerOptions?.options[dayIndex]?.orderBy((option) => option.price / (option.type?.capacity || 1))
            const bookings = providerOptions?.bookings[dayIndex]?.filter((booking) => {
              return (
                booking.service.providerId === providerOptions.providerId &&
                customers?.any((customer) => {
                  return customer.saleCustomer.id === booking.customer.id
                })
              )
            })

            return (
              <>
                <Typography key={providerOptions.providerId} variant={'h6'}>
                  {providerOptions.provider}
                </Typography>
                {options?.map((option) => (
                  <ProviderOption
                    key={option.id}
                    option={option}
                    bookings={bookings?.filter((booking) => booking.service.servicePriceId === option.id)}
                    saleService={saleService}
                    isSelected={chosenOption === option}
                    isMobile={size.isMobile}
                    onSelect={() => setFormState({ ...formState, option: option.id })}
                    onUpdate={onUpdate}
                  />
                ))}
                <div style={{ height: 4 }} />
              </>
            )
          })}
        </div>

        <Divider style={{ width: '100%' }} />

        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
            padding: 8,
          }}
        >
          <EditTextInt {...getFieldProps('customers')} style={{ width: 100, flex: 0 }} type="number" minValue={1} />
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {totalPrice}
          </div>
          <Button
            style={{ fontSize: 16 }}
            startIcon={<Beenhere fontSize="small" style={{ height: 16 }} />}
            disabled={!chosenOption}
            onClick={onBookClick}
          >
            {user.translate('book')}
          </Button>
        </div>
        <Loading show={isLoading} />
        <UseBulkMutation entity="CustomerService" bulkMutationRef={bulkMutationRef} />
      </div>
    </AppDialog>
  )
}
