import { Button } from '@mui/material'
import React, { CSSProperties, useContext } from 'react'
import { UserContext } from '../app/UserApp'
import { FormField } from './useFormQuery'
import { callOrGet, ifNull } from './utils'

type Color = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
type Variant = 'text' | 'outlined' | 'contained'

interface FormFieldProps extends FormField<any> {
  enabled?: true | ((value: any, item: any) => boolean)
  disableOnEdit?: true | ((value: any, item: any) => boolean)
  title?: string | JSX.Element | ((value: any, item: any) => string)
  variant?: Variant | ((value: any, item: any) => Variant)
  color?: Color | ((value: any, item: any) => Color | undefined)
  startIcon?: React.ReactNode | ((value: any, item: any) => React.ReactNode | undefined)
  endIcon?: React.ReactNode | ((value: any, item: any) => React.ReactNode | undefined)
  contentWidth?: number | string | ((value: any, item: any) => number | undefined)
  justifyContent?: 'left' | 'right' | 'center'
  onClick: (item: any) => void
}

export default function FormButton(props: FormFieldProps) {
  const user = useContext(UserContext)

  const item = props.item || props.rowData

  const value = ifNull(props.value, callOrGet(props.default, item))

  const size = props.size ? (props.size === 'small' ? 'small' : 'medium') : props.isForm ? 'medium' : 'small'
  const contentWidth = callOrGet(props.contentWidth, value, item)
  const justifyContent = callOrGet(props.justifyContent, value, item)

  const title = React.isValidElement(props.title)
    ? props.title
    : user.translate(callOrGet(props.title, value, item) || props.label || props.name)
  const disabled =
    callOrGet(props.disableOnEdit, value, item) ?? callOrGet(props.disabled, value, item) ?? !callOrGet(props.enabled, value, item)

  const style: CSSProperties = { whiteSpace: 'nowrap' }
  if (!props.isForm) style.fontSize = 12
  if (contentWidth !== undefined) style.width = contentWidth
  if (justifyContent) style.justifyContent = justifyContent

  return (
    <Button
      disabled={disabled}
      size={size}
      startIcon={callOrGet(props.startIcon, value, item)}
      endIcon={callOrGet(props.endIcon, value, item)}
      color={callOrGet(props.color, value, item) || 'primary'}
      variant={callOrGet(props.variant, value, item) || 'outlined'}
      style={style}
      onClick={() => props.onClick(item)}
    >
      {title}
    </Button>
  )
}

FormButton.render = (props: FormFieldProps, value: any, rowData: any) => (
  <FormButton enabled {...props} disableOnEdit={undefined} rowData={rowData} value={value} />
)

FormButton.readOnly = true
