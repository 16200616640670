import { FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { default as React, useContext, useEffect } from 'react'
import { UserContext } from '../app/UserApp'
import Show from '../common/Show'
import { callOrGet, ifNull, preventPropagation } from './utils'
import { GqlTabletField } from '../table/GqlTable'

const useStyles = makeStyles((theme: Theme) => ({
  inlineControl: {
    display: 'inline-grid',
    minWidth: 120,
  },
  formControl: {
    display: 'inline-grid',
    flexGrow: 1,
    minWidth: 60,
  },
  tableControl: {
    display: 'inline-grid',
    flexGrow: 1,
    minWidth: 60,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

export interface Option {
  value: string
  name: string
  desc?: string
}

interface FormFieldProps extends GqlTabletField<string> {
  options: { [key: string]: string } | Option[] | ((row: any, parentRow: any) => Option[] | { [key: string]: string })
  onClear?: () => void
}

const SelectOption = (props: FormFieldProps) => {
  const classes = useStyles()
  const user = useContext(UserContext)

  const item = props.rowData || props.item
  const propsOptions = callOrGet(props.options, item, props.parentRowData)

  const options: Option[] = Array.isArray(propsOptions)
    ? (propsOptions as Option[])
    : Object.keys(propsOptions).map((key) => ({
        value: key,
        name: propsOptions[key],
      }))

  const propsValue = callOrGet(props.value, item)
  const value = ifNull(propsValue, callOrGet(props.default, item)) || (options.length > 0 && options[0].value)

  const onChange = props.onChange ? props.onChange : () => {}

  useEffect(() => {
    if (value !== propsValue) {
      console.log('onchange', value, propsValue)
      onChange(value)
    }
    // eslint-disable-next-line
  }, [value, propsValue])

  return (
    <FormControl
      fullWidth={props.fullWidth}
      className={props.isForm ? classes.formControl : props.isInline ? classes.inlineControl : classes.tableControl}
      sx={{ m: props.isForm ? 1 : 0 }}
      size={props.size !== 'normal' ? 'small' : 'medium'}
    >
      <Show if={props.isForm || props.isInline}>
        <InputLabel htmlFor={`select-${props.name}`} variant={'outlined'}>
          {React.isValidElement(props.label) ? props.label : user.translate(props.label?.toString() || props.name.snakeCase())}
        </InputLabel>
      </Show>
      <Select
        fullWidth={props.fullWidth}
        style={{ fontSize: props.size !== 'normal' ? 14 : 16 }}
        size={props.size !== 'small' ? 'medium' : 'small'}
        value={value}
        label={
          props.isForm || props.isInline
            ? (React.isValidElement(props.label) ? props.label : user.translate(props.label?.toString() || props.name.snakeCase())) + '#'
            : undefined
        }
        variant={props.isForm || props.isInline ? 'outlined' : 'standard'}
        onKeyDown={preventPropagation}
        inputProps={{
          id: `select-${props.name}`,
        }}
        error={callOrGet(props.error, value)}
        disabled={callOrGet(props.disabled, value, item)}
        onChange={(event: SelectChangeEvent<any>) => {
          const option = options.find((opts) => opts.value === event.target.value)
          onChange(option?.value ? option?.value : null)
        }}
      >
        {options.map((option) => {
          if (option.desc) {
            return (
              <MenuItem key={option.value} value={option.value} onKeyDown={preventPropagation}>
                <ListItemText secondary={option.desc}>{option.name}</ListItemText>
              </MenuItem>
            )
          }
          return (
            <MenuItem key={option.value} value={option.value} onKeyDown={preventPropagation}>
              {option.name}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
SelectOption.gql = 'String'

SelectOption.render = (props: FormFieldProps, rowValue: string, row: any) => {
  const propsValue = props.name && rowValue && typeof rowValue == 'object' ? rowValue[props.name] : rowValue
  const value = ifNull(propsValue, callOrGet(props.default, props.item))

  const propsOptions = callOrGet(props.options, row, props.parentRowData)

  const options: Option[] = []
  for (const k in propsOptions) {
    options.push({ value: k, name: propsOptions[k] })
  }

  const option = options.find((opts) => opts.value === value)

  return <div style={{ display: 'inline-block' }}>{option?.name}</div>
}

export default SelectOption
