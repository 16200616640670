import * as React from 'react'
import EditText from '../../form/EditText'
import { HiddenField } from '../../form/HiddenField'
import FormDialog from '../../form/FormDialog'
import NestedItemForm from '../../form/NestedItemForm'
import SelectTimezone from '../../form/SelectTimezone'
import { UserContext } from '../UserApp'
import SelectCurrency from "../../form/SelectCurrency";

type Props = {
  value: string
  onClose: (t: any) => void
}
export default function CreateCountry({ value, onClose }: Props) {
  const user = React.useContext(UserContext)
  return (
    <FormDialog entity="Country" item={true} onClose={onClose} all autoClose>
      <HiddenField name="id" id />
      <NestedItemForm name="name" gql="Translation">
        <EditText name={user.lang} label="name" gql="String" value={value} fullWidth />
        <HiddenField name={user.secLang} value={(row: any) => (row ? row[user.lang] ?? row[`name_${user.lang}`] : '')} />
      </NestedItemForm>
      <EditText name="iso" label="iso2" gql="String" default="" width={1} />
      <EditText name="iso3" label="iso3" gql="String" default="" width={1} />
      <SelectCurrency name="currency" default={'EUR'} />
      <SelectTimezone name="timezone" gql="String" />
    </FormDialog>
  )
}
