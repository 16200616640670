import React from "react"
import EditText from "./EditText"
import { FormField } from "./useFormQuery"
import { callOrGet } from "./utils"

type FormFieldProps = FormField<number> & {
  minValue?: number
  maxValue?: number
  decimals?: number
  currency?: boolean
  hint?: string
  type?: React.InputHTMLAttributes<unknown>["type"]
  unit: string | ((row: any) => string)
}

const uoms: { [key: string]: any } = {
  PIECE: "p",
  PERCENTAGE: "%",
  MKD: "д",
  EUR: "e",
  USD: "d",
}

const getUnit = (props: FormFieldProps, row: any) => {
  return uoms[callOrGet(props.unit, row)]
}

const EditTextFloatUnit = (props: FormFieldProps) => {
  const uom = getUnit(props, props.item || props.rowData)

  return <EditText {...props} align={props.align || "right"} endAdr={uom} decimalNumber />
}

EditTextFloatUnit.render = (props: FormFieldProps, value: number, row: any) => {
  const uom = getUnit(props, row)

  const decimals = props.decimals !== undefined ? props.decimals : 2
  const multipler = Math.pow(10, decimals)
  const roundedValue = Math.floor(value * multipler) / multipler

  return (
    <>
      {roundedValue} {uom}
    </>
  )
}

EditTextFloatUnit.validate = (props: FormFieldProps, value: number) => {
  if (props.minValue != undefined && value < props.minValue) return true
  if (props.maxValue != undefined && value > props.maxValue) return true
  return false
}

EditTextFloatUnit.gql = "Float"
EditTextFloatUnit.align = "right"
EditTextFloatUnit.noPadding = true

export default EditTextFloatUnit
