import { useContext } from "react"
import { UserContext } from "../UserApp"

export default function useCustomerTitles() {
  const user = useContext(UserContext)
  return {
    Mr: user.translate("Mr"),
    Mrs: user.translate("Mrs"),
    Miss: user.translate("Miss"),
    Ms: user.translate("Ms"),
    Dr: user.translate("Dr"),
    Kid: user.translate("Kid"),
  }
}
