import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { default as React, useContext } from 'react'
import { UserContext } from '../app/UserApp'
import Show from '../common/Show'
import { FormField } from './useFormQuery'
import { callOrGet, ifNull, preventPropagation } from './utils'

var moment = require('moment-timezone')

interface Option {
  id: string
  name: string
}

// console.log(moment.tz.names())
// console.log(new Set(moment.tz.names().map((it: string) => it.split("/")[0])))
const options: Option[] = moment.tz
  .names()
  .filter((it: string) => it.startsWith('Europe') || it.startsWith('Etc') || it.startsWith('UTC'))
  .map((timezone: string) => ({
    id: timezone,
    name: timezone,
  }))

const useStyles = makeStyles((theme: Theme) => ({
  rootForm: {
    display: 'inline-grid',
    margin: theme.spacing(1),
    width: '100%',
    padding: 0,
    minWidth: '18ch',
    flexGrow: 1,
    '& > .MuiTextField-root': {
      display: 'grid',
      width: '-webkit-fill-available',
    },
    verticalAlign: '-webkit-baseline-middle',
  },
  root: {
    display: 'inline-grid',
    marginLeft: 0,
    marginRight: theme.spacing(1),
    marginBottom: 2,
    padding: 0,
    minWidth: '18ch',
    flexGrow: 1,
    '& > .MuiTextField-root': {
      display: 'grid',
      width: '-webkit-fill-available',
    },
    verticalAlign: '-webkit-baseline-middle',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

interface FormFieldProps extends FormField<string> {
  onClear?: () => void
}

const SelectTimezone = (props: FormFieldProps) => {
  const classes = useStyles()
  const user = useContext(UserContext)

  const item = props.item ?? props.rowData
  const value = ifNull(props.value, callOrGet(props.default, item))

  const selected = options.find((opts) => opts.id == value)
  console.log(selected)

  const onChange = props.onChange ? props.onChange : () => {}

  return (
    <FormControl
      className={props.isForm ? classes.rootForm : classes.root}
      sx={{ m: props.isForm ? 1 : 0, ml: 1, mb: 0 }}
      style={!props.fullWidth ? { flexGrow: 1 } : { flexGrow: 1 }}
      size={props.size === 'small' ? 'small' : 'medium'}
    >
      <Show if={props.isForm}>
        <InputLabel htmlFor={`select-${props.name}`} variant={props.isForm ? 'outlined' : 'standard'}>
          {React.isValidElement(props.label) ? props.label : user.translate(props.label?.toString() || props.name.snakeCase())}
        </InputLabel>
      </Show>
      <Select
        key={value}
        fullWidth={props.fullWidth}
        style={{ fontSize: props.size === 'small' ? 12 : 14, marginBottom: 0 }}
        value={value}
        label={
          props.isForm
            ? React.isValidElement(props.label)
              ? props.label
              : user.translate(props.label?.toString() || props.name.snakeCase())
            : undefined
        }
        variant={props.isForm ? 'outlined' : 'standard'}
        onKeyDown={preventPropagation}
        inputProps={{
          id: `select-${props.name}`,
        }}
        error={callOrGet(props.error, value)}
        disabled={callOrGet(props.disabled, value, item)}
        onChange={(event: SelectChangeEvent<any>, child: React.ReactNode) => {
          const option = options.find((opts) => opts.id === event.target.value)
          onChange(option?.id ? option?.id : null)
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id} onKeyDown={preventPropagation}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
SelectTimezone.gql = 'String'

const Render = (props: FormFieldProps) => {
  const user = useContext(UserContext)

  const propsValue = props.name && props.value && typeof props.value == 'object' ? props.value[props.name] : props.value
  const value = ifNull(propsValue, callOrGet(props.default, props.item))

  const option = options.find((opts) => opts.id == value)

  return <div style={{ display: 'inline-block' }}>{option?.name}</div>
}

SelectTimezone.render = (props: FormFieldProps, rowValue: string, row: any) => {
  return <Render {...props} value={rowValue} rowData={row} />
}

export default SelectTimezone
