import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { UserContext } from '../app/UserApp'
import useUID from '../common/useUID'
import { HiddenField } from './HiddenField'
import { callOrGet, ifNull } from './utils'

export default function NestedItemForm(props) {
  const user = useContext(UserContext)

  const entityRelFieldName = props.name
  const entityRelFieldValue = props.value

  const fieldsMap = []

  const keys = props.keys || ['id']

  const genUid = useUID()

  const relValue = entityRelFieldValue || genUid

  keys.forEach((key) => {
    fieldsMap[key] = {
      name: key,
      gql: 'String!',
      id: key === 'id',
      value: entityRelFieldName === key ? relValue : genUid,
    }
  })

  React.Children.forEach(props.children, (child) => {
    const props = child.props
    const gqlType = props.gql || child.type.gql
    if (gqlType == undefined) console.warn('gql-type not defined in ', child.type.name)

    fieldsMap[props.name] = {
      name: props.name,
      default: props.default,
      value: props.value,
      hidden: callOrGet(props.hidden),
      gql: props.nullable ? gqlType : `${gqlType}!`,
    }
  })

  const fields = Object.values(fieldsMap)

  const data =
    props.value ||
    fields.toMapBy(
      (field) => field.name,
      (field) => ifNull(callOrGet(field.value), callOrGet(field.default)),
    )

  const itemData = props.value
  const setItemData = props.onChange

  const item = itemData || data

  const onChange = (name) => (value) => {
    setItemData({ ...item, [name]: value, _orgState: item._orgState || item })
  }

  let hasError = false
  const children = React.Children.map(props.children, (child) => {
    if (!React.isValidElement(child)) return child
    if (child.type === HiddenField) return null

    const childProps = child.props
    const fieldName = childProps.name
    if (fieldName === undefined) return child

    const field = fieldsMap[fieldName]
    if (field === undefined) return child

    const value = ifNull(item[fieldName], field.value)

    if (callOrGet(childProps.hidden, value, item) === true) return null

    const error = value == undefined || value?.length == 0 || callOrGet(child.type.validate, childProps, value)

    if (error) hasError = true

    return React.cloneElement(child, {
      key: childProps.key || fieldName,
      label: user.translate(childProps.label || fieldName),
      isForm: props.isForm,
      value: value,
      hidden: false,
      item: item,
      disabled: childProps.disabled || props.isLoading,
      error: error,
      size: props.dense ? 'small' : 'medium',
      onChange: onChange(fieldName),
      onSubmit: props.onSubmit,
      style: {
        flexGrow: 1,
      },
    })
  })

  return children
}

NestedItemForm.propTypes = {
  gql: PropTypes.string,
  name: PropTypes.string.isRequired,
  nullable: PropTypes.bool,
  transitient: PropTypes.bool,
  entityRelFieldName: PropTypes.string,
  entityRelFieldValue: PropTypes.string,
  children: PropTypes.node.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  dependent: PropTypes.bool,
  keys: PropTypes.array,
}
