import { Box } from '@mui/material'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router'
import PageContainer from '../../../common/PageContainer'
import EditText from '../../../form/EditText'
import { HiddenField } from '../../../form/HiddenField'
import NestedItemForm from '../../../form/NestedItemForm'
import GqlTable from '../../../table/GqlTable'
import { UserContext } from '../../UserApp'
import FilterField from '../../../form/FilterField'
import useSimpleEntityItem from '../../hooks/useSimpleEntityItem'
import { ZeroApiContext } from '../../Organisation'

export default function LocationsList() {
  const navigate = useNavigate()
  const { cityId } = useParams()

  const user = React.useContext(UserContext)
  const organisation = React.useContext(ZeroApiContext)

  const { loading, name: cityName } = useSimpleEntityItem('City', 'id', cityId)

  const onItemClick = (e: any, row: any) => {
    navigate(`./${row.id}`)
  }

  return (
    <PageContainer visible={Boolean(cityId)} animate="fade">
      <Box sx={{ p: 1 }}>
        <GqlTable
          entity="Location"
          title={(loading ? '...' : cityName) + ' - ' + user.translate('locations')}
          onItemClick={onItemClick}
          deps={[loading]}
          dense
        >
          <HiddenField name="id" id />
          <HiddenField name="organisationId" value={organisation.id} />
          <FilterField name="cityId" value={cityId} />
          <EditText name="code" default="" width={1} />
          <NestedItemForm name="name" gql="Translation">
            <EditText
              name={user.lang}
              label={`${user.translate('name')} (${user.lang.toUpperCase()})`}
              gql="String"
              default=""
              width={48}
            />
            <EditText
              name={user.secLang}
              label={`${user.translate('name')} (${user.secLang.toUpperCase()})`}
              gql="String"
              default=""
              width={48}
            />
          </NestedItemForm>
          <HiddenField name="latitude" label="latitude" group="geo" gql="Float" default={0.0} />
          <HiddenField name="longitude" label="latitude" group="geo" gql="Float" default={0.0} />
        </GqlTable>
      </Box>
    </PageContainer>
  )
}
