import { Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import React from "react"
import { FormField } from "./useFormQuery"
import { callOrGet, ifNull } from "./utils"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: 28,
    marginRight: 4,
    marginLeft: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "min-content",
    position: "relative"
  },
  icon: {
    marginTop: theme.spacing(2),
  },
  label: {
    fontSize: 12,
  },
  labelOverlap: {
    fontSize: 12,
    position: "absolute",
    color: "white",
    fontWeight: 600,
  },
}))

interface FormFieldProps extends FormField<any> {
  showZero?: boolean
  icon: React.ReactElement
  noOverlap?: boolean
}

const QuantityIconInfo = (props: FormFieldProps) => {
  const classes = useStyles()
  const value = ifNull(props.value, callOrGet(props.default, props.item))

  if (value === 0 && !props.showZero) return <span></span>

  if (props.noOverlap) {
    return (
      <div className={classes.root}>
        {React.cloneElement(props.icon, { ...props.icon.props, fontSize: "inherit", htmlColor: "primary" })}
        <span className={classes.label}>{value}</span>
      </div>
    )
  }
  return (
    <div className={classes.root}>
      {React.cloneElement(props.icon, { ...props.icon.props, fontSize: "inherit", htmlColor: "green", opacity: 0.8 })}
      <span className={classes.labelOverlap}>{value}</span>
    </div>
  )
}
QuantityIconInfo.gql = "Long"
QuantityIconInfo.editable = false
QuantityIconInfo.readOnly = true
QuantityIconInfo.width = 1
QuantityIconInfo.render = (props: FormFieldProps, value: number) => <QuantityIconInfo {...props} value={value} />

export default QuantityIconInfo
