import { useEffect } from "react"

export default (...deps: any[]) => {
  let hasRun = false
  let effectCallback: (() => void) | undefined

  useEffect(() => {
    effectCallback && effectCallback()
    effectCallback = undefined
    hasRun = true

    if (effectCallback === undefined) return

    return () => {
      hasRun = true
      effectCallback = undefined
    }
  })

  return (callback: () => void) => {
    if (hasRun) {
      callback()
    } else {
      effectCallback = callback
    }
  }
}
