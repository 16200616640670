import { Fade, Slide } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      left: 0,
      right: 0,
      background: '#ececf3',
      padding: theme.spacing(1),
    },
  }),
)

interface Props {
  visible?: boolean
  children: React.ReactNode
  animate?: 'fade' | 'slide'
  padding?: number
}

const PageContainer = React.forwardRef<HTMLDivElement, Props>(({ visible, children, animate, padding }, ref) => {
  const classes = useStyles()

  const style = padding !== undefined ? { padding } : {}

  if (animate === 'fade') {
    return (
      <Fade in={visible !== false} mountOnEnter unmountOnExit appear enter exit timeout={350}>
        <div ref={ref} className={classes.root} style={style}>
          {children}
        </div>
      </Fade>
    )
  } else if (animate === 'slide') {
    return (
      <Slide direction="left" in={visible} mountOnEnter unmountOnExit appear={false} enter exit timeout={350}>
        <div ref={ref} className={classes.root} style={style}>
          {children}
        </div>
      </Slide>
    )
  }

  return (
    <div ref={ref} className={classes.root}>
      {children}
    </div>
  )
})

export default PageContainer
