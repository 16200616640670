import { useContext } from 'react'
import { GqlFilter } from '../../gql/interface'
import { useEntityContextReadOnlyQuery } from '../../gql/useEntityQuery'
import { UserContext } from '../UserApp'

export interface IconNameEntity {
  id: string
  codeName: string
  name: string
  icon: string
  simple: number
  faIcon: () => JSX.Element
}

interface Result {
  loading: boolean
  items: IconNameEntity[]
}

export default function useIconNameEntityItems(
  name: string,
  filter: GqlFilter | undefined = undefined,
  selection: any | undefined = undefined,
): Result {
  const user = useContext(UserContext)
  const fields = [
    { name: 'id', gql: 'String!' },
    { name: 'codeName', gql: 'String!' },
    { name: 'icon', gql: 'String!' },
    {
      name: 'name',
      gql: 'Translation!',
      subSelection: { [user.lang.toLocaleLowerCase()]: true },
    },
  ]
  if (selection) {
    Object.keys(selection).forEach((key) => {
      const value = selection[key]
      if (typeof value === 'string') {
        fields.push({
          name: key,
          gql: value,
        })
      } else {
        const gql = value.gql
        delete value.gql
        fields.push({
          name: key,
          gql: gql,
          subSelection: value,
        })
      }
    })
  }
  const query = useEntityContextReadOnlyQuery(name, fields, { filter })
  if (query.isLoading) return { loading: true, items: [] }
  if (query.items === undefined) throw Error(`Can not load ${name}`)
  return { loading: false, items: query.items.map((it: any) => ({ ...it, name: it.name[user.lang] })) }
}
