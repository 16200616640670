import { GqlTabletField } from '../table/GqlTable'
import { callOrGet } from './utils'

interface FormFieldProps extends GqlTabletField<any> {
  isName?: boolean
  hidden?: boolean
}

export function HiddenField(props: FormFieldProps, value: any, row: any) {
  const item = props.item || props.rowData || {}

  if (props.valueFromParent && props.parentRowData) {
    value = props.parentRowData[callOrGet(props.valueFromParent, props.parentRowData)]
  }

  if (item[props.name] !== value) {
    item[props.name] = value
  }
  return null
}

HiddenField.gql = 'String'

HiddenField.render = (props: any, value: any, row: any) => <HiddenField {...props} value={value} rowData={row} />
