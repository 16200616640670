import React from "react"
import EditText from "./EditText"
import { FormField } from "./useFormQuery"
import { GqlTabletField } from "../table/GqlTable";

type FormFieldProps = GqlTabletField<number> & {
  minValue?: number
  maxValue?: number
  decimals?: number
  currency?: boolean
  hint?: string
  type?: React.InputHTMLAttributes<unknown>["type"]
}

const EditTextFloat = (props: FormFieldProps) => {
  return <EditText {...props} decimalNumber/>
}

EditTextFloat.gql = "Float"

EditTextFloat.validate = (props: FormFieldProps, value: number) => {
  if (props.minValue !== undefined && value < props.minValue) return true
  return props.maxValue !== undefined && value > props.maxValue;

}

EditTextFloat.align = "right"
EditTextFloat.noPadding = true

EditTextFloat.render = (props: FormFieldProps, value: number, row: any) => {
  if (props.currency) {
    return value.formatCurrency(row.currency)
  }
  const decimals = props.decimals !== undefined ? props.decimals : 2

  const multipler = Math.pow(10, decimals)
  return Math.floor(value * multipler) / multipler
}

export default EditTextFloat
