import { useContext } from 'react'
import { useEntityRelationReadOnlyQuery } from '../../gql/useEntityRelationReadOnlyQuery'
import { UserContext } from '../UserApp'

export interface IconItem {
  id: string
  icon: string
  name: { [key: string]: string }
}

export default function useSimpleEntityIconItem(entity: string, relField: string, relValue?: string) {
  const user = useContext(UserContext)
  const fields = [
    { name: 'id', gql: 'String!' },
    { name: 'icon', gql: 'String!' },
    {
      name: 'name',
      gql: 'Translation!',
      subSelection: { [user.lang.toLocaleLowerCase()]: true },
    },
  ]
  const query = useEntityRelationReadOnlyQuery<IconItem>(entity, fields, relField, relValue, {
    skip: relValue === undefined,
    single: true,
  })
  if (query.isLoading) return { loading: true }
  const item = query.item || ({} as IconItem)
  return { loading: false, id: item.id, name: item.name && item.name[user.lang], icon: item.icon }
}
