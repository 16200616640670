import { Translations } from '@zeroapi-io/zeroapireact'

export const translations: Translations = {
  welcome: 'Добредојде',
  active: 'Активен',
  group_code: 'Код на група',
  home: 'Дома',
  macedonia: 'Македонија',
  english: 'English',
  macedonian: 'Македонски',
  en: 'Англиски',
  mk: 'Македонски',
  config: 'Подесувања',
  setting: 'Сетинг',
  settings: 'Сетирање',
  staff: 'Персонал',
  items: 'Ставки',
  item: 'Ставка',
  komitents: 'Комитенти',
  komitent: 'Комитент',
  associates_legal: 'Компании',
  associates_persons: 'Приватни Лица',
  info_no_companies:
    'Немате пристап до ниту една оргаинзација.\nКонтактирајте го вашиот администратор за да ви овозможи соодветен пристап.',
  info_no_company: 'Немате пристап до оваа оргаинзација.\nКонтактирајте го вашиот администратор за да ви овозможи соодветен пристап.',
  title_request_reset_pass: 'Барање за обнова на лозинка',
  button_request_reset_pass: 'Испрати Линк',
  label_email_address: 'Имејл Адреса',
  button_sign_in: 'Најави Се',
  button_sign_in_back: 'Се сетивте? Најави Се!',
  button_sign_in_account: 'Веќе имате сметка? Најави Се',
  button_sign_up_back: 'Креирај Сметка',
  button_signup: 'Креирај Сметка',
  label_signup: 'Креирање на сметка',
  label_name: 'Име',
  full_name: 'Целосно Име',
  button_continue_to_login: 'Продолжи на Најава',
  label_password: 'Лозинка',
  title_reset_password: 'Поставување на лозинка',
  button_reset_password: 'Обнови Лозинка',
  button_forgot_password: 'Заборавена Лозинка?',
  title_sign_in: 'Најава',
  reports: 'Извештаи',
  total_reports: 'Вкупен Извештај',
  sake_type_template_reports: 'По Комитенти',
  parameterizing: 'Параметрирање',
  add_new: 'Додади Нов',
  services: 'Услуги',
  employees: 'Вработени',
  actions: 'Акции',
  name: 'Назив',
  city: 'Град',
  county: 'Област',
  country: 'Држава',
  city_country: 'Град (Држава)',
  location_grouping: 'Групирање по локација',
  vat_num: 'Дан. Бр.',
  reg_num: 'ЕМБС',
  address: 'Адреса',
  contact_phone: 'Тел.',
  contact_email: 'е-мејл',
  contact_person: 'Контакт',
  subject: 'Субјект',
  subjects: 'Субјекти',
  parent: 'Главна',
  parent_service_type: 'Над Тип',
  code_name: 'Шифра',
  reg_info: 'Рег. Инфо',
  tree: 'Дрво',
  icon: 'Симбол',
  core: 'Основен',
  service_type: 'Тип',
  type: 'Тип',
  sub_type: 'Под-тип',
  service_option: 'Опција',
  from: 'Од',
  to: 'До',
  cost: 'Влезна',
  sell: 'Продажна',
  price: 'Цена',
  sellPrice: 'Продажна',
  currency: 'Валута',
  eur: 'ЕУР',
  usd: 'УСД',
  mkd: 'МКД',
  min_resv: 'Мин. Рез.',
  gratis_discount: 'Попуст & Гратис',
  customer: 'Корисник',
  amount: 'Износ',
  quantityAmount: 'Количина/Вредност',
  discount: 'Попуст',
  gratis: 'Гратис',
  no_records_display: 'нема записи за прикажување',
  date_range: 'Временски Опсег',
  date_time: 'Датум/Време',
  subject_type: 'Корисник',
  arrangement: 'Аранжман',
  arrangements: 'Аранжмани',
  products: 'Продукти',
  product: 'Продукт',
  prices: 'Цени',
  day_from: 'Прв Ден',
  day_to: 'Пос. Ден',
  local_time_from: 'Време Од',
  local_time_to: 'Време До',
  location: 'Локација',
  grouping: 'Групирање',
  group_size: 'Гол. Група',
  cap_groups: 'Кап. Груп.',
  cap_tourists: 'Кап. Туристи',
  eligible_type: 'Подобност',
  for_each: 'За Секој/и',
  min_quantity: 'Минимална Рез.',
  eligible_value: 'Резервации',
  discount_type: 'Тип Попуст',
  discount_amount: 'Попуст',
  provider: 'Провајдер',
  prices_available: 'цена/и достапни',
  customer_name: 'Корисник',
  activity_date: 'Датум',
  pay_type: 'Плаќање',
  cash: 'SS',
  card: 'Сметка',
  invoice: 'Фактура',
  comment: 'Коментар',
  price_per_guest: 'Цена по гостин',
  total: 'Вкупно',
  customers: 'Корисници',
  int_name: 'Инт. Име',
  title: 'Назив',
  first_name: 'Име',
  last_name: 'Презиме',
  passport: 'Пасош',
  email: 'Имејл',
  phone: 'Телефон',
  birthday: 'Ден. Раѓ.',
  reservations: 'Резервации',
  reserv: 'Резерв.',
  _no_reservations: 'Нема Резервации',
  no_info: 'Нема Информации',
  created_by: 'Креирал',
  updated_by: 'Променил',
  on: 'на',
  service_class: 'Услуга',
  none: 'Нема',
  single: 'Една',
  from_to: 'Од - До',
  capacity: 'Капацитет',
  groups: 'Групи',
  tourists: 'Туристи',
  start_point: 'Појдовна',
  end_point: 'Завршна',
  sale: 'Продажбa',
  sales: 'Продажби',
  new: 'Нова',
  new_m: 'Нов',
  new_f: 'Нова',
  new_s: 'Новo',
  new_sale: 'Нова Продажби',
  provide_services: 'Дава Услуги',
  use_services: 'Користи Услуги',
  day: 'Ден',
  time: 'Време',
  start: 'Почеток',
  end: 'Крај',
  reserve: 'Резервирај',
  group: 'Група',
  import_drop_zone_text: 'Повлечи фајлови за да ги импортираш',
  import_drop_file_text: 'Испушти ги фајловите тука...',
  group_name_pattern: 'Шема',
  min_order: 'Мин. Резервации',
  max_order: 'Мин. Резервации',
  legal_name: 'Легално Име',
  pack: 'Пак',
  packs: 'Пакс',
  _packs: ['Пак', 'Пак', 'Пакс'],
  service: 'Услуга',
  choose_customer: 'Избери Корисници',
  choose_options: 'Избери Опција',
  date_from: 'Датум Од',
  date_to: 'Датум До',
  sale_type: 'Видови Продажба',
  sale_types: 'Продажба',
  due_to_date: 'Доспева',
  bill_by: 'Наплаќа',
  pay_status: 'Платено',
  count: 'Број Корисници',
  guest_count: 'Бр на Гости',
  group_name: 'Име на Група',
  save: 'Сними',
  book: 'Резервирај',
  role: 'Улога',
  role_associate: 'Соработник',
  role_employee: 'Вработен',
  role_manager: 'Менаџер',
  role_owner: 'Сопственик',
  role_none: 'Нема Улога',
  employee: 'Вработен',
  require_provider: 'Провајдер',
  require_provider_mandatory: 'Задолжително',
  require_provider_optional: 'Опционално',
  require_provider_null: 'Нема',
  single_customer: 'Нема бр. на гости',
  accounts: 'Сметки',
  pending: 'За Обрабтока',
  invoice_in: 'Влезни Фактури',
  invoice_out: 'Излезни Фактури',
  ios: 'ИоС',
  invite: 'Покана За Пристап',
  invites: 'Покани За Пристап',
  owner: 'Сопственик',
  role_owners: 'Сопственици',
  role_managers: 'Менаџери',
  role_employees: 'Вработени',
  role_associates: 'Соработници',
  send_invite: 'Нов Вработен',
  created: 'Креирано',
  valid: 'Валидно',
  valid_to: 'Валиден до',
  profile: 'Кориснички Профил',
  logout: 'Одјави Се',
  analyze: 'Анализирај',
  import: 'Импортирај',
  create_sale: 'Креирај Продажба',
  will_create: 'Недостасува / Ќе се креира',
  missing: 'Недостасува',
  mark: 'Ознака',
  service_price: 'Цена на услуга',
  sub_groups_count: 'Под групи',
  customers_count: 'Корисници',
  reservations_count: 'Услуги',
  provider_type: 'Тип провајдер',
  expenseType: 'Тип на трошок',
  total_deal: 'Вкупно/Дог. Цена',
  providerFieldTitle: 'Назив на провајдер',
  profit_loss: 'Ќар',
  invoiced: 'Фактурирано',
  report_total: 'Вкупен Извештај',
  import_services_tooltip:
    'Продажните услуги кои се користат во прикачениот документ и се дел од аранжманот кој ќе го одберете, а ќе се креираат за корисниците од прикачениот документ.',
  import_missing_tooltip:
    'Стварите кои не се дефинирани во системот и ќе недостасувват во импорт`от на прикачениот документ. (како на пр: цена на услуга)',
  import_will_create_tooltip:
    "Стварите кои не се дефинирани во системот, но автоматски ќе се креираат при импорт'от на прикачениот документ. (како на пр: тип на услуга, комитент, локација..)",
}
