import { Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import { Box } from '@mui/system'
import React, { useContext, useEffect, useState } from 'react'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import AppDialog from '../../../../../common/AppDialog'
import Show from '../../../../../common/Show'
import { UserContext } from '../../../../UserApp'
import { SaleCustomer } from '../../Models'
import { useGroupedCustomerServicesBySaleId } from './queries/useCustomerServicesBySaleId'
import useServicePricesByDate, { useGroupServicePricesByProvider, useProviderServiceOptions } from './queries/useServicePricesByDate'
import SaleServiceChooserTable from './SaleServiceChooserTable'
import SaleServiceCustomersTable from './customers/SaleServiceCustomersTable'
import ProviderServiceChooserDialog from './provider/ProviderServiceChooserDialog'
import { SaleService } from '../../Types'
import moment from 'moment'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-grid',
      minWidth: '14ch',
      flexGrow: 1,
    },
    field: {
      minWidth: '14ch',
      paddingBottom: 1,
    },
  }),
)

export interface CustomerService {
  id: string
  customerId: string
  providerId: string
  typeId: string
  servicePriceId?: string
  dateFrom: string
  dateTo: string
  startPointId: string
  endPointId: string
  currency: string
  price: number
  cost: number
  provider: { name: { [key: string]: string } }
}

export interface SaleCustomerServices {
  saleCustomer: SaleCustomer
  services: CustomerService[]
}

export interface SelectedServiceTime {
  dayIndex: number
  startDate: moment.Moment
  endDate: moment.Moment
  providerId?: string
  customers?: SaleCustomerServices[]
}

interface Props {
  saleId?: string
  saleService?: SaleService
  saleCustomers?: SaleCustomer[]
  onClose: (udapted: boolean) => void
}

export default function ServicePriceChooserDialog({ saleId, saleService, saleCustomers, onClose }: Props) {
  const user = useContext(UserContext)

  const [selectedCustomerIds, setSelectedCustomerIds] = useState<string[]>()

  const [showOptionChooser, setShowOptionChooser] = useState<SelectedServiceTime>()

  const { customerServices, refresh: refreshCustomerServices } = useGroupedCustomerServicesBySaleId(saleService?.id)

  const saleCustomerServices = React.useMemo<SaleCustomerServices[]>(
    () =>
      saleCustomers?.map((item) => ({
        saleCustomer: item,
        services: customerServices[item.customer.id] ?? [],
      })) ?? [],
    [saleCustomers, customerServices],
  )

  const selectedCustomers = React.useMemo(() => {
    if (!saleCustomerServices || saleCustomerServices.length === 0 || !selectedCustomerIds || selectedCustomerIds.length === 0)
      return saleCustomerServices ?? []
    return saleCustomerServices.filter((it) => selectedCustomerIds.indexOf(it.saleCustomer.id) > -1)
  }, [saleCustomerServices, saleCustomers, selectedCustomerIds])

  const { servicePrices } = useServicePricesByDate(
    saleService?.classId,
    saleService?.dateFrom,
    saleService?.dateTo,
    saleService?.startPointId,
    saleService?.class?.location === 'FROM_TO' ? saleService?.endPointId : undefined,
  )

  const providerOffers = useGroupServicePricesByProvider(servicePrices)

  const providerOptions = useProviderServiceOptions(saleService, saleCustomerServices, providerOffers)

  if (!saleService) return null

  const saleServiceId = saleService?.id

  const onSelect = (rows: SaleCustomerServices[]) => {
    setSelectedCustomerIds(rows.map((it) => it.saleCustomer.id))
  }

  const show = Boolean(saleId) && Boolean(saleServiceId)

  const serviceClassName = saleService.class.name[user.lang] || user.translate('service')

  const selectedProviders = showOptionChooser
    ? showOptionChooser.providerId
      ? providerOptions.filter((item) => item.providerId === showOptionChooser.providerId)
      : providerOptions.filter((item) => item.options[showOptionChooser.dayIndex] !== undefined)
    : undefined

  return (
    <AppDialog title={serviceClassName} open={show} onClose={() => onClose(true)} background={'#ececf3'} maxWidth="xl" fullWidth>
      <Box>
        <Typography variant="subtitle2" sx={{ pb: 1 }}>
          {user.translate('choose_customer')}:
        </Typography>

        <SaleServiceCustomersTable
          saleId={saleId}
          saleService={saleService}
          servicePrices={servicePrices}
          customers={saleCustomerServices}
          onSelect={onSelect}
          onSelectServiceTime={setShowOptionChooser}
          onUpdate={refreshCustomerServices}
        />

        <br />

        <Show if={selectedCustomers && selectedCustomers.length > 0}>
          <Typography variant="subtitle2" sx={{ pb: 1 }}>
            {user.translate('choose_options')}:
          </Typography>

          <SaleServiceChooserTable
            key={saleService.id}
            saleService={saleService}
            providerOptions={providerOptions}
            customers={selectedCustomers}
            onSelectServiceTime={setShowOptionChooser}
          />
        </Show>
        <Show if={selectedCustomers && selectedCustomers.length === 0}>
          <Typography
            style={{
              lineHeight: '80px',
              height: 80,
              textAlign: 'center',
              border: '2px dashed lightgray',
              color: 'gray',
            }}
          >
            {user.translate('no_sale_customer_selected')}
          </Typography>
        </Show>

        <ProviderServiceChooserDialog
          key={saleService.id + '_' + showOptionChooser?.dayIndex}
          saleService={saleService}
          providers={selectedProviders}
          customers={showOptionChooser?.customers ?? selectedCustomers}
          dayIndex={showOptionChooser?.dayIndex ?? 0}
          startDate={showOptionChooser?.startDate}
          endDate={showOptionChooser?.endDate}
          onClose={setShowOptionChooser}
          onUpdate={refreshCustomerServices}
        />
      </Box>
    </AppDialog>
  )
}
