import { gql, MutationHookOptions, useMutation } from "@apollo/client"
import { MutableRefObject, useCallback, useEffect, useState } from "react"

interface MutationParams {
  gql: string
  data?: { [key: string]: any }
  options?: MutationHookOptions<any, any, any>
  onSuccess: (result: any) => void
  onError: (error: any) => void
}

const RunMutation = (props: MutationParams): null => {
  const [mutation] = useMutation(gql(props.gql), props.options)

  useEffect(() => {
    mutation({ variables: props.data }).then(props.onSuccess).catch(props.onError)
  }, [props.gql, props.data, mutation, props.onSuccess, props.onError])

  return null
}

export type MutateCallback = (gql: string, data?: { [key: string]: any }, options?: MutationHookOptions<any, any, any>) => Promise<any>

interface Props {
  runRef: MutableRefObject<MutateCallback>
}

export default function UseMutation({ runRef }: Props) {
  const [runState, setRunState] = useState<MutationParams>()

  runRef.current = useCallback<MutateCallback>((gql, data, options) => {
    return new Promise<any>((resolve, reject) => {
      setRunState({ gql, data, options, onSuccess: resolve, onError: reject })
    }).finally(() => {
      setRunState(undefined)
    })
  }, [])

  return runState !== undefined ? <RunMutation {...runState} /> : null
}
