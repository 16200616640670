import { Args, GqlQuery } from './interface'

export function cleanJson(obj: any) {
  var cleaned = JSON.stringify(obj, null, 2)

  return cleaned.replace(/^[\t ]*"[^:\n\r]+":/gm, function (match) {
    return match.replace(/"/g, '')
  })
}

export function keyOnlyJson(obj: any) {
  const cleaned = JSON.stringify(obj, null, 2)

  return cleaned.replace(/^[\t ]*"[^:\n\r]+":\s*([^:\n\r]+)/gm, function (match) {
    const keyVal = match.split(':')
    if (keyVal.length > 1 && keyVal[1].trim().indexOf('{') == 0) {
      return match.replace(/"/g, '').replace(/:\s*/, ' ')
    }
    return match.split(':')[0].replace(/"/g, '')
  })
}

export const extractArguments: (args: Args, outArgs: Args) => Args | null = (args: Args | undefined, outArgs: Args) => {
  if (args === null || args === undefined || args.length === 0) return null
  if (args instanceof Array) {
    return args.map((arg) => extractArguments(arg, outArgs))
  }
  if (typeof args !== 'object') {
    return args
  }

  const newArgs: { [key: string]: any } = {}
  for (const key in args) {
    const value = args[key]
    if (key.startsWith('$')) {
      if (value?.alias) {
        outArgs[value.alias] = value.type
        newArgs[key.substring(1)] = `%%${value.alias}%%`
      } else {
        outArgs[key] = value
        newArgs[key.substring(1)] = `%%${key}%%`
      }
    } else if (key.endsWith('$')) {
      newArgs[key.substring(0, key.length - 1)] = `%@${value}@%`
    } else if (key.indexOf('$') > -1) {
      const [prefix, name] = key.split('$')
      newArgs[name] = `%%$${prefix + name}%%`
    } else {
      newArgs[key] = extractArguments(value, outArgs)
    }
  }
  return newArgs
}

export default function generateGql(queries: { [key: string]: GqlQuery }, type: 'query' | 'mutation' = 'query') {
  const builder = []
  const args: Args = {}
  const queriesWithArgs = Object.keys(queries).map((name) => {
    const query = queries[name]
    return {
      name,
      args: extractArguments(query.args, args),
      select: query.select,
      alias: query.alias,
    }
  })

  if (Object.keys(args).length !== 0) {
    builder.push(
      type +
        '(' +
        Object.keys(args)
          .map((arg) => arg + ': ' + args[arg])
          .join(', ') +
        ') {',
    )
  } else {
    builder.push(type + ' {')
  }

  queriesWithArgs.forEach((query) => {
    if (query.args === null) {
      console.warn('query has null args', query.name)
      return
    }

    const queryName = query.alias ? `${query.name}: ${query.alias}` : query.name
    if (Object.keys(query.args).length !== 0) {
      const argv = cleanJson(query.args)
        .replace(/"%%\$(.+?)%%"/gm, '$$$1')
        .replace(/"%@(.+?)@%"/gm, '$1')
      builder.push(`\t${queryName}(${argv.substring(1, argv.length - 2).trim()}) `)
    } else {
      builder.push(`\t${queryName} `)
    }
    if (query.select && Object.keys(query.select).length > 0) {
      builder.push(keyOnlyJson(query.select).trim())
    }
  })

  builder.push('}')

  return builder.join('\n')
}
