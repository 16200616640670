import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button } from '@mui/material'
import { GqlQuery } from '@zeroapi-io/zeroapireact'
import { MutableRefObject, useRef } from 'react'
import { IconNameEntity } from '../../../../hooks/useIconNameEntityItems'
import { EntityIconItem } from '../../../../hooks/useSimpleEntityIconItems'
import { SaleService } from '../../Types'
import { Sale } from '../SaleForm'
import TemplateServiceSale from './TemplateServiceSale'

interface Props {
  sale: Sale
  saleType?: IconNameEntity
  template: EntityIconItem
  saleServicesRef?: MutableRefObject<SaleService[]>
  onClick: (templateId: string, saleServiceId?: string, refreshCallback?: VoidFunction) => void
}

export const TemplateServiceSaleItem: React.FC<Props> = ({ sale, saleType, onClick, template }) => {
  const queryRef = useRef<GqlQuery<any>>()

  return (
    <>
      <Box sx={{ p: 2, textAlign: 'start' }}>
        <Button
          startIcon={<FontAwesomeIcon icon={template.icon as IconName} />}
          color="primary"
          size="small"
          variant="outlined"
          onClick={() => onClick(template.id, undefined, queryRef.current?.refresh)}
        >
          {template.name}
        </Button>
      </Box>
      <Box sx={{ p: 2, pt: 0 }}>
        <TemplateServiceSale
          sale={sale}
          saleType={saleType}
          template={template}
          onItemClick={(item) => onClick(template.id, item.id, queryRef.current?.refresh)}
          queryRef={queryRef}
        />
      </Box>
    </>
  )
}
